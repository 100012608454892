import React from "react"
import {DragDropContext} from "react-beautiful-dnd"
import {useParams} from "react-router-dom"
import {notification} from '../../../components/Lnotification'

import supportedElements from "../../../formComponents/supportedElements"

import API from "../../../api"
import Navbar from "../../../components/Navbar"
import Portrait from "../../../components/Portrait"
import Toolbox from "./FormDefinition.Toolbox"
import Editor from "./FormDefinition.Editor"
import Loading from "../../../components/Loading"
import LivePreview from "./FormDefinition.LivePreview"
import NavigationPrompt from "../../../components/InavigationPrompt";
import {useAppContext} from "../../../context";
import PageNotFound from '../../../components/PageNotFound'

import {elements} from '../../../constants/elements'

const FormDefinition = () => {
    const [state, setState] = React.useState(
        {
            loadingForm: false,
            form: null,
            unsavedChanges: false,
            selectedStep: 0,
            pageNotFound: false
        },
        "form-definition"
    )

    const {
        auth: [
            {
                decodedToken: {
                    userid,
                    scope: [auth_role],
                },
            },
        ],
    } = useAppContext()

    const params = useParams()
    let editorRef = React.useRef()

    React.useEffect(() => {
        loadForm()
    }, [])

    const {form} = state

    const setForm = callback =>
        setState(state => ({
            ...state, form: callback(state.form),
            unsavedChanges: true
        }))

    const setSelectedStep = callback => {
        setState(state => ({
            ...state,
            unsavedChanges: true,
            form: {
                ...state.form,
                steps: state.form.steps.map((s, i) =>
                    state.selectedStep !== i ? s : callback(s)
                )
            }
        }))
    }

    const moveElement = (from, to) => {
        Array.prototype.move = function (from, to) {
            const arrCoppy = [...this]
            arrCoppy.splice(to, 0, arrCoppy.splice(from, 1)[0])
            return arrCoppy
        }

        setForm(form => ({
            ...form,
            steps: form.steps.map((s, i) =>
                i !== state.selectedStep
                    ? s
                    : {
                        ...form.steps[i],
                        elements: form.steps[i].elements.move(from, to),
                    }
            ),
        }))
    }

    const addElementOnPosition = (position, element) => {
        Array.prototype.insertIntoPosition = function (position, element) {
            const arrCoppy = [...this]
            arrCoppy.splice(position, 0, element)
            return arrCoppy
        }

        setState(state => {
            setSelectedStep(step => ({
                ...step,
                elements: state.form.steps[state.selectedStep].elements.insertIntoPosition(
                    position,
                    {
                        ...element,
                        code: Date.now().toString()
                    }
                ),
            }))
            return {...state}
        })

    }

    const changeStep = selectedStep => {
        setState(state => ({...state, selectedStep}))
    }


    const addScreen = () => {
        if (
            form.steps.reduce(
                (acc, step) =>
                    step.elements.reduce(
                        (acc, {editorMode}) => editorMode === "EDIT" || acc,
                        false
                    ) || acc,
                false
            )
        ) {
            notification.warning({message: `Please save the component you are editing`})
            throw Error("cant_add_screen_while_editing_component")
        } else {
            addNewScreenOnPosition(state.selectedStep + 1)
            changeStep(state.selectedStep + 1)
        }
    }

    const addNewScreenOnPosition = position => {
        Array.prototype.insertIntoPosition = function (position, element) {
            const arrCoppy = [...this]
            arrCoppy.splice(position, 0, element)
            return arrCoppy
        }

        setForm(form => ({
            ...form,
            steps: form.steps.insertIntoPosition(position, {
                elements: [],
                heading: "",
            }),
        }))
    }

    const loadForm = async () => {
        try {
            setState(state => ({...state, loadingForm: true}))
            const {
                data: {
                    form: {logo, ...formRest},
                },
            } = await API.get(`forms/${params.id}`)

            if (auth_role === "agent" || (auth_role === "manager" && userid !== formRest._owner)) {
                setState(state => ({
                    ...state,
                    pageNotFound: true
                }))
            }

            setState(state => ({
                ...state,
                loadingForm: false,
                form: {
                    ...formRest,
                    steps: (formRest.steps && formRest.steps.length > 0) ? [...formRest.steps] : [
                        {
                            elements: [],
                            heading: ""
                        }
                    ]
                },
            }))
        } catch (err) {
            setState(state => ({
                ...state,
                loadingForm: false,
                ...(err.response.status === 404 ? {pageNotFound: true} : {})
            }))
        }
    }

    const onDragEnd = result => {
        if (!result.destination) return

        // reorder
        if (result.source.droppableId === result.destination.droppableId) {
            if (result.destination.index === result.source.index) return
            moveElement(result.source.index, result.destination.index)
        }

        // add element on specific position

        // check origin
        if (
            result.source.droppableId !== "GENERAL_COMPONENT" &&
            result.source.droppableId !== "ADVANCED_COMPONENT" &&
            result.source.droppableId !== "AUTOMOTIVE_COMPONENT" &&
            result.source.droppableId !== "FINANCIAL_COMPONENT"
        ) return


        // vehicle component has to be alone in slide
        if (
            form.steps[state.selectedStep].elements.find(
                element => element.type === elements.VEHICLE_EVALUATION
            )
        ) {
            notification
                .error({
                    message: "This slide can handle only Raport Evaluare Achizitie Autovehicul component. Please add your components in another slide.",
                    duration: 3000
                })
            return
        }

        let newElement = {
            ...supportedElements[
                Object.keys(supportedElements)[result.source.index]
            ].default()
        }

        if (
            newElement.type === elements.VEHICLE_EVALUATION &&
            form.steps[state.selectedStep].elements.length !== 0
        ) {
            try {
                addScreen()
            } catch(err) {
                return
            }
        }

        addElementOnPosition(result.destination.index, newElement)
    }

    const addElement = element => {
        if (form.steps[state.selectedStep].elements.find(element => element.type === elements.VEHICLE_EVALUATION)) {
            notification
                .error({
                    message: "This slide can handle only Raport Evaluare Achizitie Autovehicul component. Please add your components in another slide.",
                    duration: 3000
                })
            return
        }

        if(
            element.type === elements.VEHICLE_EVALUATION &&
            form.steps[state.selectedStep].elements.length !== 0
        ) {
            try {
                addScreen()
            } catch(err) {
                return
            }
        }

        addElementOnPosition(form.steps[state.selectedStep].elements.length, element)

        editorRef.scrollIntoView({block: "end", behavior: "smooth"})
    }

    return (
        <>
            <Navbar/>
            {state.loadingForm || (state.savingFormDefinition && <Loading/>)}
            <PageNotFound active={!!state.pageNotFound}>

                <div className="wrapper new-form-page">
                    {!state.loadingForm && !state.savingFormDefinition && state.form && (
                        <>
                            <div className="content">
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Toolbox addElement={addElement}/>
                                    <Editor
                                        state={state}
                                        setState={setState}
                                        setForm={setForm}
                                        ref={el => (editorRef = el)}
                                        changeStep={changeStep}
                                        addElementOnPosition={addElementOnPosition}
                                        addScreen={addScreen}
                                        addElement={addElement}
                                    />
                                </DragDropContext>
                            </div>

                            <div className="sidebar sidebar-preview-slider">
                                <div className="sidebar-title">
                                    <h3 className="sidebar-title sidebar-title-fixed">Preview</h3>
                                </div>
                                {state.loadingForm && <Loading/>}
                                {!state.loadingForm && form && (
                                    <LivePreview form={form} changeStep={changeStep} state={state}/>
                                )}
                            </div>
                        </>
                    )}

                    <NavigationPrompt
                        when={state.unsavedChanges}
                        promptCallback={({onConfirm, onCancel}) => notification.warning({
                            message:
                                <>
                                    <p>Are you sure you want to leave the page? You have unsaved changes.</p>
                                    <span className="button button-outline" onClick={() => {
                                        onConfirm()
                                        notification.cancel()
                                    }}>Leave</span>
                                    <span className="tbl-btn close-subheader" onClick={() => {
                                        onCancel()
                                        notification.cancel()
                                    }}>x</span>
                                </>, duration: 0
                        })}
                    />
                        
                </div>

            </PageNotFound>
            <Portrait/>
        </>
    )
}

export default FormDefinition
