import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({ 
    element
}) => {
    return(
        <div className="fieldset-row">
            <p>{element.text}</p>
            <div className="mco-box">
                <div className="mco-cell view-mco-cell-hidden">
                    <div className="mco-cell-header">
                        <CodeCopy text={`${element.code}_with_post_data`}>
                            <div className="form-row-radio">
                                <input type="radio" name="" checked={true} onChange={f=>f} />
                                <label>By mail</label>
                            </div>
                        </CodeCopy>
                    </div>
                    <fieldset className="mco-cell-hidden form-grid">
                        <div className="form-grid-row">
                            <div className="form-row">
                                <label>Name</label>
                                <CodeCopy text={`${element.code}_first_name`}>
                                    <input type="text" name="" placeholder="" />
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>Surname</label>
                                <CodeCopy text={`${element.code}_last_name`}>
                                    <input type="text" name="" placeholder="" />
                                </CodeCopy>
                            </div>
                        </div>
                        <div className="form-grid-row">
                            <div className="form-row">
                                <label>County</label>
                                <CodeCopy text={`${element.code}_county`}>
                                    <select />
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>City</label>
                                <CodeCopy text={`${element.code}_city`}>
                                    <select />
                                </CodeCopy>
                            </div>
                        </div>
                        <div className="form-grid-row street-grid">
                            <div className="form-row">
                                <label>Street</label>
                                <CodeCopy text={`${element.code}_street`}>
                                    <input type="text" name="" placeholder="" />
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>No</label>
                                <CodeCopy text={`${element.code}_nr`}>
                                    <input type="text" name="" placeholder="" />
                                </CodeCopy>
                            </div>
                        </div>
                        <div className="form-grid-row address-grid">
                            <div className="form-row">
                                <label>Building</label>
                                <CodeCopy text={`${element.code}_building`}>
                                    <input type="text" name="" placeholder="" />
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>Entrance</label>
                                <CodeCopy text={`${element.code}_entry`}>
                                    <input type="text" name="" placeholder="" />
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>Floor</label>
                                <CodeCopy text={`${element.code}_floor`}>
                                    <input type="text" name="" placeholder="" />
                                </CodeCopy>
                            </div>
                            <div className="form-row">
                                <label>Ap</label>
                                <CodeCopy text={`${element.code}_apartment`}>
                                    <input type="text" name="" placeholder="" />
                                </CodeCopy>
                            </div>
                        </div>
                        <div className="form-grid-row address-grid">
                            <div className="form-row">
                                <label>Postal code</label>
                                <CodeCopy text={`${element.code}_postal_code`}>
                                    <input type="text" name="" placeholder="" />
                                </CodeCopy>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="mco-cell view-mco-cell-hidden">
                    <div className="mco-cell-header">
                        <div className="form-row-radio">
                                <input type="radio" checked onChange={f=>f} />
                            <label>By email</label>
                        </div>
                        <div className="mco-cell-hidden">
                            <CodeCopy text={`${element.code}_email`}>
                                <input type="text" placeholder={element.emailPlaceholder} />
                            </CodeCopy>
                        </div>
                    </div>
                </div>
                <div className="mco-cell view-mco-cell-hidden">
                    <div className="mco-cell-header">
                        <div className="form-row-radio">
                            <input type="radio" checked onChange={f=>f} />
                            <label >By phone</label>
                        </div>
                        <div className="mco-cell-hidden">
                            <CodeCopy text={`${element.code}_phone_number`}>
                                <input type="text" placeholder={element.phoneNumberPlaceholderCall} />
                            </CodeCopy>
                        </div>
                    </div>
                </div>
                <div className="mco-cell last view-mco-cell-hidden">
                    <div className="mco-cell-header">
                        <div className="form-row-radio">
                            <input type="radio" checked onChange={f=>f} />
                            <label >By text</label>
                        </div>
                        <div className="mco-cell-hidden">
                            <CodeCopy text={`${element.code}_sms_number`}>
                                <input type="text" placeholder={element.phoneNumberPlaceholderSMS} />
                            </CodeCopy>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}