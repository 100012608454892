import styled from 'styled-components';

export const ListItem = styled.li`
  :before {
     width:.4rem;
     height:100%;
     position:absolute;
     top:0;
     left:0;
     content:'';
     background-color: ${props => props.color};
  }
  
  strong {
    color: ${props => props.color};
  }
`;