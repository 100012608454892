import React from 'react'

import LivePreview from './LivePreview.image'
import {ImageEditor} from './Editor.image'
import CodeCopy from './CodeCopy.image'
import View from './View.image'
import Input from './Input.image'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <ImageEditor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,

    default: () => ({
        type: "image",
        text: "Image Caption",
        pinned: {
            status: false
        },
        image: null,
        validations: []
    }),

    validate: () => false,
    group: "GENERAL",
    icon: "icon-ia-photo",
    name: "Image",
    view: args => <View {...args}/>
}