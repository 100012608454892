import React from 'react';

import {synchronizeText} from "../../utils/synchronizeText";

const CheckboxGroup = ({
    element,
    answers_by_code,
    value = []
}) => {

    const isChecked = (item) => {
        return value.includes(item);
    };

    return(
        <div className="fieldset-row">
            {element.text &&
                <label>{synchronizeText(element.text, answers_by_code)}</label>
            }

            <div className="session-form-grid" style={{ gridTemplateColumns: `repeat(${element.grid_columns || 1}, 1fr`}}>
                {element.items.map(item => (
                    <div key={item} className="session-form-row-checkbox">
                        <input type="checkbox" checked={isChecked(item)} onChange={f=>f}/>
                        <label>{item}</label>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default CheckboxGroup;