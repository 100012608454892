import React from "react"
import Swiper from "react-id-swiper"
import styled, { ThemeProvider, css } from "styled-components"
import Frame from "../../../components/Frame"
import supportedElements from "../../../formComponents/supportedElements"
import LoadAfter from "../../../components/LoadAfter"
import Loading from "../../../components/Loading"
import look from '../livePreview.look'

const LivePreview = ({ state, changeStep }) => {
	const { form } = state

	const [gallerySwiper, getGallerySwiper] = React.useState(null)
	const [thumbnailSwiper, getThumbnailSwiper] = React.useState(null)

	const gallerySwiperParams = {
		containerClass: "pts-top",
		spaceBetween: 0,
		navigation: {
			nextEl: ".swiper-button-next.icon-ia-arrow-right",
			prevEl: ".swiper-button-prev.icon-ia-arrow-left",
		},
		getSwiper: getGallerySwiper,
	}

	const thumbnailSwiperParams = {
		getSwiper: getThumbnailSwiper,
		centeredSlides: true,
		touchRatio: 0.2,
		slideToClickedSlide: true,
		containerClass: "pts-thumbs",
		freeMode: true,
		spaceBetween: 5,
		slidesPerView: 3,
	}

	React.useEffect(() => {
		if (
			gallerySwiper !== null &&
			gallerySwiper.controller &&
			thumbnailSwiper !== null &&
			thumbnailSwiper.controller
		) {
			gallerySwiper.on("slideChange", event => {
				if (thumbnailSwiper.activeIndex !== gallerySwiper.activeIndex) {
					thumbnailSwiper.slideTo(gallerySwiper.activeIndex)
				}
				changeStep(gallerySwiper.activeIndex)
			})

			thumbnailSwiper.on("slideChange", event => {
				if (gallerySwiper.activeIndex !== thumbnailSwiper.activeIndex) {
					gallerySwiper.slideTo(thumbnailSwiper.activeIndex)
				}
				changeStep(thumbnailSwiper.activeIndex)
			})
		}
	}, [gallerySwiper, thumbnailSwiper])

	React.useEffect(() => {
		if (gallerySwiper && thumbnailSwiper) {
			gallerySwiper.update()
			gallerySwiper.slideTo(state.selectedStep)
			thumbnailSwiper.update()
			thumbnailSwiper.slideTo(state.selectedStep)
		}
	}, [form.steps])

	return (
		<ThemeProvider
			theme={{
				mode: "dark",
		

				backgroundColor: form.background_color,
				fontColor: form.font_color,
				fontFace: form.font_face,

				defaultLook: {
					fontColor: "#5D2560",
					backgroundColor: "#F1F1F1",
					fontFace: "Open Sans",
				},
			}}
		>
			<>
				<Swiper {...gallerySwiperParams}>
					{form.steps.map((step, stepIndex) => {
						let formHasVehicleEvaluation = step.elements.find(
							element => element.type === "vehicle evaluation"
						)
						return (
							<div key={stepIndex}>
								<div className="tablet-preview">
									<img src="img/tablet.svg" />
									<Frame
										head={
											<>
												<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
												<meta
													name="viewport"
													content="user-scalable=no, initial-scale=1.0, maximum-scale=1.0, width=device-width"
												/>
												<meta name="robots" content="index, follow" />
												<meta name="language" content="ro" />
												<meta name="apple-mobile-web-app-capable" content="yes" />
												<meta name="mobile-web-app-capable" content="yes" />
												<base href="/" />
												<title>IA</title>
											</>
										}
									>
										<LoadAfter
											links={[
												'https://cdnjs.cloudflare.com/ajax/libs/lato-font/3.0.0/css/lato-font.min.css',
												"https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i",
												"AlchemistAdmin/client/icons.css",
												"AlchemistAdmin/client/swiper.min.css",
												"AlchemistAdmin/client/global.css",
												"AlchemistAdmin/client/preview.css",
											]}
											loader={<Loading />}
										>
											<div className="wrapper" css={look}>
												<header className="header">
													<div className="logo">
														<img src="../img/Alchemist_Identity_Final-07.png" />
													</div>
													<div />
													<div className="menu-right">
														<div className="user-img" />
														<div className="logout" />
													</div>
												</header>

												<div className="wrapper-content" style={{padding: "3rem 0 5rem"}}>
													<div className="table">
														<div className="table-cell">
															<div
																style={
																	formHasVehicleEvaluation
																		? { background: "unset", padding: "0" }
																		: {}
																}
																className={`content`}
															>
																<form className={`form`}
																	style={formHasVehicleEvaluation ? {gridGap: "unset"} : {}}>
																	<h2>{form.steps[stepIndex].heading}</h2>
																	{form.steps[stepIndex].elements.map((element, index) => {
																		if (supportedElements[element.type]) {
																			return (
																				<React.Fragment key={index}>
																					{supportedElements[element.type].livePreview({ element })}
																				</React.Fragment>
																			)
																		}
																		return null
																	})}
																</form>
															</div>
														</div>
													</div>
												</div>

												<footer className="footer">
													<button className="footer-btn prev-btn">
														<i className="icon-ia-arrow-left" />
														<span>Prev</span>
													</button>
													<button className="footer-btn clear-btn">
														<i className="icon-refresh-cw"></i>
														<span>Clear</span>
													</button>
													<button className="footer-btn end-btn">
														<i className="icon-upload"></i>
														<span>End Session</span>
													</button>
													<button className="footer-btn next-btn">
														<i className="icon-ia-arrow-right"></i>
														<span>
															{form.steps.length !== stepIndex + 1 ? "Next" : "Finish"}
														</span>
													</button>
													<div className="footer-pagination">
														<strong>{stepIndex + 1}</strong>/ {form.steps.length} Steps
													</div>
												</footer>
											</div>
										</LoadAfter>
									</Frame>
								</div>
							</div>
						)
					})}
				</Swiper>

				<Swiper {...thumbnailSwiperParams}>
					{form.steps.map((step, stepIndex) => (
						<div key={stepIndex}>
							<div
								css={`
									&&& {
										opacity: ${state.selectedStep === stepIndex ? "1" : ".5"};
									}
								`}
								className="tablet-thumb"
							>
								<span>step {stepIndex + 1}</span>
								<strong>{step.heading}</strong>
							</div>
						</div>
					))}
				</Swiper>
			</>
		</ThemeProvider>
	)
}

export default LivePreview