import React, {useState, useEffect} from 'react';
import AnimatedNumber from 'react-animated-number';

import API from '../../api'
import {getError} from "../../utils/getError"
import {useAppContext} from '../../context'
import Documents from './Tabs.Documents'
import Clients from './Tabs.Clients'
import Agents from './Tabs.Agents'

const Counter = ({children}) => (
    <AnimatedNumber
        component="div"
        value={children}
        style={{
            transition: '0.8s ease-out',
            transitionProperty:
                'background-color, color, opacity'
        }}
        formatValue={n => parseInt(n)}
        duration={700}
    />
);

const Tabs = ({dashboardStore}) => {

    const {auth: [auth, setAuth]} = useAppContext()

    const [state, setState] = React.useState({
        loading: false,
        error: null,
        counters: {
            agents: 0,
            sessions: 0,
            clients: 0
        }
    })

    const [tab, setTab] = useState('documents')
    const auth_role = auth.decodedToken.scope[0]

    const [dashboardState, setDashboardState] = dashboardStore

    useEffect(() => {
        getDashboardCounters()
    }, [])

    const getDashboardCounters = async () => {
        try {
            setState(state => ({...state, loading: true, error: null}))
            const response = await API.get('/reports/dashboard-counters')
            setState(state => ({...state, loading: false, counters: response.data}))
            setDashboardState(state => ({...state, loadedTabsStatsOnce: true}))
        } catch (err) {
            setState(state => ({...state, loading: false, error: getError(err.response)}))
        }
    }

    return (
        <>
            <div className="content">
                <div className="content-box content-box-with-tabs">

                    <div className="content-box-header">
                        <h3>Overview</h3>
                    </div>

                    <div
                        className="content-box-body content-box-body-with-tabs content-box-body-fixed-elements content-box-body-fixed-actions">

                        <div className="content-box-body-slider">
                            <div className="swiper-wrapper">

                                {tab === 'documents' && <Documents dashboardStore={dashboardStore}/>}

                                {/* {tab === 'clients' && <Clients />} */}

                                {tab === 'agents' && auth_role !== 'agent' && <Agents/>}

                            </div>
                        </div>

                        <div className="tabs sp-tabs">
                            <span
                                className={`tab-3 ${tab === 'documents' ? 'selected' : ''}`}
                                onClick={() => setTab('documents')}
                            >
                                <i className="tab-icon icon-ia-menu-responses"/>
                                <strong>Sessions <u><Counter>{state.counters.sessions}</Counter></u></strong>
                                <data className="tab-graph">
                                    <img src="img/svg/tab-graph.svg"/>
                                </data>
                            </span>
                            {auth_role !== 'agent' &&
                            <span
                                className={`tab-1 ${(tab === 'agents') ? 'selected' : ''}`}
                                onClick={() => setTab('agents')}
                            >
                                    <i className="tab-icon icon-ia-agents"/>
                                    <strong>Users <u><Counter>{state.counters.agents}</Counter></u></strong>
                                    <data className="tab-graph">
                                        <img src="img/svg/tab-graph-down.svg"/>
                                    </data>
                                </span>
                            }
                            {/* <span
                                className={`tab-2 ${(tab === 'clients') ? 'selected' : ''}`}
                                onClick={() => setTab('clients')}
                            >
                                <i className="tab-icon icon-ia-clients"/>
                                <strong>Clients <u><Counter>{state.counters.clients}</Counter></u></strong>
                                <data className="tab-graph">
                                    <img src="img/svg/tab-graph.svg"/>
                                </data>
                            </span> */}
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default Tabs