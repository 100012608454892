import React from 'react'
import styled from 'styled-components'
import CopyToClipboard from "react-copy-to-clipboard"
import {notification} from "../components/Lnotification"

export default ({ text, children }) => {
    const code = `{${text}}`
    return(
        <CopyToClipboard text={code}>
            <ElementWrapper>
                <Overlay onClick={() => notification.success({message: `${code} Copied`, duration: 5000})}/>
                {children}
            </ElementWrapper>
        </CopyToClipboard>
    )
}

const ElementWrapper = styled.div`
    position: relative;
`

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    :hover {
        background: yellow;
        opacity: 0.3;
        border: 1px dashed black;
    }
`
