import React from 'react'
import {notification} from "../components/Lnotification"

export default ({ children, code}) => {
    let delimitedCode = `{{${code}}}`
    return(
        <>
            {React.cloneElement(
                children, 
                { 
                    children: code, 
                    onClick: e => {
                        e.stopPropagation()
                        navigator.clipboard.writeText(delimitedCode)
                        notification.success({message: `${delimitedCode} Copied`, duration: 5000});
                    },
                    style: { cursor: "copy" }
                } 
            )}
        </>
    )
}