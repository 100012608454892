import React from 'react'
import AdeleteConfirm from '../../components/AdeleteConfirm';
import supportedElements from '../supportedElements'
import Validations from '../Validations'
import CodeCopy from '../../components/Editor.codeCopy'
import styled from 'styled-components';

import {TopBarContainer} from "../comonComponents/ElementEditor.TopBarContainer";
import {AdvancedValidationsButton} from "../comonComponents/ElementEditor.AdvancedValidationButton";
import {RequiredValidationToggle} from "../comonComponents/ElementEditor.RequiredValidationToggle";
import {VALIDATION} from "../../constants/validations";

export const ItemsSelectListWithImagesEditor = ({
    element,
    remove,
    editElement,
    changeElementEditorMode,
    dragHandleProps = {},
    form,
    isApprovalForm,
    VisibilityAffected,
    formElements,
    addElementOnPosition,
    index
}) => {

    const [state, setState] = React.useState({
        localElement: {
            ...element
        },
        errors: {}
    })

    const localElement = state.localElement

    const setLocalElement = callback => setState(state => ({
        ...state,
        localElement: {...callback(state.localElement)}
    }))

    const removeOffer = index => {
        setLocalElement(localElement => (
            {
                ...localElement, offers: localElement.offers.filter((_, i) => i !== index)
            }
        ))
    };

    const addOffer = () => {
        const newOffer = {
            image: null,
            title: "Item Title",
            description: "Item Description"
        };

        setLocalElement(localElement => (
            {
                ...localElement, offers: [...localElement.offers, newOffer]
            }
        ))
    };

    const onOfferImageChange = (offerIndex, e) => {
        if (e.target.files.length === 0) return;
        let reader = new FileReader();
        reader.addEventListener('load', () => {
            setLocalElement(localElement => (
                {
                    ...localElement,
                    offers: localElement.offers.map((offer, index) => index !== offerIndex
                        ? offer
                        : ({...offer, image: reader.result})
                    )
                }
            ))
        });
        reader.readAsDataURL(e.target.files[0])
    };

    const onOfferTitleChange = (offerIndex, {target: {value}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                offers: localElement.offers.map((offer, index) => index !== offerIndex
                    ? offer
                    : ({...offer, title: value})
                )
            }
        ))
    };

    const onOfferDescriptionChange = (offerIndex, {target: {value}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                offers: localElement.offers.map((offer, index) => index !== offerIndex
                    ? offer
                    : ({...offer, description: value})
                )
            }
        ))
    };

    const onEditTitle = ({target: {value}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                title: value
            }
        ))
    };

    const onEditText = ({target: {value}}) => {
        setLocalElement(localElement => (
            {
                ...localElement, text: value
            }
        ))
    };

    const onPinnedChange = ({target: {checked}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                pinned: {
                    status: checked
                }
            }
        ))
    };

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onValidationsClick = () => {
        changeElementEditorMode("VALIDATIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        const errors = {};
        if (localElement.text.length > 600) {
            errors.text = "Maximum 600 characters allowed"
        }
        if (localElement.title.length > 60) {
            errors.title = "Maximum 60 characters allowed"
        }
        setState(state => ({
            ...state,
            errors
        }))
        if (Object.keys(errors).length === 0) {
            editElement({
                ...state.localElement
            })
            changeElementEditorMode("DEFAULT")
        }
    };

    const onAllowMultipleSelectionChange = ({target: {checked: allowMultipleSelections}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                allowMultipleSelections
            }
        ))
    }

    const duplicateComponent = () => {
        addElementOnPosition(index + 1, {
            ...element,
            editorMode: 'DEFAULT'
        })
    }

    const setValidationsLocal = newValidations => {
        setLocalElement(localElement => (
            {
                ...localElement,
                validations: newValidations
            }
        ))
    };

    const setValidationRequired = required => {
        const validations = required
            ? [...localElement.validations, {validation: VALIDATION.REQUIRED}]
            : localElement.validations.filter((validation) => validation.validation !== VALIDATION.REQUIRED)

                if(element.editorMode !== "VALIDATIONS") {
            editElement({...element, validations})
        }
        setValidationsLocal(validations)
    }

    const openEditMode = () => {
        changeElementEditorMode("EDIT")
    };

    return (
        <div
            className={`form-box-wrap offers-box ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center">
                <div className="form-box-wrap-center-content" style={{cursor: "pointer"}} onClick={openEditMode}>
                    <TopBarContainer>
                        <RequiredValidationToggle
                                                        validationRequired={
                                !!(element.editorMode === "VALIDATIONS"
                                    ? localElement
                                    : element
                                ).validations.find(v => v.validation === VALIDATION.REQUIRED)
                            }
                            setValidationRequired={setValidationRequired}
                        />
                        <VisibilityAffected element={element} formElements={formElements}/>
                    </TopBarContainer>
                    <h6 className="supTitle">Items List</h6>
                    <p className="form-box-wrap-descrition">{localElement.title}</p>
                    <p className="form-box-wrap-descrition">{localElement.text}</p>
                    <div className="form-grid" style={{gridTemplateColumns: "repeat(2, 1fr)"}}>
                        {localElement.offers.map((offer, offerIndex) => (
                            <div className="offer-row" key={offerIndex}>
                                <div className="upload-image-grid">
                                    <div className="upload-image-box"
                                         style={offer.image ? {backgroundImage: "none"} : {}}><img src={offer.image}
                                                                                                   alt=""/></div>
                                    <p>{offer.title}</p>
                                    <p>{offer.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <p className="form-row-validation-text">{localElement.validations.map(v => `[ ${v.validation} ]  `)}{localElement.pinned && localElement.pinned.status ? "[ show on top ]" : ""}</p>
                    <CodeCopy code={localElement.code}>
                        <span className="form-box-wrap-id"/>
                    </CodeCopy>
                </div>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                    <span onClick={onValidationsClick}><i
                        className="icon-check trigger-validation-box"/>Validation</span>
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <AdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </AdeleteConfirm>
                        <span onClick={duplicateComponent}><i className="icon-copy"/>Duplicate</span>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className="form-row-checkbox">
                    <input
                        id={`multiple-selection-${localElement.code}`}
                        type="checkbox"
                        checked={localElement.allowMultipleSelections}
                        onChange={onAllowMultipleSelectionChange}
                    />
                    <label htmlFor={`multiple-selection-${localElement.code}`}>Multiple selection</label>
                </div>
                <div className={`form-row ${state.errors.title ? "error" : ""}`}>
                    <input type="text" onChange={onEditTitle} placeholder="Items Select List With Images Title"
                           value={localElement.title}/>
                    {state.errors.title &&
                    <span className="form-row-error-msg">{state.errors.title}</span>
                    }
                </div>
                <div className={`form-row ${state.errors.text ? "error" : ""}`}>
                    <input type="text" onChange={onEditText} placeholder="Placeholder Title Description" value={localElement.text}/>
                    {state.errors.text &&
                    <span className="form-row-error-msg">{state.errors.text}</span>
                    }
                </div>
                {localElement.offers.map((offer, offerIndex) => (
                    <EditOfferRow className="edit-offer-row" key={offerIndex}>
                        <div className="upload-image-grid">
                            <div className={`upload-image-box ${(offer.image ? 'with-image' : '')}`}>
                                <img src={offer.image}/>
                                <input
                                    className="upload-image-input"
                                    type="file"
                                    accept="image/*"
                                    onChange={e => onOfferImageChange(offerIndex, e)}
                                />
                            </div>
                            <div className="form-row">
                                <input type="text" value={offer.title} placeholder="Item Title"
                                       onChange={e => onOfferTitleChange(offerIndex, e)}/>
                            </div>
                            <div className="form-row">
                                <input type="text" value={offer.description} placeholder="Item Description"
                                       onChange={e => onOfferDescriptionChange(offerIndex, e)}/>
                            </div>
                        </div>
                        <AdeleteConfirm
                            title="Are you sure you want to delete the offer? All data will be lost."
                            onConfirm={() => removeOffer(offerIndex)}
                            okText="Delete"
                            cancelText="Cancel"
                        >
                            <div className="tbl-btn">
                                <i className="delete-offer-row icon-ia-trash-bold"/>
                            </div>
                        </AdeleteConfirm>
                    </EditOfferRow>
                ))}
                {localElement.offers.length < 30 &&
                <span className="add-block-input mar2b" onClick={addOffer}>Add Item</span>
                }
                {!isApprovalForm &&
                    <div className="frc-box">
                        <div className="form-row-checkbox">
                            <input id={`pinned-${localElement.code}`} type="checkbox"
                                checked={localElement.pinned && localElement.pinned.status} onChange={onPinnedChange}/>
                            <label htmlFor={`pinned-${localElement.code}`}>Show on top in saved session</label>
                        </div>
                    </div>
                }
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
            <div className="form-box-wrap-center-validation mco-box">
                <div className="mco-cell"><h4>Validations</h4></div>
                <Validations
                    availableValidations={supportedElements[localElement.type].availableValidations}
                    onChange={setValidationsLocal}
                    value={localElement.validations}
                    form={form}
                />
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}

const EditOfferRow = styled.div`
.upload-image-box.with-image::before, .upload-image-box.with-image::after {
    content: unset
    }
`;