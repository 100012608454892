import React, {useEffect} from 'react'
import {notification} from '../../components/Lnotification'
import Navbar from '../../components/Navbar'
import Portrait from "../../components/Portrait"
import Loading from "../../components/Loading"
import API from '../../api'

import ProfileChangePicture from "./Profile.ChangePicture";
import ChangePassword from "./Profile.ChangePassword";
import ChangeSignature from "./Profile.ChangeSignature";
import ChangeDetails from './Profile.ChangeDetails'

export default () => {

    const [state, setState] = React.useState({
        loading: false,
        role: null,
        name: '',
        phone: '',
        signature: null,
        lastLogin: null,
        email: '',
        image: null
    });
    const [tab, setTab] = React.useState(null);
    const toggleTab = t => setTab(tab === t ? null : t);

    useEffect(() => {
        getProfileInformation()
    }, []);


    const getProfileInformation = async () => {
        try {
            setState(state => ({...state, loading: true}));
            const {data} = await API.get('/users/personal/profile');
            setState(state => ({
                ...state,
                loading: false,
                role: data.scopes.join(''),
                name: data.name,
                phone: data.phone,
                signature: data.signature,
                lastLogin: data.lastLogin,
                email: data.email,
                image: data.image
            }))
        } catch (err) {
            setState(state => ({...state, loading: false}));
            notification.error({
                message: 'Something went wrong!'
            })
        }
    };

    return (
        <>
            <Navbar/>
            {state.loading && <Loading/>}
            {!state.loading &&
            <div
                className={`wrapper profile-page ${tab === 'password' ? 'view-change-password' : ''} ${tab === 'email' ? 'view-edit-details' : ''} ${tab === 'edit-signature' ? 'view-edit-signature' : ''}`}>


                <div className="content">

                    <div className="content-box form agent-form">
                        <div className="scrollbar">

                            <div className="profile-box">
                                {state.name &&
                                <ProfileChangePicture profileStore={[state, setState]}/>
                                }
                                <div className="profile-action">
                                    <span onClick={() => toggleTab('email')}
                                          className="button button-outline with-icon-right trigger-edit-details"><i
                                        className="icon-ia-edit-bold"/>Edit details</span>
                                    <span onClick={() => toggleTab('password')}
                                          className="button button-outline with-icon-right trigger-change-password"><i
                                        className="icon-lock"/>Change password</span>
                                    <span onClick={() => toggleTab('edit-signature')}
                                          className="button button-outline with-icon-right trigger-edit-signature"><i
                                        className="icon-ia-edit-bold"/>Edit signature</span>
                                </div>
                            </div>

                            <ChangePassword toggleTab={toggleTab}/>
                            {state.name &&
                            <ChangeDetails profileStore={[state, setState]} toggleTab={toggleTab}/>
                            }
                            <ChangeSignature toggleTab={toggleTab}/>
                        </div>
                    </div>
                </div>
            </div>
            }
            <Portrait/>
        </>
    );
}