import React from 'react'
import moment from 'moment'
import {useHistory} from 'react-router-dom'

import API from '../../api'
import {getError} from "../../utils/getError"
import Loading from '../../components/Loading'
import styled from 'styled-components'

const Documents = ({dashboardStore:[,setDashboardState]}) => {
    const [state, setState] = React.useState({
        loading: false,
        error: null,
        data: []
    }, "dashboard sessions");

    const history = useHistory();

    React.useEffect(() => {
        loadDocuments()
    }, []);

    const loadDocuments = async () => {
        try {
            setState(state => ({...state, loading: true, error: null}));
            const {data} = await API.get('/sessions');
            setState(state => ({...state, loading: false, data: data.data}))
            setDashboardState(state => ({...state, loadedSelectedTabOnce: true}))
        } catch(err) {
            setState(state => ({...state, loading: false, error: getError(err.response)}))
        }
    };

    return (
        <>
            {state.loading && <Loading />}
            {!state.loading && 
                <DashboardSessionsStyled className="swiper-slide">
                    <h3 className="box-title box-title-fixed">Documents Stream</h3>
                    <div className="box-body box-body-fixed">
                        <div className="scrollbar">
                            <ul className="table-ul docs-list">
    
                                <li className="table-ul-header">
                                    <ul>
                                        <li><strong>Category</strong></li>
                                        <li><strong>Form name</strong></li>
                                        <li><strong>User name</strong></li>
                                        {/* <li><strong>Client</strong></li> */}
                                        <li className="with-cntrl-box">
                                            <strong>Date</strong>
                                            <span className="cntrl-box"><i className="cntrl-down icon-arrow-down"/><i className="cntrl-up icon-arrow-up"/></span>
                                        </li>
                                    </ul>
                                </li>
    
                                {state.data.map(session => (
                                    <li key={session._id} style={{borderLeft: `6px solid ${(session.converted? 'var(--primary-color)' : 'var(--error)')}`}}>
                                        <ul>
                                            <li><strong>{session.category_name}</strong></li>
                                            <li><strong>{session.form_name}</strong></li>
                                            <li className="tbl-li-img">
                                                <div className="tbl-img tbl-agent-img">
                                                    <div>
                                                        {
                                                            !!session._agent.image
                                                                ? <img src={
                                                                    session._agent.image?.startsWith('http')
                                                                        ? session._agent.image
                                                                        : `${process.env.REACT_APP_API_BASE_URL}/images/users/image/${session._agent.image}`
                                                                } />
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <strong>{session._agent.name}</strong>
                                            </li>
                                            {/* <li>
                                                {session._client !== null &&
                                                    <strong>{session._client.name}<span>{session._client.address}</span></strong>
                                                }
                                            </li> */}
                                            <li><strong>{moment(session.updated_at).format('D MMM, HH:mm')}<span>({moment(session.updated_at).format('YYYY')})</span></strong></li>
                                        </ul>
                                    </li>
                                ))}
    
                            </ul>
                        </div>
                    </div>
                    <div className="box-action box-action-fixed">
                        <a className="button button-outline with-icon-right" href="#" title=""
                            onClick={e => {
                                e.preventDefault();
                                history.push('/sessions')
                            }}
                        ><i className="icon-ia-arrow-right"/>View All</a>
                    </div>
                </DashboardSessionsStyled>
            }

        </>
    )
};

export default Documents

const DashboardSessionsStyled = styled.div`
    .table-ul.docs-list ul {
        grid-template-columns: repeat(3, minmax(5rem, 1fr)) 9rem;
    }
`