import React from 'react'

export default ({
                    element
                }) => {
    return (
        <div className="fieldset-row">
            <div className="form-grid" style={{gridTemplateColumns: "repeat(1, 1fr)"}}>
                <div className="form-row-image">
                    <img style={{maxWidth: '100%', height: "auto"}} src="img/camera.svg"/>
                    <p className="image-caption">{element.text}</p>
                </div>
            </div>
        </div>
    )
}