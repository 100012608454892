import React from 'react'

export default ({allItems = [], children, limit=10}) => {

    const [state, setState] = React.useState({
        loaded: 0
    });

    React.useEffect(() => {
        loadMore()
    }, []);

    const loadMore = () => {
        if(allItems.length > 0) {




                setState(state => ({
                    ...state,
                    loaded: state.loaded + 1
                }))




        } else {
            setState(state => ({...state, loaded: 1}))
        }
    };

    return (
        <>
            {children({items: allItems.slice(0, (state.loaded * limit))})}
            {(state.loaded * limit) < allItems.length && <p onClick={loadMore} style={{cursor: 'pointer'}}>Load more..</p>}
        </>
    )
}