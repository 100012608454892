import React from 'react'
import moment from 'moment'
import {notification} from "antd"
import {saveAs} from 'file-saver'
import {useParams, useLocation} from 'react-router-dom'

import Navbar from "../components/Navbar"
import SupportedElements from '../formComponents/supportedElements'
import {elements as ELEMENTS} from '../constants/elements'

import {getAnswersByCode} from "../utils/getAnswersByCode"
import Loading from "../components/Loading"
import FileSvg from "../images/file.svg"
import API from '../api'
import PageNotFound from '../components/PageNotFound'

import ShowCompletedApprovalForm from './ShowCompletetApprovalForm'
import Portrait from '../components/Portrait'

const ViewSessionVersion = props => {

    const [state, setState] = React.useState({
        loading: false,
        session: null,
        error: null,
        users: null
    })

    const version = new URLSearchParams(useLocation().search).get('v')
    const params = useParams()

    React.useEffect(() => {
        document.querySelector(".css-session").removeAttribute('disabled');
        return () => document.querySelector(".css-session").setAttribute('disabled', true)
    }, []);

    React.useEffect(() => {
        getSession()
    }, [])

    const getSession = async () => {
        try {
            setState(state => ({...state, loading: true}));
            const {data: session} = await API.get(`/sessions/${params.id}/?v=${version}`);
            setState(state => ({...state, session, loading: false}))
        } catch (err) {
            setState(state => ({
                ...state,
                loading: false,
                ...(err.response.status === 404 ? {pageNotFound: true} : {})
            }))

        }
    }

    const downloadGeneratedDocumentPdf = async ({_id: document_id, filename, provider}) => {
        try {
            setState(state => ({...state, loading: true}));
            const {data: pdf} = await API.get(`/sessions/${state.session.sessionId}/document/${document_id}/pdf/?v=${version}`, {responseType: 'blob'});
            const pdf_filename = filename.split('.').slice(0, -1).join('.') + '.pdf';
            saveAs(pdf, state.session.sessionId + "_" + pdf_filename);
            setState(state => ({...state, loading: false}))
        } catch (err) {
            let message = 'Something went wrong with downloading the file';
            if (err.response && err.response.status === 404) {
                message = "The file was not found";
            } else if (err.response && err.response.data && err.response.data.message) {
                if (err.response.data.message === "reauthorize_storage") {
                    message = "There was an error when connecting to the selected default storage, please try again authorizing it."
                } else if (err.response.data.message === "different_storage") {
                    message = `Access denied. The document is stored on an inaccessible storage platform or account. Please connect directly to your ${{
                        gdrive: "Google Drive",
                        onedrive: "Microsoft OneDrive",
                        dropbox: "Dropbox"
                    }[provider.name]} account to retrieve the file. File name: ${props.match.params.id}_${filename}`
                } else if (err.response.data.message === "different_account") {
                    message = `The account of the storage provider for this document is different from the one authorized now`
                } else if (err.response.data.message === "different_folder") {
                    message = `The folder of the storage provider for this document is different from the one selected now`
                }
            }
            notification.error({
                message: message
            });
            setState(state => ({...state, loading: false}));
        }
    };

    let answers_by_code = {};
    let pinnedItems = [];
    let values = [];
    if (state.session) {
        for (let i = 0; i < state.session.formSnapshot.length; i++) {
            for (let j = 0; j < state.session.formSnapshot[i].elements.length; j++) {
                if (state.session.formSnapshot[i].elements[j].pinned && state.session.formSnapshot[i].elements[j].pinned.status) {
                    pinnedItems.push(state.session.formSnapshot[i].elements[j]);
                }
                if (state.session.formSnapshot[i].elements[j].value) {
                    values.push({
                        element_id: state.session.formSnapshot[i].elements[j]._id,
                        value: state.session.formSnapshot[i].elements[j].value,
                    });
                }
            }
        }
        answers_by_code = getAnswersByCode(values, state.session.formSnapshot);
        pinnedItems.sort((elementA, elementB) => {
            if (elementA.pinned.updatedAt > elementB.pinned.updatedAt) {
                return 1;
            } else if (elementA.pinned.updatedAt < elementB.pinned.updatedAt) {
                return -1;
            } else
                return 0;
        });
    }

    return (
        <>

            <Navbar/>
            {state.loading && <Loading/>}
            <PageNotFound active={state.pageNotFound}>
            
                <div className="wrapper session-page">
                    <div className="content">
                        <div className="content-box session-content-box">
                            {!state.loading && !state.error && state.session &&
                                <>
                                    <div className="scrollbar">

                                        <div className="content-box-header">
                                            <div>
                                                <h5>{state.session._form._category.name}</h5>
                                                <h1>{state.session._form.name}</h1>
                                                <span>{moment(state.session.updated_at).format('D MMM YYYY, HH:mm')}</span>
                                            </div>
                                            <div style={{display: 'grid', gridTemplateRows: '1fr 1fr', gridRowGap: '.5rem'}}>
                                                <div className="tbl-li-img">
                                                    {state.session._agent &&
                                                    <>
                                                        <strong>{state.session._agent.name}</strong>
                                                        <div className="tbl-img tbl-agent-img">
                                                            <div>
                                                                {(state.session._agent.image) ? <img
                                                                    src={`${process.env.REACT_APP_API_BASE_URL}/images/users/image_thumbnail/${state.session._agent.image}`}
                                                                    alt=""/> : null}
                                                            </div>
                                                        </div>
                                                    </>
                                                    }
                                                </div>

                                            </div>
                                        </div>

                                        <div className="content-box-body">
                                            <div className="session-form">
                                                {pinnedItems.map((element) => {
                                                        if (SupportedElements[element.type]) {
                                                            let value;

                                                            if (element.value !== undefined) {
                                                                value = element.value;
                                                            }

                                                            if (element.type === ELEMENTS.FILE_UPLOAD) {
                                                                return <div key={element._id}>
                                                                    {SupportedElements[element.type].view({
                                                                        element,
                                                                        answers_by_code,
                                                                        value,
                                                                        session_id: props.match.params.id,
                                                                    })}
                                                                </div>
                                                            }

                                                            if (element.type === ELEMENTS.DOCUMENTS_PREVIEW) {
                                                                return null;
                                                            }

                                                            return <div key={element._id}>
                                                                {SupportedElements[element.type].view({
                                                                    element,
                                                                    answers_by_code,
                                                                    value
                                                                })}
                                                            </div>
                                                        } else {
                                                            return null;
                                                        }
                                                    }
                                                )}
                                                {pinnedItems.length > 0 &&
                                                <div className="step-divider"/>
                                                }
                                                {
                                                    state.session.formSnapshot.map((step, index) => {
                                                        const step_elements = step.elements.map(element => {
                                                            if (SupportedElements[element.type]) {
                                                                if (!pinnedItems.find(item => item._id === element._id)) {
                                                                    let value;

                                                                    if (element.value !== undefined) {
                                                                        value = element.value;
                                                                    }

                                                                    if (element.type === ELEMENTS.FILE_UPLOAD) {
                                                                        return <div key={element._id}>
                                                                            {SupportedElements[element.type].view({
                                                                                element,
                                                                                answers_by_code,
                                                                                value,
                                                                                session_id: props.match.params.id,
                                                                            })}
                                                                        </div>
                                                                    }

                                                                    if (element.type === ELEMENTS.DOCUMENTS_PREVIEW) {
                                                                        // const elements_with_code = [];
                                                                        //
                                                                        // for (let i = 0; i < state.session.completed_steps; i++) {
                                                                        //     state.session._form.steps[i].elements.forEach(element => {
                                                                        //         if (element.code && element.type !== ELEMENTS.FILE_UPLOAD) {
                                                                        //             elements_with_code.push(element)
                                                                        //         }
                                                                        //     });
                                                                        // }
                                                                        //
                                                                        // return <div key={element._id}>
                                                                        //     {SupportedElements[element.type].view({
                                                                        //         element,
                                                                        //         answers_by_code,
                                                                        //         value,
                                                                        //         documents: state.session.documents,
                                                                        //         session_id: props.match.params.id
                                                                        //     })}
                                                                        // </div>
                                                                        return "";
                                                                    }

                                                                    return <div key={element._id}>
                                                                        {SupportedElements[element.type].view({
                                                                            element,
                                                                            answers_by_code,
                                                                            value
                                                                        })}
                                                                    </div>
                                                                } else {
                                                                    return "";
                                                                }
                                                            }
                                                            return null;
                                                        });

                                                        return (
                                                            <React.Fragment key={index}>
                                                                {index !== 0 && <div className="step-divider"/>}
                                                                {step.header && <h2>{step.header}</h2>}
                                                                {step_elements}
                                                            </React.Fragment>
                                                        );
                                                    })
                                                }

                                                <div className="fieldset-row fieldset-row-end">
                                                    {
                                                        state.session.finished && state.session.converted &&
                                                        <h2>Session is finished</h2>
                                                    }
                                                    {
                                                        !state.session.finished && !state.session.converted && !state.session._agent &&
                                                        <h2>Session is pending client completion</h2>
                                                    }
                                                    {
                                                        !state.session.finished && !state.session.converted && state.session._agent &&
                                                        <h2>Session is pending</h2>
                                                    }
                                                    {
                                                        state.session.converted &&
                                                        state.session.userApproval &&
                                                        state.session.userApproval.users.length > 0 && state.session.userApproval.users.find(approver => approver.obligatory) &&
                                                        <>
                                                            {state.session.userApproval.users.find(approver => approver.approved !== true) &&
                                                            <h2>Session is waiting to be approved</h2>
                                                            }
                                                            {state.session.userApproval.approved === true &&
                                                            <h2>Session is approved</h2>
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        state.session.converted && state.session.clientActivation && !state.session.clientActivation.activationDate /*&& (state.session.userApproval.users.length === 0 || state.session.userApproval.approved)*/ &&
                                                        <h2>Session is pending activation</h2>
                                                    }
                                                    {
                                                        state.session.converted && state.session.clientActivation && state.session.clientActivation.activationDate /*&& (state.session.userApproval.users.length === 0 || state.session.userApproval.approved)*/ &&
                                                        <h2>Session is activated</h2>
                                                    }
                                                    {
                                                        state.session.converted &&
                                                        state.session.userApproval &&
                                                        state.session.userApproval.users.length > 0 && state.session.userApproval.users.find(approver => approver.obligatory) &&
                                                        <>
                                                            {state.session.userApproval.users.find(approver => approver.approved === true) &&
                                                            <div>
                                                                <h3>Session is approved by:</h3>
                                                                {
                                                                    state.session.userApproval.users.map(({user, approvalForm, approved}) => {
                                                                        user.scopes[0] = user.scopes[0].charAt(0).toUpperCase() + user.scopes[0].slice(1);
                                                                        if (approved === true) {
                                                                            return (
                                                                                <div key={user._id} className="apd-row">
                                                                                    <span>{user.scopes[0]}: </span>
                                                                                    <strong>{user.name}</strong>
                                                                                    {approvalForm &&
                                                                                    <ShowCompletedApprovalForm
                                                                                        approvalForm={approvalForm}
                                                                                        title={approvalForm.heading ? approvalForm.heading : "Session" + ` approved by ${user.name}`}
                                                                                        session_id={state.session._id}
                                                                                    >

                                                                                        <button className="tbl-btn"><i
                                                                                            className="icon-ia-preview-file"/>
                                                                                            <div
                                                                                                className="tooltip tooltip-top">View
                                                                                                session details
                                                                                            </div>
                                                                                        </button>
                                                                                    </ShowCompletedApprovalForm>
                                                                                    }

                                                                                    <br/>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return "";
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                            }
                                                            {state.session.userApproval.users.find(approver => approver.approved === null) &&
                                                            <div>
                                                                <h3>Session is waiting for approval from:</h3>
                                                                {

                                                                    state.session.userApproval.users.map(({user, obligatory, approved}) => {
                                                                        user.scopes[0] = user.scopes[0].charAt(0).toUpperCase() + user.scopes[0].slice(1);

                                                                        if (obligatory && approved === null) {
                                                                            return (
                                                                                <div key={user._id} className="apd-row">
                                                                                    <span>{user.scopes[0]}:</span>
                                                                                    <strong>{user.name}</strong>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        return null;
                                                                    })
                                                                }
                                                            </div>
                                                            }
                                                            {state.session.userApproval.users.find(approver => approver.approved === false) &&
                                                            <div>
                                                                <h3>Session is declined by:</h3>
                                                                {
                                                                    state.session.userApproval.users.map(({user, obligatory, approved}) => {
                                                                        user.scopes[0] = user.scopes[0].charAt(0).toUpperCase() + user.scopes[0].slice(1);

                                                                        if (obligatory && approved === false) {
                                                                            return (
                                                                                <div key={user._id} className="apd-row">
                                                                                    <span>{user.scopes[0]}:</span>
                                                                                    <strong>{user.name}</strong>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        return null;
                                                                    })
                                                                }
                                                            </div>
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        (
                                                            state.session && state.session.converted && state.session.notified_users &&
                                                            (
                                                                state.session.notified_users.agent ||
                                                                state.session.notified_users.client ||
                                                                (state.session.notified_users.others && state.session.notified_users.others.length > 0)
                                                            )
                                                        ) &&
                                                        <div>
                                                            <h3>Confirmation email sent to:</h3>
                                                            {state.session.notified_users.agent &&
                                                            <p>
                                                                <span>Agent:</span> {state.session.notified_users.agent.name} ({state.session.notified_users.agent.email})<br/>
                                                            </p>
                                                            }

                                                            {state.session.notified_users.client && state.session.notified_users.client.email &&
                                                            <p>
                                                                <span>Client:</span> {state.session.notified_users.client.name} ({state.session.notified_users.client.email})<br/>
                                                            </p>
                                                            }

                                                            {(state.session.notified_users.others && state.session.notified_users.others.length > 0) &&
                                                            <p><span>Others:</span><br/></p>
                                                            }

                                                            {state.session.notified_users.others && state.session.notified_users.others.map((notification_recipient, notification_recipient_index) => (
                                                                <div style={{paddingLeft: 10}}
                                                                    key={notification_recipient_index}>{notification_recipient.name} ({notification_recipient.email})<br/>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }

                                                    {state.session.location &&
                                                    <div>
                                                        <br/>
                                                        <b>Proof of
                                                            location: {state.session.location.coordinates[1]}, {state.session.location.coordinates[0]}</b>
                                                        <br/>
                                                    </div>
                                                    }

                                                    {state.session.documents.length !== 0 &&
                                                    <div>
                                                        <h3 className="session-form-box-wrap-title">Generated Documents</h3>
                                                        <div className="session-form-grid files-list mar1b"
                                                            style={{gridTemplateColumns: 'repeat(3, 1fr)'}}>
                                                            {state.session.documents.map(file => (
                                                                <div key={file._id} className="file"
                                                                    onClick={() => downloadGeneratedDocumentPdf(file)}>
                                                                    <img src={FileSvg} alt=""/>
                                                                    <strong>{file.filename.replace(/\.[^/.]+$/, "")}</strong>
                                                                    <div className="button button-outline">Download
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </>
                            }
                        </div>

                    </div>
                </div>
            
            </PageNotFound>
            <Portrait />
        </>
    )
};

export default ViewSessionVersion
