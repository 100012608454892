import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText";

const ShortAnswer = ({
     element,
     answers_by_code,
     value = []
 }) => {

    return (
        <div className="fieldset-row">
            <div className="session-form-row">
                {element.text &&
                <label>{synchronizeText(element.text, answers_by_code)}</label>
                }
                <span className="input-box">{value.join(', ')}
                    {(element.validations.find(validation => validation.validation === 'email two step validation' || validation.validation === 'phone two step validation')) && value &&
                    <i
                        className="verified icon-check"/>
                    }
                </span>
            </div>
        </div>
    );
};

export default ShortAnswer;