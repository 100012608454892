import React from 'react'

export default ({element}) => {
    return(
         <>
            <div className="fieldset-row-header" style={{padding: "0 12% !important"}}>
                 {/*<h6 className="sup-title">Lorem Ipsum is simply dummy text</h6>*/}
                <p className="paragraph">{element.title}</p>
                <p className="paragraph">{element.text}</p>
                {/*<nav className="car-insurance-menu">*/}
                {/*    <a href="#outside-car-row"><i className="icon-ia-exterior"/><span>Exterior</span></a>*/}
                {/*    <a href="#inside-car-row"><i className="icon-ia-interior"/><span>Interior</span></a>*/}
                {/*    <a href="#engine-row"><i className="icon-ia-engine"/><span>Pornire Motor</span></a>*/}
                {/*    <a href="#accessory-car-row"><i className="icon-ia-accessories"/><span>Accesorii</span></a>*/}
                {/*</nav>*/}
            </div>

            <div className="exp-row" id="outside-car-row">
                <div className="exp-row-header">
                    <h3><i className="exp-icon icon-ia-exterior"/>Exterior</h3>
                    <span className="exp-row-arrow icon-ia-arrow"/>
                </div>
                <div className="exp-row-body">
                    <div className="exterior-car-diagram">
                        <svg viewBox="0 0 720 250" style={{enableBackground: "new 0 0 720 250"}} xmlSpace="preserve">
                        <image width="720" height="250" xlinkHref="../img/car-exterior-diagram-image-1.svg" transform="matrix(1 0 0 1 0 0)"/>
                        <g className="car-label active">
                        <circle cx="168" cy="211.1" r="22" />

                        <text transform="matrix(1 0 0 1 167.9834 216.1417)">1</text>
                            </g>
                        <g className="car-label active">
                        <circle cx="168" cy="160.2" r="22" />

                        <text transform="matrix(1 0 0 1 167.9834 165.2606)">2</text>
                            </g>
                        <g className="car-label">
                        <circle cx="168" cy="108.5" r="22" />

                        <text transform="matrix(1 0 0 1 167.9834 113.4987)">3</text>
                            </g>
                        <g className="car-label active">
                        <circle cx="74" cy="131" r="22" />

                        <text transform="matrix(1 0 0 1 73.9834 136.1417)">4</text>
                            </g>
                        <g className="car-label">
                        <circle cx="264" cy="131" r="22" />

                        <text transform="matrix(1 0 0 1 263.9834 136.1417)">5</text>
                            </g>
                        <g className="car-label">
                        <circle cx="168" cy="54" r="22" />

                        <text transform="matrix(1 0 0 1 167.9834 58.9987)">6</text>
                            </g>
                        <g className="car-label">
                        <circle cx="550" cy="181" r="22" />

                        <text transform="matrix(1 0 0 1 548.7021 186.2606)">15</text>
                            </g>
                        <g className="car-label">
                        <circle cx="550" cy="102" r="22" />

                        <text transform="matrix(1 0 0 1 548.7021 108.2606)">16</text>
                            </g>
                        <g className="car-label">
                        <circle cx="550" cy="38" r="22" />

                        <text transform="matrix(1 0 0 1 548.7021 43.2606)">17</text>
                            </g>
                        <g className="car-label">
                        <circle cx="451" cy="103" r="22" />

                        <text transform="matrix(1 0 0 1 449.7021 108.2606)">18</text>
                            </g>
                        <g className="car-label">
                        <circle cx="651" cy="103" r="22" />

                        <text transform="matrix(1 0 0 1 649.7021 108.2606)">19</text>
                            </g>
                        </svg>
                        <div className="info-content info-content-exterior-1">
                            <ul>
                                <li className="active"><span>1</span><strong>Bara fata</strong></li>
                                <li className="active"><span>2</span><strong>Grila fata</strong></li>
                                <li><span>3</span><strong>Capota fata</strong></li>
                                <li className="active"><span>4</span><strong>Far dreapta</strong></li>
                                <li><span>5</span><strong>Far stanga</strong></li>
                                <li><span>6</span><strong>Parbriz</strong></li>
                            </ul>
                            <ul>
                                <li><span>15</span><strong>Bara spate</strong></li>
                                <li><span>16</span><strong>Capota spate</strong></li>
                                <li><span>17</span><strong>Luneta</strong></li>
                                <li><span>18</span><strong>Stop stanga</strong></li>
                                <li><span>19</span><strong>Stop dreapta</strong></li>
                            </ul>
                        </div>
                        <i className="info icon-ia-info info-exterior-1"/>
                    </div>
                    <div className="exterior-car-diagram">
                        <svg viewBox="0 0 720 250" style={{enableBackground: "new 0 0 720 250"}} xmlSpace="preserve">
                        <image width="720" height="250" xlinkHref="../img/car-exterior-diagram-image-2.svg" transform="matrix(1 0 0 1 0 0)"/>
                        <g className="car-label active">
                        <circle cx="46" cy="196" r="22" />

                        <text id="_x31_" transform="matrix(1 0 0 1 45.9834 201.2606)">1</text>
                            </g>
                        <g className="car-label">
                        <circle cx="176" cy="127" r="22" />

                        <text id="_x37_" transform="matrix(1 0 0 1 175.9834 132.2606)">7</text>
                            </g>
                        <g className="car-label">
                        <circle cx="147" cy="196" r="22" />

                        <text id="_x38_" transform="matrix(1 0 0 1 146.9834 201.2606)">8</text>
                            </g>
                        <g className="car-label">
                        <circle cx="259" cy="103" r="22" />

                        <text id="_x39_" transform="matrix(1 0 0 1 258.9834 108.2606)">9</text>
                            </g>
                        <g className="car-label">
                        <circle cx="307" cy="158" r="22" />

                        <text id="_x31_0" transform="matrix(1 0 0 1 306.7021 163.2606)">10</text>
                            </g>
                        <g className="car-label">
                        <circle cx="452" cy="158" r="22" />

                        <text id="_x31_1" transform="matrix(1 0 0 1 451.7021 163.2606)">11</text>
                            </g>
                        <g className="car-label">
                        <circle cx="380" cy="208" r="22" />

                        <text id="_x31_2" transform="matrix(1 0 0 1 379.7021 213.2606)">12</text>
                            </g>
                        <g className="car-label">
                        <circle cx="585" cy="196" r="22" />

                        <text id="_x31_3" transform="matrix(1 0 0 1 584.7021 201.2606)">13</text>
                            </g>
                        <g className="car-label">
                        <circle cx="623" cy="119" r="22" />

                        <text id="_x31_4" transform="matrix(1 0 0 1 621.7021 124.2606)">14</text>
                            </g>
                        <g className="car-label">
                        <circle cx="678" cy="158" r="22" />

                        <text id="_x31_5" transform="matrix(1 0 0 1 676.7021 163.2606)">15</text>
                            </g>
                        <g className="car-label">
                        <circle cx="420" cy="23" r="22" />

                        <text id="_x32_5" transform="matrix(1 0 0 1 419.7021 28.2606)">25</text>
                            </g>
                        </svg>
                        <div className="info-content info-content-exterior-2">
                            <ul>
                                <li className="active"><span>1</span><strong>Bara fata</strong></li>
                                <li><span>7</span><strong>Aripa stanga fata</strong></li>
                                <li><span>8</span><strong>Janta stanga fata</strong></li>
                                <li><span>9</span><strong>Oglinda stanga fata</strong></li>
                                <li><span>10</span><strong>Portiera stanga fata</strong></li>
                                <li><span>11</span><strong>Portiera stanga spate</strong></li>
                            </ul>
                            <ul>
                                <li><span>12</span><strong>Prag stanga</strong></li>
                                <li><span>13</span><strong>Janta stanga spate</strong></li>
                                <li><span>14</span><strong>Aripa stanga spate</strong></li>
                                <li><span>15</span><strong>Bara spate</strong></li>
                                <li><span>25</span><strong>Plafon</strong></li>
                            </ul>
                        </div>
                        <i className="info icon-ia-info info-exterior-2"/>
                    </div>
                    <div className="exterior-car-diagram">
                        <svg viewBox="0 0 720 250" style={{enableBackground: "new 0 0 720 250"}} xmlSpace="preserve">
                        <image width="720" height="250" xlinkHref="../img/car-exterior-diagram-image-3.svg" transform="matrix(0.9999 0 0 0.9999 0 0)"/>
                        <g className="car-label active">
                        <circle cx="674" cy="196" r="22" />

                        <text id="_x31__1_" transform="matrix(1 0 0 1 672.9834 201.2606)">1</text>
                            </g>
                        <g className="car-label">
                        <circle cx="42" cy="158" r="22" />

                        <text id="_x31_5" transform="matrix(1 0 0 1 41.438 163.2606)">15</text>
                            </g>
                        <g className="car-label">
                        <circle cx="97" cy="119" r="22" />

                        <text id="_x32_0" transform="matrix(1 0 0 1 96.7021 124.2606)">20</text>
                            </g>
                        <g className="car-label">
                        <circle cx="135" cy="196" r="22" />

                        <text id="_x32_1" transform="matrix(1 0 0 1 135.7021 201.2606)">21</text>
                            </g>
                        <g className="car-label">
                        <circle cx="269" cy="158" r="22" />

                        <text id="_x32_2" transform="matrix(1 0 0 1 268.7021 163.2606)">22</text>
                            </g>
                        <g className="car-label active">
                        <circle cx="413" cy="158" r="22" />

                        <text id="_x32_3" transform="matrix(1 0 0 1 412.7021 163.2606)">23</text>
                            </g>
                        <g className="car-label active">
                        <circle cx="340" cy="208" r="22" />

                        <text id="_x32_4" transform="matrix(1 0 0 1 339.7021 213.2606)">24</text>
                            </g>
                        <g className="car-label">
                        <circle cx="300" cy="23" r="22" />

                        <text id="_x32_5" transform="matrix(1 0 0 1 299.7021 28.2606)">25</text>
                            </g>
                        <g className="car-label active">
                        <circle cx="461" cy="103" r="22" />

                        <text id="_x32_6" transform="matrix(1 0 0 1 460.7021 108.2606)">26</text>
                            </g>
                        <g className="car-label active">
                        <circle cx="573" cy="196" r="22" />

                        <text id="_x32_7" transform="matrix(1 0 0 1 572.7021 201.2606)">27</text>
                            </g>
                        <g className="car-label active">
                        <circle cx="544" cy="127" r="22" />

                        <text id="_x32_7_1_" transform="matrix(1 0 0 1 543.7021 132.2606)">28</text>
                            </g>
                        </svg>
                        <div className="info-content info-content-exterior-3">
                            <ul>
                                <li className="active"><span>1</span><strong>Bara fata</strong></li>
                                <li><span>15</span><strong>Bara spate</strong></li>
                                <li><span>20</span><strong>Aripa dreapta spate</strong></li>
                                <li><span>21</span><strong>Janta dreapta spate</strong></li>
                                <li><span>22</span><strong>Portiera dreapta spate</strong></li>
                                <li className="active"><span>23</span><strong>Portiera dreapta fata</strong></li>
                            </ul>
                            <ul>
                                <li className="active"><span>24</span><strong>Prag dreapta</strong></li>
                                <li><span>25</span><strong>Plafon</strong></li>
                                <li className="active"><span>26</span><strong>Oglinda dreapta</strong></li>
                                <li className="active"><span>27</span><strong>Janta dreapta fata</strong></li>
                                <li className="active"><span>28</span><strong>Aripa dreapta fata</strong></li>
                            </ul>
                        </div>
                        <i className="info icon-ia-info info-exterior-3"/>
                    </div>

                    <div className="fieldset-row">
                        <div className="switcher">
                            <input id="ext-s1" type="checkbox" name="" />
                            <label htmlFor="ext-s1">Stalpi / Montanti</label>
                        </div>
                        <div className="switcher">
                            <input id="ext-s2" type="checkbox" name="" />
                            <label htmlFor="ext-s2">Lonjeroane</label>
                        </div>
                    </div>
                    <div className="fieldset-row">
                        <div className="form-row">
                            <label htmlFor="st">Alte avarii / Observatii</label>
                            <textarea id="st"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="exp-row" id="inside-car-row">
                <div className="exp-row-header">
                    <h3><i className="exp-icon icon-ia-interior"/>Interior</h3>
                    <span className="exp-row-arrow icon-ia-arrow"/>
                </div>
                <div className="exp-row-body">
                    <div className="interior-car-diagram">
                        <svg viewBox="0 0 720 450" style={{enableBackground: "new 0 0 720 450"}} xmlSpace="preserve">
                        <image width="720" height="450" xlinkHref="../imgcar-interior-diagram-image.svg" transform="matrix(0.9999 0 0 0.9999 0 0)"/>
                        <g className="car-label">
                        <circle cx="116" cy="366" r="22" />

                        <text id="_x31__0_" transform="matrix(1 0 0 1 115.9834 371.2606)">1</text>
                        </g>
                        <g className="car-label">
                        <circle cx="193" cy="292" r="22" />

                        <text id="_x32_" transform="matrix(1 0 0 1 192.9834 297.2606)">2</text>
                            </g>
                        <g className="car-label">
                        <circle cx="258" cy="244" r="22" />

                        <text id="_x32__1_" transform="matrix(1 0 0 1 257.9834 249.2606)">3</text>
                            </g>
                        <g className="car-label">
                        <circle cx="142" cy="210" r="22" />

                        <text id="_x32__2_" transform="matrix(1 0 0 1 141.9834 215.2606)">4</text>
                            </g>
                        <g className="car-label active">
                        <circle cx="180" cy="175" r="22" />

                        <text id="_x32__3_" transform="matrix(1 0 0 1 179.9834 180.2606)">5</text>
                            </g>
                        <g className="car-label active">
                        <circle cx="269" cy="133" r="22" />

                        <text id="_x32__4_" transform="matrix(1 0 0 1 268.9834 138.2606)">6</text>
                            </g>
                        <g className="car-label">
                        <circle cx="362" cy="225" r="22" />

                        <text id="_x32__5_" transform="matrix(1 0 0 1 361.9834 230.2606)">7</text>
                            </g>
                        <g className="car-label">
                        <circle cx="121" cy="119" r="22" />

                        <text id="_x32__6_" transform="matrix(1 0 0 1 120.9834 124.2606)">8</text>
                            </g>
                        <g className="car-label">
                        <circle cx="499" cy="23" r="22" />

                        <text id="_x32__7_" transform="matrix(1 0 0 1 498.9834 28.2606)">9</text>
                            </g>
                        <g className="car-label">
                        <circle cx="458" cy="272" r="22" />

                        <text id="_x32__8_" transform="matrix(1 0 0 1 457.7021 277.2606)">10</text>
                            </g>
                        <g className="car-label">
                        <circle cx="425" cy="158" r="22" />

                        <text id="_x32__9_" transform="matrix(1 0 0 1 424.7021 163.2606)">11</text>
                            </g>
                        <g className="car-label">
                        <circle cx="690" cy="244" r="22" />

                        <text id="_x32__10_" transform="matrix(1 0 0 1 689.7021 249.2606)">12</text>
                            </g>
                        <g className="car-label">
                        <circle cx="564" cy="257" r="22" />

                        <text id="_x32__11_" transform="matrix(1 0 0 1 563.7021 262.2606)">13</text>
                            </g>
                        <g className="car-label">
                        <circle cx="372" cy="60" r="22" />

                        <text id="_x32__12_" transform="matrix(1 0 0 1 371.7021 65.2606)">13</text>
                            </g>
                        <g className="car-label">
                        <circle cx="472" cy="119" r="22" />

                        <text id="_x32__13_" transform="matrix(1 0 0 1 471.7021 124.2606)">14</text>
                            </g>
                        <g className="car-label">
                        <circle cx="423" cy="380" r="22" />

                        <text id="_x32__14_" transform="matrix(1 0 0 1 422.7021 385.2606)">15</text>
                            </g>
                        <g className="car-label">
                        <circle cx="171" cy="380" r="22" />

                        <text id="_x32__15_" transform="matrix(1 0 0 1 170.7021 385.2606)">15</text>
                            </g>
                        </svg>
                        <div className="info-content info-content-interior">
                            <ul>
                                <li><span>1</span><strong>Pedale</strong></li>
                                <li><span>2</span><strong>Volan</strong></li>
                                <li><span>3</span><strong>Schimbaror viteze</strong></li>
                                <li><span>4</span><strong>Butoane plansa bord</strong></li>
                                <li className="active"><span>5</span><strong>Butoane multimedia</strong></li>
                                <li className="active"><span>6</span><strong>Butoane portiere / geamuri</strong></li>
                                <li><span>7</span><strong>Cotiera</strong></li>
                                <li><span>8</span><strong>Plansa bord ( incl .torpedou )</strong></li>
                            </ul>
                            <ul>
                                <li><span>9</span><strong>Plafon</strong></li>
                                <li><span>10</span><strong>Scaun sofer</strong></li>
                                <li><span>11</span><strong>Scaun pasager</strong></li>
                                <li><span>12</span><strong>Bancheta spate</strong></li>
                                <li><span>13</span><strong>Centuri siguranta</strong></li>
                                <li><span>14</span><strong>Capitonaje usi</strong></li>
                                <li><span>15</span><strong>Covorase</strong></li>
                            </ul>
                        </div>
                        <i className="info icon-ia-info info-interior"/>
                    </div>

                    <div className="fieldset-row">
                        <div className="switcher">
                            <input id="ext-s22" type="checkbox" name="" />
                            <label htmlFor="ext-s22">Miros neplacut</label>
                        </div>
                        <div className="switcher">
                            <input id="ext-s23" type="checkbox" name="" />
                            <label htmlFor="ext-s23">Podea / Tapiserie cu urme de umezeala</label>
                        </div>
                        <div className="switcher">
                            <input id="sw-more-inside" type="checkbox" name="switcher2"/>
                            <label htmlFor="sw-more-inside">Alte avarii / Observatii</label>
                            <div id="switcher-expand-box-2" className="fieldset-row switcher-expand-box" style={{display:"none"}}>
                                <div className="form-row">
                                    <textarea/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="exp-row" id="engine-row">
                <div className="exp-row-header">
                    <h3><i className="exp-icon icon-ia-engine"/>Pornire Motor</h3>
                    <span className="exp-row-arrow icon-ia-arrow"/>
                </div>
                <div className="exp-row-body">
                    <div className="fieldset-row">
                        <div className="switcher">
                            <input id="ext-e1" type="checkbox" name="" />
                            <label htmlFor="ext-e1">Martori importanti aprinsi<span className="info-txt">de ex. ECU, Airbag, ABS, ulei, temperatura apa, placute frana etc</span></label>
                        </div>
                        <div className="switcher">
                            <input id="ext-e2" type="checkbox" name="" />
                            <label htmlFor="ext-e2">Alte alerte active<span className="info-txt">de ex. becuri faruri, stopuri arse, revizie depasita etc</span></label>
                        </div>
                        <div className="switcher">
                            <input id="ext-e3" type="checkbox" name="" />
                            <label htmlFor="ext-e3">Probleme / Zgomote motor</label>
                        </div>
                        <div className="switcher">
                            <input id="ext-e4" type="checkbox" name="" />
                            <label htmlFor="ext-e5">Probleme / Zgomote motor ( cutie de viteze si diferential )</label>
                        </div>
                        <div className="switcher">
                            <input id="sw-more-engine" type="checkbox" name="switcher3"/>
                            <label htmlFor="sw-more-engine">Alte avarii / Observatii</label>
                            <div id="switcher-expand-box-3" className="fieldset-row switcher-expand-box" style={{display:"none"}}>
                                <div className="form-row">
                                    <textarea/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="exp-row" id="accessory-car-row">
                <div className="exp-row-header">
                    <h3><i className="exp-icon icon-ia-accessories"/>Accesorii</h3>
                    <span className="exp-row-arrow icon-ia-arrow"/>
                </div>
                <div className="exp-row-body">
                    <div className="exp-row-body">
                        <div className="fieldset-row">
                            <div className="switcher">
                                <input id="ext-a1" type="checkbox" name="" />
                                <label htmlFor="ext-a1">Bare transversale ( inclusiv cheie )</label>
                            </div>
                            <div className="switcher">
                                <input id="ext-a2" type="checkbox" name="" />
                                <label htmlFor="ext-a2">Bull bar</label>
                            </div>
                            <div className="switcher">
                                <input id="ext-a3" type="checkbox" name="" />
                                <label htmlFor="ext-a3">Camera video parbriz</label>
                            </div>
                            <div className="switcher">
                                <input id="ext-a4" type="checkbox" name="" />
                                <label htmlFor="ext-a4">Carlig tractare</label>
                            </div>
                            <div className="switcher">
                                <input id="ext-a5" type="checkbox" name="" />
                                <label htmlFor="ext-a5">Pachet siguranta ( extinctor, trusa medicala )</label>
                            </div>
                            <div className="switcher">
                                <input id="ext-a6" type="checkbox" name="" />
                                <label htmlFor="ext-a6">Portbagaj suplimentar ( inclusiv cheie )</label>
                            </div>
                            <div className="switcher">
                                <input id="ext-a7" type="checkbox" name="" />
                                <label htmlFor="ext-a7">Praguri inox</label>
                            </div>
                            <div className="switcher">
                                <input id="ext-a8" type="checkbox" name="" />
                                <label htmlFor="ext-a8">Suport biciclete</label>
                            </div>
                            <div className="switcher">
                                <input id="ext-a8" type="checkbox" name="" />
                                <label htmlFor="ext-a8">Suport schiuri</label>
                            </div>
                            <div className="switcher">
                                <input id="ext-a9" type="checkbox" name="" />
                                <label htmlFor="ext-a9">Alte accesorii importante</label>
                            </div>
                            <div className="switcher">
                                <input id="sw-more-accessories" type="checkbox" name="switcher4"/>
                                <label htmlFor="sw-more-accessories">Alte accesorii importante</label>
                                <div id="switcher-expand-box-4" className="fieldset-row switcher-expand-box" style={{display:"none"}}>
                                    <div className="form-row">
                                        <textarea/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
         </>
    )
}
