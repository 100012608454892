import React from 'react'

import LivePreview from './LivePreview.contractCommunicationOptions'
import {ContractCommunicationOptionsEditor} from './Editor.contractCommunicationOptions'
import validate from './validate.contractCommunicationOptions'
import CodeCopy from './CodeCopy.contractCommunicationOptions'
import View from './View.contractCommunicationOptions'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <ContractCommunicationOptionsEditor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,

    default: () => ({
        type: "contract communication options",
        text: "Contract Communication Options Text",
        pinned: {
            status: false
        },
        title: "Contract Communication Options Title",
        validations: []
    }),

    availableValidations: ['required'],
    validate,
    group: "ADVANCED",
    icon: "icon-ia-marketing",
    name: "Contract Communication Options",
    view: args => <View {...args}/>
}

