import React from 'react'

export default ({ 
    element, 
    onChange = f => f, 
    value = [],
    error = null
}) => {

    return (
        <div className="fieldset-row">
            <p>{element.text}</p>
            <div className="form-grid" style={{ gridTemplateColumns: `repeat(${element.grid_columns}, 1fr)` }}>
                {element.items.map((item, itemIndex) => (
                    <div className="form-row-checkbox" key={itemIndex}>
                        <input  type="checkbox" name="" />
                        <label>{item}</label>
                    </div>
                ))}
            </div>
        </div>
    )
}