import React from 'react'
import RichTextViewer from '../../components/RichTextViewer'

export default ({ element }) => {
    return (
        <div className="fieldset-row">
            <RichTextViewer>{element.text}</RichTextViewer>
        </div>
    )
}
