import React from 'react'

import {synchronizeText} from "../../utils/synchronizeText"
import IsignaturePad from '../../components/LsignaturePad'

const AgentSignature = ({
    element,
    answers_by_code,
    value = {
        name: '',
        signature: null
    }
}) => {

    return (
        <div className="fieldset-row">
            {element.text && <p>{synchronizeText(element.text, answers_by_code)}</p>}
            <p className="form-box-wrap-title">{value.name}</p>
            <IsignaturePad value={value.signature}/>
        </div>
    )
}

export default AgentSignature