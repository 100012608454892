import React from 'react'
import API from "../../api";
import Loading from "../../components/Loading";
import {notification} from "../../components/Lnotification";

const ChangePassword = ({toggleTab}) => {

    const [state, setState] = React.useState({
        loading: false,
        currentPassword: "",
        confirmPassword: "",
        newPassword: "",
        errors: {}
    })

    const changePassword = async () => {
        try {
            setState(state => ({...state, loading: true, errors: {}}));
            const errors = {};

            if (state.currentPassword.trim() === '') errors.currentPassword = 'Invalid current password';

            if (state.newPassword.trim() === '') errors.newPassword = 'Invalid new password';
            else if (
                !(/[a-z]/.test(state.newPassword)) ||
                !(/[A-Z]/.test(state.newPassword)) ||
                !(/[0-9]/.test(state.newPassword))
            ) errors.newPassword = 'New password must be min 6 char long and include lowercase, uppercase letter and number';

            if (state.confirmPassword.trim() === '') errors.confirmPassword = 'Invalid confirm password';
            else if (
                !(/[a-z]/.test(state.confirmPassword)) ||
                !(/[A-Z]/.test(state.confirmPassword)) ||
                !(/[0-9]/.test(state.confirmPassword))
            ) errors.confirmPassword = 'Confirm password must be min 6 char long and include lowercase, uppercase letter and number';
            else if (state.newPassword !== state.confirmPassword) errors.confirmPassword = 'Confirm password does not match';

            if (Object.keys(errors).length > 0) {
                setState(state => ({...state, errors, loading: false}));
                return
            }

            await API.put('users/set/new/password', {password: state.currentPassword, new_password: state.newPassword});
            toggleTab("password")
            setState(state => ({
                ...state,
                loading: false,
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
                errors: {}
            }))
        } catch (err) {
            const errors = {};

            if (err.response && err.response.data && err.response.data.error) {
                const error = err.response.data.error;
                if (error === 'password_mismatch') {
                    errors.currentPassword = "Wrong current password";
                }
            }
            setState(state => ({...state, loading: false, errors}))
            if (Object.keys(errors).length === 0) {
                notification.error({message: 'Something went wrong'})
            }
        }
    };

    return (
        <div className="change-password">
            <div className="titleDesc">
                <h3>Change password</h3>
                <p>Please note that when changing your password, we ask you to set your self a
                    secure password that contains both uppercase and lowercase letters as well
                    as numbers. This is for your own safety.</p>
            </div>
            {state.loading && <Loading/>}
            {!state.loading &&
            <form className={`form ${Object.keys(state.errors).length > 0 ? 'error-anim' : ''}`}
                  onSubmit={async e => {
                      e.preventDefault();
                      await changePassword()
                  }}>
                <div className="fieldset-row">
                    <div className={`form-row ${state.errors.currentPassword ? 'error' : ''}`}>
                        <label>Current Password</label>
                        <input type="password" maxLength={40}
                               value={state.currentPassword}
                               onChange={({target: {value}}) => setState(state => ({
                                   ...state,
                                   currentPassword: value
                               }))}/>
                        {state.errors.currentPassword &&
                        <span
                            className="form-row-error-msg">{state.errors.currentPassword}</span>
                        }
                    </div>
                    <div className={`form-row ${state.errors.newPassword ? 'error' : ''}`}>
                        <label>New Password</label>
                        <input type="password" maxLength={40}
                               value={state.newPassword}
                               onChange={({target: {value}}) => setState(state => ({
                                   ...state,
                                   newPassword: value
                               }))}/>
                        {state.errors.newPassword &&
                        <span
                            className="form-row-error-msg">{state.errors.newPassword}</span>
                        }
                    </div>
                    <div className={`form-row ${state.errors.confirmPassword ? 'error' : ''}`}>
                        <label>Password Confirmation</label>
                        <input type="password"
                               maxLength={40}
                               value={state.confirmPassword}
                               onChange={({target: {value}}) => setState(state => ({
                                   ...state,
                                   confirmPassword: value
                               }))}/>
                        {state.errors.confirmPassword &&
                        <span
                            className="form-row-error-msg">{state.errors.confirmPassword}</span>
                        }
                    </div>
                </div>
                <div className="form-actions">
                    <button className="button with-icon-right close-box"><i
                        className="icon-ia-checked"/>Save
                    </button>
                    <span className="button button-link close-box" onClick={() => toggleTab("password")}>Cancel</span>
                </div>
            </form>
            }


        </div>
    )
}

export default ChangePassword