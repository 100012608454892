import React from 'react'
import styled, {ThemeProvider, css} from 'styled-components'
import Frame from '../../../components/Frame'
import supportedElements from '../../../formComponents/supportedElements'
import LoadAfter from '../../../components/LoadAfter'
import Loading from '../../../components/Loading'

export const LivePreview = ({form, approvalForm}) => {

    let styles = {
        color: form.font_color,
        background: form.background_color,
        fontFamily: form.font_face
    }

    if (form.font_color === "#8F75DB" && form.background_color === "#fafbfe") {
        styles.background = undefined
        styles.fontFamily = undefined
    }


    return (
        <ThemeProvider theme={{mode: 'dark', ...styles}}>
            <div className="sidebar sidebar-preview-slider">

                <div className="sidebar-title">
                    <h3 className="sidebar-title sidebar-title-fixed">Preview</h3>
                </div>

                <div className="pts-top">
                    <div className="tablet-preview">
                        <img src="img/tablet.svg"/>
                        <Frame
                            head={
                                <>
                                    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
                                    <meta name="viewport"
                                          content="user-scalable=no, initial-scale=1.0, maximum-scale=1.0, width=device-width"/>
                                    <meta name="robots" content="index, follow"/>
                                    <meta name="language" content="ro"/>
                                    <meta name="apple-mobile-web-app-capable" content="yes"/>
                                    <meta name="mobile-web-app-capable" content="yes"/>
                                    <base href="/"/>
                                    <title>IA</title>
                                </>
                            }
                        >
                            <LoadAfter
                                links={[
                                    'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i',
                                    'AlchemistAdmin/client/icons.css',
                                    'AlchemistAdmin/client/swiper.min.css',
                                    'AlchemistAdmin/client/global.css',
                                    'AlchemistAdmin/client/preview.css'
                                ]}
                                loader={<Loading/>}
                            >
                                <Wrapper className="wrapper">

                                    {/*<header className="header">*/}
                                    {/*    <div className="logo">*/}
                                    {/*        <img src="../img/logo.png"/>*/}
                                    {/*    </div>*/}
                                    {/*    <div />*/}
                                    {/*    <div className="menu-right">*/}
                                    {/*        <div className="user-img"/>*/}
                                    {/*        <div className="logout"/>*/}
                                    {/*    </div>*/}
                                    {/*</header>*/}

                                    <div className="wrapper-content" style={{padding: "3rem 0 5rem"}}>
                                        <div
                                            className="content">
                                            <form className="form">
                                                {/* <h2>{form.steps[stepIndex].heading}</h2> */}
                                                {approvalForm.elements.map((element, index) => {
                                                        if (supportedElements[element.type]) {
                                                            return <React.Fragment key={index}>
                                                                {supportedElements[element.type].livePreview({element})}
                                                            </React.Fragment>
                                                        }
                                                        return null;
                                                    }
                                                )}
                                            </form>
                                        </div>
                                    </div>

                                    {/*<footer className="footer">*/}

                                    {/*    /!* <button className="footer-btn prev-btn">*/}
                                    {/*        <i className="icon-ia-arrow-left"></i>*/}
                                    {/*        <span>Prev</span>*/}
                                    {/*    </button> *!/*/}

                                    {/*    <button className="footer-btn clear-btn">*/}
                                    {/*        <i className="icon-refresh-cw"></i>*/}
                                    {/*        <span>Clear</span>*/}
                                    {/*    </button>*/}

                                    {/*    /!* <button className="footer-btn end-btn">*/}
                                    {/*        <i className="icon-upload"></i>*/}
                                    {/*        <span>End Session</span>*/}
                                    {/*    </button> *!/*/}

                                    {/*    <button className="footer-btn next-btn">*/}
                                    {/*        <i className="icon-ia-arrow-right"></i>*/}
                                    {/*        <span>Finish</span>*/}
                                    {/*    </button>*/}

                                    {/*    /!* <div className="footer-pagination">*/}
                                    {/*        <strong>{stepIndex + 1}</strong>*/}
                                    {/*        / {form.steps.length} Steps*/}
                                    {/*    </div> *!/*/}
                                    {/*</footer>*/}

                                </Wrapper>
                            </LoadAfter>

                        </Frame>
                    </div>
                </div>


            </div>
        </ThemeProvider>
    )
}

const Wrapper = styled.div`
    ${({theme: {background, color, fontFamily}}) => background && color
    ? css`
                background:  ${background};

                color: ${color};

                .form {
                    font-family: ${fontFamily};
                    color: ${color};
                }

                .sup-title,
                .main-title,
                .form .fieldset-row,
                .form .form-grid,
                .form label {
                    font-family: ${fontFamily};
                    color: ${color};
                }

                input, input::placeholder, select, textarea, .add-block-input.mar2b, .add-block-input.mar2b {
                    font-family: ${fontFamily} !important;
                    background: ${background} !important;
                    color: ${color} !important;
                }

                .button {
                    background: ${color} !important;
                    color: ${background} !important;
                }

                .ant-calendar-picker > div > i {
                    color: ${color} !important;
                }

                .form-row-radio label:after {
                    color: ${color} !important;
                    background: ${background} !important;
                }

                .form-row-checkbox label:after {
                    color: ${color} !important;
                    background: ${background} !important;
                }

                .upload-image-box {
                    background: ${background} !important;
                }
                
                .upload-image-box:before, .upload-image-box:after {
                    color: ${color} !important;
                }

                .signature-pad {
                    background: ${background} !important;
                }
            `
    : null
}
    
`