import { VALIDATION } from '../constants/validations'
import bytes from "bytes"

const getMaxFileSizeFromValidations = element => {
    let max_file_size;

    if (element.validations) {
        for (let i = 0; i < element.validations.length; i++) {
            const validation = element.validations[i];

            if (validation.validation === VALIDATION.MAX_FILE_SIZE) {
                max_file_size = validation.value;
            }
            
        }
    }

    return bytes(max_file_size)
}

export default getMaxFileSizeFromValidations