import React from "react"
import { message } from "antd"
import { useParams } from "react-router-dom"
import {v4 as uuid} from 'uuid'

import Loading from "../../../components/Loading"
import API from "../../../api"

import AdeleteConfirm from '../../../components/AdeleteConfirm'
import {useAppContext} from '../../../context'

import styled from 'styled-components'

const DocumentEditor = ({ state, setState }) => {
    const params = useParams()
    const {auth: [{access_token}]} = useAppContext()

	if (state.loadingForm || !state.form || !state.docsApiLoaded)
		return <Loading />

	const { documents } = state.form

	const onFilesChange = e => {
		let form = new FormData()
		const files = [...e.target.files]
		const fileNames = documents.map(doc => doc.name)
		const newFiles = []
		let documentsToUpload = false
		files.forEach(doc => {
			if (fileNames.includes(doc.name)) {
				message.error(
					`Failed to add the document ${doc.name} because you have already added one with the same name!`
				)
			} else {
				fileNames.push(doc.name)
				newFiles.push(doc.name)
				form.append("docs", doc)
				if (!documentsToUpload) {
					documentsToUpload = true
				}
			}
		})
		if (documentsToUpload) {
			API.post(`forms/${params.id}/documents`, form)
				.then(({ data }) => {
					// message.success(
					// 	`Document${files.length > 1 ? "s" : ""} uploaded successfully.`
					// )
					addDocuments(
						data.map(({ id }, i) => ({
							_id: id,
							filename: newFiles[i],
						}))
					)
				})
				.catch(() => {
					message.error(`Failed to add the document${files.length > 1 ? "s" : ""}!`)
				})
		}
		e.target.value = ""
	}

	const onDocumentRemove = documentIndex => {
		API.delete(`forms/${params.id}/documents/${documents[documentIndex]._id}`)
			.then(() => {
				// message.success("Document deleted successfully.")
				removeDocument(documentIndex)
			})
			.catch(() => {
				message.error("Failed to delete the document!")
			})
	}

	const setForm = callback =>
		setState(state => ({ ...state, form: callback(state.form) }))

	const addDocuments = documents =>
		setForm(form => ({ ...form, documents: [...form.documents, ...documents] }))

	const removeDocument = documentIndex =>
		setForm(form => ({
			...form,
			documents: form.documents.filter((_, i) => documentIndex !== i),
		}))

	const toggleDoumentActive = documentIndex =>
		setForm(form => ({
			...form,
			documents: form.documents.map((doc, i) =>
				i !== documentIndex
					? { ...doc, active: false }
					: { ...doc, active: !doc.active }
			),
		}))

	const setDocumentEditKey = (documentIndex, key) =>
		setForm(form => ({
			...form,
			documents: form.documents.map((d, i) =>
				i !== documentIndex ? d : { ...d, key }
			),
        }))
        
    const setDocumentChanged = (documentIndex, changed) =>
    setForm(form => ({
        ...form,
        documents: form.documents.map((d, i) =>
            i !== documentIndex ? d : { ...d, changed }
        ),
    }))

	return (
		<DocumentEditorStyledWrapper>
			<div className="docs-list">
				<div className="scrollbar">
					<div className="table">
						<div className="table-cell">
							<>
								{documents.map((document, documentIndex) => (
									<div
										className={`dl-cell ${document.active ? "active" : ""}`}
										key={documentIndex}
									>
										<div className="dl-cell-header">
											<h3>{document.filename}</h3>
											<ul>
												<li>
													<a
														className="tbl-btn"
														href="EditForm#"
														onClick={e => {
                                                            e.preventDefault()
															toggleDoumentActive(documentIndex)
														}}
													>
														<i className="icon-ia-edit-bold"></i>
													</a>
												</li>
												<li>
													<AdeleteConfirm
														title="Are you sure you want to delete this slide? All data will be lost."
														okText="Delete"
														onConfirm={() => onDocumentRemove(documentIndex)}
													>
														<a
															className="tbl-btn trigger-delete-slide-overlay"
															href="EditForm#"
														>
															<i className="icon-ia-trash-bold"/>
														</a>
													</AdeleteConfirm>
												</li>
												<li>
													<a
														className="tbl-btn dl-arrow"
														href="EditForm#"
														onClick={e => {
                                                            e.preventDefault()
															toggleDoumentActive(documentIndex)
														}}
													>
														<i className="icon-chevron-down"></i>
													</a>
												</li>
											</ul>
										</div>
										<div className="dl-cell-body">
											<DocumentEdit
												index={documentIndex}
												document={document}
												formId={params.id}
                                                access_token={access_token}
                                                setDocumentEditKey={setDocumentEditKey}
                                                setDocumentChanged={setDocumentChanged}
											/>
										</div>
									</div>
								))}
							</>

							<div className="dl-cell">
								<div className="upload-file-btn">
									<span>Upload files</span>
									<input type="file" multiple accept=".docx,.xlsx" onChange={onFilesChange} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</DocumentEditorStyledWrapper>
	)
}

const DocumentEditorStyledWrapper = styled.div`
	.dl-cell {
		width: 100%;
		max-width: 100%;
	}

	.docs-list {
		.scrollbar {
			padding: 3.5rem 0;
		}
	}
`

export default DocumentEditor

const DocumentEdit = ({ document, formId, access_token, index, setDocumentEditKey, setDocumentChanged }) => {
	React.useEffect(() => {
		if (document.active && document.key === undefined) {
			let key = uuid()
			setDocumentEditKey(index, key)

			const url = `http://api:3000/forms/${formId}/documents/${document._id}?token=${access_token}`
			new window.DocsAPI.DocEditor(document._id, {
				height: "100%",
				documentType:
					(
						document.filename.endsWith('.docx')
						&& "word"
					)
					||
					(
						document.filename.endsWith('.xlsx')
					) && "cell"
				,
				document: {
					url,
					title: document.filename,
					key,
					fileTYpe: (
							document.filename.endsWith('.docx')
							&& "docx"
						)
						||
						(
							document.filename.endsWith('.xlsx')
						) && "xlsx"
				},
				editorConfig: {
					mode: "edit",
					callbackUrl: url,
					customization: {
						forcesave: true,
						autosave: true,
						hideRightMenu:true,
						"chat": false,
						"comments": false,
						"anonymous": {
							"request": false,
						},
					},
				},
				events: {
					onDocumentStateChange: e => {
						if (e.data) {
							setDocumentChanged(index, true)
						}
					},
				},
			})
		}
	}, [document.active])

	return (
		<div css={`
            	> iframe {
                width: 100%;
                height: 80vh;
                border: 0;
            }
        `}>
			<div id={document._id} />
		</div>
	)
}
