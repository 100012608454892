import React from 'react'

export default ({
                    element
                }) => {
    return(
        <div className="fieldset-row">
            <div className="form-row with-calendar">
                <label>{element.text}</label>
                <input type="text" placeholder={element.placeholder} />
                <i className="calendar-btn icon-ia-calendar"/>
            </div>
        </div>
    )
}