import React from 'react'

import LivePreview from './LivePreview.privacyAgreement'
import {PrivacyAgreementEditor} from './Editor.privacyAgreement'
import CodeCopy from './CodeCopy.privacyAgreement'
import View from './View.privacyAgreement'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <PrivacyAgreementEditor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,

    default: () => ({
        type: "privacy agreement",
        text: "Privacy / GDPR Agreement Text",
        title: "Privacy / GDPR Agreement Title",
        pinned: {
            status: false
        },
        validations: []
    }),

    validate: () => false,
    group: "ADVANCED",
    icon: "icon-ia-gdpr",
    name: "Privacy / GDPR Agreement",
    view: args => <View {...args}/>
}