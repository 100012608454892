import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({
    element
}) => {
    return (
        <div className="fieldset-row">
            <div className="form-row">
                <label htmlFor="">{element.text}</label>
                <CodeCopy text={element.code}>
                    <textarea value="" onChange={f=>f} placeholder={element.placeholder}/>
                </CodeCopy>
            </div>
        </div>
    )
}

