import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({ 
    element, 
}) => {
    return(
        <div className="fieldset-row">
            <div className="form-row with-calendar">
                <label>{element.text}</label>
                <CodeCopy text={element.code}>
                    <input type="text" placeholder={element.placeholder} />
                </CodeCopy>
                <i className="calendar-btn icon-ia-calendar"/>
            </div>
        </div>
    )
}