import React from "react"
import { useParams, useHistory } from "react-router-dom"
import { dataURLToBlob } from "blob-util"

import Navbar from "../../../components/Navbar"
import FormCodes from "./FormMapping.FormCodes"
import DocumentEditor from "./FormMapping.DocumentEditor"
import { notification } from "../../../components/Lnotification"
import API from "../../../api"
import Loading from "../../../components/Loading"
import PageNotFound from "../../../components/PageNotFound"
import {useAppContext} from "../../../context";
import {css} from 'styled-components'
import Portrait from '../../../components/Portrait'

const FormMapping = () => {
	const [state, setState] = React.useState(
		{
			loadingForm: false,
			docsApiLoaded: false,
			form: null,
			savingDocuments: false,
			pageNotFound: false
		},
		"form-mapping"
	)

	const {
		auth: [
			{
				decodedToken: {
					userid,
					scope: [auth_role],
				},
			},
		],
	} = useAppContext()

	const params = useParams()
	const history = useHistory()

	React.useEffect(() => {
		const script = document.createElement("script")
		script.async = true
		script.src = `${process.env.REACT_APP_DOCUMENT_SERVER_URL}/web-apps/apps/api/documents/api.js`
		script.onload = () => setState(state => ({ ...state, docsApiLoaded: true }))
		script.onerror = () =>
			notification.warning({ message: "Document server not available" })
		const element = document.head.appendChild(script)
		return () => element.remove()
	}, [])

	React.useEffect(() => {
		loadForm()
	}, [])

	const loadForm = async () => {
		try {
			setState(state => ({ ...state, loadingForm: true }))
			const {
				data: { form },
			} = await API.get(`forms/${params.id}`)

			if (auth_role === "agent" || (auth_role === "manager" && userid !== form._owner)) {
				setState(state => ({
					...state,
					pageNotFound: true
				}))
			}

			setState(state => ({
				...state,
				form: {
					...form,
					logo: form.logo ? URL.createObjectURL(dataURLToBlob(form.logo)) : null,
					documents: form.documents.map(document => ({ ...document }))
				},
				loadingForm: false,
			}))
		} catch (err) {
			setState(state => ({
                ...state,
                loadingForm: false,
                ...(err.response.status === 404 ? {pageNotFound: true} : {})
            }))
		}
	}

	const setForm = callback =>
		setState(state => ({ ...state, form: callback(state.form) }))

	const save = async () => {
		try {
			const documentsToSave = state.form.documents.reduce(
				(acc, item) => (item.changed ? [...acc, item] : acc),
				[]
			)
			if (documentsToSave.length === 0) return

			setState(state => ({ ...state, savingDocuments: true }))

			await Promise.all(
				documentsToSave.map(
					item =>
						new Promise((resolve, reject) => {
							API.post(`forms/${params.id}/documents/${item._id}/saveRequest`, {
								key: item.key,
							})
								.then(response => resolve(response))
								.catch(err => reject(err))
						})
				)
			)

			setForm(form => ({
				...form,
				documents: form.documents.map(d =>
					d.changed ? { ...d, changed: false } : d
				),
			}))

			setState(state => ({ ...state, savingDocuments: false }))
		} catch (err) {
			setState(state => ({ ...state, savingDocuments: false }))
			notification.error({
				message: 'Failed to save changes!'
			})
		}
	}

	return (
		<>
			<Navbar />
			{state.loadingForm && <Loading/>}
			<PageNotFound active={!!state.pageNotFound}>
			
				<div className="wrapper parameters-page">
					<div className="content">
						
						{state.savingDocuments && 
							<Loading 
								rawCss={css`
									background: #F1F1F1;
									width: 100%;
									height: 100%;
									z-index: 100;
									position: absolute;
								`}
							/>
						}
						
						<div className="content-box">
							<div className="content-box-header">
								<h3>Documents and parameters</h3>
								<p className="supDescription">
									Drag and drop corresponding fields to their destination into the
									generated document(s).
								</p>
							</div>

							<FormCodes state={state} />

							<DocumentEditor state={state} setState={setState} />

							{history.location.state && history.location.state.newForm &&
								<div className="page-btn" css={`
									left: 2rem;
									right: unset;
									>button {border: none;}
								`}>
									<button className="button button-outline" onClick={async e => {
									e.preventDefault()
									await save()
									history.push(`/forms/${state.form._id}/edit/definition`, {newForm: true})
									}}><i className="icon-ia-arrow-left"></i><span>Back</span></button>
								</div>
								
							}
							<div className="page-btn">
								<a
									className="button trigger-overlay-finish-steps"
									onClick={async e => {
										e.preventDefault()
										await save()
										if (history.location.state && history.location.state.newForm && state.form.approvalForm.active) {
											history.push(`/forms/${state.form._id}/edit/approval`, {
												newForm: true
											})
										} else {
											history.push("/forms")
										}
									}}
									href="EditForm#"
								>
									<i className="icon-ia-checked-outline"/>
									<span>Save</span>
								</a>
							</div>
						</div>
					</div>
				</div>

			</PageNotFound>
			<Portrait />
		</>
	)
}

export default FormMapping
