import React from 'react'

import LivePreview from './LivePreview.imageUpload'
import {ImageUploadEditor} from './Editor.imageUpload'
import CodeCopy from './CodeCopy.imgeUpload'
import View from './View.imageUpload'
import Input from './Input.imageUpload'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <ImageUploadEditor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,

    default: () => ({
        type: "image upload",
        text: "Camera Caption Upload",
        pinned: {
            status: false
        },
        title: "Upload Another Image Button",
        validations: []
    }),

    availableValidations: ['required', 'max file size', 'multiple images'],
    validate: () => false,
    group: "GENERAL",
    icon: "icon-ia-camera",
    name: "Camera",
    view: args => <View {...args}/>
}