import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({ 
    element
}) => {
    return(
        <div className="fieldset-row">
            <p>{element.text}</p>
            <CodeCopy text={`%${element.code}`} >
                <div className="signature-pad"/>
            </CodeCopy>
        </div>
    )   
}
