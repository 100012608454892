import React from "react"
import { GoogleMap, InfoWindow } from "@react-google-maps/api"
import { Marker } from "@react-google-maps/api"

import { synchronizeText } from "../../utils/synchronizeText"
import { useAppContext } from "../../context"
import Loading from "../../components/Loading"
import GoogleMapsLoader from '../../components/GoogleMapsLoader'

const ViewMap = ({ element, answers_by_code, value = null  }) => {
	const {
		company: [company],
	} = useAppContext()

	return (
		<div className="fieldset-row" style={{ pointerEvents: "all" }}>
			{element.title && <p>{synchronizeText(element.title, answers_by_code)}</p>}
			{element.text && <p>{synchronizeText(element.text, answers_by_code)}</p>}
			<div className="location-box">
				<div id="map">
					<MapComponent value={value} />
				</div>
			</div>
		</div>
	)
}

const defaultLocation = {
	lat: 45.9237859,
	lng: 22.7775086,
}

const MapComponent = ({ value }) => {
	
	const {
		company: [company],
	} = useAppContext()


	return (
		<GoogleMapsLoader googleMapsApiKey={company.google_maps_api_key} loader={<Loading />}>
			<GoogleMap
				mapContainerStyle={{
					height: "100%",
					width: "100%",
				}}
				zoom={12}
				center={
					(value &&
						value.location &&
						value.location.coordinates && {
							lat: value.location.coordinates[1],
							lng: value.location.coordinates[0],
						}) ||
					defaultLocation
				}
				options={{
					mapTypeControl: false,
				}}
			>
				{value && (
					<Marker
						position={
							(value.location &&
								value.location.coordinates && {
									lat: value.location.coordinates[1],
									lng: value.location.coordinates[0],
								}) ||
							defaultLocation
						}
						icon={"img/pin.png"}
					>
						<InfoWindow>
							<span>{value.address || ""}</span>
						</InfoWindow>
					</Marker>
				)}
			</GoogleMap>
		</GoogleMapsLoader>
	)
}

export default ViewMap
