import React from "react"
import AdeleteConfirm from "../../components/AdeleteConfirm"
import defaultPhoto from "../../images/photo.svg"
import {TopBarContainer} from "../comonComponents/ElementEditor.TopBarContainer";

export const ImageEditor = ({
	element,
	remove,
	editElement,
	changeElementEditorMode,
	dragHandleProps = {},
	VisibilityAffected,
	formElements,
	isApprovalForm,
	addElementOnPosition,
	index
}) => {
	const [state, setState] = React.useState({
		localElement: {
			...element,
		},
		errors: {},
	})

	const localElement = state.localElement

	const setLocalElement = callback =>
		setState(state => ({
			...state,
			localElement: { ...callback(state.localElement) },
		}))

	const onOptionClick = () => {
		changeElementEditorMode(
			element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS"
		)
	}

	const onEditClick = () => {
		changeElementEditorMode("EDIT")
	}

	const onSaveClick = () => {
		const errors = {}
		if (localElement.text.length > 60) {
			errors.text = "Maximum 60 characters allowed"
		}
		setState(state => ({
			...state,
			errors,
		}))
		if (Object.keys(errors).length === 0) {
			editElement({
				...state.localElement,
			})
			changeElementEditorMode("DEFAULT")
		}
	}

	const onPinnedChange = ({ target: { checked } }) => {
		setLocalElement(localElement => ({
			...localElement,
			pinned: {
				status: checked,
			},
		}))
	}

	const onImageUplopad = e => {
		let reader = new FileReader()

		reader.addEventListener("load", () => {
			setLocalElement(localElement => ({
				...localElement,
				image: reader.result,
			}))
		})

		reader.readAsDataURL(e.target.files[0])
	}

	const duplicateComponent = () => {
		addElementOnPosition(index + 1,{
			...element,
			editorMode: 'DEFAULT'
		})
	}

	const openEditMode = () => {
		changeElementEditorMode("EDIT")
	};

	return (
		<div
			className={`form-box-wrap ${
				element.editorMode === "OPTIONS" && "view-box-options"
			} ${element.editorMode === "EDIT" && "view-box-edit"}`}
		>
			<div className="form-box-wrap-drag-btn" {...dragHandleProps}>
				<i className="icon-ia-drag" />
			</div>
			<div className="form-box-wrap-center">
				<div className="form-box-wrap-center-content" style={{cursor: "pointer"}} onClick={openEditMode}>
					<h6 className="supTitle">Upload image</h6>
					<div
						className="form-grid"
						style={{ gridTemplateColumns: "repeat(1, 1fr)" }}
					>
						<div className="form-row-image">
							<div className="form-row-image-cell">
								<img src={localElement.image ? localElement.image : defaultPhoto} />
							</div>
							<p
								className="image-caption"
								style={{ padding: 0, fontStyle: "normal", opacity: 1 }}
							>
								{localElement.text}
							</p>
						</div>
					</div>
					<TopBarContainer>
						<VisibilityAffected element={element} formElements={formElements}/>
					</TopBarContainer>
					<p className="form-row-validation-text">
						{localElement.validations.map(v => `[ ${v.validation} ]  `)}
						{localElement.pinned && localElement.pinned.status
							? "[ show on top ]"
							: ""}
					</p>
				</div>
				<div className="form-box-wrap-options">
					<div className="form-box-wrap-options-buttons">
						<span onClick={onEditClick}>
							<i className="icon-ia-edit-bold trigger-edit-box" />
							Edit
						</span>
						<AdeleteConfirm
							onConfirm={remove}
							okText="Delete"
							cancelText="Cancel"
							title="Are you sure you want to delete this component? All data will be lost. "
						>
							<span>
								<i className="icon-ia-trash-bold trigger-delete-box" />
								Delete
							</span>
						</AdeleteConfirm>
						<span onClick={duplicateComponent}><i className="icon-copy"/>Duplicate</span>
					</div>
				</div>
			</div>
			<div className="form-box-wrap-options-btn" onClick={onOptionClick}>
				<i className="icon-ia-more" />
			</div>
			<div className="form-box-wrap-center-edit">
				<div className="upload-image-grid">
					<div className="upload-image-box">
						<input
							className="upload-image-input"
							type="file"
							name=""
							onChange={onImageUplopad}
							accept="image/*"
						/>
					</div>
					<div className={`form-row ${state.errors.text ? "error" : ""}`}>
						<input
							id="img-caption"
							type="text"
							name=""
							placeholder="Image Caption"
							value={localElement.text}
							onChange={({ target: { value } }) => {
								setLocalElement(localElement => ({
									...localElement,
									text: value,
								}))
							}}
						/>
						{state.errors.text && (
							<span className="form-row-error-msg">{state.errors.text}</span>
						)}
					</div>
				</div>
				{!isApprovalForm &&
                    <div className="frc-box">
                        <div className="form-row-checkbox">
                            <input
                                id={`pinned-${localElement.code}`}
                                type="checkbox"
                                checked={localElement.pinned && localElement.pinned.status}
                                onChange={onPinnedChange}
                            />
                            <label htmlFor={`pinned-${localElement.code}`}>
                                Show on top in saved session
                            </label>
                        </div>
                    </div>
                }
				<a className="form-box-wrap-button" onClick={onSaveClick}>
					Save
				</a>
			</div>
		</div>
	)
}
