import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({ 
    element
}) => {
    return(
        <div className="fieldset-row">
            <CodeCopy text={`${element.code}_name`}>
                <p>User Name</p>
            </CodeCopy>
            <CodeCopy text={`${`%${element.code}`}_signature`}>
                <div className="signature-pad"></div>
            </CodeCopy>
        </div>
    )
}