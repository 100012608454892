import React from "react"
import { Droppable, Draggable } from "react-beautiful-dnd"
import { useHistory } from "react-router-dom"

import supportedElements from "../../../formComponents/supportedElements"
import AdeleteConfirm from '../../../components/AdeleteConfirm'
import ConditionalValidation, {VisibilityAffected} from '../../../formComponents/ConditionalValidation'
import API from "../../../api"
import {notification} from "../../../components/Lnotification";
import {elements as ELEMENTS} from '../../../constants/elements'

const Editor = React.forwardRef(
	(
		{ state, setState, setForm, changeStep, addElementOnPosition, addElement, addScreen },
		ref
	) => {
		const { form, selectedStep } = state

		const history = useHistory()

		const setSelectedStep = callback =>
			setForm(form => ({
				...form,
				steps: form.steps.map((s, i) => (selectedStep !== i ? s : callback(s))),
			}))

		const editElement = (elementIndex, el) =>
			setSelectedStep(step => ({
				...step,
				elements: step.elements.map((e, i) => (elementIndex !== i ? e : el)),
			}))

		const removeElement = elementIndex =>
			setSelectedStep(step => ({
				...step,
				elements: step.elements.filter((e, i) => i !== elementIndex),
			}))

		const changeElementEditorMode = (elementIndex, mode) => {
			setSelectedStep(step => ({
				...step,
				elements: step.elements.map((el, i) =>
					i !== elementIndex
						? { ...el, editorMode: "DEFAULT" }
						: { ...el, editorMode: mode }
				),
			}))
		}

		const editElementByCode = (code, edited) => {

			setForm(form => ({
				...form,
				steps: form.steps.map((step) => {
					if(step.elements.find(element => element.code === code)) {
						return {
							...step,
							elements: step.elements.map(e => (e.code !== code ? e : edited))
						}
					}  
					return step
				}),
			}))
		}

		const formElements = form
			? form.steps.reduce((acc, step) => [...acc, ...step.elements], [])
			: []

		const removeScreen = screenIndex => {

			// Clear Conditional visibility dependency
			const oppressedElements = formElements.filter(element => !!element.dependency)
			if(oppressedElements.length > 0) {
				const oppressorElementTypes = [ELEMENTS.DROPDOWN, ELEMENTS.CHECKBOX_GROUP, ELEMENTS.RADIO_GROUP]

				const oppressorElementsOfThisStep = form.steps[screenIndex].elements.filter(element => 
					oppressorElementTypes.includes(element.type)
				)
				.filter(oppressor => 
					!!oppressedElements.find(oppressed => oppressed.dependency.element_code === oppressor.code)
				)

				oppressorElementsOfThisStep.forEach(oppresor => {
					clearOppressedElements(oppresor.code)
				})
			}

			setForm(form => ({
				...form,
				steps: form.steps.filter((s, i) => i !== screenIndex),
			}))
		}

		const clearOppressedElements = oppressorCode => {
			setForm(form => ({
				...form,
				steps: form.steps.map(step => ({
					...step,
					elements: step.elements.map(potencialyOppressedElement =>
						potencialyOppressedElement.dependency &&
						potencialyOppressedElement.dependency.element_code === oppressorCode
							? { ...potencialyOppressedElement, dependency: undefined }
							: potencialyOppressedElement
					),
				})),
			}))
		}


		const clearOppressedAndShiftAffectedIndexes = (masterCode, optionIndex) =>
			setForm(form => ({
				...form,
				steps: form.steps.map(step => ({
					...step,
					elements: step.elements.map(potencialyOppressedElement => {
						if (
							potencialyOppressedElement.dependency &&
							potencialyOppressedElement.dependency.element_code === masterCode &&
							potencialyOppressedElement.dependency.option === optionIndex
						)
							return { ...potencialyOppressedElement, dependency: undefined }

						if (
							potencialyOppressedElement.dependency &&
							potencialyOppressedElement.dependency.element_code === masterCode &&
							potencialyOppressedElement.dependency.option > optionIndex
						)
							return {
								...potencialyOppressedElement,
								dependency: {
									...potencialyOppressedElement.dependency,
									option: potencialyOppressedElement.dependency.option - 1,
								},
							}

						return potencialyOppressedElement
					}),
				})),
			}))

		const deleteChain = masterCode => {
			setForm(form => ({
				...form,
				steps: form.steps.map(step => ({
					...step,
					elements: step.elements.filter(
						potencialSlave =>
							!(
								potencialSlave.type === "dropdown" &&
								potencialSlave.chained &&
								potencialSlave.chained.role === "slave" &&
								potencialSlave.chained.masterCode === masterCode
							)
					),
				})),
			}))
		}


		const changeTitle = newTitle =>
			setSelectedStep(step => ({ ...step, heading: newTitle }))

		const save = async callback => {
			if (state.form.activation && !state.form.steps.reduce(
				(acc, step) => step.elements.reduce((acc, {type}) => (type === ELEMENTS.CONTRACT_COMMUNICATION_OPTIONS) || acc, false) || acc, false)) {
				notification.warning({message: `Contract Communication Options is mandatory. Please add it in your form so that your client gets to receive your contract.`});
				return
			}

			if (state.form.steps.reduce(
				(acc, step) => step.elements.reduce((acc, {editorMode}) => editorMode === "EDIT" || acc, false) || acc, false)) {
					notification.warning({
						message: <p>Please save the component you are editing</p>
					})
				// notification.warning({message: `Please save the component you are editing`});
				return
			}

			try {
				setState(state => ({ ...state, savingFormDefinition: true }))

				const steps = form.steps.map(step => ({
					...step,
					elements: step.elements.map(element => {
						const {editorMode, ...elementCleanFromUiProps} = element;
						return elementCleanFromUiProps
					})
				}))

				await API.put(`forms/${state.form._id}`, { steps })
				// notification.cancel()
				setState(state => ({
					...state,
					savingFormDefinition: false,
					unsavedChanges: false
				}))
				callback()
			} catch (err) {
				setState(state => ({ ...state, savingFormDefinition: false }))
			}
		}

		return (
			<>
				<div className="content-box">
					<form className="form form-step-2">
						<div className="scrollbar">
							<div className="form-box-header">
								<h6 className="supTitle">New form</h6>
								<div className="form-row">
									<input
										id="form-name"
										type="text"
										value={form.steps[selectedStep].heading}
										onChange={({ target: { value } }) => changeTitle(value)}
										placeholder="Screen Title"
									/>
								</div>
							</div>
							<Droppable droppableId="editor">
								{provided => (
									<div
										className="form-box-body"
										ref={el => {
											provided.innerRef(el)
											ref(el)
										}}
										{...provided.droppableProps}
									>
										{form.steps[selectedStep].elements.map((element, elementIndex) => {
											if(!supportedElements[element.type]) return null
										
											return (
												<Draggable
													draggableId={elementIndex.toString()}
													index={elementIndex}
													key={element.code}
												>
													{provided => (
														<div ref={provided.innerRef} {...provided.draggableProps}>
															{supportedElements[element.type].editor({
																element,
																index: elementIndex,
																editElement: el => editElement(elementIndex, el),
																remove: () => removeElement(elementIndex),
																changeElementEditorMode: mode =>
																	changeElementEditorMode(elementIndex, mode),
																dragHandleProps: provided.dragHandleProps,
																form: {...form, selectedStep: state.selectedStep},
																formElements,
																VisibilityAffected,
																ConditionalValidation,
																editElementByCode,
																clearOppressedElements,
																clearOppressedAndShiftAffectedIndexes,
																addElementOnPosition,
																addElement,
																deleteChain,
																addScreen
															})}
														</div>
													)}
												</Draggable>
											)
									
										})}
										{provided.placeholder}

										{form.steps[selectedStep].elements.length === 0 && (
											<div className="form-box-wrap drag-zone-box">
												<div>
													<i className="icon-ia-drag-out" />
													<span>
														Drag a Form Component
														<br />
														or a specialised Component
													</span>
												</div>
											</div>
										)}

										<div className="form-box-actions">
											{history.location.state && history.location.state.newForm && (
												<button
													onClick={() => {
														save(() => {
															history.push(`/forms/${form._id}/edit/properties`, {
																newForm: true,
															})
														})
													}}
													type="button"
													className="button button-outline"
												>
													<i className="icon-ia-arrow-left" />
													<span>Back</span>
												</button>
											)}

											{form.steps.length > 1 && (
												<AdeleteConfirm
													title="Are you sure you want to delete this slide? All data will be lost."
													onConfirm={() => {
														selectedStep > 0 && changeStep(selectedStep - 1)
														removeScreen(selectedStep)
													}}
													okText="Delete"
													cancelText="Cancel"
												>
													<button type="button" className="button button-outline">
														<i className="icon-ia-trash" />
														<span>Delete Screen</span>
													</button>
												</AdeleteConfirm>
											)}
											<button
												onClick={() => {
													try {
														addScreen()
													} catch(err) {
														return
													}
												}}
												type="button"
												className="button button-outline"
											>
												<i className="icon-ia-plus" />
												<span>
													Add
													<br />
													Screen
												</span>
											</button>
											<button
												onClick={() => {
													save(() => {
														if (history.location.state && history.location.state.newForm) {
															history.push(`/forms/${state.form._id}/edit/mapping`, {
																newForm: true,
															})
														} else {
															history.push("/forms")
														}
													})
												}}
												type="button"
												className="button"
											>
												<i className="icon-ia-checked-outline" />
												<span>SAVE</span>
											</button>
										</div>
									</div>
								)}
							</Droppable>
						</div>
					</form>
				</div>
			</>
		)
	}
)

export default Editor
