import React from 'react'
import { withRouter, Prompt } from 'react-router-dom'

export default withRouter(({when=false, children, history, promptCallback}) => {
    const [state, setState] = React.useState({
        active: false,
        confirm: false,
        nextLocation: null
    })

    React.useEffect(() => {
        state.confirm && history.push(state.nextLocation.pathname)
    }, [state.confirm])

    React.useEffect(() => {
        if(state.active) {
            promptCallback({onConfirm, onCancel})
        }
    }, [state.active])

        const onConfirm = () => setState(state => ({...state, confirm: true, active: false}))
        const onCancel = () => setState(state => ({...state, confirm: false, active: false}))

    return (
        <>
            <Prompt 
                when={when}
                message={nextLocation => {
                    if(state.confirm){
                        return true
                    } else {
                        setState(state => ({...state, active: true, nextLocation}))
                        return false
                    }
                }}
            />
            {state.active && typeof children === 'function' &&
                children({onConfirm, onCancel})
            }
        </>
    )
})