import React from 'react'

export default ({
    element
}) => {
    return (
   
        <div className="fieldset-row">
            <div className="form-row">
                <label>{element.text}</label>
                <select>
                    <option/>
                </select>
            </div>
        </div>
 
    )
}
