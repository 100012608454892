export const GET_CLIENTS_PENDING = 'GET_CLIENTS_PENDING';
export const GET_CLIENTS_REJECTED = 'GET_CLIENTS_REJECTED';
export const GET_CLIENTS_FULFILLED = 'GET_CLIENTS_FULFILLED';
export const SET_PAGE = 'SET_PAGE';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_SORT = 'SET_SORT';

export const GET_CLIENT_PENDING = 'GET_CLIENT_PENDING';
export const GET_CLIENT_REJECTED = 'GET_CLIENT_REJECTED';
export const GET_CLIENT_FULFILLED = 'GET_CLIENT_FULFILLED';

export const GET_CLIENT_SESSIONS_PENDING = 'GET_CLIENT_SESSIONS_PENDING';
export const GET_CLIENT_SESSIONS_REJECTED = 'GET_CLIENT_SESSIONS_REJECTED';
export const GET_CLIENT_SESSIONS_FULFILLED = 'GET_CLIENT_SESSIONS_FULFILLED';

export const SET_UPDATE_CLIENT_ERRORS = 'SET_UPDATE_CLIENT_ERRORS';
export const UPDATE_CLIENT_PENDING = 'UPDATE_CLIENT_PENDING';
export const UPDATE_CLIENT_REJECTED = 'UPDATE_CLIENT_REJECTED';
export const UPDATE_CLIENT_FULFILLED = 'UPDATE_CLIENT_FULFILLED';

export const DELETE_CLIENT_PENDING = 'DELETE_CLIENT_PENDING';
export const DELETE_CLIENT_FULFILLED = 'DELETE_CLIENT_FULFILLED';
export const DELETE_CLIENT_REJECTED = 'DELETE_CLIENT_REJECTED';

export const SET_CLIENT_FIRST_NAME = 'SET_CLIENT_FIRST_NAME';
export const SET_CLIENT_LAST_NAME = 'SET_CLIENT_LAST_NAME';
export const SET_CLIENT_PHONE = 'SET_CLIENT_PHONE';
export const SET_CLIENT_EMAIL = 'SET_CLIENT_EMAIL';
export const SET_CLIENT_COUNTRY = 'SET_CLIENT_COUNTRY';
export const SET_CLIENT_LOCALITY = 'SET_CLIENT_LOCALITY';
export const SET_CLIENT_STREET = 'SET_CLIENT_STREET';
export const SET_CLIENT_NR = 'SET_CLIENT_NR';
export const SET_CLIENT_BLOCK = 'SET_CLIENT_BLOCK';
export const SET_CLIENT_ENTRY = 'SET_CLIENT_ENTRY';
export const SET_CLIENT_FLOOR = 'SET_CLIENT_FLOOR';
export const SET_CLIENT_APARTMENT = 'SET_CLIENT_APARTMENT';

export const initialState = {
    loading: false,
    loading_client_sessions: false,
    deletingClient: false,
    update_pending: false,
    update_errors: [],
    error: null,
    error_client_sessions: null,
    data: [],
    pages: 0,
    page: 1,
    search: '',
    sort: 'updated_at',
    order: 'desc',
    client_sessions: [],
    updating_client: {
        _id: '',
        name: '',
        phone: '',
        address: '',
        email: '',
        monthly_income: '',
        children_no: null
    }
};

export const reducer = (state, {type, payload}) => {
    switch (type) {

        case GET_CLIENTS_PENDING:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_CLIENTS_REJECTED:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case GET_CLIENTS_FULFILLED:
            return {
                ...state,
                loading: false,
                data: payload.data,
                pages: payload.pages
            };

        case SET_PAGE:
            return {
                ...state,
                page: payload
            };
        case SET_SEARCH:
            return {
                ...state,
                search: payload
            };
        case SET_SORT:
            return {
                ...state,
                sort: payload.sort,
                order: payload.order
            };

        case GET_CLIENT_PENDING:
            return {
                ...state,
                update_pending: true,
                update_error: null
            };
        case GET_CLIENT_REJECTED:
            return {
                ...state,
                update_pending: false,
                update_error: payload,
            };
        case GET_CLIENT_FULFILLED:
            return {
                ...state,
                update_pending: false,
                updating_client: payload,
                update_errors: []
            };

        case SET_UPDATE_CLIENT_ERRORS:
            return {
                ...state,
                update_errors: payload
            };
        case UPDATE_CLIENT_PENDING:
            return {
                ...state,
                update_pending: true,
                update_errors: []
            };
        case UPDATE_CLIENT_REJECTED:
            return {
                ...state,
                update_pending: false,
                update_errors: [payload]
            };
        case UPDATE_CLIENT_FULFILLED:
            return {
                ...state,
                update_pending: false,
                data: state.data.map(client => {
                    if (client._id === state.updating_client._id) {
                        let address = '';

                        const country_has_been_set = state.updating_client.country !== null && state.updating_client.country !== '';
                        const locality_has_been_set = state.updating_client.locality !== null && state.updating_client.locality !== '';
                        const street_has_been_set = state.updating_client.street !== null && state.updating_client.street !== '';

                        if (street_has_been_set) {
                            address = state.updating_client.street;
                        }

                        if (locality_has_been_set) {
                            if (street_has_been_set) {
                                address += ', ' + state.updating_client.locality;
                            } else {
                                address = state.updating_client.locality;
                            }
                        }

                        if (country_has_been_set) {
                            if (street_has_been_set || locality_has_been_set) {
                                address += ', ' + state.updating_client.country;
                            } else {
                                address = state.updating_client.country;
                            }
                        }

                        return {
                            ...client,
                            name: (state.updating_client.first_name ? state.updating_client.first_name : "") + ' ' + (state.updating_client.last_name ? state.updating_client.last_name : ""),
                            phone: state.updating_client.phone,
                            address,
                            email: state.updating_client.email,
                        }
                    }

                    return client;
                })
            };
        case SET_CLIENT_FIRST_NAME:
            return {
                ...state,
                updating_client: {
                    ...state.updating_client,
                    first_name: payload
                }
            };
        case SET_CLIENT_LAST_NAME:
            return {
                ...state,
                updating_client: {
                    ...state.updating_client,
                    last_name: payload
                }
            };
        case SET_CLIENT_EMAIL:
            return {
                ...state,
                updating_client: {
                    ...state.updating_client,
                    email: payload
                }
            };
        case SET_CLIENT_PHONE:
            return {
                ...state,
                updating_client: {
                    ...state.updating_client,
                    phone: payload
                }
            };
        case SET_CLIENT_COUNTRY:
            return {
                ...state,
                updating_client: {
                    ...state.updating_client,
                    country: payload
                }
            };
        case SET_CLIENT_LOCALITY:
            return {
                ...state,
                updating_client: {
                    ...state.updating_client,
                    locality: payload
                }
            };
        case SET_CLIENT_STREET:
            return {
                ...state,
                updating_client: {
                    ...state.updating_client,
                    street: payload
                }
            };
        case SET_CLIENT_NR:
            return {
                ...state,
                updating_client: {
                    ...state.updating_client,
                    nr: payload
                }
            };
        case SET_CLIENT_BLOCK:
            return {
                ...state,
                updating_client: {
                    ...state.updating_client,
                    block: payload
                }
            };
        case SET_CLIENT_ENTRY:
            return {
                ...state,
                updating_client: {
                    ...state.updating_client,
                    entry: payload
                }
            };
        case SET_CLIENT_FLOOR:
            return {
                ...state,
                updating_client: {
                    ...state.updating_client,
                    floor: payload
                }
            };
        case SET_CLIENT_APARTMENT:
            return {
                ...state,
                updating_client: {
                    ...state.updating_client,
                    apartment: payload
                }
            };

        case GET_CLIENT_SESSIONS_PENDING:
            return {
                ...state,
                loading_client_sessions: true,
                error_client_sessions: null,
                client_sessions: []
            };
        case GET_CLIENT_SESSIONS_REJECTED:
            return {
                ...state,
                loading_client_sessions: false,
                error_client_sessions: payload
            };
        case GET_CLIENT_SESSIONS_FULFILLED:
            return {
                ...state,
                loading_client_sessions: false,
                client_sessions: payload
            };


        case DELETE_CLIENT_PENDING:
            return {
                ...state,
                deletingClient: true
            };
        case DELETE_CLIENT_REJECTED:
            return {
                ...state,
                deletingClient: false,
                error: payload
            };
        case DELETE_CLIENT_FULFILLED:
            return {
                ...state,
                deletingClient: false,
                data: state.data.filter(client => client._id !== payload)
            };
        default:
            return state;
    }
};