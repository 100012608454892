import React from 'react'
import API from '../../api'
import Loading from '../../components/Loading'

const ResetPassword = (props) => {
    const [state, setState] = React.useState({
        token: props.token,
        password: "",
        repeatPassword: "",
        loading: false,
        success: false,
        error: null
    });

    const renderError = () => {
        switch (state.error) {
            case 'invalid_token':
                return 'Token was not found';
            case 'token_expired':
                return 'Token has expired';
            case 'user_not_found':
                return 'There is no user with such email';
            case 'password_required':
                return 'Password is required';
            case 'password_min_length':
                return 'Password needs to be at least 6 characters';
            case 'passwords_do_not_match':
                return 'Passwords do not match';
            default:
                return 'Something went wrong'
        }
    };

    const onClickReset = async () => {
        let {password, repeatPassword, token} = state;
        setState(state => ({...state, loading: true, success: false, error: null}));
        if (!password || password.length === 0) {
            setState({loading: false, error: 'password_required'});
            return;
        }

        if (password.length < 6) {
            setState(state => ({...state, loading: false, error: 'password_min_length'}));
            return;
        }

        if (password !== repeatPassword) {
            setState(state => ({...state, loading: false, error: 'passwords_do_not_match'}));
            return;
        }

        try {
            await API.post('/auth/reset-password', {token, password});
            setState({loading: false, success: true})
        } catch (err) {
            setState(state => ({...state, loading: false, error: err.response.data.error}))
        }
    };

    return (
        <>
            {state.loading && <Loading />}
            {!state.loading &&
            <div className="change-pass-box">
                <div className="forgot-pass-header">
                    <span className="button button-link with-icon-left hide-forgot-pass" onClick={props.onLogin}><i
                        className="icon-ia-arrow-bottom"/>Back to login</span>
                </div>
                <div className="forgot-pass-content">
                    <div className="forgot-pass-content-wrapper">
                        {!state.success &&
                        <div className="forgot-pass-content-tab">
                            <div className="scrollbar">
                                <div className="table">
                                    <div className="table-cell">
                                        <form className="form login-form" onSubmit={e => {
                                            e.preventDefault();
                                            onClickReset();
                                        }}>
                                            <h3>Change password</h3>
                                            {state.error !== null &&
                                            <div className="error-msg">{renderError()}</div>
                                            }
                                            <div className="form-row">
                                                <label>New Password</label>
                                                <input id="form-name" type="password" name="password"
                                                       placeholder="Password"
                                                       onChange={({target: {value: password}}) => setState(state => ({
                                                           ...state,
                                                           password
                                                       }))} value={state.password}/>
                                            </div>
                                            <div className="form-row">
                                                <label>Retype New Password</label>
                                                <input id="form-name" type="password" name="repeatPassword"
                                                       placeholder="Repeat Password"
                                                       onChange={({target: {value: repeatPassword}}) => setState(state => ({
                                                           ...state,
                                                           repeatPassword
                                                       }))}
                                                       value={state.repeatPassword}/>
                                            </div>
                                            <div className="form-row last">
                                                <button className="button with-icon-right"><i
                                                    className="icon-ia-checked-outline"/>Save
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {state.success &&
                        <div className="forgot-pass-content-tab">
                            <div className="table">
                                <div className="table-cell">
                                    <div className="form login-form forgot-pass-message">
                                        <h3><i className="icon-check-circle"/>Your password has been changed</h3>
                                        <p><span className="hide-forgot-pass"
                                                 onClick={props.onLogin}>Back to login</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default ResetPassword