import React from 'react'
import list from './countryCityList.json'

export { list }

export const Country = ({ 
    placeholder = "", 
    value,
    onChange=f=>f,
    name="",
    ...rest 
}) => {
    return(
        <select {...rest} value={value || ""} onChange={onChange} name={name}>
            <option value="" disabled hidden>{placeholder}</option>
            {Object.keys(list).sort().map((country, countryIndex) => (
                <option value={country} key={countryIndex}>{country}</option>
            ))}
        </select>
    )
}

export const City = ({ 
    selectedCountry = null, 
    placeholder = "",
    name="",
    value,
    onChange=f=>f, 
    ...rest
}) => {
    return(
        <select {...rest} value={value || ""} onChange={onChange} name={name}>
            <option value="" disabled hidden>{placeholder}</option>
            {!!selectedCountry && list[selectedCountry]
                && list[selectedCountry].sort().map((city, cityIndex) => (
                    <option value={city} key={cityIndex}>{city}</option>
                ))
            }
        </select>
    )
}