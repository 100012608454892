import React from "react"
import { useHistory } from "react-router-dom"
import moment from "moment"
import { notification } from "../../../components/Lnotification"
import { saveAs } from "file-saver"
import { css } from "styled-components"
import Imodal from "../../../components/ApopUp"

import Loading from "../../../components/Loading"
import API from "../../../api"
import ExtraColumnsConfig from './ExtraColumnsConfig'
import Ipagination from '../../../components/Ipagination'
import {getError} from "../../../utils/getError"
import {useAppContext} from '../../../context'

import AdatePicker from '../../../components/AdatePicker/AdatePicker'
import AdeleteConfirm from '../../../components/AdeleteConfirm'
import Map from "./SessionData.Map";
import styled from 'styled-components'

const Sessions = ({setHeaderTab, headerTab, sessionsPageStore:[,setSessionsPageStore]}) => {
    
    const history = useHistory()
    const page = new URLSearchParams(history.location.search).get('page')

    const [state, setState] = React.useState({
        loading: false,
        error: null,
        data: [],
        pages: 0,
        total: 0,
        categories: [],
        categoriesDropDown: false,
        categorySearch: '',

        filters: [],

        forms: [],
        formsDropDown: false,
        formsSearch: '',

        agents: [],
        agentsDropdown: false,
        agentSearch: '',

        clientSearch: '',

        clients: [],
        selected_categories: [],
        selected_forms: [],
        selected_agents: [],
        page: page || 1,
        //for the map infowindow
        places: [],
        date_from: null,
        date_to: null,

        sort: null,
        order: null,

        activation: [],
        activationDropdown: false,

        completion: [],
        completionDropdown: '',

        approvation: [],
        approvationDropdown: false,

        search: "",
        filtersCollapsed: true,
        savedFiltersCollapsed: true,

        extraColumns: [],

        newFilterName: '',
        newFilterError: null,

        editFilterName: '',
        editFilterError: null,
        editFilterId: null
    }, 'sessions-data')



    const searchDebounceStore = React.useState(null)


    const {auth: [auth], company: [company]} = useAppContext()
    const auth_role = auth.decodedToken.scope[0]

    React.useEffect(() => {
        getSessions(state)
        getCategories()
        getForms()
        getAgents()
        getFilters()
        getExtraColumnsConfig()
    }, [])

    //

    const debounce = (callback, delay, store) => {
        const [sceduled, setSceduled] = store
        if (sceduled) clearTimeout(sceduled)
        setSceduled(setTimeout(callback, delay))
    }

    const getDebouncedSessions = args => debounce(() => getSessions(args), 1000, searchDebounceStore)

    const searchClient = clientSearch => {
        setState(state => ({...state, clientSearch}))
        getDebouncedSessions({...state, page: 1, clientSearch})
    }

    const getSessions = async (state = undefined) => {
        try {
            setState(state => ({...state, loading: true, error: null}))
            let url = '/sessions'
            const filters = []
            if (state) {
                if (state.sort) filters.push(`sort=${state.sort}&order=${state.order}`)
                if (state.page) filters.push(`page=${state.page}`)
                if (state.selected_categories.length) filters.push(`category_ids=${state.selected_categories.join(',')}`)
                if (state.clientSearch.length) filters.push(`client_text=${state.clientSearch}`)
                if (state.selected_forms.length) filters.push(`form_ids=${state.selected_forms.join(',')}`)
                if (state.selected_agents.length) filters.push(`agent_ids=${state.selected_agents.join(',')}`)
                if (state.date_from) filters.push(`date_from=${state.date_from}`)
                if (state.date_to) filters.push(`date_to=${state.date_to}`)
                if (state.activation) filters.push(`activation=${state.activation}`)
                if (state.completion) filters.push(`finished=${state.completion}`)
                if (state.approvation) filters.push(`approval=${state.approvation}`)
                if (state.search) filters.push(`search=${state.search}`)
            }
            if (filters.length) url += '?' + filters.join('&')
            const {data} = await API.get(url)
            setState(state => ({
                ...state,
                loading: false,
                data: data.data,
                pages: data.pages,
                total: data.total
            }))
            if (state && state.page) selectPage(state.page)
            setSessionsPageStore(sessionsPageSate => ({...sessionsPageSate, loadedSessionsOnce: true}))
        } catch (err) {
            setState(state => ({...state, loading: false, error: getError(err.response)}))
        }
    }

    const getSessionsByLocation = async () => {
        try {
            setState(state => ({...state, loading: true, error: null}))
            let url = '/sessions/withLocation'
            const {data} = await API.get(url)
            setState(state => ({
                ...state,
                loading: false,
                places: data
            }))
        } catch (err) {
            setState(state => ({...state, loading: false, error: getError(err.response)}))
        }
    }

    const selectPage = page => setState(state => ({...state, page}))

    const exportSessionsRaw = async () => {
        try {
           let url = `${process.env.REACT_APP_API_BASE_URL}/sessions/export/excel-with-filters`

            const filters = []
            if (state.sort) filters.push(`sort=${state.sort}&order=${state.order}`)
            if (state.selected_categories.length) filters.push(`category_ids=${state.selected_categories.join(',')}`)
            if (state.selected_forms.length) filters.push(`form_ids=${state.selected_forms.join(',')}`);
            if (state.clientSearch.length) filters.push(`client_text=${state.clientSearch}`)
            if (state.selected_agents.length) filters.push(`agent_ids=${state.selected_agents.join(',')}`)
            if (state.date_from) filters.push(`date_from=${state.date_from}`)
            if (state.date_to) filters.push(`date_to=${state.date_to}`)
            if (state.activation) filters.push(`activation=${state.activation}`)
            if (state.completion) filters.push(`finished=${state.completion}`)
            if (state.approvation) filters.push(`approval=${state.approvation}`)
            if (state.search) filters.push(`search=${state.search}`)
            url += '?' + filters.join('&')

            const response = await API.get(url, {responseType: 'blob'})

            saveAs(response.data, `sessions_list.xlsx`)
        } catch(err) {
            notification.error({
                message: 'Something went wrong with downloading the file'
            })
        }
    }

    const exportSessionRequest = async export_type => {

        try {
            let url = `${process.env.REACT_APP_API_BASE_URL}/sessions/list/export/${export_type}`
            const filters = []
            if (state.sort) filters.push(`sort=${state.sort}&order=${state.order}`)
            if (state.selected_categories.length) filters.push(`category_ids=${state.selected_categories.join(',')}`)
            if (state.selected_forms.length) filters.push(`form_ids=${state.selected_forms.join(',')}`);
            if (state.clientSearch.length) filters.push(`client_text=${state.clientSearch}`)
            if (state.selected_agents.length) filters.push(`agent_ids=${state.selected_agents.join(',')}`)
            if (state.date_from) filters.push(`date_from=${state.date_from}`)
            if (state.date_to) filters.push(`date_to=${state.date_to}`)
            if (state.activation) filters.push(`activation=${state.activation}`)
            if (state.completion) filters.push(`finished=${state.completion}`)
            if (state.approvation) filters.push(`approval=${state.approvation}`)
            if (state.search) filters.push(`search=${state.search}`)
            url += '?' + filters.join('&')
            const response = await API.get(url, {responseType: 'blob'})

            saveAs(response.data, `sessions_list.${{'pdf': 'pdf', 'excel': 'xlsx'}[export_type]}`)
        } catch (err) {
            notification.error({
                message: 'Something went wrong with downloading the file'
            })
        }
    }

    const changePage = page => {
        history.push({
            pathname: history.location.pathname,
            search: `?page=${page}`
        })
        getSessions({...state, page})
    }

    const toggleHeaderTab = async (h) => {
        if (h === "map" && state.places.length === 0) {
            await getSessionsByLocation()
        }
        setHeaderTab(headerTab => headerTab === h ? null : h)
    }

    const getCategories = async () => {
        try {
            const {data} = await API.get('/categories/list/all')
            setState(state => ({...state, categories: data}))
        } catch (err) {
            notification.error({message: "Failed to load categories"})
        }
    }

    const getForms = async () => {
        try {
            const {data} = await API.get('/forms/list/all')
            setState(state => ({...state, forms: data}))
        } catch (err) {
            notification.error({message: "Failed to load forms"})
        }
    }

    const getAgents = async () => {
        try {
            const {data} = await API.get('/users/list/all/agents?publicForm=true')
            // if (state) {
            //     await selectAgents(state.selected_agents.filter(agent => response.data.map(agent => agent._id).includes(agent)))
            // }
            setState(state => ({...state, agents: data}))
        } catch (err) {
            notification.error({message: "Failed to load users"})
        }
    }

    const toggleChoseCategory = category => {
        let {selected_categories} = state
        selected_categories.includes(category)
            ? selected_categories = selected_categories.filter(c => c !== category)
            : selected_categories = [...selected_categories, category]
        getSessions({...state, page: 1, selected_categories})
        setState(state => ({...state, selected_categories}))
    }

    const toggleChoseForm = form => {
        let {selected_forms} = state
        selected_forms.includes(form)
            ? selected_forms = selected_forms.filter(f => f !== form)
            : selected_forms = [...selected_forms, form]
        getSessions({...state, page: 1, selected_forms})
        setState(state => ({...state, selected_forms}))
    }

    const toggleChoseAgent = agent => {
        let {selected_agents} = state
        selected_agents.includes(agent)
            ? selected_agents = selected_agents.filter(a => a !== agent)
            : selected_agents = [...selected_agents, agent]
        getSessions({...state, page: 1, selected_agents})
        setState(state => ({...state, selected_agents}))
    }

    const toggleChoseActivation = activationStatus => {
        let {activation} = state
        activation.includes(activationStatus)
            ? activation = activation.filter(a => a !== activationStatus)
            : activation = [...activation, activationStatus]
        getSessions({...state, page: 1, activation})
        setState(state => ({...state, activation}))
    }

    const toggleChoseCompletion = completionStatus => {
        let {completion} = state
        completion.includes(completionStatus)
            ? completion = completion.filter(a => a !== completionStatus)
            : completion = [...completion, completionStatus]
        getSessions({...state, page: 1, completion})
        setState(state => ({...state, completion}))
    }

    const toggleChoseApprovation = approvationStatus => {
        let {approvation} = state
        approvation.includes(approvationStatus)
            ? approvation = approvation.filter(a => a !== approvationStatus)
            : approvation = [...approvation, approvationStatus]
        getSessions({...state, page: 1, approvation})
        setState(state => ({...state, approvation}))
    }

    const changeDateFrom = date => {
        if (date !== null && state.date_to) {
            if (date > state.date_to) {
                notification.error({
                    message: 'Date from should be earlier or the same as Date to.'
                })
                return
            }
        }
        setState(state => ({...state, date_from: date}))
        getSessions({...state, page: 1, date_from: date})
    }

    const changeDateTo = date => {
        if (date !== null && state.date_from) {
            if (date < state.date_from) {
                notification.error({
                    message: 'Date to should be later or the same as Date From.'
                });
                return;
            }
        }
        setState(state => ({...state, date_to: date}))
        getSessions({...state, age: 1, date_to: date})
    }

    const getFilters = async () => {
        try {
            const {data} = await API.get('/users/filters')
            setState(state => ({...state, filters: data.sessionFilters}))
        } catch (err) {
            notification.error({message: "Failed to load filters"})
        }
    }

    const clearFilters = () => {
        const clearedFilters = {
            selected_forms: [],
            selected_agents: [],
            selected_categories: [],
            selected_clients: '',
            date_from: null,
            date_to: null,
            activation: [],
            completion: [],
            approvation: [],
            search: "",
            clientSearch: ''
        }
        setState(state => ({...state, ...clearedFilters}))
        getSessions({...state, ...clearedFilters})
    }

    const saveFilter = async () => {

        try {
            setState(state => ({...state, newFilterError: ''}))
            const newFilterError =
                (!state.newFilterName && 'This field is required')
                || (state.newFilterName.length > 100 && 'Max 100 characters allowed')
                || (state.filters.map(({name}) => name).includes(state.newFilterName) && `${state.newFilterName} already exists`)
                || null
            if (newFilterError) {
                setState(state => ({...state, newFilterError}))
                return
            }

            await API.post('/users/filters', {
                name: state.newFilterName,
                search: state.search ? state.search : undefined,
                date_to: state.date_to ? state.date_to : undefined,
                date_from: state.date_from ? state.date_from : undefined,
                category_ids: state.selected_categories.length > 0 ? state.selected_categories : undefined,
                form_ids: state.selected_forms.length > 0 ? state.selected_forms : undefined,
                agent_ids: state.selected_agents.length > 0 ? state.selected_agents : undefined,
                client_text: state.clientSearch ? state.clientSearch : undefined,
                activation: state.activation.length > 0 ? state.activation : undefined,
                approval: state.approvation.length > 0 ? state.approvation : undefined,
                finished: state.completion.length > 0 ? state.completion : undefined,
            })

            getFilters()
            setHeaderTab('savedFilters')
            setState(state => ({...state, newFilterName: ''}))

        } catch (err) {
            notification.error({message: "Failed to save filters"})
        }
    }

    const deleteSavedFilter = async id => {
        try {
            await API.delete(`/users/filters/${id}`)
            getFilters()
        } catch (err) {
            notification.error({message: "Failed deleting saved filter"})
        }
    }

    const applySavedFilter = async id => {
        try {
            const {data: {sessionFilters: [filter]}} = await API.get('/users/filters?filterId=' + id)

            const filterData = {
                page: 1,
                search: filter.search ? filter.search : "",
                date_to: filter.date_to ? filter.date_to : null,
                date_from: filter.date_from ? filter.date_from : null,
                selected_categories: filter.category_ids ? filter.category_ids : [],
                selected_forms: filter.form_ids ? filter.form_ids : [],
                selected_agents: filter.agent_ids ? filter.agent_ids : [],
                selected_clients: filter.client_text ? filter.client_text : "",
                activation: filter.activation.length > 0 ? filter.activation : [],
                approvation: filter.approval.length > 0 ? filter.approval : [],
                completion: filter.finished.length > 0 ? filter.finished : []
            }

            setState(state => ({...state, ...filterData}))
            getSessions({...state, ...filterData})
            setHeaderTab(null)

        } catch (err) {
            notification.error({message: "Failed to load the saved filter"})
        }
    }

    const saveEditedFilterName = async () => {
        try {
            setState(state => ({...state, editFilterError: null}))
            const editFilterError =
                (!state.editFilterName && 'This field is required')
                || (state.editFilterName.length > 100 && 'Max 100 characters allowed')
                || (
                    state.filters.map(({name}) => name).filter(({_id}) => _id !== state.editFilterId)
                        .includes(state.editFilterName) && `${state.editFilterName} already exists`
                )
                || null
            if (editFilterError) {
                setState(state => ({...state, editFilterError}))
                return
            }
            await API.patch('/users/filters/' + state.editFilterId, {name: state.editFilterName})
            setState(state => ({...state, editFilterName: '', editFilterId: null}))
            setHeaderTab('savedFilters')
            getFilters()
        } catch (err) {
            notification.error({message: "Failed to edit filter name"})
        }
    }

    const renderSortIcon = column => {

        const setColumnSorting = (sort, order) => () => {
            setState(state => ({...state, sort, order}))
            getSessions({...state, page: 1, sort, order})
        }

        if (column !== state.sort)
            return <span className="cntrl-box"><i
                className="cntrl-down icon-arrow-down" onClick={setColumnSorting(column, 'desc')}/><i
                className="cntrl-up icon-arrow-up" onClick={setColumnSorting(column, 'asc')}/></span>;

        if (state.order === 'desc')
            return <span className="cntrl-box"><i
                className="cntrl-down icon-arrow-down selected" onClick={setColumnSorting(null, null)}/><i
                className="cntrl-up icon-arrow-up" onClick={setColumnSorting(column, 'asc')}/></span>;

        return <span className="cntrl-box"><i
            className="cntrl-down icon-arrow-down" onClick={setColumnSorting(column, 'desc')}/><i
            className="cntrl-up icon-arrow-up selected" onClick={setColumnSorting(null, null)}/></span>;
    }

    const changeSearch = ({target: {value: search}}) => {
        setState(state => ({...state, search}))
        getDebouncedSessions({...state, search})
    }

    const deleteSession = async id => {
        try {
            await API.delete(`/sessions/${id}`)
            getSessions(state)
            getAgents()

        } catch (err) {
            notification.error({message: "Failed to delete session"})
        }
    }

    //

    const getExtraColumnsConfig = async () => {
        try {
            // setState(state => ({...state, loading: true}))
            const {data: extraColumns} = await API.get('users/sessions/columns');
            setState(state => ({...state, extraColumns}))
        } catch (err) {
            notification.error({message: "Failed to load extra columns"})
        }
    }

    return (
        <StyledContentWeapper className="content" state={state}>
            <div className="content-box">

                <div className="content-box-header">
                    <h3>Sessions</h3>
                    <div className="content-box-options">
                        <div className="content-box-header-search">
                            <form className="content-search"
                                  onSubmit={e => e.preventDefault()}
                            >
                                <fieldset>
                                    <input type="text" name="" placeholder="Search" value={state.search}
                                           onChange={changeSearch}/>
                                    <button className="tbl-btn"></button>
                                </fieldset>
                            </form>
                        </div>
                        <span className="button button-outline with-icon-right trigger-filters"
                              onClick={() => toggleHeaderTab('filters')}><i className="icon-ia-arrow"></i>Filters</span>
                        <span className="button button-outline with-icon-right trigger-saved-filters"
                              onClick={() => toggleHeaderTab('savedFilters')}><i className="icon-ia-arrow"></i>Saved filters</span>
                        <span className="button button-outline with-icon-right trigger-map"
                              onClick={() => toggleHeaderTab('map')}><i className="icon-ia-arrow"></i>Map</span>
                    </div>
                </div>

                <div className="content-box-subheader filters">
                    <h4>Filters</h4>
                    <ul className="applied-filters">
                        {(state.selected_categories.length > 0
                            || state.selected_forms.length > 0
                            || state.selected_agents.length > 0
                            || state.clientSearch.length > 0
                            || state.activation.length > 0
                            || state.completion.length > 0
                            || state.approvation.length > 0
                            || state.date_from
                            || state.date_to
                        ) &&
                        <>
                            <li>
                                <span>Filters:</span>
                            </li>
                            {state.selected_categories.map((categoryId, categoryIndex) =>
                                <li key={`${categoryIndex}${categoryId}`}>
                                    <i className="delete-filter" onClick={() => toggleChoseCategory(categoryId)}/>
                                    <span>{state.categories.find(({_id}) => _id === categoryId).name}</span>
                                </li>
                            )}
                            {state.selected_forms.map((formId, formIndex) =>
                                <li key={`${formIndex}${formId}`}>
                                    <i className="delete-filter" onClick={() => toggleChoseForm(formId)}/>
                                    <span>{state.forms.find(({_id}) => _id === formId).name}</span>
                                </li>
                            )}
                            {state.selected_agents.map((agentId, agentIndex) =>
                                <li key={`${agentIndex}${agentId}`}>
                                    <i className="delete-filter" onClick={() => toggleChoseAgent(agentId)}/>
                                    <span>{state.agents.find(({_id}) => _id === agentId).name}</span>
                                </li>
                            )}

                            {state.clientSearch.length > 0 &&
                            <li>
                                <i className="delete-filter" onClick={() => searchClient('')}/>
                                <span>{state.clientSearch}</span>
                            </li>
                            }
                            
                            {state.completion.map((completionStatus, completionStatusIndex) =>
                                <li key={`${completionStatusIndex}${completionStatus}`}>
                                    <i className="delete-filter"
                                       onClick={() => toggleChoseCompletion(completionStatus)}/>
                                    <span>{{
                                        finished: 'Finished',
                                        pending: 'Pending',
                                        pending_client: 'Public sent forms'
                                    }[completionStatus]}</span>
                                </li>
                            )}
                            {state.date_from &&
                            <li>
                                <i className="delete-filter" onClick={() => changeDateFrom(null)}/>
                                <span>{state.date_from}</span>
                            </li>
                            }
                            {state.date_to &&
                            <li>
                                <i className="delete-filter" onClick={() => changeDateTo(null)}/>
                                <span>{state.date_to}</span>
                            </li>
                            }
                            {state.activation.map((activationStatus, activationStatusIndex) =>
                                <li key={`${activationStatusIndex}${activationStatus}`}>
                                    <i className="delete-filter"
                                       onClick={() => toggleChoseActivation(activationStatus)}/>
                                    <span>{{
                                        activated: 'Activated',
                                        pending: 'Pending Activation'
                                    }[activationStatus]}</span>
                                </li>
                            )}
                            {state.approvation.map((approvationStatus, approvationStatusIndex) =>
                                <li key={`${approvationStatusIndex}${approvationStatus}`}>
                                    <i className="delete-filter"
                                       onClick={() => toggleChoseApprovation(approvationStatus)}/>
                                    <span>{{
                                        approved: 'Approved',
                                        pending: 'Pending approval',
                                        declined: 'Declined'
                                    }[approvationStatus]}</span>
                                </li>
                            )}
                            <li className="applied-filters-actions">
                                <div className="tbl-btn"><i className="icon-refresh-ccw" onClick={clearFilters}/>
                                    <div className="tooltip tooltip-top">Clear all</div>
                                </div>
                                <div className="tbl-btn trigger-save-filter"><i className="icon-save"
                                                                                onClick={() => setHeaderTab(() => 'saveFilter')}/>
                                    <div className="tooltip tooltip-top">Save filter</div>
                                </div>
                            </li>
                        </>
                        }
                    </ul>
                    <div className="form">
                        <div
                            className={`form-row ${state.categoriesDropDown ? 'view-dropdown' : ''}`}
                            onBlur={() => setState(state => ({...state, categoriesDropDown: false}))}
                        >
                            <label htmlFor="">Category(s)</label>
                            <input
                                type="text"
                                onChange={({target: {value}}) => setState(state => ({...state, categorySearch: value}))}
                                value={state.categorySearch}
                                placeholder="Select Categories"
                                onFocus={() => setState(state => ({...state, categoriesDropDown: true}))}
                            />
                            <div className="dropdown">
                                {state.categories.length > 0
                                    ? <div className="scrollbar">
                                        <ul>
                                            {state.categories
                                                .filter(({name}) => state.categorySearch.length > 0 ? name.toLowerCase().includes(state.categorySearch.toLowerCase()) : true)
                                                .map((category, categoryIndex) =>
                                                    <li
                                                        key={categoryIndex}
                                                        className={`${state.selected_categories.includes(category._id) ? 'selected' : ''}`}
                                                        onMouseDown={e => {
                                                            e.preventDefault()
                                                            toggleChoseCategory(category._id)
                                                        }}
                                                    >{category.name}</li>
                                                )}
                                        </ul>
                                    </div>
                                    :
                                    <div className="no-content">
                                        <p>No categories</p>
                                    </div>
                                }
                            </div>
                        </div>

                        <div
                            className={`form-row ${state.formsDropDown ? 'view-dropdown' : ''}`}
                            onBlur={() => setState(state => ({...state, formsDropDown: false}))}
                        >
                            <label htmlFor="">Form(s)</label>
                            <input
                                type="text"
                                onChange={({target: {value}}) => setState(state => ({...state, formsSearch: value}))}
                                value={state.formsSearch}
                                placeholder="Select Forms"
                                onFocus={() => setState(state => ({...state, formsDropDown: true}))}
                            />
                            <div className="dropdown">
                                {state.forms.length > 0
                                    ? <div className="scrollbar">
                                        <ul>
                                            {state.forms
                                                .filter(({name}) => state.formsSearch.length > 0 ? name.toLowerCase().includes(state.formsSearch.toLowerCase()) : true)
                                                .map((form, formIndex) =>
                                                    <li
                                                        key={formIndex}
                                                        className={`${state.selected_forms.includes(form._id) ? 'selected' : ''}`}
                                                        onMouseDown={e => {
                                                            e.preventDefault()
                                                            toggleChoseForm(form._id)
                                                        }}
                                                    >{form.name}</li>
                                                )}
                                        </ul>
                                    </div>
                                    :
                                    <div className="no-content">
                                        <p>No forms</p>
                                    </div>
                                }
                            </div>
                        </div>

                        <div
                            className={`form-row ${state.agentsDropdown ? 'view-dropdown' : ''}`}
                            onBlur={() => setState(state => ({...state, agentsDropdown: false}))}
                        >
                            <label htmlFor="">User(s)</label>
                            <input
                                type="text"
                                onChange={({target: {value}}) => setState(state => ({...state, agentSearch: value}))}
                                value={state.agentSearch}
                                placeholder="Select Users"
                                onFocus={() => setState(state => ({...state, agentsDropdown: true}))}
                            />
                            <div className="dropdown">
                                {state.agents.length > 0
                                    ?
                                    <div className="scrollbar">
                                        <ul>
                                            {state.agents
                                                .filter(({name}) => state.agentSearch.length > 0 ? name.toLowerCase().includes(state.agentSearch.toLowerCase()) : true)
                                                .map((agent, agentIndex) =>
                                                    <li
                                                        key={agentIndex}
                                                        className={`${state.selected_agents.includes(agent._id) ? 'selected' : ''}`}
                                                        onMouseDown={e => {
                                                            e.preventDefault()
                                                            toggleChoseAgent(agent._id)
                                                        }}
                                                    >{agent.name}</li>
                                                )}
                                        </ul>
                                    </div>
                                    :
                                    <div className="no-content">
                                        <p>No agents</p>
                                    </div>
                                }
                            </div>
                        </div>

                        {/* <div className="form-row">
                            <label htmlFor="">Clients</label>
                            <input type="text" value={state.clientSearch}
                                   onChange={({target: {value}}) => searchClient(value)} placeholder="Filter Clients"/>
                        </div> */}

                        <div className="form-row with-calendar">
                            <label htmlFor="">Date from</label>
                            <AdatePicker
                                placeholder="Select Date"
                                value={state.date_from ? moment(state.date_from, 'DD/MM/YYYY').toDate() : null}
                                onChange={date => changeDateFrom(date ? moment(date).format('DD/MM/YYYY') : date)}
                                dateFormat={"dd/MM/yyyy"}
                            />
                            <i className="calendar-btn icon-ia-calendar"/>
                        </div>

                        <div className="form-row with-calendar">
                            <label htmlFor="">Date to</label>
                            <AdatePicker
                                placeholder="Select Date"
                                value={state.date_to ? moment(state.date_to, 'DD/MM/YYYY').toDate() : null}
                                onChange={date => changeDateTo(date ? moment(date).format('DD/MM/YYYY') : date)}
                                dateFormat={"dd/MM/yyyy"}
                            />
                            <i className="calendar-btn icon-ia-calendar"/>
                        </div>

                        <div className={`form-row ${state.completionDropdown ? 'view-dropdown' : ''}`}
                             onBlur={() => setState(state => ({...state, completionDropdown: false}))}
                        >
                            <label htmlFor="">Completion status</label>
                            <input type="text" placeholder="Select completion status" value="" onChange={f => f}
                                   onFocus={() => setState(state => ({...state, completionDropdown: true}))}
                            />
                            <div className="dropdown">
                                <div className="scrollbar">
                                    <ul>
                                        <li className={`${state.completion.includes('finished') ? 'selected' : ''}`}
                                            onMouseDown={e => {
                                                e.preventDefault()
                                                toggleChoseCompletion('finished')
                                            }}
                                        >Finished
                                        </li>
                                        <li className={`${state.completion.includes('pending') ? 'selected' : ''}`}
                                            onMouseDown={e => {
                                                e.preventDefault()
                                                toggleChoseCompletion('pending')
                                            }}
                                        >Pending
                                        </li>
                                        <li className={`${state.completion.includes('pending_client') ? 'selected' : ''}`}
                                            onMouseDown={e => {
                                                e.preventDefault()
                                                toggleChoseCompletion('pending_client')
                                            }}
                                        >Public sent forms
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={`form-row ${state.activationDropdown ? 'view-dropdown' : ''}`}
                             onBlur={() => setState(state => ({...state, activationDropdown: false}))}
                        >
                            <label htmlFor="">Activation status</label>
                            <input type="text" placeholder="Select activation status" value="" onChange={f => f}
                                   onFocus={() => setState(state => ({...state, activationDropdown: true}))}
                            />
                            <div className="dropdown">
                                <div className="scrollbar">
                                    <ul>
                                        <li
                                            className={`${state.activation.includes('activated') ? 'selected' : ''}`}
                                            onMouseDown={e => {
                                                e.preventDefault()
                                                toggleChoseActivation('activated')
                                            }}
                                        >Activated
                                        </li>
                                        <li
                                            className={`${state.activation.includes('pending') ? 'selected' : ''}`}
                                            onMouseDown={e => {
                                                e.preventDefault()
                                                toggleChoseActivation('pending')
                                            }}
                                        >Pending Activation
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={`form-row ${state.approvationDropdown ? 'view-dropdown' : ''}`}
                             onBlur={() => setState(state => ({...state, approvationDropdown: false}))}
                        >
                            <label htmlFor="">Approval status</label>
                            <input type="text" placeholder="Select approval status" value="" onChange={f => f}
                                   onFocus={() => setState(state => ({...state, approvationDropdown: true}))}
                            />
                            <div className="dropdown">
                                <div className="scrollbar">
                                    <ul>
                                        <li
                                            className={`${state.approvation.includes('approved') ? 'selected' : ''}`}
                                            onMouseDown={e => {
                                                e.preventDefault()
                                                toggleChoseApprovation('approved')
                                            }}
                                        >Approved
                                        </li>
                                        <li
                                            className={`${state.approvation.includes('pending') ? 'selected' : ''}`}
                                            onMouseDown={e => {
                                                e.preventDefault()
                                                toggleChoseApprovation('pending')
                                            }}
                                        >Pending approval
                                        </li>
                                        <li
                                            className={`${state.approvation.includes('declined') ? 'selected' : ''}`}
                                            onMouseDown={e => {
                                                e.preventDefault()
                                                toggleChoseApprovation('declined')
                                            }}
                                        >Declined
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <span className="tbl-btn close-subheader" onClick={() => setHeaderTab(null)}><i
                        className="icon-x"></i></span>
                </div>

                <div className="content-box-subheader saved-filters">
                    <h4>Saved filters</h4>
                    <div className="saved-filter-list">
                        {state.filters.length === 0 &&
                        <div className="empty-list">
                            This list is empty
                        </div>
                        }
                        {state.filters.length > 0 &&
                        <div className="scrollbar">
                            <ul className="table-ul subheader-list">
                                {state.filters.map(filter =>
                                    <li key={filter.id}>
                                        <ul>
                                            <li><strong>{filter.name}</strong></li>
                                            <li>
                                                <div className="table-actions">
                                                    <AdeleteConfirm
                                                        title={
                                                            <>
                                                                Are you sure you want to delete this list?
                                                            </>
                                                        }
                                                        onConfirm={() => deleteSavedFilter(filter._id)}
                                                        overlayClassName="tooltip-overlay"
                                                        okText="Delete"
                                                        cancelText="Cancel"
                                                    >
                                                        <div className="tbl-btn"><i className="icon-ia-trash-bold"></i>
                                                            <div className="tooltip tooltip-bottom">Delete</div>
                                                        </div>
                                                    </AdeleteConfirm>
                                                    <div className="tbl-btn"
                                                         onClick={() => {
                                                             setHeaderTab('editFilterName')
                                                             setState(state => ({
                                                                 ...state,
                                                                 editFilterName: filter.name,
                                                                 editFilterId: filter._id
                                                             }))
                                                         }}
                                                    ><i className="icon-ia-edit-bold"></i>
                                                        <div className="tooltip tooltip-bottom">Edit name</div>
                                                    </div>
                                                    <div className="tbl-btn"
                                                         onClick={() => applySavedFilter(filter._id)}><i
                                                        className="icon-ia-eye-view"></i>
                                                        <div className="tooltip tooltip-bottom">Apply filter</div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                )}
                            </ul>
                        </div>
                        }
                    </div>

                    <div
                        className="save-filter-box"
                        css={`${headerTab !== 'saveFilter' ? css`
                                && {
                                    transform: translate3d(0,-100%,0);
                                    visibility:hidden;
                                }
                            `
                            : ''}`}
                    >
                        <div className="form">
                            <div className={`form-row ${state.newFilterError ? 'error' : ''}`}>
                                <label htmlFor="">Saved Filter Name</label>
                                <input type="text" placeholder=""
                                       value={state.newFilterName}
                                       onChange={({target: {value}}) => setState(state => ({
                                           ...state,
                                           newFilterName: value
                                       }))}
                                />
                                {state.newFilterError &&
                                <span className="form-row-error-msg">{state.newFilterError}</span>}
                            </div>
                            <div className="save-filter-actions">
                                <button className="button with-icon-right" onClick={saveFilter}><i
                                    className="icon-ia-checked"/>Save
                                </button>
                                <button className="button button-link"
                                        onClick={() => {
                                            setHeaderTab(() => null)
                                            setState(state => ({...state, newFilterName: ''}))
                                        }}
                                >Cancel
                                </button>
                            </div>
                        </div>
                    </div>

                    <div
                        className="save-filter-box"
                        css={`${headerTab !== 'editFilterName' ? css`
                                && {
                                    transform: translate3d(0,-100%,0);
                                    visibility:hidden;
                                }
                            `
                            : ''}`}
                    >
                        <div className="form">
                            <div className={`form-row ${state.editFilterError ? 'error' : ''}`}>
                                <label htmlFor="">Edit Filter Name</label>
                                <input type="text" placeholder=""
                                       value={state.editFilterName}
                                       onChange={({target: {value}}) => setState(state => ({
                                           ...state,
                                           editFilterName: value
                                       }))}
                                />
                                {state.newFilterError &&
                                <span className="form-row-error-msg">{state.newFilterError}</span>}
                            </div>
                            <div className="save-filter-actions">
                                <button className="button with-icon-right" onClick={saveEditedFilterName}><i
                                    className="icon-ia-checked"/>Save
                                </button>
                                <button className="button button-link"
                                        onClick={() => {
                                            setHeaderTab(() => null)
                                            setState(state => ({...state, editFilterError: ''}))
                                        }}
                                >Cancel
                                </button>
                            </div>
                        </div>
                    </div>

                    <span className="tbl-btn close-subheader" onClick={() => setHeaderTab(null)}><i
                        className="icon-x"/></span>
                </div>
                {state.loading && <Loading/>}
                {!state.loading &&
                <div className="content-box-subheader map">
                    <h3>{state.total - state.places.length} sessions not present on map</h3>
                    <div className="map-box" css={`
                                  .gm-style-iw {padding: 0}
                                  .gm-style-iw .gm-ui-hover-effect {
                                  top: 1rem !important;
                                  right: 0.5rem !important;
                                  width: 3.2rem !important;
                                  height: 3.2rem !important;
                                  display: inline-block !important;
                                  opacity: 0 !important;
                                  position: relative;
                                  background: transparent !important;
                                  font-size: 2rem !important;
                                  line-height: 3.1rem !important;
                                  text-align: center;
                                  color: var(--primary-color) !important;
                                  border-radius: 0.3rem;
                                  border: 1px solid transparent !important;
                                  cursor: pointer;
                                  -moz-transition: all 0.3s ease-in-out;
                                  -o-transition: all 0.3s ease-in-out;
                                  -webkit-transition: all 0.3s ease-in-out;
                                  transition: all 0.3s ease-in-out; 
                                }
                                .gm-style-iw .gm-ui-hover-effect img {
                                    display: none !important;
                                }
                                 .gm-style-iw .gm-ui-hover-effect:hover {
                                    opacity: 1 !important;
                                    background: var(--primary-color) !important;
                                    border-color: var(--primary-color) !important;
                                    color: #f8f8f8 !important;
                                    text-decoration: none;
                                    box-shadow: 0 0.1rem 0.2rem rgba(125,125,125,.2);
                                }
                                 .gm-style-iw .gm-ui-hover-effect::before {
                                content: "\\ee6a";
                                font-family: "icons";
                                }                   
                        `}>
                        <Map
                            places={state.places}
                        />
                    </div>
                    <span className="tbl-btn close-subheader" onClick={() => setHeaderTab(null)}><i className="icon-x"/></span>
                </div>
                }

                <div className="content-box-body content-box-body-fixed-elements">
                    <div className="box-body box-body-fixed scrollbar">

                        <div className="table-fixed-header">
                            <ul className="table-ul sessions-list">
                                <li className="table-ul-header">
                                    <ul>
                                        <li className="with-cntrl-box">
                                            <strong>Category</strong>
                                            {renderSortIcon('category_name')}
                                        </li>
                                        <li className="with-cntrl-box">
                                            <strong>Form</strong>
                                            {renderSortIcon('form_name')}
                                        </li>
                                        <li className="with-cntrl-box">
                                            <strong>User</strong>
                                            {renderSortIcon('agent_name')}
                                        </li>
                                        {/* <li className="with-cntrl-box">
                                            <strong>Client</strong>
                                            {renderSortIcon('client_name')}
                                        </li> */}
                                        <li className="with-cntrl-box">
                                            <strong>Date & Time</strong>
                                            {renderSortIcon('updated_at')}
                                        </li>
                                        <li className="with-cntrl-box">
                                            <strong>Versions</strong>
                                        </li>

                                        {state.extraColumns.map((column, i) => (
                                            <li className="with-cntrl-box" key={i}>
                                                <strong>{column.title}</strong>
                                                {renderSortIcon(column.title)}
                                            </li>
                                        ))}

                                        <li><strong></strong></li>
                                    </ul>
                                </li>
                            </ul>
                            <ExtraColumnsConfig
                                onUpdate={() => {
                                    getSessions(state)
                                    getExtraColumnsConfig()
                                }}
                            />
                        </div>

                        <div className="scrollbar">
                            {state.loading && <Loading/>}
                            {state.data.length === 0 &&
                            <div className="empty-list">No results found</div>
                            }
                            {state.data.length > 0 &&
                            <ul className="table-ul sessions-list">

                                {state.data.map((session, sessionIndex) => (
                                    <li key={session._id}>
                                        <ul>
                                            <li><strong>{session.category_name}</strong></li>
                                            <li>
                                                <strong
                                                    {...(session.form_id ? {
                                                        onClick: () => history.push(`/forms/${session.form_id}/preview`),
                                                        style: {cursor: 'pointer', whiteSpace: "nowrap"}
                                                    } : {})}
                                                >{session.form_name}</strong>
                                            </li>
                                            <li className="tbl-li-img">
                                                <div className="tbl-img tbl-agent-img">
                                                    <div
                                                        {...(session.form_id ? {
                                                            onClick: () => history.push(`/users/${session._agent._id}`),
                                                            style: {cursor: 'pointer'}
                                                        } : {})}
                                                    >
                                                        {
                                                            !!session._agent.image
                                                                ? <img src={
                                                                    session._agent.image?.startsWith('http')
                                                                        ? session._agent.image
                                                                        : `${process.env.REACT_APP_API_BASE_URL}/images/users/image/${session._agent.image}`
                                                                } />
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <strong {...(session.form_id ? {
                                                    onClick: () => history.push(`/users/${session._agent._id}`),
                                                    style: {cursor: 'pointer'}
                                                } : {})}>{session._agent.name}</strong>
                                            </li>
                                            {/* <li>
                                                {session._client !== null &&
                                                    <strong>
                                                        {
                                                            (session._client.name && session._client.name.trim() && session._client.name) ||
                                                            (session._client.email &&<span>{session._client.email}</span>) ||
                                                            (session._client.phone &&<span>{session._client.phone}</span>) ||
                                                            (session._client.address && session._client.address.trim() &&<span>{session._client.address}</span>)
                                                        }
                                                    </strong>
                                                }
                                            </li> */}
                                            <li>
                                                <strong>{moment(session.updated_at).format('D MMM HH:mm')}<span>({moment(session.updated_at).format('YYYY')})</span></strong>
                                            </li>

                                            <li>

                                                <VersionsButton session={session}/>

                                            </li>
                                            {state.extraColumns.map((column, cIndex) => {
                                                const sColumn = (session.extraColumns || []).find(c => c.title === column.title);
                                                const value = sColumn
                                                    ? !sColumn.value
                                                        ? sColumn.value = ""
                                                        : sColumn.type === 'checkbox group'
                                                            ? sColumn.value.join(', ')
                                                            : sColumn.value
                                                    : "";
                                                return (
                                                    <li className="scrollbar" key={cIndex}>{value}</li>
                                                )
                                            })}

                                            <li>
                                                <div className="table-actions">
                                                    {auth_role === 'admin' &&
                                                    <AdeleteConfirm
                                                        title="Are you sure you want to delete this session?"
                                                        onConfirm={() => deleteSession(session._id)}
                                                        okText="Delete"
                                                        cancelText="Cancel"
                                                    >
                                                        <div className="tbl-btn trigger-overlay-delete-session">
                                                            <i className="icon-ia-trash-bold"></i>
                                                            <div
                                                                className={`tooltip ${sessionIndex === 0 ? 'tooltip-bottom' : 'tooltip-top'}`}>Delete
                                                                session
                                                            </div>
                                                        </div>
                                                    </AdeleteConfirm>
                                                    }
                                                    <a className="tbl-btn" href="#" title="View session"
                                                       onClick={e => {
                                                           e.preventDefault()
                                                           history.push(`/sessions/${session._id}`)
                                                       }}
                                                    >
                                                        <i className="icon-ia-preview-file"/>
                                                        <div
                                                            className={`tooltip ${sessionIndex === 0 ? 'tooltip-bottom' : 'tooltip-top'}`}>View
                                                            session
                                                        </div>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                ))}

                            </ul>
                            }
                        </div>


                    </div>

                    <div className="box-action table-action-fixed with-pagination">
                        <div className="export-options">
                            <span>Export:</span>
                            <button className="button button-outline" onClick={() => exportSessionRequest('pdf')}>PDF
                            </button>
                            <button className="button button-outline"
                                    onClick={() => exportSessionRequest('excel')}>XLS
                            </button>
                            <button className="button button-outline"
                                    onClick={exportSessionsRaw}>RAW
                            </button>
                        </div>
                        <Ipagination
                            page={state.page}
                            pages={state.pages}
                            onChange={changePage}
                        />
                    </div>

                </div>

            </div>
        </StyledContentWeapper>
    )
}

const VersionsButton = ({ session }) => {
	const [state, setState] = React.useState(
		{
			open: false,
			openedOnce: false,
			loadingVersions: false,
			versions: [],
			error: null,
		},
		"versions"
	)

	const disabled = !(session.version && session.version > 1)
	const history = useHistory()

	React.useEffect(() => {
		if (state.open && !state.openedOnce) {
			getVersions()
		}
	}, [state.open])

	const getVersions = async () => {
		try {
			setState(state => ({ ...state, loadingVersions: true }))
			const { data } = await API.get(`sessions/${session._id}/versions`)
			setState(state => ({ ...state, loadingVersions: false, versions: data }))
		} catch (err) {}
	}

	return (
		<>
			<strong
				css={`
					&& {
						display: grid;
						grid-template-columns: max-content max-content;
						align-items: center;
						grid-gap: 1rem;
					}
				`}
			>
				{session.version && session.version > 1
					? parseInt(session.version) - 1
					: " - "}
				<a
					className="tbl-btn"
					href="#"
					onClick={e => {
						e.preventDefault()
						setState(state => ({ ...state, open: true }))
					}}
					css={`
						${disabled
							? css`
									pointer-events: none;
									cursor: not-allowed;
							  `
							: ""}
					`}
				>
					<i className="icon-database" />
				</a>
			</strong>
			<Imodal
				visible={state.open}
				onCancel={() => setState(state => ({ ...state, open: false }))}
				title={`Versions from agent ${session._agent.name}`}
				disableFooter
			>
				<div className="scrollbar" style={{ maxHeight: "40rem" }}>
					{state.loadingVersions && <Loading />}
					<div
						css={`
							position: relative;
						`}
					>
						<ul className="table-ul">
							<li className="table-ul-header">
								<ul>
									<li>
										<strong>Version</strong>
									</li>
									<li>
										<strong>Date</strong>
									</li>
									<li>
										<strong>View version</strong>
									</li>
								</ul>
							</li>
						</ul>
						<div
							className="scrollbar"
							css={`
								&& {
									padding: 0;
								}
							`}
						>
							<ul className="table-ul">
								{state.versions.map(version => (
									<li key={version.version}>
										<ul>
											<li>
												<strong>{version.version}</strong>
											</li>
											<li>
												<strong>
													{moment(version.updated_at).format("D MMM HH:mm")}
													<span>({moment(version.updated_at).format("YYYY")})</span>
												</strong>
											</li>
											<li>
												<a
													href="#"
													className="tbl-btn"
													onClick={e => {
														e.preventDefault()
														history.push(
															`/sessions/${session._id}/version/?v=${version.version}`
														)
													}}
												>
													<i className="icon-ia-document" />
												</a>
											</li>
										</ul>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</Imodal>
		</>
	)
}

export default Sessions


const StyledContentWeapper = styled.div.attrs(props => ({
    extraColumnsLength: props.state.extraColumns.length
}))`

   .content-box-body {
        display: grid;
        grid-template-rows: auto min-content;

        .box-body-fixed {
            overflow-x: auto;
            display: grid;
            grid-template-rows: min-content auto;
            padding: unset;

            &::-webkit-scrollbar {
                height: 0.4rem;
            }
        }

        .table-fixed-header {
            position: relative;
            display: grid;
        }

        .table-fixed-header .tbl-btn {
            right: 1.5rem;
        }

        .with-pagination {
            position: unset;
        }

        .table-ul-header > ul {
            grid-template-columns: ${props => 
                props.extraColumnsLength > 0 
                    ? `repeat(${props.extraColumnsLength + 5}, 15rem) 8rem` 
                    : 'minmax(5rem, 1fr) minmax(8rem, 1.5fr) minmax(5rem, 1fr) minmax(5rem, 1fr) minmax(5rem, 1fr) 8rem'
            };
        }

        .table-ul.sessions-list ul {
            grid-template-columns: ${props => 
                props.extraColumnsLength > 0 
                    ? `repeat(${props.extraColumnsLength + 5}, 15rem) 8rem` 
                    : 'minmax(5rem, 1fr) minmax(8rem, 1.5fr) minmax(5rem, 1fr)  minmax(5rem, 1fr) minmax(5rem, 1fr) 8rem'
            };
        }
   }
   
`