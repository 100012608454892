import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText";

export default ({
    element,
    value = '',
    changeValue,
    errors = [],
    answers_by_code
}) => {

    const setValue = value => {
        changeValue(value)
    }

    return (
            <div className={`form-row ${(errors.length > 0) ? 'error' : '' }`}>
                {element.text &&
                    <label>{synchronizeText(element.text, answers_by_code)}</label>
                }

                <input placeholder={element.placeholder} value={value} onChange={e => setValue(e.target.value.toUpperCase())} />
                {renderErrors(errors)}
            </div>
    );
};

const renderErrors = (errors) => {
    if (errors.length === 0)
        return null;

    return errors.map(error => {
        const error_array = error.split('|');
        switch(error_array[0]) {
            case 'required':
                return 'This field is required';
            case 'not_valid_vin':
                return 'Invalid VIN number';
        }
    }).map(error => <span key={error} className="form-row-error-msg">{error}</span>)
};