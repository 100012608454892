import React from 'react'
import moment from 'moment';
import styled, { css } from 'styled-components'
import levenshtein from 'js-levenshtein'
import {
    BrowserView,
    MobileView
} from "react-device-detect"

import API from '../../api'
import {VALIDATION} from '../../constants/validations'
import {getError} from "../../utils/getError"
import Loading from "../../components/Loading"
import {Country, City, list} from '../../components/CountryCity'
import TakePicture from '../../components/TakePicture'
import AdatePicker from '../../components/AdatePicker/AdatePicker'
import CdeleteConfirm from '../../components/AdeleteConfirm'


const findClosest = (pattern, list) => {
    return list.reduce((acc, item) => {
        let {closest, diference} = acc;
        let thisDiference = levenshtein(pattern, item);
        if (thisDiference < diference) {
            closest = item;
            diference = thisDiference;
        }
        return {closest, diference}
    }, {closest: undefined, diference: 999})
        .closest
}

const cssFixes = css`
    .id-card-image-actions .button, .add-photo-image-actions .button {
        width: auto;
        font-size: 0.9rem;
    }
`

export default ({
    element,
    value = null,
    changeValue = f => f,
    errors = []
}) => {

    const [state, setState] = React.useState({
        loading: false,
        error: null,
        step: 1,
        image: null
    });
    const skipFirstTwoSteps = element.validations.map((validation) => validation.validation).includes(VALIDATION.SKIP_FIRST_TWO_STEPS);
    const setStep = step => {
        setState(state => ({...state, step}))
    };

    const uploadImage = image => {
        setState(state => ({
            ...state,
            step: 2,
            image
        }))
    };

    const getIdCardInformation = async (data, callback) => {
        try {
            setState(state => ({...state, loading: true}));
            const response = await API.post('/services/extract-id-card-information', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            callback(response.data);
            setState(state => ({...state, loading: false}))
        } catch (err) {
            setState(state => ({
                ...state,
                loading: false,
                error: getError(err)
            }))
        }
    };


    let previewImg;

    React.useEffect(() => {
        if (value !== null || skipFirstTwoSteps) {
            setStep(3);
        }
    }, [value, skipFirstTwoSteps]);

    React.useEffect(() => {
        if (value === null && !skipFirstTwoSteps)
            setStep(1);
    }, [value, skipFirstTwoSteps]);

    React.useEffect(() => {
        if (state.image !== null) {
            const reader = new FileReader();

            reader.onload = function (e) {
                previewImg.setAttribute('src', e.target.result)
            }

            reader.readAsDataURL(state.image);
        }
    }, [state.image, previewImg]);

    const setUploadImage = (e) => {
        const file = e.target.files[0];
        uploadImage(file);
        e.target.value = null;
    };

    const getCardInformation = () => {
        const data = new FormData();

        data.append('doc', state.image);

        getIdCardInformation(data, (card_information) => {
            if(card_information.date_of_birth) {
                card_information.date_of_birth = moment(card_information.date_of_birth , 'YYYY-MM-DD').format('DD/MM/YYYY')
            } else {
                delete card_information.date_of_birth
            }

            if(card_information.available_from) {
                card_information.available_from = moment(card_information.available_from , 'YYYY-MM-DD').format('DD/MM/YYYY')
            } else {
                delete card_information.available_from
            }

            if(card_information.available_to) {
                card_information.available_to = moment(card_information.available_to , 'YYYY-MM-DD').format('DD/MM/YYYY')
            } else {
                 delete card_information.available_to
            }  

            if(!card_information.phone) delete card_information.phone
            if(!card_information.nr_ci) delete card_information.nr_ci
            
            changeValue({
                ...card_information,
            })
            setStep(3)
        });
    };

    const resetData = () => {
        changeValue(null);
        setStep(1);
    };

    const getValue = (name) => {
        if (value === null) return ''
        return value[name]
    }

    const setValue = (e) => {
        changeValue({
            ...value,

            [e.target.name]: e.target.value
        })
    };

    const onCountryChange = (e, fromUser = false) => {
        if (fromUser) {
            changeValue({
                ...value,
                [e.target.name]: e.target.value,
                locality: ""
            })
        } else {
            changeValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    };

    const errorsContain = (...args) => {
        for (let i = 0; i < args.length; i++) {
            if (errors.includes(args[i])) {
                return true;
            }
        }
        return false;
    };

    return (
        <div className="form-row" css={cssFixes}>
            <div className={`id-card-extractor ${state.step >= 2 ? 'view-ice-step2' : ''} ${state.step === 3 ? 'view-ice-step3' : ''}`}>

                <div className="id-card-extractor-list">

                    <div className="id-card-extractor-cell">
                        <h3><span>{`${element.step1} `}</span>{element.uploadTakePictureLabel}</h3>
                        <div className="step1-btns">

                            <UploadButton className="upload-image-box" uploadButton={element.uploadImageButton}>
                                <input className="upload-image-input" type="file" name="image" accept="image/*"
                                       onChange={setUploadImage}/>
                            </UploadButton>


                            <BrowserView>
                                <TakePicture
                                    onCapture={file => uploadImage(file)}
                                >
                                    <UploadButton className="upload-image-box upload-camera-img"
                                                  uploadButton={element.cameraImageButton}/>
                                </TakePicture>
                            </BrowserView>

                            <MobileView>
                                <UploadButton className="upload-image-box upload-camera-img"
                                              uploadButton={element.cameraImageButton}>
                                    <input className="upload-image-input" type="file" name="image" accept="image/*"
                                           capture="camera" onChange={setUploadImage}/>
                                </UploadButton>
                            </MobileView>

                        </div>
                        <p>Please take a photo<br/> or upload a photo of your ID card</p>
                        <p>OR</p>
                        <span className="add-block-input mar2b" onClick={() => setStep(3)}>Complete manually</span>
                    </div>

                    <div className="id-card-extractor-cell">
                        <h3><span>{`${element.step2} `}</span>{element.previewUploadedImage}</h3>
                        <div className="id-card-image">
                            <img ref={ref => previewImg = ref} src="img/id-card.svg" alt=""/>
                            {state.loading && <Loading/>}
                        </div>

                        {!state.loading &&
                            <div class="id-card-image-actions">
                                <span class="button button-outline discard-btn" onClick={() => setStep(1)}>{element.uploadImageAgainButton}</span>
                                <span class="button button-outline accept-btn" onClick={getCardInformation}>{element.getIdInformationButton}</span>
                            </div>
                        }
                    </div>

                </div>

                <div className="id-card-extractor-cell id-card-extractor-cell-step-3">
                    <h3><span>{`${element.step3} `}</span>{element.cardInformation}</h3>
                    <fieldset className="form-grid">
                        <div className="form-grid-row">
                            <div 
                                className={`form-row ${errorsContain('max_length_first_name') ? 'error' : ''}`}
                            >
                                <label>Name</label>
                                <input value={getValue('first_name')} name="first_name" onChange={setValue}/>
                                {errorsContain('max_length_first_name') &&
                                    <span className="form-row-error-msg">The name is too long</span>
                                }
                            </div>
                            <div 
                                className={`form-row ${errorsContain('max_length_last_name') ? 'error' : ''}`}
                            >
                                <label>Surname</label>
                                <input value={getValue('last_name')} name="last_name" onChange={setValue}/>
                                {errorsContain('max_length_last_name') &&
                                    <span className="form-row-error-msg">The surname is too long</span>
                                }
                            </div>
                        </div>
                        <div className="form-grid-row">
                            <div
                                className={`form-row ${errorsContain('not_valid_email', 'email_required') ? 'error' : ''}`}>
                                <label>Email</label>
                                <input value={getValue('email')} name="email" onChange={setValue}/>
                                {errorsContain('not_valid_email') &&
                                <span className="form-row-error-msg">Invalid email</span>
                                }
                            </div>
                            <div
                                className={`form-row ${errorsContain('not_valid_phone_number', 'phone_number_required') ? 'error' : ''}`}>
                                <label>Phone</label>
                                <input value={getValue('phone')} name="phone" onChange={setValue}/>
                                {errorsContain('not_valid_phone_number') &&
                                <span className="form-row-error-msg">Invalid phone</span>
                                }
                            </div>
                        </div>
                        <div className="form-grid-row">
                            <div className="form-row">
                                <label>CNP</label>
                                <input value={getValue('cnp')} name="cnp" onChange={setValue}/>
                            </div>
                            <div className={`form-row with-calendar ${errorsContain('not_after_session_date_of_birth') ? 'error' : ''}`}>
                                <label>Date of birth</label>

                                <AdatePicker
                                    dateFormat={"dd/MM/yyyy"}
                                    value={(value && value.date_of_birth) ? moment(value.date_of_birth, 'DD/MM/YYYY').toDate() : null}
                                    onChange={date => changeValue({...value, date_of_birth: date ? moment(date).format('DD/MM/YYYY') : date})}
                                />

                                {errorsContain('not_after_session_date_of_birth') &&
                                    <span className="form-row-error-msg">The date cannot be in the future</span>
                                }
                                <i class="calendar-btn icon-ia-calendar"></i>
                            </div>
                        </div>
                        <div className="form-grid-row">
                            <div className="form-row">
                                <label>County</label>
                                {((receivedValue, onChange) => {
                                    let value = receivedValue
                                    if (value !== "" && value !== undefined && !list[value]) {
                                        value = findClosest(value, Object.keys(list))
                                        onChange({target: {name: "country", value}})
                                    }
                                    return (
                                        <Country value={value} onChange={e => onChange(e, true)} name="country"
                                                    placeholder="Alege"/>
                                    )
                                })(getValue('country'), onCountryChange)}

                            </div>
                            <div className="form-row">
                                <label>City</label>
                                {((receivedValue, onChange, selectedCountry) => {
                                    let value = receivedValue
                                    if (value !== "" && !!selectedCountry && list[selectedCountry] && !list[selectedCountry].includes(value)) {
                                        value = findClosest(value, list[selectedCountry])
                                        onChange({target: {name: "locality", value}})
                                    }
                                    return (
                                        <City value={value} selectedCountry={selectedCountry} onChange={onChange}
                                                name="locality" placeholder="Alege"/>
                                    )
                                })(getValue('locality'), setValue, getValue('country'))}

                            </div>
                        </div>
                        <div className="form-grid-row street-grid">
                            <div className="form-row">
                                <label>Street</label>
                                <input value={getValue('street')} name="street" onChange={setValue}/>
                            </div>
                            <div className="form-row">
                                <label htmlFor="">No</label>
                                <input value={getValue('nr')} name="nr" onChange={setValue}/>
                            </div>
                        </div>
                        <div className="form-grid-row address-grid">
                            <div className="form-row">
                                <label>Building</label>
                                <input value={getValue('block_value')} name="block_value" onChange={setValue}/>
                            </div>
                            <div className="form-row">
                                <label>Entry</label>
                                <input value={getValue('entry')} name="entry" onChange={setValue}/>
                            </div>
                            <div className="form-row">
                                <label>Floor</label>
                                <input value={getValue('floor')} name="floor" onChange={setValue}/>
                            </div>
                            <div className="form-row">
                                <label>Apartment</label>
                                <input value={getValue('apartment')} name="apartment" onChange={setValue}/>
                            </div>
                        </div>
                        <div className="form-grid-row id-grid">
                            <div className={`form-row ${errorsContain('not_valid_serie_ci') ? 'error' : ''}`}>
                                <label>CI Series</label>
                                <input value={getValue('series_ci')} name="series_ci" onChange={setValue}/>
                                {errorsContain('not_valid_serie_ci') &&
                                <span className="form-row-error-msg">CI series invalid</span>
                                }
                            </div>
                            <div className={`form-row ${errorsContain('not_valid_nr_ci') ? 'error' : ''}`}>
                                <label>CI No</label>
                                <input value={getValue('nr_ci')} name="nr_ci" onChange={setValue}/>
                                {errorsContain('not_valid_nr_ci') &&
                                <span className="form-row-error-msg">CI number invalid</span>
                                }
                            </div>
                            <div className="form-row">
                                <label>Emitted by</label>
                                <input value={getValue('emitted_by')} name="emitted_by" onChange={setValue}/>
                            </div>
                        </div>
                        <div className="form-grid-row">
                            <div
                                className={`form-row with-calendar ${errorsContain('not_after_session_date_available_from') ? 'error' : ''}`}>
                                <label>Valid from</label>
                                <AdatePicker
                                    dateFormat={"dd/MM/yyyy"}
                                    value={(value && value.available_from) ? moment(value.available_from, 'DD/MM/YYYY').toDate() : null}
                                    onChange={date => changeValue({...value, available_from: date ? moment(date).format('DD/MM/YYYY') : date})}
                                />
                                {errorsContain('not_after_session_date_available_from') &&
                                    <span className="form-row-error-msg">The date cannot be in the future</span>
                                }
                                <i class="calendar-btn icon-ia-calendar"></i>
                            </div>
                            <div
                                className={`form-row with-calendar ${errorsContain('not_before_session_date_available_to') ? 'error' : ''}`}>

                                <label>Valid until</label>
                                <AdatePicker
                                    dateFormat={"dd/MM/yyyy"}
                                    value={(value && value.available_to) ? moment(value.available_to, 'DD/MM/YYYY').toDate() : null}
                                    onChange={date => changeValue({...value, available_to: date ? moment(date).format('DD/MM/YYYY') : date})}
                                />
                                {errorsContain('not_before_session_date_available_to') &&
                                    <span className="form-row-error-msg">The date cannot be in the past</span>
                                }
                                <i class="calendar-btn icon-ia-calendar"></i>
                            </div>
                        </div>
                    </fieldset>

                    {!skipFirstTwoSteps &&
                        <CdeleteConfirm
                            title="Are you sure you want to upload another image?"
                            onConfirm={resetData}
                            okText="Ok"
                        >
                            <span className="add-block-input mar2b">{element.uploadAnotherImageButton}</span>
                        </CdeleteConfirm>
                    }
                </div>

            </div>
        </div>
    );
};

const UploadButton = styled.div`
    ::before {
        content: "${({uploadButton}) => uploadButton}" !important;
    }
`