import React from 'react'
import defaultImage from '../../images/photo.svg'
import CodeCopy from '../CodeCopy'

export default ({
    element
}) => {
    return (
        <CodeCopy text={element.code}>
            <div className="fieldset-row">
                <p className="form-box-wrap-descrition">{element.title}</p>
                <p className="form-box-wrap-descrition">{element.text}</p>
                <div className="form-grid" style={{ gridTemplateColumns: "repeat(2, 1fr)" }}>
                    {element.offers.map((offer, offerIndex) => (
                        <div className="offer-row" key={offerIndex}>
                            <div className="upload-image-grid">
                                <div className="upload-image-box"><img src={offer.image || defaultImage} alt="" /></div>
                                <p>{offer.title}</p>
                                <p>{offer.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </CodeCopy>
    )
}




