import {saveAs} from "file-saver";

import {
    GET_CLIENTS_PENDING, GET_CLIENTS_REJECTED, GET_CLIENTS_FULFILLED,
    SET_PAGE, SET_SEARCH, SET_SORT,
    GET_CLIENT_PENDING, GET_CLIENT_REJECTED, GET_CLIENT_FULFILLED,
    SET_CLIENT_FIRST_NAME, SET_CLIENT_LAST_NAME, SET_CLIENT_EMAIL, SET_CLIENT_PHONE, SET_CLIENT_COUNTRY,
    SET_CLIENT_LOCALITY, SET_CLIENT_STREET, SET_CLIENT_NR, SET_CLIENT_BLOCK, SET_CLIENT_ENTRY,
    SET_CLIENT_FLOOR, SET_CLIENT_APARTMENT, SET_UPDATE_CLIENT_ERRORS,
    UPDATE_CLIENT_PENDING, UPDATE_CLIENT_REJECTED, UPDATE_CLIENT_FULFILLED, DELETE_CLIENT_PENDING, DELETE_CLIENT_FULFILLED, DELETE_CLIENT_REJECTED,
    GET_CLIENT_SESSIONS_PENDING, GET_CLIENT_SESSIONS_REJECTED, GET_CLIENT_SESSIONS_FULFILLED
} from './ClientsData.reducer';
import API from "../../../api";
import {getError} from "../../../utils/getError";

export const getClients = async (dispatch, state, callback) => {
    try {
        dispatch({ type: GET_CLIENTS_PENDING });

        let url = '/clients';

        if (state) {
            const filters = [];

            if (state.search) {
                filters.push(`search=${state.search}`);
            }

            if (state.sort) {
                filters.push(`sort=${state.sort}&order=${state.order}`);
            }

            if (state.page) {
                filters.push(`page=${state.page}`);
            }

            if (filters.length) {
                url += '?' + filters.join('&');
            }
        }

        const response = await API.get(url);

        dispatch({ type: GET_CLIENTS_FULFILLED, payload: response.data });
        if(state && state.page){
            setPage(dispatch, state.page);
        }
        callback()
    } catch (err) {
        dispatch({ type: GET_CLIENTS_REJECTED, payload: getError(err.response) });
    }
};

export const setPage = (dispatch, page) => {
    dispatch({ type: SET_PAGE, payload: page });
};

export const setSearch = (dispatch, search) => {
    dispatch({ type: SET_SEARCH, payload: search });
};

export const setSort = (dispatch, sort) => {
    dispatch({ type: SET_SORT, payload: sort });
};

export const getClient = async (dispatch, id) => {
    try {
        dispatch({ type: GET_CLIENT_PENDING });

        const response = await API.get('/clients/' + id);

        dispatch({ type: GET_CLIENT_FULFILLED, payload: response.data });
    } catch (err) {
        dispatch({ type: GET_CLIENT_REJECTED, payload: getError(err.response) });
    }
};

export const setClientFirstName = (dispatch, first_name) => {
    dispatch({ type: SET_CLIENT_FIRST_NAME, payload: first_name });
};

export const setClientLastName = (dispatch, last_name) => {
    dispatch({ type: SET_CLIENT_LAST_NAME, payload: last_name });
};

export const setClientEmail = (dispatch, email) => {
    dispatch({ type: SET_CLIENT_EMAIL, payload: email });
};

export const setClientPhone = (dispatch, phone) => {
    dispatch({ type: SET_CLIENT_PHONE, payload: phone });
};

export const setClientCountry = (dispatch, country) => {
    dispatch({ type: SET_CLIENT_COUNTRY, payload: country });
};

export const setClientLocality = (dispatch, locality) => {
    dispatch({ type: SET_CLIENT_LOCALITY, payload: locality });
};

export const setClientStreet = (dispatch, street) => {
    dispatch({ type: SET_CLIENT_STREET, payload: street });
};

export const setClientNr = (dispatch, nr) => {
    dispatch({ type: SET_CLIENT_NR, payload: nr });
};

export const setClientBlock = (dispatch, block) => {
    dispatch({ type: SET_CLIENT_BLOCK, payload: block });
};

export const setClientEntry = (dispatch, entry) => {
    dispatch({ type: SET_CLIENT_ENTRY, payload: entry });
};

export const setClientFloor = (dispatch, floor) => {
    dispatch({ type: SET_CLIENT_FLOOR, payload: floor });
};

export const setClientApartment = (dispatch, apartment) => {
    dispatch({ type: SET_CLIENT_APARTMENT, payload: apartment });
};

export const deleteClient = async (dispatch, id) => {
    try {
        dispatch({ type: DELETE_CLIENT_PENDING });

        await API.delete(`/clients/${id}`);

        dispatch({ type: DELETE_CLIENT_FULFILLED, payload: id });
    } catch (err) {
        dispatch({ type: DELETE_CLIENT_REJECTED, payload: getError(err.response) });
    }
};

export const setUpdateClientErrors = (dispatch, errors) => {
    dispatch({ type: SET_UPDATE_CLIENT_ERRORS, payload: errors });
};

export const updateClient = async (dispatch, id, data, callback) => {
    try {
        dispatch({ type: UPDATE_CLIENT_PENDING });

        const response = await API.put('/clients/' + id, data);

        dispatch({ type: UPDATE_CLIENT_FULFILLED, payload: response.data });

        callback();
    } catch (err) {
        dispatch({ type: UPDATE_CLIENT_REJECTED, payload: getError(err.response) });
    }
};

export const getClientSessions = async (dispatch, id) => {
    try {
        dispatch({ type: GET_CLIENT_SESSIONS_PENDING });

        const response = await API.get(`/clients/${id}/sessions`);

        dispatch({ type: GET_CLIENT_SESSIONS_FULFILLED, payload: response.data });
    } catch (err) {
        dispatch({ type: GET_CLIENT_SESSIONS_REJECTED, payload: getError(err.response) });
    }
};

const constructExportUrl = (state, export_type) => {

    let url = `${process.env.REACT_APP_API_BASE_URL}/clients/list/export/${export_type}`;

    const filters = [];

    if (state.sort) {
        filters.push(`sort=${state.sort}&order=${state.order}`);
    }

    if (state.search) {
        filters.push(`search=${state.search}`);
    }

    url += '?' + filters.join('&');

    return url;
};

export const exportSessions = async (state, export_type, error_callback) => {
    const extension = {
        'pdf': 'pdf',
        'excel': 'xlsx'
    };

    try {
        const response = await API.get(constructExportUrl(state, export_type), {responseType: 'blob'});

        saveAs(response.data, `clients_list.${extension[export_type]}`);
    } catch (err) {
        error_callback();
    }
};
