import React from 'react'
import styled from "styled-components";
import {Abutton} from "./Abutton";

export const AlinkButton = styled(({className, children, ...rest}) => {

    return (
        <Abutton
            buttonLink
            className={className}
            {...rest}
        >
            {children}
        </Abutton>
    )
})`

`