import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {notification} from '../../components/Lnotification'
import moment from 'moment'
import {createGlobalStyle, css} from 'styled-components'

import API from '../../api'
import Loading from "../../components/Loading"
import PageNotFound from '../../components/PageNotFound'
import Portrait from "../../components/Portrait"

const GlobalStyle = createGlobalStyle`
    && {
        html, body {
            font-size: 12px;
        }
    }
`

const cssFixes = css`
    /* background: ${({ background }) => background}; */
    color: ${({ color }) => color} !important;
    font-family: ${({ fontFamily }) => fontFamily} !important;
    .main-title, .file {
        color: ${({ color }) => color} !important;
        font-family: ${({ fontFamily }) => fontFamily} !important;
    }

    .session-form {
        display: grid;
        grid-gap: 6rem;
        padding: 5rem 12%;
    }


    .main-title {
        color: var(--primary-color);
        font-size: 2.8rem;
        font-weight: 400;
        line-height: 3.6rem;
    }

    .content-box-body {
        background: #fff;
        box-shadow: 0.2rem 0 0.5rem #f1f1f1;
        border: 1px solid #ccc;
    }

    .button {
        height: 4.6rem;
        line-height: 4.6rem;
        border-radius: 3rem;
    }

    .button.with-icon-right i {
        line-height: 4.6rem;
    }
`

const ActivateSession = () => {

    const [state, setState] = useState({
        session: null,
        loading: false,
        error: null
    }, 'sessionActivation')

    const params = useParams()

    useEffect(() => {
        getSession()
    }, [])

    const getSession = async () => {
        try {
            setState(state => ({...state, loading: true}))
            const {data:session} = await API.get(`sessions/secret/${params.token}/activate`)
            setState(state => ({...state, loading: false, session}))
        } catch(err) {
            setState(state => ({
                ...state,
                loading: false,
                ...(err.response.status === 404 ? {pageNotFound: true} : {})
            }))
        }
    }

    const activateSession = async () => {
        try {
            setState(state => ({...state, loading: true}))

            await API.post(`sessions/secret/${params.token}/activate`)

            notification['success']({
                description: 'Confirmarea a fost trimisa, multumim!',
                duration: 0
            })

            setState(state => ({...state, loading: false}))

            await getSession()
            
        } catch(err) {
            setState(state => ({...state, loading: false}))
        }
    }

    return (
        <>  
            <GlobalStyle />
            <header className="header">
                <div className="logo">
                    <img src={state.session?._form?.logo || 'img/Alchemist_Identity_Final-08.png'} />
                </div>
            </header>
            {state.loading && <Loading/>}
            <PageNotFound active={!!state.pageNotFound}>
                {!state.loading && state.session &&
                    <div className="wrapper session-page" css={cssFixes}>
                        <div className="content">
                            <div className="content-box session-content-box">
                                <div className="scrollbar">

                                    <div className="content-box-body">
                                        <div className="session-form" background={state.session._form.background_color} color={state.session._form.font_color} fontFamily={state.session._form.font_face}>

                                            <div className="fieldset-row fieldset-row-title">
                                                <h1 className="main-title" style={{ textAlign: "center" }}>{state.session.clientActivation.title}</h1>
                                            </div>

                                            <div className="fieldset-row">
                                                <pre>{state.session.clientActivation.description}</pre>
                                            </div>

                                            {state.session.documents.length > 0 &&                                            
                                                <div className="fielset-row">
                                                    <div className="session-form-grid files-list mar1b" style={{ gridTemplateColumns: "repeat(3, 1fr)" }}>
                                                        {state.session.documents.map((document, documentIndex) => (
                                                            <a key={documentIndex} href={`${process.env.REACT_APP_API_BASE_URL}/sessions/secret/${params.token}/document/${document._id}`} target="_blank">
                                                                <div className="file" key={documentIndex}>
                                                                    <img src="img/file.svg" />
                                                                    <strong>{document.filename.replace(/\.[^/.]+$/, "")}</strong>
                                                                </div>
                                                            </a>
                                                        ))}
                                                    </div>
                                                </div>
                                            }

                                            {state.session.clientActivation.activationDate
                                                ? <p>{`Confirmare inregistrata in data de: ${moment(state.session.clientActivation.activationDate).format("DD/MM/YYYY hh:mm")}`}</p>
                                                :   (
                                                    <div className="fielset-row" style={{ textAlign: "center" }}>
                                                    <div className="button with-icon-right close-overlay" onClick={activateSession}>
                                                        <i className="icon-ia-checked-outline"/>
                                                        Sunt de acord cu continutul tuturor documentelor de mai sus
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                }
            </PageNotFound>
            <Portrait />
        </>
        
    )
}

export default ActivateSession