import React from 'react'
import moment from 'moment'
import bytes from 'bytes'
import {notification} from '../../components/Lnotification'
import validator from 'validator'
import joi from '@hapi/joi'
import Navbar from '../../components/Navbar'
import Loading from '../../components/Loading'
import Logo from '../../images/logo.png'
import API from '../../api'
import AdeleteConfirm from '../../components/AdeleteConfirm'
import {BlockPicker} from "react-color"
import Portrait from "../../components/Portrait"
import {Country, City} from '../../components/CountryCity'
import SessionsStorage from './SessionsStorage'
import {isEmail} from "validator";
import {phoneValidator, addPlusPrefix} from "../../utils/phoneValidator";
import { css } from 'styled-components'

const cssFixes = css`
    .company-box .profile-image:after {
        content: '';
        background: #f8f8f8 url(../img/Alchemist_Identity_Final-07.png) no-repeat 50% 50%;
        background-size: auto 2.6rem;
    }
`

const Settings = () => {

    const [state, setState] = React.useState({
        validationErrors: {},
        loading: false,
        error: null,
        editing_departments: {
            mode: false,
            index: -1,
            code: ""
        },
        new_department: '',
        company: {
            name: "",
            logo: null,
            email: "",
            phone: "",
            registration_code: "",
            vat: "",

            country: "",
            locality: "",
            street: "",
            nr: "",
            block: "",
            entry: "",
            floor: "",
            apartment: "",

            departments: []
        },
        application: {
            name: "",
            font_color: "",
            background_color: "",
            font_face: 'Open Sans'
        },
        google_maps_api_key: "",
        statistics: {
            last_session: null,
            last_conversion: null,
            top_form: null,
            no_forms: '-',
            total_sessions: '-',
            total_conversions: '-',
            total_users: '-'
        },
        mailGateway: {
            enabled: false,
            smtp_host: "",
            smtp_port: "",
            username: "",
            password: "",
            from_email: ""
        },
        smsGateway: {
            enabled: false,
            provider: "",
            api_key: "",
            username: ""
        },
        storage: '',
        storageFailed: false,
        loadedOnce: false
    }, 'settings');

    const elements_references = {};

    React.useEffect(() => {
        getCompanyData()
    }, []);

    const scrollToError = (validation_errors) => {
        let y, ref = null;
        const validation_errors_keys = Object.keys(validation_errors);
        const elements_references_keys = Object.keys(elements_references).filter(ref => validation_errors_keys.includes(ref));
        elements_references_keys.forEach(key => {
            if (!y || y > elements_references[key].getBoundingClientRect().y) {
                y = elements_references[key].getBoundingClientRect().y;
                ref = elements_references[key]
            }
        });
        // setTimeout(() => {
        if (ref) {
            ref.scrollIntoView()
        }
        // }, 1000)
    };

    const validateData = () => {
        setState(state => ({...state, validationErrors: {}}));
        let validationErrors = {};
        //email gateway
        if (state.mailGateway.enabled) {
            const mailGateway = state.mailGateway;
            if (validator.isEmpty(mailGateway.smtp_host, {ignore_whitespace: true})) validationErrors.smtp_host = "invalid smtp host";

            const {error} = joi.number().positive().required().min(1).validate(mailGateway.smtp_port);
            if (error) validationErrors.smtp_port = error.message;

            if (validator.isEmpty(mailGateway.from_email, {ignore_whitespace: true}) || !isEmail(mailGateway.from_email)) validationErrors.from_email = "invalid From";
            if (validator.isEmpty(mailGateway.username, {ignore_whitespace: true})) validationErrors.username = "invalid smtp username";
            if (validator.isEmpty(mailGateway.password, {ignore_whitespace: true})) validationErrors.password = "invalid smtp password"
        }
        if (state.company.email && !isEmail(state.company.email)) {
            validationErrors.email = "Email is invalid";
        }
        if (state.company.phone && phoneValidator(state.company.phone) === false) {
            validationErrors.phone = "Phone is invalid";
        }
        const duplicates = state.company.departments.reduce((acc, item, itemIndex) => state.company.departments.includes(item, itemIndex + 1) ? [...acc, item] : acc, [])
        if (duplicates.length > 0) {
            validationErrors.departments = {duplicates};
        }
        setState(state => ({...state, validationErrors}));

        if (Object.keys(validationErrors).length > 0) {
            scrollToError(validationErrors);
        }
        return Object.keys(validationErrors).length === 0
    };

    const getCompanyData = async () => {
        try {
            setState(state => ({...state, loading: true}));
            const {data} = await API.get('/company');
            const {
                name, email, phone, registration_code, vat, application, logo, departments,
                country, locality, street, nr, block, entry, floor, apartment, mailGateway, smsGateway, storage, storageFailed, google_maps_api_key
            } = data.company;
            const companyDetails = {
                name, email, phone, registration_code, vat, logo, departments,
                country, locality, street, nr, block, entry, floor, apartment
            };
            setState(state => ({
                ...state,
                loading: false,
                company: companyDetails,
                application,
                statistics: data.statistics,
                mailGateway,
                smsGateway,
                storage,
                storageFailed,
                google_maps_api_key,
                loadedOnce: true
            }))
        } catch (err) {
            console.log('server error place 5')
            setState(state => ({...state, loading: false}))
            notification.error({
                message: 'Something went wrong with the server'
            })
        }
    };


    const onCompanyChange = ({target: {name, value}}) => setState(state => ({
        ...state,
        company: {
            ...state.company,
            [name]: value
        }
    }));

    const setPhone = phone => {
        setState(state =>
            ({
                ...state,
                company: {
                    ...state.company,
                    phone: addPlusPrefix(phone)
                }
            })
        );
    };

    const onApplicationChange = ({target: {name, value}}) => {
        setState(state => ({
            ...state,
            application: {
                ...state.application,
                [name]: value
            }
        }))
    };

    const onMapsKeyChange = ({target: {value}}) => {
        setState(state => ({
            ...state,
            google_maps_api_key: value
        }))
    };

    const onSave = async ({storage}) => {
        const {application, mailGateway, smsGateway, google_maps_api_key} = state;
        if (!storage) storage = state.storage
        let data = {...state.company, application, smsGateway, mailGateway, storage, google_maps_api_key};

        if (validateData()) {
            try {
                setState(state => ({...state, loading: true}));
                await API.put('/company', data);
                // window.location.reload();
                notification.success({
                    message: 'Company Information Updated'
                });
                setState(state => ({...state, loading: false}));
            } catch (error) {
                setState(state => ({...state, loading: false, error: error.response.data.error}))
            }
        } else {

        }

    };

    const editDepartment = (i, value) => {
        const departments = state.company.departments || [];
        setState(state => ({
            ...state,
            company: {
                ...state.company,
                departments: departments.map((department, index) => {
                    if (index === i) department = value;
                    return department
                })
            },
            editing_departments: {
                mode: false,
                index: -1,
                code: ""
            }
        }))
    };

    const removeDepartment = i => {
        const departments = state.company.departments || [];
        setState(state => ({
            ...state,
            company: {
                ...state.company,
                departments: departments.filter((_, index) => {
                    return index !== i
                })
            }
        }))
    };

    const addDepartment = () => {
        const departments = state.company.departments || [];
        setState(state => ({
            ...state,
            new_department: '',
            company: {
                ...state.company,
                departments: [...departments, state.new_department]
            }
        }))
    };

    const onImageChange = e => {
        if (e.target.files.length === 0) {
            return
        }
        if (e.target.files[0].size > bytes('4MB')) {
            notification.error({
                message: 'The file is too large, please choose another one.'
            });
            e.target.value = null;
            return
        }
        const reader = new FileReader();
        reader.onload = e => {
            setState(state => ({
                ...state,
                company: {
                    ...state.company,
                    logo: e.target.result
                }
            }))
        };
        reader.readAsDataURL(e.target.files[0]);
        e.target.value = null
    };

    const removeImage = () => {
        setState(state => ({
            ...state,
            company: {
                ...state.company,
                logo: null
            }
        }))
    };

    const getCompanyLogo = () => {
        if (state.company.logo) {
            // const img = document.querySelector(".ils-uploaded-img > img:first-of-type");
            // if (state.company.logo.split('.').pop() === "svg" || state.company.logo.substring("data:image/".length, state.company.logo.indexOf(";base64")) === "svg+xml") {
            //     img.style.objectFit = "unset"
            // } else {
            //     img.style.objectFit = "contain"
            // }
            return state.company.logo
        }
        return Logo
    };

    const setDefaultLook = e => {
        e.preventDefault();
        setState(state => ({
            ...state,
            application: {
                ...state.application,
                font_color: '#000000',
                background_color: '#F1F1F1',
                font_face: 'Open Sans'
            }
        }))
    };

    const onEmailGatewayChange = ({target: {name, checked = null, value, type}}) => {
        setState(state => ({
            ...state,
            mailGateway: {...state.mailGateway, [name]: type === "checkbox" ? checked : value}
        }))
    };

    const onSmsGatewayChange = ({target: {name, checked = null, value, type}}) => {
        setState(state => ({
            ...state,
            smsGateway: {...state.smsGateway, [name]: type === "checkbox" ? checked : value}
        }))
    };

    const onStorageChange = storage => {
        setState(state => ({...state, storage}))
    }

    return (
        <>
            <Navbar/>
            {!state.loadedOnce && <Loading />}
            <div 
                className="wrapper company-page" 
                css={`
                    ${cssFixes}
                    display: ${!state.loadedOnce ? 'none' : 'visible'};
                `}
            >
                <div className="content">

                    <div className="toolbox toolbox-1">
                        <h3 className="tb-header-title">Look and Feel</h3>
                        <div className="scrollbar">
                            <h6>Font color</h6>
                            <div
                                css={`
										margin: 0.5rem 0 1rem;
										> .block-picker > div:nth-child(2) {
											border: 2px solid white;
										}
									`}
                            >
                                <BlockPicker
                                    triangle="hide"
                                    width="100%"
                                    colors={[
                                        "#1abc9c",
                                        "#2ecc71",
                                        "#3498db",
                                        "#9b59b6",
                                        "#000000",
                                        "#16a085",
                                        "#27ae60",
                                        "#2980b9",
                                        "#8e44ad",
                                        "#7f8c8d",
                                        "#f1c40f",
                                        "#e67e22",
                                        "#fafbfe",
                                        "#fea540",
                                        "#95a5a6",
                                    ]}
                                    color={state.application.font_color}
                                    onChange={color =>
                                        setState(state => ({
                                            ...state,
                                            application: {
                                                ...state.application,
                                                font_color: color.hex
                                            }
                                        }))
                                    }
                                />
                            </div>
                            <h6>Background color</h6>
                            <div
                                css={`
										margin: 0.5rem 0 1rem;
										> .block-picker > div:nth-child(2) {
											border: 2px solid white;
										}
									`}
                            >
                                <BlockPicker
                                    triangle="hide"
                                    width="100%"
                                    colors={[
                                        "#1abc9c",
                                        "#2ecc71",
                                        "#3498db",
                                        "#9b59b6",
                                        "#000000",
                                        "#16a085",
                                        "#27ae60",
                                        "#2980b9",
                                        "#8e44ad",
                                        "#7f8c8d",
                                        "#f1c40f",
                                        "#e67e22",
                                        "#fafbfe",
                                        "#fea540",
                                        "#95a5a6",
                                    ]}
                                    color={state.application.background_color}
                                    onChange={color =>
                                        setState(state => ({
                                            ...state,
                                            application: {
                                                ...state.application,
                                                background_color: color.hex
                                            }
                                        }))
                                    }
                                />
                            </div>
                            <h6>Font face</h6>
                            <div className="choose-font">
                                <select name="font_face" className="input" onChange={onApplicationChange}
                                        value={state.application.font_face}>
                                    <option value="Open Sans">Open Sans</option>
                                    <option value="Sans Serif">Sans Serif</option>
                                    <option value="Calibri">Calibri</option>
                                </select>
                            </div>

                            <button className="button button-outline" style={{width: '100%'}}
                                    onClick={setDefaultLook}>Reset
                            </button>
                        </div>
                    </div>

                    <form className="content-box form company-form" autoComplete="off">
                        <div className="scrollbar">

                            {state.loading &&
                            <Loading/>
                            }

                            {!state.loading &&
                            <>
                                <div className="profile-box company-box">
                                    <div className="profile-details">
                                        <div className="profile-image" style={{textAlign: "center"}}>
                                            <input className="ils-upload-file" style={{position: "relative"}}
                                                   type="file" onChange={onImageChange} accept="image/*"/>
                                            <span>Upload logo</span>
                                            {state.company.logo &&
                                            <>
                                                <div className="ils-uploaded-img">
                                                    <img style={{objectFit: "contain", width: "100%"}}
                                                         src={getCompanyLogo()} alt=""
                                                    />
                                                </div>
                                                <AdeleteConfirm
                                                    title="Are you sure you want to remove the image?"
                                                    onConfirm={removeImage}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <div className="tbl-btn"><i className="icon-ia-trash-bold"/>
                                                        <div className="tooltip tooltip-bottom">Delete</div>
                                                    </div>
                                                </AdeleteConfirm>
                                            </>
                                            }
                                        </div>

                                        <div className="form-row">
                                            <label htmlFor="">Company Name</label>
                                            <input id="" type="text" name="name" value={state.company.name}
                                                   maxLength={60} placeholder=""
                                                   onChange={onCompanyChange}/>
                                        </div>

                                    </div>
                                </div>
                                <div className="form-box-body">
                                    <fieldset>
                                        <h4>Company details</h4>
                                        <div className="form-grid-row">
                                            <div className="form-row">
                                                <label>VAT</label>
                                                <input type="text" name="vat" value={state.company.vat || ""}
                                                       maxLength={30}
                                                       onChange={onCompanyChange}/>
                                            </div>
                                            <div className="form-row">
                                                <label>Reg. Code</label>
                                                <input type="text" name="registration_code"
                                                       value={state.company.registration_code || 0} maxLength={30}
                                                       onChange={onCompanyChange}/>
                                            </div>
                                        </div>
                                        <div className="form-grid-row">
                                            <div ref={ref => elements_references["phone"] = ref}
                                                 className={`form-row ${state.validationErrors.phone ? "error" : ""}`}>
                                                <label>Phone</label>
                                                <input type="text" name="phone" maxLength={20}
                                                       value={state.company.phone || ""} onChange={({target:{value}}) => setPhone(value)}/>
                                                {state.validationErrors.phone && (
                                                    <span
                                                        className="form-row-error-msg">{state.validationErrors.phone}</span>
                                                )}
                                            </div>
                                            <div ref={ref => elements_references["email"] = ref}
                                                 className={`form-row ${state.validationErrors.email ? "error" : ""}`}>
                                                <label>Email</label>
                                                <input type="email" name="email" value={state.company.email || ""}
                                                       maxLength={255} onChange={onCompanyChange}/>
                                                {state.validationErrors.email && (
                                                    <span
                                                        className="form-row-error-msg">{state.validationErrors.email}</span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-grid-row">
                                            <div className="form-row">
                                                <label>County</label>
                                                <Country
                                                    value={state.company.country}
                                                    onChange={e => {
                                                        onCompanyChange(e);
                                                        onCompanyChange({target: {name: "locality", value: ""}})
                                                    }}
                                                    name="country"
                                                />
                                            </div>

                                            <div className="form-row">
                                                <label>City</label>
                                                <City value={state.company.locality} onChange={onCompanyChange}
                                                      name="locality" selectedCountry={state.company.country}/>
                                            </div>
                                        </div>

                                        <div className="form-grid-row street-grid">
                                            <div className="form-row">
                                                <label>Street</label>
                                                <input name="street" value={state.company.street || ""}
                                                       onChange={onCompanyChange}/>
                                            </div>

                                            <div className="form-row">
                                                <label>No</label>
                                                <input name="nr" value={state.company.nr || ""}
                                                       onChange={onCompanyChange}/>
                                            </div>
                                        </div>

                                        <div className="form-grid-row address-grid">
                                            <div className="form-row">
                                                <label>Building</label>
                                                <input name="block" value={state.company.block || ""}
                                                       onChange={onCompanyChange}/>
                                            </div>

                                            <div className="form-row">
                                                <label>Entrance</label>
                                                <input name="entry" value={state.company.entry || ""}
                                                       onChange={onCompanyChange}/>
                                            </div>

                                            <div className="form-row">
                                                <label>Floor</label>
                                                <input name="floor" value={state.company.floor || ""}
                                                       onChange={onCompanyChange}/>
                                            </div>

                                            <div className="form-row">
                                                <label>Ap</label>
                                                <input name="apartment" value={state.company.apartment || ""}
                                                       onChange={onCompanyChange}/>
                                            </div>
                                        </div>

                                        {/*<div style={{position: 'relative'}}>*/}
                                        {/*<div className="tbl-btn" style={{position: 'absolute', right: 0, top: 0}}*/}
                                        {/*     onClick={() => setState(state => ({*/}
                                        {/*         ...state,*/}
                                        {/*         editing_departments: !state.editing_departments*/}
                                        {/*     }))}><i className="icon-ia-edit-bold"/></div>*/}

                                        <h4>Departments</h4>
                                        {/*<div className="form-row autocomplete-tags-box">*/}
                                        {/*    <div className="autocomplete-tags">*/}
                                        {/*        <div className="scrollbar">*/}
                                        {/*            <span className="at-tag"><i*/}
                                        {/*                className="icon-ia-close"/>Sales</span>*/}
                                        {/*            <span className="at-tag"><i className="icon-ia-close"/>Marketing</span>*/}
                                        {/*            <input className="at-input" type="text" value=""*/}
                                        {/*                   placeholder="Add department"/>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="form-row autocomplete-tags-box" ref={ref => elements_references["departments"] = ref}>
                                            <div className="autocomplete-tags">
                                                <div className="scrollbar">
                                                    {state.company.departments && state.company.departments.map((department, index) => {

                                                        // if (state.editing_departments) {
                                                        // return (
                                                        //     <div key={index} style={{
                                                        //         display: 'flex',
                                                        //         alignItems: 'center',
                                                        //         marginBottom: '3px'
                                                        //     }}>
                                                        //         <input type="text"
                                                        //                style={{width: 'calc(100% - 50px)'}}
                                                        //                maxLength={50} value={department}
                                                        //                onChange={({target: {value}}) => editDepartment(index, value)}/>
                                                        //         <IPopConfirm
                                                        //             title="Are you sure you want to remove this department?"
                                                        //             onConfirm={() => removeDepartment(index)}
                                                        //             okText="Remove"
                                                        //             cancelText="Cancel"
                                                        //         >
                                                        //             <div className="tbl-btn"><i
                                                        //                 className="icon-ia-trash-bold"/></div>
                                                        //         </IPopConfirm>
                                                        //     </div>
                                                        // )
                                                        // }

                                                        return (
                                                            <>
                                                                {(!state.editing_departments.mode || state.editing_departments.index !== index) &&
                                                                <span className="at-tag"
                                                                      style={(state.validationErrors.departments && state.validationErrors.departments.duplicates && state.validationErrors.departments.duplicates.includes(department) ? {border: "1px solid red"} : {})}
                                                                      key={index}
                                                                      onDoubleClick={() => {
                                                                          setState(state => ({
                                                                              ...state,
                                                                              editing_departments: {
                                                                                  mode: true,
                                                                                  index,
                                                                                  code: department
                                                                              }
                                                                          }))
                                                                      }}>
                                                                <AdeleteConfirm
                                                                    title="Are you sure you want to remove this department?"
                                                                    onConfirm={() => removeDepartment(index)}
                                                                    okText="Remove"
                                                                    cancelText="Cancel">
                                                                    <i className="icon-ia-close"/>
                                                                            </AdeleteConfirm>
                                                                    {department}</span>
                                                                }
                                                                {state.editing_departments.mode && state.editing_departments.index === index &&
                                                                <input className="at-input" type="text" maxLength={50}
                                                                       value={state.editing_departments.code}
                                                                       placeholder="Add department"
                                                                       onChange={({target: {value}}) => setState(state => ({
                                                                           ...state,
                                                                           editing_departments: {
                                                                               ...state.editing_departments,
                                                                               code: value
                                                                           }
                                                                       }))} onKeyDown={async e => {
                                                                    if (e.keyCode === 13) {
                                                                        e.preventDefault();
                                                                        editDepartment(index, state.editing_departments.code)
                                                                    }
                                                                }}/>
                                                                }
                                                            </>
                                                        )

                                                    })}
                                                    {/*<input type="text" style={{width: 'calc(100% - 50px)'}}*/}
                                                    {/*       maxLength={50} value={state.new_department}*/}
                                                    {/*       onChange={({target: {value}}) => setState(state => ({*/}
                                                    {/*           ...state,*/}
                                                    {/*           new_department: value*/}
                                                    {/*       }))}/>*/}
                                                    {!state.editing_departments.mode &&
                                                    <input className="at-input" type="text" maxLength={50}
                                                           value={state.new_department}
                                                           placeholder="Add department"
                                                           onChange={({target: {value}}) => setState(state => ({
                                                               ...state,
                                                               new_department: value
                                                           }))} onKeyDown={async e => {
                                                        if (e.keyCode === 13) {
                                                            e.preventDefault();
                                                            addDepartment()
                                                        }
                                                    }}/>
                                                    }
                                                    {/*<div className="tbl-btn" onClick={addDepartment}><i*/}
                                                    {/*    className="icon-ia-plus"/>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>

                                        {/*</div>*/}

                                        <h4>Application settings</h4>
                                        <div className="form-row">
                                            <label>Application Name</label>
                                            <input type="text" name="name" value={state.application.name}
                                                   onChange={onApplicationChange}/>
                                        </div>
                                        <div className="mBox">
                                            <div className={`form-row-checkbox`}>
                                                <input id="smsGateway" type="checkbox" name="enabled"
                                                       checked={state.smsGateway.enabled}
                                                       onChange={onSmsGatewayChange}/>
                                                <label htmlFor="smsGateway">Add SMS gateway</label>
                                            </div>
                                            {state.smsGateway.enabled &&
                                            <div className="mBox-hidden">
                                                <div className="form-grid-row">
                                                    <div className="form-row">
                                                        <label>Provider</label>
                                                        <select name="provider" value={state.smsGateway.provider}
                                                                onChange={onSmsGatewayChange}>
                                                            <option/>
                                                            <option value="bizpack">bizpack</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-row">
                                                        <label>Username</label>
                                                        <input type="text" name="username"
                                                               value={state.smsGateway.username}
                                                               onChange={onSmsGatewayChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <label>API Key</label>
                                                    <input type="text" name="api_key" value={state.smsGateway.api_key}
                                                           onChange={onSmsGatewayChange}/>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        <div className="mBox">
                                            <div className={`form-row-checkbox`}>
                                                <input id="emailGateway" type="checkbox" name="enabled"
                                                       checked={state.mailGateway.enabled}
                                                       onChange={onEmailGatewayChange}/>
                                                <label htmlFor="emailGateway">Add email gateway</label>
                                            </div>
                                            {state.mailGateway.enabled &&
                                            <div className="mBox-hidden">
                                                <div className="form-grid-row street-grid">
                                                    <div ref={ref => elements_references["smpt_host"] = ref}
                                                         className={`form-row ${state.validationErrors.smtp_host ? "error" : ""}`}>
                                                        <label>SMTP host</label>
                                                        <input type="text" name="smtp_host"
                                                               value={state.mailGateway.smtp_host}
                                                               onChange={onEmailGatewayChange}/>
                                                        {state.validationErrors.smtp_host &&
                                                        <span
                                                            className="form-row-error-msg">{state.validationErrors.smtp_host}</span>
                                                        }
                                                    </div>
                                                    <div ref={ref => elements_references["smtp_port"] = ref}
                                                         className={`form-row ${state.validationErrors.smtp_port ? "error" : ""}`}>
                                                        <label>SMTP port</label>
                                                        <input type="number" name="smtp_port"
                                                               value={state.mailGateway.smtp_port}
                                                               onChange={onEmailGatewayChange}/>
                                                        {state.validationErrors.smtp_port &&
                                                        <span
                                                            className="form-row-error-msg">{state.validationErrors.smtp_port}</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div ref={ref => elements_references["from_email"] = ref}
                                                     className={`form-row ${state.validationErrors.from_email ? "error" : ""}`}>
                                                    <label>From</label>
                                                    <input type="text" name="from_email"
                                                           value={state.mailGateway.from_email}
                                                           onChange={onEmailGatewayChange}/>
                                                    {state.validationErrors.from_email &&
                                                    <span
                                                        className="form-row-error-msg">{state.validationErrors.from_email}</span>
                                                    }
                                                </div>
                                                <div className="form-grid-row">
                                                    <div ref={ref => elements_references["username"] = ref}
                                                         className={`form-row ${state.validationErrors.username ? "error" : ""}`}>
                                                        <label>Username</label>
                                                        <input type="text" name="username"
                                                               value={state.mailGateway.username}
                                                               onChange={onEmailGatewayChange}/>
                                                        {state.validationErrors.username &&
                                                        <span
                                                            className="form-row-error-msg">{state.validationErrors.username}</span>
                                                        }
                                                    </div>
                                                    <div ref={ref => elements_references["password"] = ref}
                                                         className={`form-row ${state.validationErrors.password ? "error" : ""}`}>
                                                        <label>Password</label>
                                                        <input type="password" autoComplete="new-password"
                                                               name="password"
                                                               value={state.mailGateway.password}
                                                               onChange={onEmailGatewayChange}/>
                                                        {state.validationErrors.password &&
                                                        <span
                                                            className="form-row-error-msg">{state.validationErrors.password}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        <div className="form-row">
                                            <label>Google Maps API Key (please refresh the page for the updated key to take effect)</label>
                                            <input type="text" name="google_maps_api_key" value={state.google_maps_api_key}
                                                   onChange={onMapsKeyChange}/>
                                        </div>
                                        <SessionsStorage
                                            value={state.storage}
                                            failed={state.storageFailed}
                                            setValue={onStorageChange}
                                            saveConfig={onSave}
                                            reloadParent={getCompanyData}
                                        />
                                    </fieldset>

                                    <div className="form-box-actions">
                                        <button className="button" onClick={onSave} type="button"><i
                                            className="icon-check"/><span>Save</span></button>
                                    </div>
                                </div>
                            </>
                            }

                        </div>
                    </form>

                </div>

                <div className="sidebar">

                    <div className="scrollbar">
                        {/*<h3 className="sidebar-title">Company logo</h3>*/}

                        {/*<div className="img-logo-sidebar">*/}
                        {/*    <div className="ils-uploaded-img">*/}
                        {/*        <img style={{objectFit: "contain"}} src={getCompanyLogo()} alt=""*/}
                        {/*        /></div>*/}
                        {/*    <input className="ils-upload-file" type="file" onChange={onImageChange}/>*/}
                        {/*</div>*/}

                        {/*{state.company.logo &&*/}
                        {/*<>*/}
                        {/*    <IPopConfirm*/}
                        {/*        title="Are you sure you want to remove the image?"*/}
                        {/*        onConfirm={removeImage}*/}
                        {/*        okText="Yes"*/}
                        {/*        cancelText="No"*/}
                        {/*    >*/}
                        {/*        <div className="button button-outline">*/}
                        {/*            <i className="icon-ia-trash-bold"/> Remove Image*/}
                        {/*        </div>*/}
                        {/*    </IPopConfirm>*/}
                        {/*    <br/><br/>*/}
                        {/*</>*/}
                        {/*}*/}

                        <h3 className="sidebar-title">Company statistics</h3>

                        <ul className="sidebar-list">
                            <li>
                                <i className="icon-ia-user"/>
                                <strong>Total Users</strong>
                                <span className="highlight">{state.statistics.total_users}</span>
                            </li>
                            <li>
                                <i className="icon-ia-session"/>
                                <strong>Total Sessions</strong>
                                <span className="highlight">{state.statistics.total_sessions}</span>
                            </li>
                            <li className="alert">
                                <i className="icon-ia-conversion"/>
                                <strong>Conversions</strong>
                                <span className="highlight">{state.statistics.total_conversions}</span>
                            </li>
                            <li>
                                <i className="icon-ia-document"/>
                                <strong>No. of forms</strong>
                                <span className="highlight">{state.statistics.no_forms}</span>
                            </li>

                            {state.statistics.top_form &&
                            <li>
                                <i className="icon-ia-gdpr"/>
                                <strong>Best Form</strong>
                                <span>{state.statistics.top_form.name}<em>{state.statistics.top_form.conversions} conversions</em></span>
                            </li>
                            }
                            {!state.statistics.top_form &&
                            <li>
                                <i className="icon-ia-gdpr"/>
                                <strong>Best Form</strong>
                                <span>-</span>
                            </li>
                            }

                            {state.statistics.last_session &&
                            <li>
                                <i className="icon-ia-session-c-f"><span><i className="icon-ia-session-c-b"/></span></i>
                                <strong>Last session</strong>
                                <span>{moment(state.statistics.last_session).format('DD.MM.YYYY, H:mm')}</span>
                            </li>
                            }
                            {!state.statistics.last_session &&
                            <li>
                                <i className="icon-ia-session-c-f"><span><i className="icon-ia-session-c-b"/></span></i>
                                <strong>Last session</strong>
                                <span>-</span>
                            </li>
                            }

                            {state.statistics.last_conversion &&
                            <li>
                                <i className="icon-ia-conversion-c-f"><span><i
                                    className="icon-ia-conversion-c-b"/></span></i>
                                <strong>Last conversion</strong>
                                <span>{moment(state.statistics.last_conversion).format('DD.MM.YYYY, H:mm')}</span>
                            </li>
                            }
                            {!state.statistics.last_conversion &&
                            <li>
                                <i className="icon-ia-conversion-c-f"><span><i
                                    className="icon-ia-conversion-c-b"/></span></i>
                                <strong>Last conversion</strong>
                                <span>-</span>
                            </li>
                            }
                        </ul>

                    </div>
                </div>

            </div>

            <Portrait/>
        </>
    )
};

export default Settings
