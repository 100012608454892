import React from 'react'

import LivePreview from './LivePreview.marketingCommunicationOptions'
import {MarketingComunicationOptionsEditor} from './Editor.marketingCommunicationOptions'
import CodeCopy from './CodeCopy.marketingCommunicationOptions'
import View from './View.marketingCommunicationOptions'

export default {
  livePreview: args => <LivePreview {...args}/>,
  editor: args => <MarketingComunicationOptionsEditor {...args}/>,
  codeCopy: args => <CodeCopy {...args}/>,

  default:() => ({
    type: "communication options",
    text: "Marketing and Communications Options Placeholder Title",
    pinned: {
      status: false
    },
    emailPlaceholder: "",
    phoneNumberPlaceholderCall: "",
    phoneNumberPlaceholderSMS: "",
    validations: []
  }),
  
  validate: () => false,
  group: "ADVANCED",
  icon: "icon-ia-marketing",
  name: "Marketing & Communication Options",
  view: args => <View {...args}/>
}