import React from "react"

import AcopyToClipboard from "../../../components/AcopyToClipboard";

const Availability = ({
	changeValue = f => f,
	errors = null,
	publicAvailabilityObject = {
		status: false,
		downloadDocuments: false,
		redirect: { seconds: 1, status: false },
	},
	formId = null,
	elements_references = {}
}) => {
	// const elements_references = {}

	const onDownloadDocumentChange = ({
		target: { checked: downloadDocuments },
	}) => {
		changeValue({
			...publicAvailabilityObject,
			downloadDocuments,
		})
	}

	return (
		<div className="mBox availability-box">
			<h4>Availability</h4>
			<div className="form-row-checkbox">
				<input
					id="form-avalilability"
					type="checkbox"
					onChange={({ target: { checked } }) =>
						changeValue({
							...publicAvailabilityObject,
							status: checked,
						})
					}
					checked={publicAvailabilityObject && publicAvailabilityObject.status}
				/>
				<label htmlFor="form-avalilability">Make form public</label>
			</div>
			{publicAvailabilityObject && publicAvailabilityObject.status && (
				<>
					<div
						ref={ref => (elements_references["thankYouText"] = ref)}
						className={`form-row ${errors.thankYouText ? "error" : ""}`}
					>
						<label htmlFor="thank-you-text">THANK YOU PAGE</label>
						<textarea
							id="thank-you-text"
							value={publicAvailabilityObject.thankYouText}
							name="description"
							onChange={({ target: { value } }) =>
								changeValue({
									...publicAvailabilityObject,
									thankYouText: value,
								})
							}
						/>
						{errors.thankYouText && (
							<span className="form-row-error-msg">{errors.thankYouText}</span>
						)}
					</div>

					<div className="form-row">
						<div className="form-row-checkbox">
							<input
								id="downloadDocuments"
								type="checkbox"
								onChange={onDownloadDocumentChange}
								checked={publicAvailabilityObject.downloadDocuments}
							/>
							<label htmlFor="downloadDocuments">
								Add generated documents in thank you page
							</label>
						</div>
					</div>

					<ul className="redirect-grid">
						<li>
							<div className="form-row-checkbox">
								<input
									id="redirect"
									type="checkbox"
									onChange={({ target: { checked } }) =>
										changeValue({
											...publicAvailabilityObject,
											redirect: { ...publicAvailabilityObject.redirect, status: checked },
										})
									}
									checked={publicAvailabilityObject.redirect.status}
								/>
								<label htmlFor="redirect">Redirect page automatically after</label>
							</div>
						</li>
						<li>
							<div className="form-row">
								<select
									onChange={({ target: { value } }) =>
										changeValue({
											...publicAvailabilityObject,
											redirect: {
												...publicAvailabilityObject.redirect,
												seconds: value,
												status: true,
											},
										})
									}
									value={
										publicAvailabilityObject.redirect &&
										publicAvailabilityObject.redirect.seconds
									}
								>
									{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(nr => (
										<option value={nr} key={nr}>
											{nr}
										</option>
									))}
								</select>
							</div>
						</li>
						<li>
							<span>seconds to:</span>
						</li>
						<li>
							<div
								ref={ref => (elements_references["redirectURL"] = ref)}
								className={`form-row ${errors.redirectURL ? "error" : ""}`}
							>
								<input
									type="text"
									onChange={({ target: { value } }) =>
										changeValue({
											...publicAvailabilityObject,
											redirect: {
												...publicAvailabilityObject.redirect,
												url: value,
												status: true,
											},
										})
									}
									value={
										publicAvailabilityObject.redirect &&
										publicAvailabilityObject.redirect.url
									}
								/>
								{errors.redirectURL && (
									<span className="form-row-error-msg">{errors.redirectURL}</span>
								)}
							</div>
						</li>
					</ul>
					{formId && (
						<>
							<div
								ref={ref => (elements_references["customId"] = ref)}
								className={`form-row ${errors.customId ? "error" : ""}`}
							>
								<div className="generated-link">
									<AcopyToClipboard
										text={`${process.env.REACT_APP_CLIENT_URL}/${publicAvailabilityObject.customId}`}
										feedback={(
											<div className="tbl-btn"><i className="icon-ia-checked"/>
												<div
													className={`tooltip tooltip-top`}>Copied!
												</div>
											</div>
										)}
									>
										<div className="tbl-btn"><i className="icon-link-2"/>
											<div
												className={`tooltip tooltip-top`}>Copy
												Link
											</div>
										</div>
									</AcopyToClipboard>

									<div style={{display: 'grid', alignItems: 'center', gridTemplateColumns: 'max-content auto', height: '100%'}}>
										<span style={{paddingLeft: '1rem'}}>{process.env.REACT_APP_CLIENT_URL}/</span>
										<input
											style={{paddingLeft: '0', paddingRight: '4rem'}}
											type="text"
											onChange={
												({target: {value}}) => {
													changeValue({ ...publicAvailabilityObject, customId: value})
												}
											}
											value={publicAvailabilityObject.customId}
										/>
									</div>



									{/*<a target="_blank">{`${process.env.REACT_APP_CLIENT_URL}/public/forms/${formId}/session`}</a>*/}

								</div>
								{errors.customId && (
									<span className="form-row-error-msg">{errors.customId}</span>
								)}
							</div>
						</>

					)}

				</>
			)}
		</div>
	)
}

export default Availability
