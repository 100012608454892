import React from 'react';
import styled from "styled-components";

const TopBarContainer = styled(({className, children, style}) => {
    return (
      <div style={style} className={className} onClick={e => {
          e.stopPropagation()
      }}>
          <div>
              {children}
          </div>
      </div>
    );
})`
  position: absolute;
  top: 1px;
  right: 1.6rem;
  height: 3rem;
  z-index: 8;
  > div{
    position: relative;
    height: 3rem;
  }
`;

export {TopBarContainer}