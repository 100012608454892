import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import LoginComponent from './LoginComponent'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import Portrait from "../../components/Portrait";

const Login = () => {

    const params = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const passwordResetToken = params.get('passwordResetToken');
    const [tab, setTab] = React.useState(passwordResetToken ? 'resetPassword' : 'login');

    return (
        <>
            <div
                className={`wrapper login-page ${tab === 'forgotPassword' ? 'view-forgot-pass' : ''} ${tab === 'resetPassword' ? 'view-change-pass' : ''} ${tab === 'checkInbox' ? ' view-forgot-pass view-forgot-pass-msg' : ''}`}>
                <div className="table-cell">

                    <div className="logo-box"><img src="img/Alchemist_Identity_Final-08.png" alt=""/></div>

                    <LoginComponent onForgotPassword={() => setTab('forgotPassword')}/>

                    {tab === 'forgotPassword' &&
                    <ForgotPassword backToLogin={() => setTab("login")} checkInbox={() => setTab('checkInbox')}/>
                    }
                    {tab === 'resetPassword' && <ResetPassword token={passwordResetToken} onLogin={() => {
                        setTab("login");
                        history.push("/");
                    }}/>}
                </div>
            </div>
            <Portrait/>
        </>
    )
}

export default Login