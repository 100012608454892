import React from 'react'
import ApopUp from '../components/ApopUp'
import supportedElements from '../formComponents/supportedElements'

export default ({approvalForm, children}) => {
    const [open, setOpen] = React.useState(false)

    return (
        <>
            {React.cloneElement(children, {
                onClick: () => setOpen(true),
                style: {cursor: 'pointer'}
            })}
            <ApopUp
                visible={open}
                onCancel={() => setOpen(false)}
                onConfirm={() => setOpen(false)}
                disableFooter
                okText="Close"
                title={approvalForm.heading}
                overLayClass="overlay-approved-session"
            >
                <div className="scrollbar">
                    <div className="session-form">
                        {approvalForm.elements.map(element => {
                            if (supportedElements[element.type]) {
                                return (
                                    supportedElements[element.type].view({
                                        value: element.value,
                                        element
                                    })
                                )
                            }
                            return null;
                        })}
                    </div>
                </div>
            </ApopUp>

        </>
    )
}