import React, { useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import CodeCopy from '../CodeCopy'


export default ({
    element
}) => {

    return(
        <div className="fieldset-row">
            <p>{element.title}</p>
            <p>{element.text}</p>
            <div className="form-grid-row">
                <div className="form-row">
                    <label>Name</label>
                    <input type="text"/>
                </div>
                <div className="form-row">
                    <label>Surname</label>
                    <input type="text"/>
                </div>
            </div>
            <div className="signature-pad"/>
        </div>
    )
}