import React from 'react'

export default ({ 
    element, 
    onChange = f => f, 
    value = ""
}) => {
    return (
        <div className="fieldset-row">
            <div className="form-row">
                <label>{element.text}</label>
                <input type="text" value={value.toUpperCase()} onChange={e => onChange(e.target.value)} placeholder={element.placeholder}/>
            </div>
        </div>
    )
}