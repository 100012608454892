import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({ 
    element
}) => {

    return (
        <CodeCopy text={`#${element.code}}
        {%.}
        {/${element.code}`}>
            <div className="fieldset-row">
                <label>{element.text}</label>
                <img style={{ maxWidth: '100%', height: "auto" }} src="img/camera.svg"/>
            </div>
        </CodeCopy>
    )
}