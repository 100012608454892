import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import moment from "moment"
import {isEmail} from "validator";
import { notification } from "../../../components/Lnotification"
import { Modal } from "antd"
import { Country, City } from "../../../components/CountryCity"

import { initialState, reducer } from "./ClientsData.reducer"
import {
	getClients,
	setSort,
	setSearch,
	getClient,
	updateClient,
	setUpdateClientErrors,
	setClientFirstName,
	setClientLastName,
	setClientEmail,
	setClientPhone,
	setClientCountry,
	setClientLocality,
	setClientStreet,
	setClientNr,
	setClientBlock,
	setClientEntry,
	setClientFloor,
	setClientApartment,
	deleteClient,
	getClientSessions,
	exportSessions,
} from "./ClientsData.actions"
import debounce from "lodash/debounce"
import Loading from "../../../components/Loading"
import { useRedux } from "../../../utils/useRedux"
import { phoneValidator } from "../../../utils/phoneValidator"
import AdeleteConfirm from "../../../components/AdeleteConfirm"

import ApopUp from "../../../components/ApopUp"
import Ipagination from "../../../components/Ipagination"
import ClientsStats from "../ClientsStats/ClientsStats"

const getClientsAfterSearch = debounce(
	(dispatch, state, value) =>
		getClients(dispatch, {
			...state,
			search: value,
			page: 1,
		}),
	1000
)

const ClientsData = ({clientsStore}) => {

    const history = useHistory()
    const page = new URLSearchParams(history.location.search).get('page')

	const [state, dispatch] = useRedux(reducer, {
        ...initialState,
        page: page || 1
    })
    
	const [showModal, setShowModal] = useState(false)
	const [showSessionsModal, setShowSessionsModal] = useState(false)


    
    const [, setClientsState] = clientsStore

	useEffect(() => {
		getClients(dispatch, state, () => {
            setClientsState(clientsState => ({...clientsState, loadedClientsOnce: true}))
        })
	}, [])

	const changeSearch = e => {
		setSearch(dispatch, e.target.value)
		getClientsAfterSearch(dispatch, state, e.target.value)
	}

	const openEditModal = id => () => {
		getClient(dispatch, id)
		setShowModal(true)
	}

	const openClientSessionsModal = id => () => {
		getClientSessions(dispatch, id)
		setShowSessionsModal(true)
	}

	const saveClient = () => {
		const {
			first_name,
			last_name,
			phone,
			email,
			country,
			locality,
			street,
			nr,
			block,
			entry,
			floor,
			apartment,
		} = state.updating_client

		const errors = []

		if (email && !isEmail(email)) {
			errors.push("not_valid_email")
		}

		if (phoneValidator(phone) === false) {
			errors.push("invalid_phone")
		}

		if (errors.length > 0) {
			setUpdateClientErrors(dispatch, errors)
			return
		}

		updateClient(
			dispatch,
			state.updating_client._id,
			{
				first_name,
				last_name,
				phone,
				email,
				country,
				locality,
				street,
				nr,
				block,
				entry,
				floor,
				apartment,
			},
			() => {
				setShowModal(false)
				notification.success({
					message: "Client Information Updated",
				})
			}
		)
	}

	const changePage = page => {
        history.push({
            pathname: history.location.pathname,
            search: `?page=${page}`
        })
		getClients(dispatch, {
			...state,
			page,
		})
	}

	const setColumnSorting = (sort, order) => () => {
		setSort(dispatch, { sort, order })

		getClients(dispatch, {
			...state,
			page: 1,
			sort,
			order,
		})
	}

	const removeClient = id => async () => {
		await deleteClient(dispatch, id)
		console.log("remove")
		await getClients(dispatch, state)
		// await getClientsTopStats(dispatch)
	}

	const renderSortIcon = column => {
		if (column !== state.sort)
			return (
				<span className="cntrl-box">
					<i
						className="cntrl-down icon-arrow-down"
						onClick={setColumnSorting(column, "desc")}
					/>
					<i
						className="cntrl-up icon-arrow-up"
						onClick={setColumnSorting(column, "asc")}
					/>
				</span>
			)

		if (state.order === "desc")
			return (
				<span className="cntrl-box">
					<i
						className="cntrl-down icon-arrow-down selected"
						onClick={setColumnSorting(null, null)}
					/>
					<i
						className="cntrl-up icon-arrow-up"
						onClick={setColumnSorting(column, "asc")}
					/>
				</span>
			)

		return (
			<span className="cntrl-box">
				<i
					className="cntrl-down icon-arrow-down"
					onClick={setColumnSorting(column, "desc")}
				/>
				<i
					className="cntrl-up icon-arrow-up selected"
					onClick={setColumnSorting(null, null)}
				/>
			</span>
		)
	}

	const updateErrorsContain = (...args) => {
		for (let i = 0; i < args.length; i++) {
			if (state.update_errors.includes(args[i])) return true
		}

		return false
	}

	const exportSessionRequest = export_type => () => {
		exportSessions(state, export_type, () => {
			notification.error({
				message: "Something went wrong with downloading the file",
			})
		})
	}

	return (
		<>
			<div className="content">
				<div className="content-box">
					<div className="content-box-header">
						<h3>Clients</h3>
						<div className="content-box-options">
							<div className="content-box-header-search">
								<div className="content-search">
									<fieldset>
										<input
											type="text"
											value={state.search}
											onChange={changeSearch}
											placeholder="Search"
										/>
										<button className="tbl-btn" />
									</fieldset>
								</div>
							</div>
						</div>
					</div>
                    {state.loading && <Loading />}
                    {!state.loading &&               
                        <div className="content-box-body content-box-body-fixed-elements">
                            <div className="box-body box-body-fixed">
                                <div className="table-fixed-header">
                                    <ul className="table-ul clients-list">
                                        <li className="table-ul-header">
                                            <ul>
                                                <li className="with-cntrl-box">
                                                    <strong>Name</strong>
                                                    {renderSortIcon("name")}
                                                </li>
                                                <li className="with-cntrl-box">
                                                    <strong>Email</strong>
                                                    {renderSortIcon("email")}
                                                </li>
                                                <li className="with-cntrl-box">
                                                    <strong>Phone</strong>
                                                    {renderSortIcon("phone")}
                                                </li>
                                                <li className="with-cntrl-box">
                                                    <strong>Address</strong>
                                                    {renderSortIcon("address")}
                                                </li>
                                                <li className="with-cntrl-box">
                                                    <strong>Last Update</strong>
                                                    {renderSortIcon("updated_at")}
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="scrollbar">
                                    {state.data.length === 0 && (
                                        <div className="empty-list">This list is empty</div>
                                    )}
                                    {state.data.length > 0 && (
                                        <ul className="table-ul clients-list">
                                            {state.data.map((client, index) => (
                                                <li key={client._id}>
                                                    <ul>
                                                        <li>
                                                            <strong>{client.name ? client.name : ""}</strong>
                                                        </li>
                                                        <li title={client.email}>
                                                            <strong>{client.email}</strong>
                                                        </li>
                                                        <li title={client.phone}>
                                                            <strong>{client.phone}</strong>
                                                        </li>
                                                        <li title={client.address}>
                                                            <strong>{client.address}</strong>
                                                        </li>
                                                        <li>
                                                            <strong>
                                                                {moment(client.updated_at).format("D MMM, HH:mm")}
                                                                <span>({moment(client.updated_at).format("YYYY")})</span>
                                                            </strong>
                                                        </li>
                                                        <li>
                                                            <div className="table-actions">
                                                                <AdeleteConfirm
                                                                    title={
                                                                        <>
                                                                            Are you sure to delete this client?
                                                                            <br />
                                                                            All data will be erased.
                                                                        </>
                                                                    }
                                                                    onConfirm={removeClient(client._id)}
                                                                    overlayClassName="tooltip-overlay"
                                                                    okText="Delete"
                                                                    cancelText="Cancel"
                                                                >
                                                                    <div className="tbl-btn trigger-overlay-delete-client">
                                                                        <i className="icon-ia-trash-bold" />
                                                                        <div
                                                                            className={`tooltip ${
                                                                                index === 0 ? "tooltip-bottom" : "tooltip-top"
                                                                            }`}
                                                                        >
                                                                            Delete
                                                                        </div>
                                                                    </div>
                                                                </AdeleteConfirm>
                                                                <div
                                                                    className="tbl-btn"
                                                                    onClick={e => {
                                                                        e.preventDefault()
                                                                        history.push(`/clients/${client._id}/edit`)
                                                                    }}
                                                                >
                                                                    <i className="icon-ia-edit-bold" />
                                                                    <div
                                                                        className={`tooltip ${
                                                                            index === 0 ? "tooltip-bottom" : "tooltip-top"
                                                                        }`}
                                                                    >
                                                                        Change client information
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="tbl-btn trigger-overlay-client-sessions"
                                                                    onClick={openClientSessionsModal(client._id)}
                                                                >
                                                                    <i className="icon-ia-preview-file" />
                                                                    <div
                                                                        className={`tooltip ${
                                                                            index === 0 ? "tooltip-bottom" : "tooltip-top"
                                                                        }`}
                                                                    >
                                                                        View clients' sessions
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>

                            <div className="box-action table-action-fixed with-pagination">
                                <div className="export-options">
                                    <span>Export list to:</span>
                                    <button
                                        className="button button-outline"
                                        onClick={exportSessionRequest("pdf")}
                                    >
                                        PDF
                                    </button>
                                    <button
                                        className="button button-outline"
                                        onClick={exportSessionRequest("excel")}
                                    >
                                        XLS
                                    </button>
                                </div>

                                <Ipagination
                                    page={state.page}
                                    pages={state.pages}
                                    onChange={changePage}
                                />

                                <Link className="button with-icon-right" to={"/clients/create/new"}>
                                    Add new client
                                    <i className="icon-plus" />
                                </Link>
                            </div>

                            <ApopUp
                                title="View clients' sessions"
                                visible={showSessionsModal}
                                disableFooter
                                onCancel={() => setShowSessionsModal(false)}
                                overLayClass="overlay-client-sessions"
                            >
                                <div
									className="scrollbar"
                                    style={state.client_sessions.length === 0 ? { height: "14rem" } : {}}
                                >
                                    {state.loading_client_sessions && <Loading />}
                                    {!state.loading_client_sessions &&
                                        state.client_sessions.length === 0 && (
                                            <div className="empty-list">
                                                There are no sessions for this client
                                            </div>
                                        )}
                                    {!state.loading_client_sessions && state.client_sessions.length > 0 && (
                                        <ul className="table-ul clients-sessions-list">
                                            {state.client_sessions.map(session => (
                                                <li
                                                    key={session._id}
                                                    onClick={() => history.push(`/sessions/${session._id}`)}
                                                >
                                                    <ul>
                                                        <li>
                                                            <strong>{session.form_name}</strong>
                                                        </li>
                                                        <li>
                                                            <strong>
                                                                {moment(session.updated_at).format("D MMM, HH:mm")}
                                                                <span>({moment(session.updated_at).format("YYYY")})</span>
                                                            </strong>
                                                        </li>
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </ApopUp>

                            <Modal
                                title="Change Client Information"
                                visible={showModal}
                                footer={null}
                                onCancel={() => setShowModal(false)}
                                width="70rem"
                            >
                                <div className="form">
                                    <div className="fieldset-row form-grid">
                                        <div className="form-grid-row">
                                            <div className="form-row form-row-with-autocomplete">
                                                <label>Name</label>
                                                <input
                                                    name="first_name"
                                                    value={state.updating_client.first_name}
                                                    maxLength={40}
                                                    onChange={e => setClientFirstName(dispatch, e.target.value)}
                                                />
                                            </div>
                                            <div className="form-row">
                                                <label>Surname</label>
                                                <input
                                                    name="last_name"
                                                    value={state.updating_client.last_name}
                                                    maxLength={40}
                                                    onChange={e => setClientLastName(dispatch, e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-grid-row">
                                            <div
                                                className={`form-row ${
                                                    updateErrorsContain("invalid_phone") ? "error" : ""
                                                }`}
                                            >
                                                <label>Phone</label>
                                                <input
                                                    value={state.updating_client.phone}
                                                    maxLength={20}
                                                    name="phone"
                                                    onChange={e => setClientPhone(dispatch, e.target.value)}
                                                />
                                                {updateErrorsContain("invalid_phone") && (
                                                    <span className="form-row-error-msg">
                                                        Value must be a valid phone number
                                                    </span>
                                                )}
                                            </div>

                                            <div
                                                className={`form-row ${
                                                    updateErrorsContain("not_valid_email") ? "error" : ""
                                                }`}
                                            >
                                                <label htmlFor="">Email</label>
                                                <input
                                                    value={state.updating_client.email}
                                                    maxLength={255}
                                                    name="email"
                                                    onChange={e => setClientEmail(dispatch, e.target.value)}
                                                />
                                                {updateErrorsContain("not_valid_email") && (
                                                    <span className="form-row-error-msg">
                                                        Value must be a valid email
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-grid-row">
                                            <div className="form-row">
                                                <label>County</label>
                                                <Country
                                                    value={state.updating_client.country}
                                                    onChange={e => setClientCountry(dispatch, e.target.value)}
                                                    name="country"
                                                />
                                            </div>

                                            <div className="form-row">
                                                <label>City</label>
                                                <City
                                                    value={state.updating_client.locality}
                                                    onChange={e => setClientLocality(dispatch, e.target.value)}
                                                    name="locality"
                                                    selectedCountry={state.updating_client.country}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-grid-row street-grid">
                                            <div className="form-row">
                                                <label>Street</label>
                                                <input
                                                    value={state.updating_client.street}
                                                    maxLength={150}
                                                    name="street"
                                                    onChange={e => setClientStreet(dispatch, e.target.value)}
                                                />
                                            </div>

                                            <div className="form-row">
                                                <label>Nr</label>
                                                <input
                                                    value={state.updating_client.nr}
                                                    maxLength={10}
                                                    name="nr"
                                                    onChange={e => setClientNr(dispatch, e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-grid-row address-grid">
                                            <div className="form-row">
                                                <label>BL</label>
                                                <input
                                                    value={state.updating_client.block}
                                                    maxLength={150}
                                                    name="block"
                                                    onChange={e => setClientBlock(dispatch, e.target.value)}
                                                />
                                            </div>

                                            <div className="form-row">
                                                <label>SC</label>
                                                <input
                                                    value={state.updating_client.entry}
                                                    maxLength={10}
                                                    name="entry"
                                                    onChange={e => setClientEntry(dispatch, e.target.value)}
                                                />
                                            </div>

                                            <div className="form-row">
                                                <label>ET</label>
                                                <input
                                                    value={state.updating_client.floor}
                                                    maxLength={10}
                                                    name="floor"
                                                    onChange={e => setClientFloor(dispatch, e.target.value)}
                                                />
                                            </div>

                                            <div className="form-row">
                                                <label>Ap</label>
                                                <input
                                                    value={state.updating_client.apartment}
                                                    maxLength={10}
                                                    name="apartment"
                                                    onChange={e => setClientApartment(dispatch, e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    className="button"
                                    disabled={state.update_pending}
                                    onClick={saveClient}
                                >
                                    Save
                                </button>
                            </Modal>
                        </div>
                    }

				</div>
			</div>

			<div className="sidebar">
				<div className="scrollbar">
					<ClientsStats parentData={state.data} clientsStore={clientsStore}/>
				</div>
			</div>
		</>
	)
}

export default ClientsData
