import React from "react"
import {v4 as uuid} from 'uuid'
import IPopConfirm from "../../components/ApopConfirm"
import AdeleteConfirm from "../../components/AdeleteConfirm"
import supportedElements from "../supportedElements"
import Validations from "../Validations"
import CodeCopy from "../../components/Editor.codeCopy"
import { read, utils } from "xlsx"
import Loading from "../../components/Loading"
import Iscroll from "../../components/Iscroll"
import dropdown from "../../formComponents/DropDown/DropDown"
import {css} from 'styled-components'

import {TopBarContainer} from "../comonComponents/ElementEditor.TopBarContainer";
import {RequiredValidationToggle} from "../comonComponents/ElementEditor.RequiredValidationToggle";
import {VALIDATION} from "../../constants/validations";
import {notification} from "../../components/Lnotification";

const cssFixes = css`
    .form-row .tbl-btn {
        top: 1.8rem;
    }

    .mbi-row {
        height: unset;
    }

    &&&& {
        .mbi-row.with-delete-btn input {
            padding-right: 7.5rem;
        }
    }
`

export const DropdownEditor = ({
	element,
	remove,
	editElement,
	changeElementEditorMode,
	dragHandleProps = {},
	index,
	formElements,
	VisibilityAffected,
	ConditionalValidation,
	editElementByCode,
	clearOppressedElements,
	clearOppressedAndShiftAffectedIndexes,
	addElementOnPosition,
	deleteChain,
	form,
	isApprovalForm
}) => {
	const [state, setState] = React.useState({
		keepDependency: false,
		merge: false,
		duplicates: {},
		localElement: {
			...element,
		},
		errors: {},
	}, `dropdown${element.code}`)

	const localElement = state.localElement

	const setLocalElement = callback =>
		setState(state => ({
			...state,
			localElement: { ...callback(state.localElement) },
		}))

	const [loading, setLoading] = React.useState(false)

	let excelRef = React.useRef()
	if (!localElement.items || !(localElement.items instanceof Array)) {
		localElement.items = []
	}
	React.useEffect(() => {
		if (!localElement.chained) {
			const duplicates = {}
			localElement.items.forEach((item, itemIndex) => {
				if (localElement.items.includes(item, itemIndex + 1))
					duplicates[item] = true
			})
			setState(state => ({ ...state, duplicates }))
		}
	}, [localElement.items])

	const removeItem = itemIndex => {
		clearOppressedAndShiftAffectedIndexes(localElement.code, itemIndex)
		setLocalElement(localElement => ({
			...localElement,
			items: localElement.items.filter((_, i) => i !== itemIndex),
		}))
	}

	const onOptionClick = () => {
		changeElementEditorMode(
			element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS"
		)
	}

	const onEditClick = () => {
		changeElementEditorMode("EDIT")
	}

	const onPinnedChange = ({ target: { checked } }) => {
		setLocalElement(localElement => ({
			...localElement,
			pinned: {
				status: checked,
			},
		}))
	}

	const onValidationsClick = () => {
		changeElementEditorMode("VALIDATIONS")
	}

	const onSaveClick = () => {
		const errors = {}
		if (localElement.text.length > 400) {
			errors.text = "Maximum 400 characters allowed"
		}
		if (localElement.items.length > 300) {
			errors.items = "Maximum 300 items allowed"
		}

		const itemMaxLength = localElement.items.reduce((acc, item, index) => {
			if (item.length > 300) acc[index] = true
			return acc
		}, {})
		if (Object.keys(itemMaxLength).length > 0) {
			errors.itemMaxLength = itemMaxLength
		}

		if (Object.keys(state.duplicates).length > 0) {
			errors.duplicates = "Some of the dropdown data is duplicated"
		}

		setState(state => ({ ...state, errors }))

		if (Object.keys(errors).length === 0) {
			editElement({ ...state.localElement })
			changeElementEditorMode("DEFAULT")
		}
	}

	const onAddOptionClick = () => {
		setLocalElement(localElement => ({
			...localElement,
			items: [...localElement.items, `Option`],
		}))
	}

	const onEditOption = (index, value) => {
		setLocalElement(localElement => ({
			...localElement,
			items: localElement.items.map((el, i) => (i !== index ? el : value)),
		}))
	}

	const createChainedDropdowns = ({ names, masterCode }) => {
		names.forEach((name, nameIndex) => {
			addElementOnPosition(index + nameIndex + 1, {
				...dropdown.default(),
				text: name,
				items: [],
				chained: {
					role: "slave",
					masterCode,
					chainIndex: nameIndex,
				},
			})
		})
	}

	const getDropdownData = async input => {
		const merge = state.merge
		setLoading(true)
		const errors = {}
		try {
			if (input.target.files[0].size > 1024 * 1024) {
				errors.import = "No files more than 1MB are allowed"
				setState(state => ({
					...state,
					errors,
				}))
				return setLoading(false)
			}
			// var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
			// let excel = utils.sheet_to_json(first_worksheet, {header:1})

			clearOppressedElements(localElement.code)

			let files = input.target.files,
				f = files[0]
			let reader = new FileReader()
			reader.onload = function (e) {
				let data = new Uint8Array(e.target.result)
				let workbook = read(data, { type: "array" })
				let items = []
				let first_worksheet = workbook.Sheets[workbook.SheetNames[0]]
				let excel = utils.sheet_to_json(first_worksheet, {
					header: 1,
					blankrows: false,
				})
				if (excel[0].length > 10) {
					errors.import = "No more than 10 columns allowed"
					setState(state => ({
						...state,
						errors,
					}))
					return setLoading(false)
				}

				if (!state.keepDependency || excel[0].length === 1) {
					for (let i = 0; i < excel.length; i++) {
						items.push(excel[i][0])
					}
					setLocalElement(localElement => ({
						...localElement,
						items: [...localElement.items, ...items],
						data: excel,
					}))
				} else {
					//The function that deals with multi column imports
					let dropdownValues = excel.reduce((r, a, i) => {
						let last = a.pop()
						if (merge) {
							a.pop()
						}
						if (i === 1) r = {}
						a.reduce((items, k, i) => {
							if (merge && i % 2 === 0) {
								return items
							}
							let temp = items[k]
							if (!temp) temp = items[k] = a.length === i + 1 ? [] : {}
							return temp
						}, r).push(last)
						return r
					})
					let names = Array(merge ? excel[0].length / 2 : excel[0].length).fill(
						localElement.text
					)

					setLocalElement(localElement => ({
						...localElement,
						items: Object.keys(dropdownValues),
						data: excel,
						chained: {
							role: "master",
							structure: dropdownValues,
						},
					}))

					// ==============
					createChainedDropdowns({
						names: names
							.slice(1, names.length)
							.map((_, i) => `Dropdown Label ${i + 1}`),
						masterCode: localElement.code,
					})
					// ==============
				}
				setState(state => ({
					...state,
					excelFile: true,
					items: items,
				}))

				/* DO SOMETHING WITH workbook HERE */
			}
			reader.readAsArrayBuffer(f)
			input.target.value = ""
		} catch (err) {
			errors.import =
				"There was an error while importing the provided spreadsheet file"
			setState(state => ({
				...state,
				errors,
			}))
		}
		setLoading(false)
	}

	const clearUploadedData = () => {
		setLoading(true)

		if (localElement.chained && localElement.chained.role === "master") {
			deleteChain(localElement.code)
		}
		setLocalElement(localElement => ({
			...localElement,
			items: [],
			data: undefined,
			chained: undefined,
		}))

		setState(state => ({...state, errors: {}}))

		setLoading(false)
	}

	const duplicateComponent = () => {
		addElementOnPosition(index + 1, {
			...element,
			editorMode: 'DEFAULT'
		})
	}

	const setValidationsLocal = newValidations => {
		setLocalElement(localElement => (
			{
				...localElement,
				validations: newValidations
			}
		))
	};

	const setValidationRequired = required => {

		const validations = required
			? [...localElement.validations, {validation: VALIDATION.REQUIRED}]
			: localElement.validations.filter((validation) => validation.validation !== VALIDATION.REQUIRED)

		        if(element.editorMode !== "VALIDATIONS") {
            editElement({...element, validations})
        }
		setValidationsLocal(validations)
	}

	const openEditMode = () => {
		changeElementEditorMode("EDIT")
	};

	const setMultipleValues = (multipleValues) => {

		if(multipleValues && localElement.chained) {
			notification.warning({
				message: 'Multiple instances can’t be generated for this dropdown. Clear dropdown first.'
			});
			return
		}
		setLocalElement(element => ({
			...element,
			multipleValues
		}))
	}

	return (
		<div
			className={`form-box-wrap ${
				element.editorMode === "OPTIONS" && "view-box-options"
			} ${element.editorMode === "EDIT" && "view-box-edit"} ${
				element.editorMode === "VALIDATIONS" && "view-box-validation"
            }`}
            css={cssFixes}
		>
			<div className="form-box-wrap-drag-btn" {...dragHandleProps}>
				<i className="icon-ia-drag" />
			</div>
			<div className="form-box-wrap-center">
				<div className="form-box-wrap-center-content" style={{cursor: "pointer"}} onClick={openEditMode}>
					<div className="form-row">
						<label>{localElement.text}</label>
						<select disabled style={{pointerEvents: "none"}}>
							<option />
						</select>
					</div>

					<TopBarContainer>
						<RequiredValidationToggle
							                            validationRequired={
                                !!(element.editorMode === "VALIDATIONS"
                                    ? localElement
                                    : element
                                ).validations.find(v => v.validation === VALIDATION.REQUIRED)
                            }
							setValidationRequired={setValidationRequired}
						/>
						<VisibilityAffected element={element} formElements={formElements}/>
					</TopBarContainer>

					<p className="form-row-validation-text">
						{localElement.validations.map(v => `[ ${v.validation} ]  `)}
						{localElement.multipleValues && "[ multiple instances ] "}
						{localElement.pinned && localElement.pinned.status
							? "[ show on top ]"
							: ""}
					</p>
					<CodeCopy code={localElement.code}>
						<span className="form-box-wrap-id" />
					</CodeCopy>
				</div>
				<div className="form-box-wrap-options">
					<div className="form-box-wrap-options-buttons">
						<span onClick={onValidationsClick}>
							<i className="icon-check trigger-validation-box" />
							Validation
						</span>
						<span onClick={onEditClick}>
							<i className="icon-ia-edit-bold trigger-edit-box" />
							Edit
						</span>
						{(!localElement.chained || localElement.chained.role === "master") && (
							<AdeleteConfirm
								onConfirm={() => {
									clearOppressedElements(localElement.code)
									if(localElement?.chained?.role === "master") {
										deleteChain(localElement.code)
									}
									remove()
								}}
								okText="Delete"
								cancelText="Cancel"
								title="Are you sure you want to delete this component? All data will be lost. "
							>
								<span>
									<i className="icon-ia-trash-bold trigger-delete-box" />
									Delete
								</span>
							</AdeleteConfirm>
						)}
						<span onClick={duplicateComponent}><i className="icon-copy"/>Duplicate</span>
					</div>
				</div>
			</div>
			<div className="form-box-wrap-options-btn" onClick={onOptionClick}>
				<i className="icon-ia-more" />
			</div>
			<div className="form-box-wrap-center-edit">
				<div className={`form-row ${state.errors.text ? "error" : ""}`}>
					<input
						type="text"
						placeholder="Dropdown Label"
						value={localElement.text}
						onChange={({ target: { value: text } }) =>
							setLocalElement(localElement => ({
								...localElement,
								text,
							}))
						}
					/>
					{state.errors.text && (
						<span className="form-row-error-msg">{state.errors.text}</span>
					)}
				</div>

				<div
					className={`form-row ${
						state.errors.import || state.errors.items ? "error" : ""
					}`}
				>
					{loading && <Loading />}
					<label>Options list</label>
					<div className="scrollbar" style={{ maxHeight: "65rem" }}>
						{!localElement.chained && (
							<Iscroll allItems={localElement.items} limit={20}>
								{({ items }) => (
									<>
										{items.map((item, itemIndex) => (
											<div
												key={itemIndex}
												className={`mbi-row with-delete-btn ${
													state.duplicates[item] || state.errors?.itemMaxLength?.[itemIndex]
														? "error"
														: ""
												}`}
											>
												<input
													type="text"
													name=""
													value={item}
													onChange={e => onEditOption(itemIndex, e.target.value)}
												/>
												<ConditionalValidation
													oppressorItemIndex={itemIndex}
													formElements={formElements}
													oppressorElement={localElement}
													editElementByCode={editElementByCode}
												/>
												<AdeleteConfirm
													onConfirm={() => removeItem(itemIndex)}
													okText="Delete"
													cancelText="Cancel"
													title="Are you sure you want to delete this option? All data will be lost. "
												>
													<div className=" tbl-btn">
														<i
															className="icon-ia-trash-bold delete-form-row-btn"
															title="Delete"
														/>
													</div>
												</AdeleteConfirm>

												{state.errors?.itemMaxLength?.[itemIndex] && (
													<span className="form-row-error-msg">
														Maximum 300 characters allowed
													</span>
												)}
											</div>
										))}
									</>
								)}
							</Iscroll>
						)}
					</div>
					{state.errors.duplicates && (
						<span style={{ display: "unset" }} className="form-row-error-msg">
							{state.errors.duplicates}
						</span>
					)}
					{state.errors.import && (
						<span style={{ display: "unset" }} className="form-row-error-msg">
							{state.errors.import}
						</span>
					)}
					{state.errors.items && (
						<span className="form-row-error-msg">{state.errors.items}</span>
					)}

					{!localElement.chained && (
						<span className="add-block-input" onClick={onAddOptionClick}>
							Add option
						</span>
					)}
					{!localElement.chained &&
						(!localElement.data || localElement.data.length === 0) && (
							<>
								<br />
								<IPopConfirm
									onConfirm={() => {
										if(localElement.multipleValues && (state.keepDependency || state.merge)) {
											notification.warning({
												message: 'You can’t import a document that keeps dependency between columns. Remove ‘Allow multiple instances’ first.'
											});
											return
										}
										excelRef.current.click()
									}}
									okText="Import"
									title={
										state.keepDependency
											? "All edited data will be lost after importing new data!"
											: ""
									}
									body={
										<div>
											<br />
											{(uniqueName => (
												<div
													css={`
														.form .form-row-radio input[type="radio"]:checked + label::after {
															background: #2c2457;
														}
													`}
												>
													{(id => (
														<div className="form-row-radio">
															<input
																style={{ width: "auto", height: "auto", margin: "0 1rem" }}
																type="radio"
																id={id}
																checked={!state.keepDependency && !state.merge}
																onChange={() =>
																	setState(state => ({
																		...state,
																		keepDependency: false,
																		merge: false,
																	}))
																}
																name={uniqueName}
															/>
															<label htmlFor={id} style={{ fontSize: "1.2rem" }}>
																Import first column's data
															</label>
															&nbsp;
														</div>
													))(uuid())}
													{(id => (
														<div className="form-row-radio">
															<input
																style={{ width: "auto", height: "auto", margin: "0 1rem" }}
																type="radio"
																id={id}
																checked={state.keepDependency && !state.merge}
																onChange={() =>
																	setState(state => ({
																		...state,
																		keepDependency: true,
																		merge: false,
																	}))
																}
																name={uniqueName}
															/>
															<label htmlFor={id} style={{ fontSize: "1.2rem" }}>
																Keep dependency between columns
															</label>
															&nbsp;
														</div>
													))(uuid())}
													{(id => (
														<div className="form-row-radio">
															<input
																style={{ width: "auto", height: "auto", margin: "0 1rem" }}
																type="radio"
																id={id}
																checked={state.merge && state.keepDependency}
																onChange={() =>
																	setState(state => ({
																		...state,
																		merge: true,
																		keepDependency: true,
																	}))
																}
																name={uniqueName}
															/>
															<label htmlFor={id} style={{ fontSize: "1.2rem" }}>
																Keep dependency and merge columns
															</label>
															&nbsp;
														</div>
													))(uuid())}
												</div>
											))(uuid())}
										</div>
									}
								>
									<label>
										<span className="import-buttons add-block-input">
											Import options from Excel
										</span>
									</label>
								</IPopConfirm>
								<input
									className="upload-image-input"
									type="file"
									onChange={getDropdownData}
									ref={excelRef}
									style={{ display: "none" }}
									accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
								/>
							</>
						)}
					{localElement.data && localElement.data.length > 0 && (
						<>
							<br />
							<AdeleteConfirm
								onConfirm={clearUploadedData}
								okText="Remove"
								cancelText="Cancel"
								title="Are you sure you want to remove the imported data?"
							>
								<label>
									<span className="import-buttons add-block-input">Clear dropdown</span>
								</label>
							</AdeleteConfirm>
						</>
					)}
				</div>
				{!isApprovalForm && (
					<div className="frc-box">
						<div className="form-row-checkbox">
							<input
								id={`pinned-${localElement.code}`}
								type="checkbox"
								checked={localElement.pinned && localElement.pinned.status}
								onChange={onPinnedChange}
							/>
							<label htmlFor={`pinned-${localElement.code}`}>
								Show on top in saved session
							</label>
						</div>
					</div>
				)}
				{
					<div className="frc-box">
						<div className="form-row-checkbox">
							<input
								id={`multiple-instances-${localElement.code}`}
								type="checkbox"
								checked={!!localElement.multipleValues}
								onChange={({target:{checked}}) => setMultipleValues(checked)}
							/>
							<label htmlFor={`multiple-instances-${localElement.code}`}>Allow multiple instances</label>
						</div>
					</div>
				}

				<a className="form-box-wrap-button" onClick={onSaveClick}>
					Save
				</a>
			</div>
			<div className="form-box-wrap-center-validation mco-box">
				<div className="mco-cell">
					<h4>Validations</h4>
				</div>
				<Validations
					availableValidations={
						supportedElements[localElement.type].availableValidations
					}
					onChange={setValidationsLocal}
					value={localElement.validations}
					form={form}
				/>
				<a className="form-box-wrap-button" onClick={onSaveClick}>
					Save
				</a>
			</div>
		</div>
	)
}
