import React from 'react'

import LivePreview from './LivePreview.SignaturePad'
import {SignaturePadEditor} from './Editor.SignaturePad'
import CodeCopy from './CodeCopy.SignaturePad'
import View from './View.SignaturePad'
import Input from './Input.SignaturePad'

export default {
  livePreview: args =>  <LivePreview {...args}/>,
  editor: args => <SignaturePadEditor {...args}/>,
  codeCopy: args => <CodeCopy {...args}/>,
  input: args => <Input {...args}/>,

  default: () => ({
    type: "signature",
    text: "Signature Pad Label",
    pinned: {
      status: false
    },
    validations: []
  }),
  
  availableValidations: ['required'],
  validate: () => false,
  group: "ADVANCED",
  icon: "icon-ia-signature",
  name: "Signature Pad",
  view: args => <View {...args}/>
}