import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({ 
    element
}) => {

    return(
        <div className="fieldset-row">
            <div className="id-card-extractor">
                {/* <div className="id-card-extractor-list view-ice-step3">
                    <div className="id-card-extractor-cell">
                        <p><span>{`${element.step1} `}</span>{element.uploadTakePictureLabel}</p>
                        <div className="step1-btns">
                            <div className="upload-image-box">
                                <input className="upload-image-input" type="file" name="" />
                            </div>
                            <div className="upload-image-box upload-camera-img">
                                <input className="upload-image-input" type="file" name="" />
                            </div>
                        </div>
                        <p>Please take a photo <br /> or upload your Identity Card</p>
                    </div>
                    <div className="id-card-extractor-cell">
                        <p><span>{`${element.step2} `}</span>{element.previewUploadedImage}</p>
                        <div className="id-card-image"><img src="../img/id-card.svg" /></div>
                    </div>

                </div> */}

                    <div className="id-card-extractor-cell">
                        <p><span>{`${element.step3} `}</span>{element.cardInformation}</p>
                        <fieldset className="form-grid">
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>Name</label>
                                    <CodeCopy text={`${element.code}_first_name`}>
                                        <input type="text" name="" placeholder="" />
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Surname</label>
                                    <CodeCopy text={`${element.code}_last_name`}>
                                        <input type="text" name="" placeholder="" />
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>Email</label>
                                    <CodeCopy text={`${element.code}_email`}>
                                        <input type="text" name="" placeholder="" />
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Phone</label>
                                    <CodeCopy text={`${element.code}_phone`}>
                                        <input type="text" name="" placeholder="" />
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>CNP</label>
                                    <CodeCopy text={`${element.code}_cnp`}>
                                        <input type="text" name="" placeholder="" />
                                    </CodeCopy>
                                </div>
                                <div className="form-row with-calendar">
                                    <label>Date of birth</label>
                                    <CodeCopy text={`${element.code}_date_of_birth`}>
                                        <input type="text" name="" value="DD / MM / YYYY" onChange={f=>f}/>
                                    </CodeCopy>
                                    <i className="calendar-btn icon-ia-calendar"></i>
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>County</label>
                                    <CodeCopy text={`${element.code}_county`}>
                                        <select>
                                            <option value="">Select Option</option>
                                            <option value="">Select Option</option>
                                            <option value="">Select Option</option>
                                            <option value="">Select Option</option>
                                            <option value="">Select Option</option>
                                            <option value="">Select Option</option>
                                        </select>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>City</label>
                                    <CodeCopy text={`${element.code}_city`}>
                                        <select>
                                            <option value="">Select Option</option>
                                            <option value="">Select Option</option>
                                            <option value="">Select Option</option>
                                            <option value="">Select Option</option>
                                            <option value="">Select Option</option>
                                            <option value="">Select Option</option>
                                        </select>
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row street-grid">
                                <div className="form-row">
                                    <label>Street</label>
                                    <CodeCopy text={`${element.code}_street`}>
                                        <input type="text" name="" placeholder="" />
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>No</label>
                                    <CodeCopy text={`${element.code}_nr`}>
                                        <input type="text" name="" placeholder="" />
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row address-grid">
                                <div className="form-row">
                                    <label>Building</label>
                                    <CodeCopy text={`${element.code}_building`}>
                                        <input type="text" name="" placeholder="" />
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Entrance</label>
                                    <CodeCopy text={`${element.code}_entry`}>
                                        <input type="text" name="" placeholder="" />
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Floor</label>
                                    <CodeCopy text={`${element.code}_floor`}>
                                        <input type="text" name="" placeholder="" />
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Ap</label>
                                    <CodeCopy text={`${element.code}_apartment`}>
                                        <input type="text" name="" placeholder="" />
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row id-grid">
                                <div className="form-row">
                                    <label>ID Series</label>
                                    <CodeCopy text={`${element.code}_series_ci`}>
                                        <input type="text" name="" placeholder="" />
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>ID No</label>
                                    <CodeCopy text={`${element.code}_nr_ci`}>
                                        <input type="text" name="" placeholder="" />
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Emitted by</label>
                                    <CodeCopy text={`${element.code}_emitted_by`}>
                                        <input type="text" name="" placeholder="" />
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row with-calendar">
                                    <label>Valid from</label>
                                    <CodeCopy text={`${element.code}_available_from`}>
                                        <input type="text" name="" value="DD / MM / YYYY" onChange={f=>f} />
                                    </CodeCopy>
                                    <i className="calendar-btn icon-ia-calendar"></i>
                                </div>
                                <div className="form-row with-calendar">
                                    <label>Valid until</label>
                                    <CodeCopy text={`${element.code}_available_to`}>
                                        <input type="text" name="" value="DD / MM / YYYY" onChange={f=>f} />
                                    </CodeCopy>
                                    <i className="calendar-btn icon-ia-calendar"></i>
                                </div>
                            </div>
                        </fieldset>
                    </div>
            </div>
        </div>
    )
}