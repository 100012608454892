import React from 'react'
import {DragDropContext} from "react-beautiful-dnd"
import NavigationPrompt from "../../../components/InavigationPrompt"
import {useParams, useHistory} from 'react-router-dom'

import Toolbox from '../FormDefinition/FormDefinition.Toolbox'
import {elements} from '../../../constants/elements'
import supportedElements from '../../../formComponents/supportedElements'
import Editor from './ApprovationForm.Editor'
import {LivePreview} from './ApprovationForm.LivePreview'
import API from '../../../api'
import Loading from '../../../components/Loading'
import Navbar from '../../../components/Navbar'
import {notification} from '../../../components/Lnotification'
import ApopUp from "../../../components/ApopUp";
import {useAppContext} from "../../../context";
import PageNotFound from "../../../components/PageNotFound"
import Portrait from '../../../components/Portrait'

const neededElements = Object.keys(supportedElements)
    .filter(key => ![
        elements.AGENT_SIGNATURE,
        elements.STANDARD_ADDRESS,
        elements.PRIVACY_AGREEMENT,
        elements.CONTRACT_COMMUNICATION_OPTIONS,
        elements.COMMUNICATION_OPTIONS,
        elements.CUSTOMER_SATISFACTION,
        elements.VEHICLE_EVALUATION,
        elements.DOCUMENTS_PREVIEW,
        elements.MAP
    ].includes(key))
    .reduce((acc, key) => ({...acc, [key]: supportedElements[key]}), {})

const ApprovationForm = () => {
    const history = useHistory()

    const [state, setState] = React.useState({
        form: null,
        loadingForm: false,
        approvalFormNeedsSave: false,
        savingForm: false,
        pageNotFound: false,
        newForm: history.location.state && history.location.state.newForm
    }, 'approvation-form-editor')

    const {
        auth: [
            {
                decodedToken: {
                    userid,
                    scope: [auth_role],
                },
            },
        ],
    } = useAppContext()

    const params = useParams()

    React.useEffect(() => {
        loadForm()
    }, [])

    const loadForm = async () => {
        try {
            setState(state => ({...state, loadingForm: true}))
            const {
                data: {
                    form: {logo, ...formRest},
                },
            } = await API.get(`forms/${params.id}`)
            if (auth_role === "agent" || (auth_role === "manager" && userid !== formRest._owner)) {
                setState(state => ({
                    ...state,
                    pageNotFound: true
                }))
            }
            setState(state => ({
                ...state,
                loadingForm: false,
                form: {
                    ...formRest,
                    approvalForm: {
                        ...formRest.approvalForm,
                        form: formRest.approvalForm.form || {
                            elements: [], heading: ""
                        }
                    }
                },
            }))
        } catch (err) {
            setState(state => ({
                ...state,
                loadingForm: false,
                ...(err.response.status === 404 ? {pageNotFound: true} : {})
            }))
        }
    }

    const onNext = async () => {
        await save()
        history.push('/forms')
    }

    const save = async () => {
        try {
            setState(state => ({...state, savingForm: true}))

            const approvalForm = {
                ...state.form.approvalForm,
                form: {
                    ...state.form.approvalForm.form,
                    elements: state.form.approvalForm.form.elements.map(element => {
                        const {editorMode, ...elementCleanFromUiProps} = element;
                        return elementCleanFromUiProps
                    })
                }
            }

            await API.put(`forms/${params.id}`, {approvalForm})
            setState(state => ({...state, savingForm: false, approvalFormNeedsSave: false}))
            notification.cancel()
        } catch (err) {
            console.log(err);
            setState(state => ({...state, savingForm: false}))
            notification.warning("Failed saving approvation form")
        }
    }

    const onBack = async () => {
        await save()
        if (history.location.state && history.location.state.newForm) {
            history.push(`/forms/${state.form._id}/edit/mapping`, {newForm: true})
        } else {
            history.push('/forms')
        }
    }

    const setForm = callback => setState(state => ({
        ...state, form: callback(state.form), approvalFormNeedsSave: true
    }))

    const setApprovationForm = callback => setForm(form => ({
        ...form,
        approvalForm: {
            ...form.approvalForm,
            form: callback(form.approvalForm.form)
        }
    }))

    let editorBoxRef = React.useRef()

    const deleteChain = masterCode => setApprovationForm(approvationForm => ({
        ...approvationForm,
        elements: approvationForm.elements.filter(potencialSlave =>
            !(
                potencialSlave.type === "dropdown" &&
                potencialSlave.chained &&
                potencialSlave.chained.role === 'slave' &&
                potencialSlave.chained.masterCode === masterCode
            )
        )
    }))

    const editElementByCode = (code, edited) => setApprovationForm(approvationForm => ({
        ...approvationForm,
        elements: approvationForm.elements.map(element => code !== element.code ? element : edited)
    }))

    const clearOppressedElements = oppressorCode => setApprovationForm(approvationForm => ({
        ...approvationForm,
        elements: approvationForm.elements.map(potencialyOppressedElement =>
            potencialyOppressedElement.dependency &&
            potencialyOppressedElement.dependency.element_code === oppressorCode
                ? ({...potencialyOppressedElement, dependency: undefined})
                : potencialyOppressedElement
        )
    }))

    const clearOppressedAndShiftAffectedIndexes = (masterCode, optionIndex) => setApprovationForm(approvationForm => ({
        ...approvationForm,
        elements: approvationForm.elements.map(potencialyOppressedElement => {

                if (
                    potencialyOppressedElement.dependency &&
                    potencialyOppressedElement.dependency.element_code === masterCode &&
                    potencialyOppressedElement.dependency.option === optionIndex
                ) return ({...potencialyOppressedElement, dependency: undefined})

                if (
                    potencialyOppressedElement.dependency &&
                    potencialyOppressedElement.dependency.element_code === masterCode &&
                    potencialyOppressedElement.dependency.option > optionIndex
                ) return ({
                    ...potencialyOppressedElement,
                    dependency: {
                        ...potencialyOppressedElement.dependency,
                        option: potencialyOppressedElement.dependency.option - 1
                    }
                })

                return potencialyOppressedElement
            }
        )
    }))

    const onAddElement = element => {
        setApprovationForm(approvationForm => ({...approvationForm, elements: [...approvationForm.elements, element]}))
        editorBoxRef.scrollIntoView({block: "end", behavior: "smooth"})
    }

    const addElementOnPosition = (position, element) => {
        function insert_into_position(orArr, index, element) {
            const arr = [...orArr]
            arr.splice(index, 0, element)
            return arr
        }

        setApprovationForm(approvationForm => ({
            ...approvationForm,
            elements: insert_into_position(approvationForm.elements, position, element)
        }))
    }

    const moveElement = (fromIndex, toIndex) => {
        Array.prototype.move = function (from, to) {
            this.splice(to, 0, this.splice(from, 1)[0])
            return this
        }

        setApprovationForm(approvationForm => ({
            ...approvationForm,
            elements: [...approvationForm.elements].move(fromIndex, toIndex)
        }))
    }

    const removeElement = elementIndex => setApprovationForm(approvationForm => ({
        ...approvationForm,
        elements: approvationForm.elements.filter((_, i) => i !== elementIndex)
    }))

    const editElement = (elementIndex, data) => setApprovationForm(approvationForm => ({
        ...approvationForm,
        elements: approvationForm.elements.map((el, i) => i !== elementIndex ? el : data)
    }))

    const onDragEnd = result => {

        if (!result.destination) return

        // reorder
        if (result.source.droppableId === result.destination.droppableId) {
            if (result.destination.index === result.source.index) return
            moveElement(result.source.index, result.destination.index)
            return
        }

        // add element on specific position
        if (!result.source.droppableId === "GENERAL_COMPONENT" &&
            !result.source.droppableId === "ADVANCED_COMPONENT" &&
            !result.source.droppableId === "AUTOMOTIVE_COMPONENT" &&
            !result.source.droppableId === "FINANCIAL_COMPONENT"
        ) return

        // add element on specified position
        addElementOnPosition(
            result.destination.index,
            {...neededElements[Object.keys(neededElements)[result.source.index]].default(), code: Date.now().toString()}
        )
    }

    const changeElementEditorMode = (elementIndex, mode) => {
        setApprovationForm(approvationForm => ({
            ...approvationForm,
            elements: approvationForm.elements.map((el, i) => i !== elementIndex
                ? {...el, editorMode: "DEFAULT"}
                : {...el, editorMode: mode}
            )
        }))
    }

    const changeTitle = heading => setApprovationForm(approvationForm => ({...approvationForm, heading}))

    const approvalForm = state.form ? state.form.approvalForm.form : null

    return (
        <>
            <Navbar/>
            {(state.loadingForm || state.savingForm) && <Loading/>}
            <PageNotFound active={!!state.pageNotFound}>
               
                <div className="wrapper new-form-page">
                    {state.newForm && state.form &&
                        <ApopUp
                            title="FORM APPROVAL"
                            visible={state.newForm}
                            onCancel={() => history.push(`/forms/${state.form._id}/edit/mapping`, {newForm: true})}
                            disableFooter
                            overLayClass="overlay-template overlay-add-category"
                        >
                            <div className="form">
                                <div className="overlay-icon">
                                    <img src="img/form.svg" alt=""/>
                                </div>
                                <h3>Your form needs an additional approval form. Configure it by clicking the button below.</h3>
                                <div className="overlay-actions">
                                    <button
                                        type="button"
                                        onClick={() => setState(state => ({...state, newForm: false}))}
                                        className="button with-icon-right close-overlay"
                                    >
                                        <i className="icon-ia-checked-outline"/>CONFIGURE
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => history.push('/forms')}
                                        className="button button-link close-overlay"
                                    >
                                        CANCEL
                                    </button>
                                </div>
                            </div>
                        </ApopUp>
                    }

                    {!state.loadingForm && state.form && !state.newForm &&
                        <>
                            <div className="content">
                                <DragDropContext onDragEnd={onDragEnd}>

                                    <Toolbox addElement={onAddElement} elements={neededElements}/>

                                    <Editor
                                        ref={el => editorBoxRef = el}
                                        elements={approvalForm.elements}
                                        changeElementEditorMode={changeElementEditorMode}
                                        onNext={onNext}
                                        onBack={onBack}
                                        removeElement={removeElement}
                                        editElement={editElement}
                                        changeTitle={changeTitle}
                                        title={approvalForm.heading}
                                        setForm={setForm}
                                        editElementByCode={editElementByCode}
                                        clearOppressedElements={clearOppressedElements}
                                        clearOppressedAndShiftAffectedIndexes={clearOppressedAndShiftAffectedIndexes}
                                        addElementOnPosition={addElementOnPosition}
                                        deleteChain={deleteChain}
                                    />

                                </DragDropContext>
                            </div>

                            <LivePreview
                                form={state.form}
                                approvalForm={approvalForm}
                            />

                            <NavigationPrompt
                                when={ (state.form.approvalForm.active && state.approvalFormNeedsSave)}
                                promptCallback={({onConfirm, onCancel}) => notification.warning({
                                    message:
                                        <>
                                            <p>Please edit and save your form before exiting the page.</p>
                                            <span className="button button-outline" onClick={() => {
                                                onConfirm()
                                                notification.cancel()
                                            }}>Leave</span>
                                            <span className="tbl-btn close-subheader" onClick={() => {
                                                onCancel()
                                                notification.cancel()
                                            }}>x</span>
                                        </>, duration: 0
                                })}
                            />
                            
                        </>
                    }
                </div>

            </PageNotFound>
            <Portrait />
        </>
    )
}

export default ApprovationForm