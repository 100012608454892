import React from 'react'
import AdeleteConfirm from '../../components/AdeleteConfirm';
import {TopBarContainer} from "../comonComponents/ElementEditor.TopBarContainer";

export const AgentSignaturePad = ({
    element,
    remove,
    editElement,
    changeElementEditorMode,
    dragHandleProps = {},
    VisibilityAffected,
    formElements,
    addElementOnPosition,
    index
}) => {

    const [state, setState] = React.useState({
        localElement: {
            ...element
        },
        errors: {}
    })

    const localElement = state.localElement

    const setLocalElement = callback => setState(state => ({
        ...state,
        localElement: {...callback(state.localElement)}
    }))

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        editElement({
            ...state.localElement
        })
        changeElementEditorMode("DEFAULT")
    };

    const onPinnedChange = ({target: {checked}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                pinned: {
                    status: checked
                }
            }
        ))
    };

    const duplicateComponent = () => {
        addElementOnPosition(index + 1, {
            ...element,
            editorMode: 'DEFAULT'
        })
    }

    const openEditMode = () => {
        changeElementEditorMode("EDIT")
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center">
                <div className="form-box-wrap-center-content" style={{cursor: "pointer"}} onClick={openEditMode}>
                    <TopBarContainer>
                        <VisibilityAffected element={element} formElements={formElements}/>
                    </TopBarContainer>
                    <h6 className="supTitle">User Signature</h6>
                    <p>User Name</p>
                    <div className="agent-signature"/>
                    <p className="form-row-validation-text">{localElement.validations.map(v => `[ ${v.validation} ]  `)}{localElement.pinned && localElement.pinned.status ? "[ show on top ]" : ""}</p>
                </div>
                {/* <span class="form-box-wrap-id">{element.code}</span> */}
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <AdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </AdeleteConfirm>
                        <span onClick={duplicateComponent}><i className="icon-copy"/>Duplicate</span>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-center-edit">
                <div className="frc-box">
                    <div className="form-row-checkbox">
                        <input id={`pinned-${localElement.code}`} type="checkbox"
                               checked={localElement.pinned && localElement.pinned.status} onChange={onPinnedChange}/>
                        <label htmlFor={`pinned-${localElement.code}`}>Show on top in saved session</label>
                    </div>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
        </div>
    )
}