import React from 'react'

import LivePreview from './LivePreview.customerSatisfaction'
import {CustomerSatisfactionEditor} from './Editor.customerSatisfaction'
import CodeCopy from './CodeCopy.customerSatisfaction'
import View from './View.customerSatisfaction'

export default {
  livePreview: args => <LivePreview {...args}/>,
  editor: args => <CustomerSatisfactionEditor {...args}/>,
  codeCopy: args => <CodeCopy {...args}/>,

  default:() => ({
    type: "customer satisfaction",
    title: "Recipient Satisfaction Title",
    text: "Recipient Satisfaction Description",
    descriptionTitle: "Details",
    pinned: {
      status: false
    },

    worst: "Worst",
    poor: "Poor",
    average: "Average",
    good: "Good",
    excellent: "Excellent",

    validations: [],
    placeholder: "Recipient satisfaction Placeholder"
  }),

  availableValidations: ['required'],
  validate: () => false,
  group: "ADVANCED",
  icon: "icon-ia-satisfaction",
  name: "Recipient Satisfaction",
  view: args => <View {...args}/>
}