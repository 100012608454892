import React from 'react'
import API from "../../api";
import SignatureCanvasWrapper from "../../components/LsignaturePad";
import Loading from "../../components/Loading";
import {notification} from "../../components/Lnotification";

const ChangeSignature = ({toggleTab}) => {

    const [state, setState] = React.useState({
        loading: false,
        signature: null
    });

    const setSignature = signature => setState(state => ({...state, signature}));

    const changeSignature = async () => {
        try {
            setState(state => ({...state, loading: true}));
            await API.put('users', {signature: state.signature});
            toggleTab();
            setState(state => ({
                ...state,
                loading: false
            }))
        } catch (err) {
            setState(state => ({...state, loading: false}))
            notification.error({message: 'Something went wrong'})
        }
    };

    return (
        <div className="edit-signature">
            <div className="titleDesc">
                <h3>Edit signature</h3>
            </div>
            {state.loading && <Loading/>}
            {!state.loading &&
            <form className="form" onSubmit={async e => {
                e.preventDefault();
                await changeSignature()
            }}>
                <div className="fieldset-row">
                    <div className="form-row">
                        <label>Agent signature</label>
                        <SignatureCanvasWrapper
                            onChange={setSignature}
                            value={state.signature}
                        />
                    </div>
                </div>
                <div className="form-actions">
                    <button className="button with-icon-right close-box"><i
                        className="icon-ia-checked"/>Save
                    </button>
                    <span className="button button-link close-box" onClick={toggleTab}>Cancel</span>
                </div>
            </form>
            }
        </div>
    )
}

export default ChangeSignature