import React from "react"
import moment from "moment"
import { useHistory } from "react-router-dom"
import { useAppContext } from "../../context"
import AdeleteConfirm from "../../components/AdeleteConfirm"
import SendToClient from "./SendToClient"
import Assign from "./Forms.Assign"
import AcopyToClipboard from '../../components/AcopyToClipboard'
import {notification} from '../../components/Lnotification'
import styled from 'styled-components'
import API from "../../api"

const Form = ({
	form,
	formId,
	selectedForm,
	selectForm,
	index,
	removeForm,
	agents,
	getForms
}) => {
	const [state, setState] = React.useState({
		options: false,
	})

	const {
		auth: [
			{
				decodedToken: {
					userid,
					scope: [auth_role],
				},
			},
		],
	} = useAppContext()

	const history = useHistory()

	const duplicateForm = async () => {
		try {
			await API.post(`/forms/${formId}/duplications`);
			getForms();
		} catch(err) {
			throw err
		}
	}

	return (

			<FormItemStyledLi
				key={form._id}
                style={form._id === selectedForm ? { background: "rgb(0 0 0 / 29%)" } : {}}
			>
				<ul>
					<li style={{ cursor: "pointer" }} onClick={() => selectForm(form._id)}>
						<strong>{form.name}</strong>
					</li>

					<li style={{ cursor: "pointer" }} onClick={() => selectForm(form._id)}>
						<strong>{form.category_name}</strong>
					</li>

					<li style={{ cursor: "pointer" }} onClick={() => selectForm(form._id)}>
						<strong>
							{form.no_sessions}
							{form.no_sessions !== 0 && (
								<span>
									{parseFloat((form.no_conversions / form.no_sessions) * 100).toFixed(2)}
									% conversion rate
								</span>
							)}
						</strong>
					</li>

					<li style={{ cursor: "pointer" }} onClick={() => selectForm(form._id)}>
						{!form.last_session && <strong>-</strong>}
						{form.last_session && (
							<strong>
								{moment(form.last_session).format("D MMM, HH:mm")}
								<span>({moment(form.last_session).format("YYYY")})</span>
							</strong>
						)}
					</li>

					<li className={`${state.options  ? "view-row-options" : ""}`}>
						<div className="table-actions">
							{(auth_role === "admin" ||
								(auth_role === "manager" && userid === form.owner)) && (
								<div
									className="tbl-btn trigger-row-options"
									onClick={() => setState({ ...state, options: !state.options })}
									onBlur={() =>
										setTimeout(() => setState({ ...state, options: false }), 100)
									}
									tabIndex="-1"
								>
									<i className="icon-more-horizontal" />
									<div
										className={`tooltip ${
											index === 0 ? "tooltip-bottom" : "tooltip-top"
										}`}
									>
										More
									</div>
									{(auth_role === "admin" ||
										(auth_role === "manager" && userid === form.owner)) && (
										<ul className='row-options'>
											<li onClick={() => history.push(`/forms/${form._id}/edit/properties`)}>
												<i className="icon-ia-document" />
												Form properties
											</li>
											<li onClick={() => history.push(`/forms/${form._id}/edit/definition`)}>
												<i className="icon-ia-document" />
												Form definition
											</li>
											<li onClick={() => history.push(`/forms/${form._id}/edit/mapping`)}>
												<i className="icon-ia-document" />
												Document mapping
											</li>
											{form.approvalForm && (
												<li onClick={() => history.push(`/forms/${form._id}/edit/approval`)}>
													<i className="icon-ia-document" />
													Approval form
												</li>
											)}
											<li onClick={duplicateForm}>
												<i className="icon-ia-document" />
												Duplicate form
											</li>
										</ul>
									)}
								</div>
							)}
							{(auth_role === "admin" ||
								(auth_role === "manager" && userid === form.owner)) && (
								<AdeleteConfirm
									title={
										<>
											Are you sure you want to delete this form?
											<br />
											All data will be erased.
										</>
									}
									onConfirm={removeForm}
									okText="Delete"
									cancelText="Cancel"
								>
									<div className="tbl-btn trigger-overlay-delete-form">
										<i className="icon-ia-trash-bold" />
										<div
											className={`tooltip ${
												index === 0 ? "tooltip-bottom" : "tooltip-top"
											}`}
										>
											Delete form
										</div>
									</div>
								</AdeleteConfirm>
							)}
							<Assign index={index} agents={agents} form={form} />
							<a
								className="tbl-btn"
								href="#"
								onClick={e => {
									e.preventDefault()
									history.push(`/forms/${form._id}/preview`)
								}}
							>
								<i className="icon-ia-preview-bold" />
								<div
									className={`tooltip ${index === 0 ? "tooltip-bottom" : "tooltip-top"}`}
								>
									Preview Form
								</div>
							</a>
							{form.public_availability.status &&
								form.steps
									.reduce((acc, { elements }) => [...acc, ...elements], [])
									.find(({ type }) => type === "contract communication options") !==
									undefined && <SendToClient formId={form._id} index={index} />}

                            {form.public_availability.status &&
                                <>
                                    {(form.steps.length === 1 && form.steps[0].elements.length === 0)
                                        ? (
                                            <div className="tbl-btn"
                                                    onClick={() => notification.info({message: "Form is empty, please fill it before sharing with a user"})}>
                                                <i className="icon-link-2"/>
                                                <div
                                                    className={`tooltip ${(index === 0 ? "tooltip-bottom" : "tooltip-top")}`}>Copy
                                                    URL
                                                </div>
                                            </div>
                                        )

                                        : (
                                            <AcopyToClipboard
                                                text={`${process.env.REACT_APP_CLIENT_URL}/public/forms/${form._id}/session`}
                                                feedback={(
                                                    <div className="tbl-btn"><i className="icon-ia-checked"/>
                                                        <div
                                                            className={`tooltip ${(index === 0 ? "tooltip-bottom" : "tooltip-top")}`}>Copied!
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                <div className="tbl-btn"><i className="icon-link-2"/>
                                                    <div
                                                        className={`tooltip ${(index === 0 ? "tooltip-bottom" : "tooltip-top")}`}>Copy
                                                        URL
                                                    </div>
                                                </div>
                                            </AcopyToClipboard>
                                        )
                                    }
                                </>
                            }

						</div>
					</li>
				</ul>
			</FormItemStyledLi>

	)
}

export default Form

const FormItemStyledLi = styled.li`
	.view-row-options {
		.row-options {
			height: min-content;
		}
	}
`