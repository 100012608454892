import React from 'react'
import ReactDOM from 'react-dom'

const Atooltip = ({children, text}) => {

    const [state, setState] = React.useState({
        visible: false,
        top: 0,
        left: 0
    })

    const elementRef = React.useRef()

    const onMouseEnter = ({target}) => {
        const y = target.getBoundingClientRect().y - (target.offsetHeight + 10)
        const x = (target.getBoundingClientRect().x - (elementRef.current.offsetWidth / 2)) + target.offsetWidth / 2
        setState(state => ({...state, visible: true, top: y, left: x}))
    }

    const onMouseLeave = () => {
        setState(state => ({...state, visible: false}))
    }

    return(
        <>
            {React.cloneElement(children, {onMouseEnter, onMouseLeave})}

            {ReactDOM.createPortal(
				<>
					<div 
                        ref={elementRef}
                    css={`
                        width: auto;
                        max-width:auto;
                        position: absolute;
                        z-index: 500;
                        opacity: ${state.visible ? 1 : 0};
                        background: var(--primary-color);
                        color: #fff;
                        transition: opacity .1s ease-in-out, transform .1s ease-in-out;
                        padding: .5rem .6rem;
                        border-radius: .3rem;
                        font-size: .86rem;
                        line-height: 1rem;
                        text-transform: uppercase;
                        text-align: center;
                        white-space: nowrap;
                        backface-visibility: hidden;

           
                        top: ${state.top}px;
                        left: ${state.left}px;
                        z-index: 100;


                        ::before {
                            width: 0;
                            height: 0;
                            display: block;
                            position: absolute;
                            left: 50%;
                            transform: translate(-50%,0);
                            content: '';
                        }

                        /* tooltip top */
                        ::before {
                            bottom: -.4rem;
                            border-top: .5rem solid var(--primary-color);
                            border-left: .5rem solid transparent;
                            border-right: .5rem solid transparent;
                        }
                    `}
                        
                    >
                        {text}
                    </div>
				</>,
				document.querySelector("#tooltip")
			)}
        </>
    )
}

export default Atooltip