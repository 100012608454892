import { useState, useEffect } from 'react';
import { useAppContext } from '../context';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

const SessionMaintainer = ({ debug = false }) => {
    const { auth: [auth, setAuth] } = useAppContext();
    const [refreshTimeoutId, setRefreshTimeoutId] = useState(null);

    const refreshToken = async () => {
        if (debug) console.log('Refreshing token...');

        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/token`, {
                grant_type: 'refresh_token',
                refresh_token: auth.refresh_token,
            }, {
                auth: { username: process.env.REACT_APP_API_KEY, password: process.env.REACT_APP_API_SECRET },
            });

            const { access_token, refresh_token, expires_in, refresh_token_expires_in } = data;
            const expirationTime = Date.now() + expires_in * 1000;
            const refreshExpirationTime = Date.now() + refresh_token_expires_in * 1000;
            const decodedToken = jwtDecode(access_token);

            setAuth({
                access_token,
                expirationTime,
                refreshExpirationTime,
                refresh_token,
                decodedToken,
            });
        } catch (error) {
            if (debug) console.error('Error refreshing token', error);
            setAuth(null);
        }
    };

    useEffect(() => {
        if (auth) {
            const tokenExpirationTime = auth.expirationTime;
            const now = Date.now();
            const refreshTokenExpirationTime = auth.refreshExpirationTime;
            const timeUntilRefresh = tokenExpirationTime - now - 60 * 1000; // 1 minute before expiration

            if (debug) console.log({ tokenExpirationTime, now, timeUntilRefresh });

            if (timeUntilRefresh <= 0) {
                if (debug) console.log('Token has expired');
                if (now > refreshTokenExpirationTime) {
                    if (debug) console.log('Refresh token has expired');
                    setAuth(null);
                } else {
                    if (debug) console.log('Refresh token has not expired yet');
                    refreshToken();
                }
            } else {
                if (debug) console.log('Token has not expired yet');
                const timeoutId = setTimeout(refreshToken, timeUntilRefresh);
                setRefreshTimeoutId(timeoutId);
            }
        } else {
            clearTimeout(refreshTimeoutId);
            setRefreshTimeoutId(null);
        }
    }, [auth]);

    return null;
};

export default SessionMaintainer;