import React from "react"

import { GoogleMap } from "@react-google-maps/api"

import Spiderfy from "../../../components/Spiderfy"
import { useAppContext } from "../../../context"
import Loading from "../../../components/Loading"
import GoogleMapsLoader from "../../../components/GoogleMapsLoader"


const Map = ({ places }) => {
	const [map, setMap] = React.useState(null)

	const {
		company: [company],
	} = useAppContext()

	const onLoad = React.useCallback(function callback(map) {
		setMap(map)
	}, [])

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null)
	}, [])

	return (
		<GoogleMapsLoader
			googleMapsApiKey={company.google_maps_api_key}
			loader={<Loading />}
		>
			<GoogleMap
				mapContainerStyle={{
					height: "100%",
					width: "100%",
				}}
				zoom={12}
				center={{ lat: 44.439663, lng: 26.096306 }}
				options={{
					mapTypeControl: false,
				}}
				onLoad={onLoad}
				onUnmount={onUnmount}
			>
				{map ? (
					<Spiderfy map={map} places={places} />
				) : null}
			</GoogleMap>
		</GoogleMapsLoader>
	)
}

export default Map

// const OldMap = withScriptjs(
//     withGoogleMap(({zoom, center, places}) => (
//         <>
//             <GoogleMap
//                 defaultZoom={zoom}
//                 defaultCenter={{
//                     lat: center.lat,
//                     lng: center.lng
//                 }}
//             >
//                 <Spiderfy>
//                     {places.map((session, index) => {
//                         return <Marker key={index} className="pin" position={{
//                             lat: session.location.coordinates[1],
//                             lng: session.location.coordinates[0]
//                         }}
//                                     session={session}
//                                     icon={{
//                                         url: `${process.env.REACT_APP_DASHBOARD_URL}/AlchemistAdmin/img/pin.png`,
//                                         anchor: new window.google.maps.Point(4, 46)
//                                     }}>
//                         </Marker>
//                     })}
//                 </Spiderfy>
//             </GoogleMap>
//         </>
//     ))
// );
