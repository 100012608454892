export const getError = (response) => {
    if (response === undefined) {
        return 'server_error';
    }

    if (response.data !== undefined && (response.data.error !== undefined || response.data.errors !== undefined)) {
        return response.data.error || response.data.errors;
    }

    return 'server_error';
};