import React from "react"
import moment from "moment"
import oms from "npm-overlapping-marker-spiderfier/lib/oms.min"

class Spiderfy extends React.Component {
	constructor(props, context) {
		super(props, context)
		this.oms = new oms.OverlappingMarkerSpiderfier(this.props.map, {
			keepSpiderfied: true,
			markersWontMove: true,
			markersWontHide: true,
			basicFormatEvents: true,
		})
	}

	componentDidMount() {
		this.props.places.forEach(place => {
			const marker = new window.google.maps.Marker({
				position: {
					lat: place.location.coordinates[1],
					lng: place.location.coordinates[0],
				},
				map: this.props.map,
				icon: "img/pin.png",
			})
			this.oms.addMarker(marker)

			const infowindow = new window.google.maps.InfoWindow()

			window.google.maps.event.addListener(marker, "spider_click", e => {
				infowindow.setContent(`
                    <div class="map-tooltip" style="
                        position: unset;
                        top: unset;
                        left: unset;
                        margin-top: unset;
                        transform: unset">
                        <div class="tbl-li-img">
                            <div class="tbl-img tbl-agent-img">
                                <div>
                                    ${
                                        place._agent.image !== null
                                            ? '<img src="' +
                                                process.env.REACT_APP_API_BASE_URL +
                                                "/images/users/image_thumbnail/" +
                                                place._agent.image +
                                                '"/>'
                                            : ""
                                    }
                                </div>
                            </div>
                            <strong>${place._agent.name}</strong>
                        </div>
                        <div class="map-details">
                            <h6>${place.form_name}</h6>
                            <span>${moment(place.updated_at).format("D MMM YYYY, HH:mm")}</span>
                        </div>
                    </div>
                `)
				if (infowindow.getMap()) {
					infowindow.close()
				} else {
					infowindow.open(marker.map, marker)
				}
			})
		})
	}

	render() {
		return null
	}
}

export default Spiderfy
