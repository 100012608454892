import React from 'react'
import {saveAs} from "file-saver"
import {useHistory} from 'react-router-dom'
import moment from 'moment'
import debounce from 'lodash/debounce'
import {notification} from '../../../components/Lnotification'

import Loading from "../../../components/Loading"
import API from '../../../api'
import {getError} from "../../../utils/getError"
import Ipagination from '../../../components/Ipagination'
import {InviteUsers} from "./InviteUsers";
import styled from "styled-components";

const UsersData = ({toggleFilters, usersStore:[,setUsersState], className=""}) => {

    const history = useHistory()
    const page = new URLSearchParams(history.location.search).get('page')

    const [state, setState] = React.useState({
        loading: false,
        error: null,
        data: [],
        pages: 0,
        page: page || 1,
        search: '',
        selected_roles: [],
        sort: null,
        order: null,
        filtersCollapsed: true,
        userRolesFilterDropdown: false,
    }, "users data")



    React.useEffect(() => {
        getUsersList(state)
    }, [])

    // 
    const getUsersList = async (args = undefined) => {
        try {
            setState(state => ({...state, loading: true, error: null}))
            let url = '/users'
            if (args) {
                const filters = []
                if (args.search) filters.push(`search=${args.search}`)
                if (args.sort) filters.push(`sort=${args.sort}&order=${state.order}`)
                if (args.page) filters.push(`page=${args.page}`);
                if (args.selected_roles.length > 0) args.selected_roles.forEach(role => filters.push(`roles[]=${role}`))
                if (filters.length) url += '?' + filters.join('&')
            }
            const {data: {data, pages}} = await API.get(url)
            setState(state => ({...state, loading: false, data, pages}))
            if (args && args.page) setPage(args.page)
            setUsersState(usersState => ({...usersState, usersLoadedOnce: true}))
        } catch (err) {
            setState(state => ({...state, loading: false, error: getError(err.response)}))
        }
    }

    const setPage = page => setState(state => ({...state, page}))

    const setSearch = search => setState(state => ({...state, search}))

    const getUsersAfterSearch = debounce(
        (state, value) => getUsersList({
            ...state,
            search: value,
            page: 1
        }),
        1000
    )

    const changeSearch = e => {
        setSearch(e.target.value)
        getUsersAfterSearch(state, e.target.value)
    }

    const changePage = page => {
        history.push({
            pathname: history.location.pathname,
            search: `?page=${page}`
        })
        getUsersList({...state, page})
    }

    const toggleRole = role => {
        setState(state => {
            const selected_roles = state.selected_roles.includes(role)
                ? state.selected_roles.filter(r => r !== role)
                : [...state.selected_roles, role]
            getUsersList({...state, selected_roles, page: 1})
            return {...state, selected_roles}
        })
    }

    const clearFilters = () => {
        setState(state => ({...state, selected_roles: []}))
        getUsersList({...state, selected_roles: [], page: 1})
    }

    const setSort = payload => setState(state => ({...state, sort: payload.sort, order: payload.order}))

    const setColumnSorting = (sort, order) => () => {
        setSort({sort, order})
        getUsersList({...state, page: 1, sort, order})
    }

    const exportSessions = async (state, export_type, error_callback) => {
        try {
            const extension = {'pdf': 'pdf', 'excel': 'xlsx'}
            const response = await API.get(constructExportUrl(state, export_type), {responseType: 'blob'})
            saveAs(response.data, `users_list.${extension[export_type]}`)
        } catch (err) {
            error_callback()
        }
    }

    const constructExportUrl = (state, export_type) => {
        let url = `${process.env.REACT_APP_API_BASE_URL}/users/list/export/${export_type}`
        const filters = []
        if (state.sort) filters.push(`sort=${state.sort}&order=${state.order}`)
        if (state.search) filters.push(`search=${state.search}`)
        url += '?' + filters.join('&')
        return url
    }

    const renderSortIcon = (column) => {
        if (column !== state.sort)
            return <span className="cntrl-box"><i
                className="cntrl-down icon-arrow-down" onClick={setColumnSorting(column, 'desc')}/><i
                className="cntrl-up icon-arrow-up" onClick={setColumnSorting(column, 'asc')}/></span>;

        if (state.order === 'desc')
            return <span className="cntrl-box"><i
                className="cntrl-down icon-arrow-down selected" onClick={setColumnSorting(null, null)}/><i
                className="cntrl-up icon-arrow-up" onClick={setColumnSorting(column, 'asc')}/></span>;

        return <span className="cntrl-box"><i
            className="cntrl-down icon-arrow-down" onClick={setColumnSorting(column, 'desc')}/><i
            className="cntrl-up icon-arrow-up selected" onClick={setColumnSorting(null, null)}/></span>;
    }

    const exportSessionRequest = (export_type) => () => {
        exportSessions(state, export_type, () => {
            notification.error({
                message: 'Something went wrong with downloading the file'
            })
        })
    }

    return (
        <div className={`content ${className}`} >
            <div className="content-box content-box-with-filters">

                <div className="content-box-header">
                    <h3>Users</h3>
                    <div className="content-box-options">
                        <div className="content-box-header-search">
                            <form className="content-search">
                                <fieldset>
                                    <input type="text" value={state.search} onChange={changeSearch}
                                           placeholder="Search"/>
                                    <button className="tbl-btn" onClick={e => {
                                        e.preventDefault()
                                    }}/>
                                </fieldset>
                            </form>
                        </div>
                        <span className="button button-outline with-icon-right trigger-filters" onClick={toggleFilters}>
                            <i className="icon-ia-arrow"/>Filters
                        </span>
                    </div>
                </div>

                <div className="content-box-subheader filters">
                    <h4>Filters</h4>
                    {state.selected_roles.length > 0 &&
                    <ul className="applied-filters">
                        <li>
                            <span>Filters:</span>
                        </li>
                        {state.selected_roles.map(role => (
                            <li key={role}>
                                <i className="delete-filter" onClick={() => toggleRole(role)}/>
                                <span>{role}</span>
                            </li>
                        ))}
                        <li className="applied-filters-actions">
                            <div className="tbl-btn"
                                 onClick={clearFilters}
                            ><i className="icon-refresh-ccw"/>
                                <div className="tooltip tooltip-top">Clear all</div>
                            </div>
                        </li>
                    </ul>
                    }
                    <div className="form">
                        <div
                            className={`form-row ${(state.userRolesFilterDropdown ? 'view-dropdown' : '')}`}
                            onBlur={() => setState(state => ({...state, userRolesFilterDropdown: false}))}
                            tabIndex="0"
                            style={{outline: 'none'}}
                        >
                            <label htmlFor="">Role(s)</label>
                            <input
                                type="text" name="" placeholder="Select roles"
                                onFocus={() => setState(state => ({...state, userRolesFilterDropdown: true}))}
                            />
                            <div className="dropdown">
                                <div className="scrollbar">
                                    <ul>
                                        {['agent', 'manager', 'admin'].map(role => (
                                            <li
                                                className={`${state.selected_roles.includes(role) ? "selected" : ""}`}
                                                key={role}
                                                onMouseDown={e => {
                                                    e.preventDefault()
                                                    toggleRole(role)
                                                }}
                                            >{role === 'agent' ? 'user' : role}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="tbl-btn close-subheader"><i className="icon-x" onClick={toggleFilters}/></span>
                </div>

                <div className="content-box-body content-box-body-fixed-elements">
                    <div className="box-body box-body-fixed">

                        <div className="table-fixed-header">
                            <ul className="table-ul users-agents-list">
                                <li className="table-ul-header">
                                    <ul>
                                        <li className="with-cntrl-box">
                                            <strong>Name</strong>
                                            {renderSortIcon('name')}
                                        </li>
                                        <li className="with-cntrl-box">
                                            <strong>Username</strong>
                                            {renderSortIcon('username')}
                                        </li>
                                        <li className="with-cntrl-box">
                                            <strong>User Type</strong>
                                            {renderSortIcon('scopes')}
                                        </li>
                                        <li className="with-cntrl-box">
                                            <strong>No. of Sessions</strong>
                                            {renderSortIcon('no_sessions')}
                                        </li>
                                        <li className="with-cntrl-box">
                                            <strong>Last Login</strong>
                                            {renderSortIcon('lastLogin')}
                                        </li>
                                        <li><strong></strong></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="scrollbar">
                            {state.loading && <Loading/>}
                            {state.data.length === 0 &&
                            <div className="empty-list">This list is empty</div>
                            }
                            {state.data.length > 0 &&
                            <ul className="table-ul users-agents-list">
                                {state.data.map((user, index) => (
                                    <li key={user._id}>
                                        <ul>
                                            <li className="tbl-li-img">
                                                <div className="tbl-img tbl-agent-img">
                                                    <div>
                                                        {
                                                            !!user.image
                                                                ? <img src={
                                                                    user.image?.startsWith('http')
                                                                        ? user.image
                                                                        : `${process.env.REACT_APP_API_BASE_URL}/images/users/image/${user.image}`
                                                                } />
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <strong>{user.name}</strong>
                                            </li>
                                            <li><strong>{user.username}</strong></li>
                                            <li><strong>{user.scopes.toString() === 'agent' ? 'user' : user.scopes.toString()}</strong></li>
                                            <li><strong>{user.no_sessions}</strong></li>
                                            <li>
                                                {user.lastLogin &&
                                                <strong>{moment(user.lastLogin).format('D MMM HH:mm')}<span>({moment(user.lastLogin).format('YYYY')})</span></strong>
                                                }
                                            </li>
                                            <li>
                                                <div className="table-actions" title="Change User Information">
                                                    <a className="tbl-btn" href="#"
                                                       onClick={e => {
                                                           e.preventDefault()
                                                           history.push(`/users/${user._id}`)
                                                       }}
                                                    ><i className="icon-ia-edit"></i>
                                                        <div
                                                            className={`tooltip ${(index === 0 ? 'tooltip-bottom' : 'tooltip-top')}`}>Edit
                                                        </div>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                            }
                        </div>

                        <div className="box-action table-action-fixed with-pagination" >
                            <div className="export-options">
                                <span>Export list to:</span>
                                <button className="button button-outline"
                                        onClick={exportSessionRequest('pdf')}>PDF
                                </button>
                                <button className="button button-outline"
                                        onClick={exportSessionRequest('excel')}>XLS
                                </button>
                            </div>
                            <Ipagination
                                page={state.page}
                                pages={state.pages}
                                onChange={changePage}
                            />
                            <div className="add-uses-buttons-wrapper">
                                <InviteUsers />
                                <a className="button with-icon-right" href="#" title=""
                                   onClick={e => {
                                       e.preventDefault()
                                       history.push('/users/create/new')
                                   }}
                                ><i className="icon-plus"></i>Add new</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const UsersDataStyled  = styled(UsersData)`
  .with-pagination {
    grid-template-columns: 1fr 2fr auto;
  }
  
  .add-uses-buttons-wrapper {
    display: flex;
    column-gap: 1rem;
  }
  
`

export {UsersDataStyled as UsersData}

