import React from 'react'

import LivePreview from './LivePreview.documentsPreview'
import {DocumentPreviewEditor} from './Editor.documentsPreview'
import CodeCopy from './CodeCopy.documentsPreview'
import View from './View.documentsPreview'

export default {
  livePreview: args => <LivePreview {...args}/>,
  editor: args => <DocumentPreviewEditor {...args}/>,
  codeCopy: args => <CodeCopy {...args}/>,

  default:() => ({
    type: "documents preview",
    title: "Documents Preview Title",
    text: "Documents Preview Text",
    validations: []
  }),

  validate: () => false,
  group: "ADVANCED",
  icon: "icon-ia-preview-file",
  name: "Documents Preview",
  view: args => <View {...args}/>
}