import React, { Fragment } from "react"
import { ThemeProvider, css } from "styled-components"

import suportedElements from "../../../formComponents/supportedElements"
import Frame from "../../../components/Frame"
import Swiper from "react-id-swiper"
import LoadAfter from "../../../components/LoadAfter"
import Loading from "../../../components/Loading"
import look from '../livePreview.look'


const cssFixes = css`
	&&&&&&& {
		.fieldset-row, .id-card-extractor  {
			background: unset !important;
		}
		
	}
`

const FormCodes = ({ state }) => {
    if(state.loadingForm || !state.form) return <Loading />

    const {form} = state

	return (
		<>
			{!state.loadingForm && state.form && (
				<ThemeProvider
					theme={{
						mode: "dark",

						backgroundColor: form.background_color,
						fontColor: form.font_color,
						fontFace: form.font_face,
		
						defaultLook: {
							fontColor: "#5D2560",
							backgroundColor: "#F1F1F1",
							fontFace: "Open Sans",
						},
					}}
				>
					<Swiper
						containerClass="pts-top"
						spaceBetween={0}
						navigation={{
							nextEl: ".swiper-button-next.icon-ia-arrow-right",
							prevEl: ".swiper-button-prev.icon-ia-arrow-left",
						}}
					>
						{form.steps.map((step, stepIndex) => {
							let formHasVehicleEvaluation = step.elements.find(
								element => element.type === "vehicle evaluation"
							)
							return (
								<div key={stepIndex}>
									<div className="tablet-preview">
										<img src="img/tablet.svg" />
										<Frame
											head={
												<>
													<meta
														httpEquiv="Content-Type"
														content="text/html; charset=utf-8"
													/>
													<meta
														name="viewport"
														content="user-scalable=no, initial-scale=1.0, maximum-scale=1.0, width=device-width"
													/>
													<meta name="robots" content="index, follow" />
													<meta name="language" content="ro" />
													<meta name="apple-mobile-web-app-capable" content="yes" />
													<meta name="mobile-web-app-capable" content="yes" />
													<base href="/" />
													<title>IA</title>
												</>
											}
										>
											<LoadAfter
												links={[
													'https://cdnjs.cloudflare.com/ajax/libs/lato-font/3.0.0/css/lato-font.min.css',
													"https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i",
													"AlchemistAdmin/client/icons.css",
													"AlchemistAdmin/client/swiper.min.css",
													"AlchemistAdmin/client/global.css",
													"AlchemistAdmin/client/preview-code-copy.css",
												]}
												loader={<Loading />}
											>
												<div className={`wrapper`} css={`${look}${cssFixes}`}>
													<header className="header">
														<div className="logo">
															<img src="../img/Alchemist_Identity_Final-07.png" />
														</div>
														<div />
														<div className="menu-right">
															<div className="user-img" />
															<div className="logout" />
														</div>
													</header>

													<div className="wrapper-content" style={{padding: "3rem 0 5rem"}}>
														<div className="table">
															<div className="table-cell">
																<div
																	className={`content ${
																		formHasVehicleEvaluation ? "content-padding-0" : ""
																	}`}
																>
																	<form className="form">
																		{step.elements.map((element, elementIndex) => {
																			if (suportedElements[element.type]) {
																				return (
																					<Fragment key={elementIndex}>
																						{suportedElements[element.type].codeCopy({ element })}
																					</Fragment>
																				)
																			}
																			return null
																		})}
																	</form>
																</div>
															</div>
														</div>
													</div>

													<footer className="footer">
														<button className="footer-btn prev-btn">
															<i className="icon-ia-arrow-left"></i>
															<span>Prev</span>
														</button>
														<button className="footer-btn clear-btn">
															<i className="icon-refresh-cw"></i>
															<span>Clear</span>
														</button>
														<button className="footer-btn end-btn">
															<i className="icon-upload"></i>
															<span>End Session</span>
														</button>
														<button className="footer-btn next-btn">
															<i className="icon-ia-arrow-right"></i>
															<span>
																{form.steps.length !== stepIndex + 1 ? "Next" : "Finish"}
															</span>
														</button>
														<div className="footer-pagination">
															<strong>{stepIndex + 1}</strong>/ {form.steps.length} Steps
														</div>
													</footer>
												</div>
											</LoadAfter>
										</Frame>
									</div>
								</div>
							)
						})}

						{(!form.approvalForm.active ? [] : [1]).map((_, i) => (
							<div key={i}>
										<div className="tablet-preview">
											<img src="img/tablet.svg" />
											<Frame
												head={
													<>
														<meta
															httpEquiv="Content-Type"
															content="text/html; charset=utf-8"
														/>
														<meta
															name="viewport"
															content="user-scalable=no, initial-scale=1.0, maximum-scale=1.0, width=device-width"
														/>
														<meta name="robots" content="index, follow" />
														<meta name="language" content="ro" />
														<meta name="apple-mobile-web-app-capable" content="yes" />
														<meta name="mobile-web-app-capable" content="yes" />
														<base href="/" />
														<title>IA</title>
													</>
												}
											>
												<LoadAfter
													links={[
														'https://cdnjs.cloudflare.com/ajax/libs/lato-font/3.0.0/css/lato-font.min.css',
														"https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i",
														"AlchemistAdmin/client/icons.css",
														"AlchemistAdmin/client/swiper.min.css",
														"AlchemistAdmin/client/global.css",
														"AlchemistAdmin/client/preview-code-copy.css",
													]}
													loader={<Loading />}
												>
													<div className={`wrapper`} css={`${look}${cssFixes}`}>
														<header className="header" style={{borderBottom: '1px dashed rgba(0,0,0,.1)'}}>
															<h3 style={{fontSize: '1.8rem'}}>Approve session</h3>
														</header>

														<div className="wrapper-content" style={{padding: "7rem 0px 9rem"}}>
															<div className="table">
																<div className="table-cell">
																	<div
																		className="content"
																	>
																		<form className="form">
																			{(form.approvalForm.form?.elements || []).map((element, elementIndex) => {
																				if (suportedElements[element.type]) {
																					return (
																						<Fragment key={elementIndex}>
																							{suportedElements[element.type].codeCopy({ element })}
																						</Fragment>
																					)
																				}
																				return null
																			})}
																		</form>
																	</div>
																</div>
															</div>
														</div>

														<footer style={{pointerEvents: 'none', borderTop: '1px dashed rgba(0,0,0,.1)', backgroundColor: '#f8f8f8', position: 'absolute', bottom: '0', top: 'auto', padding: '2rem 3rem', columnGap: '1.5rem', display: 'flex', width: '100%', zIndex: '99999'}}>
															<div
																className="button with-icon-right"
															>
																<i className="icon-ia-checked-outline"></i>Approve
															</div>
															<div className="button button-link close-overlay">
																Decline
															</div>
														</footer>
													</div>
												</LoadAfter>
											</Frame>
										</div>
									</div>
						))}
					</Swiper>
				</ThemeProvider>
			)}
		</>
	)
}

export default FormCodes