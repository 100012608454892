import React from 'react'

import SessionsData from './SessionsData/SessionsData'
import FormsStats from './FormsStats/FormsStats'
import Navbar from "../../components/Navbar"
import Portrait from "../../components/Portrait"
import Loading from "../../components/Loading";

const Sessions = () => {
    const [headerTab, setHeaderTab] = React.useState(null)
    const [state, setState] = React.useState({
        loadedSessionsOnce: false, 
        loadedStatsOnce: false
    }, 'sessions-page')
    return (
        <>
            <Navbar/>
            {(!state.loadedSessionsOnce || !state.loadedStatsOnce) && <Loading/>}

            <div 
                className={`wrapper data-explorer-page ${{
                    editFilterName: 'view-save-filter-box view-saved-filters view-edit-filter-name',
                    savedFilters: 'view-saved-filters',
                    saveFilter: 'view-save-filter-box view-saved-filters',
                    filters: 'view-filters',
                    map: 'view-map',
                    null: ''
                }[headerTab]}`}
                css={`
                    display: ${(!state.loadedSessionsOnce || !state.loadedStatsOnce) ? 'none' : 'visible'};
                `}
            >
                
                <SessionsData
                    sessionsPageStore={[state, setState]}
                    headerTab={headerTab}
                    setHeaderTab={setHeaderTab}
                />
                <FormsStats sessionsPageStore={[state, setState]}
                />
            </div>
            <Portrait/>
        </>
    )
}

export default Sessions;