import React from "react"
import {isEmpty} from "validator"
import {useParams, useHistory} from "react-router-dom"
import {BlockPicker} from "react-color"
import {dataURLToBlob} from "blob-util"
import {notification} from "../../../components/Lnotification"
import Navbar from "../../../components/Navbar"
import formLogoImg from "../../../images/form-logo.png"
import Approvers from "./FormProperties.Approvers"
import Availability from "./FormProerties.Availability"
import AnotifyOthersList from "../../../components/AnotifyOthersList"
import AtabletPreview from "../../../components/AtabletPreview"
import API from "../../../api"
import {readObjectUrl} from "../../../utils/readObjectUrl"
import {css, ThemeProvider} from 'styled-components'
import Loading from "../../../components/Loading"
import {elements} from '../../../constants/elements'
import NavigationPrompt from "../../../components/InavigationPrompt";
import {useAppContext} from "../../../context";
import PageNotFownd from '../../../components/PageNotFound'
import Portrait from '../../../components/Portrait'
import look from '../livePreview.look'
import {v4 as uuid} from 'uuid'

const defaultLookAndFeel = {
    formLogo: null,
    fontColor: "#5D2560",
    backgroundColor: "#F1F1F1",
    fontFace: "Open Sans",
}

const FormProperties = () => {
    const [state, setState] = React.useState(
        {
            formCategories: null,
            form: null,
            loadingForm: false,
            unsavedChanges: false,
            pageNotFound: false,
            errors: {},
            saving: false
        },
        "Form-Properties"
    )

    const {form} = state

    const params = useParams()
    const history = useHistory()
    const refs = React.useRef({});
    const elements_references = refs.current

    React.useEffect(() => {
        loadForm()
    }, [])

    const {
        auth: [
            {
                decodedToken: {
                    userid,
                    scope: [auth_role],
                },
            },
        ],
    } = useAppContext()

    const setForm = callback =>
        setState(state => ({
            ...state,
            form: callback(state.form),
            unsavedChanges: true,
        }))

    const loadForm = async () => {
        try {
            setState(state => ({...state, loadingForm: true}))

            const {data} = await API.get(`forms/${params.id}`)
            if (auth_role === "agent" || (auth_role === "manager" && userid !== data.form._owner)) {
                setState(state => ({
                    ...state,
                    pageNotFound: true
                }))
            }

            const loadedForm = {
                _id: data.form._id,
                formLogo: data.form.logo
                    ? URL.createObjectURL(dataURLToBlob(data.form.logo))
                    : null,
                backgroundColor: data.form.background_color,
                fontFace: data.form.font_face,
                fontColor: data.form.font_color,
                name: data.form.name,
                description: data.form.description,
                notificationRecipients: {
                    ...data.form.notification_recipients,
                },
                notifyOthers: {
                    enabled: data.form.notification_recipients.others.length > 0,
                    others:
                        data.form.notification_recipients.others.length > 0
                            ? typeof data.form.notification_recipients.others[0] === 'object'
                            ? data.form.notification_recipients.others.map(({email}) => email)
                            : data.form.notification_recipients.others
                            : [],
                    other: "",
                },
                approvers: data.form.approvers,
                approvalForm: data.form.approvalForm,
                activation: {
                    ...(data.form.activation ? {...data.form.activation, active: true} : {
                        title: "",
                        description: "",
                    }),
                },
                public_availability: data.form.public_availability,
                proofOfLocation: data.form.proofOfLocation,
                documentOutputType: data.form?.documentOutputType || {},
                steps: (data.form.steps && data.form.steps.length > 0) ? [...data.form.steps] : [
                    {
                        elements: [],
                        heading: ""
                    }
                ]
            }

            if(
                data.form._category._id && 
                !data.categories.find(category => category._id === data.form._category._id)
            ) {
                loadedForm.category = ""
            }  else {
                loadedForm.category = data.form._category._id
            }


            setState(state => ({
                ...state,
                loadingForm: false,
                formCategories: data.categories,
                form: loadedForm
            }))

        } catch (err) {
            setState(state => ({
                ...state,
                loadingForm: false,
                ...(err.response.status === 404 ? {pageNotFound: true} : {})
            }))
        }
    }

    const scrollToError = (validation_errors) => {
        let y, ref = null;
        const validation_errors_keys = Object.keys(validation_errors);
        const elements_references_keys = Object.keys(elements_references).filter(ref => validation_errors_keys.includes(ref));
        elements_references_keys.forEach(key => {
            if (!y || y > elements_references[key]?.getBoundingClientRect().y) {
                y = elements_references[key]?.getBoundingClientRect().y;
                ref = elements_references[key]
            }
        });
        // setTimeout(() => {
        if (ref) {
            ref.scrollIntoView()
        }
        // }, 1000)
    };

    const validate = () => {
        let err = {}

        if (isEmpty(form.category)) err.category = "Category is required"

        if (isEmpty(form.name)) err.name = "Name is required"
        else if(form.name.length > 60) err.name = "Name is too long."

        if (isEmpty(form.description)) err.description = "this field is required"

        if (form.description.length > 5000)
            err.description = "Form description is too long."

        if (
            form.public_availability.thankYouText &&
            form.public_availability.thankYouText.length > 100
        )
            err.thankYouText = "Text is too long."

        if (
            form.public_availability.redirect &&
            form.public_availability.redirect.status
        ) {
            if (
                !form.public_availability.redirect.url ||
                form.public_availability.redirect.url.length === 0
            ) {
                err.redirectURL = "Please input a URL."
            } else if (
                !form.public_availability.redirect.url.match(
                    new RegExp(
                        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
                    )
                )
            ) {
                err.redirectURL = "Please input a valid URL."
            }
        }

        if (
            form.notifyOthers.enabled &&
            form.notifyOthers.others.length === 0 &&
            form.notifyOthers.other !== ""
        )
            err.others = "You should add at least one recipient"

        // Activation
        if (form.activation.active) {
            if (isEmpty(form.activation.title))
                err.activationTitle = "Activation title is required"
            if (form.activation.title.length > 200) err.activationTitle = "max 200 ch."

            if (isEmpty(form.activation.description))
                err.activationDescription = "Activation content is required"
            if (form.activation.description.length > 5000)
                err.activationDescription = "max 5000 ch."
        }


        setState(state => ({...state, errors: err}))
        if (Object.keys(err).length > 0) {
            scrollToError(err);
        }
        return Object.keys(err).length === 0
    }

    const onLogoChange = event => {
        const image = event.target.files[0]
        event.target.value = ""
        setForm(form => ({...form, formLogo: URL.createObjectURL(image)}))
    }

    const reset = () => setForm(form => ({...form, ...defaultLookAndFeel}))

    const setFormName = name => setForm(form => ({...form, name}))

    const changeCategory = category => setForm(form => ({...form, category}))

    const changeDescription = description =>
        setForm(form => ({...form, description}))

    const changeNotifyAgent = notify =>
        setForm(form => ({
            ...form,
            notificationRecipients: {...form.notificationRecipients, agent: notify},
        }))

    const changeNotifyClient = notify =>
        setForm(form => ({
            ...form,
            notificationRecipients: {...form.notificationRecipients, client: notify},
        }))

    const onAvailabilityChange = newValue =>
        setForm(form => ({...form, public_availability: newValue}))

    const changeProofOfLocation = active =>
        setForm(form => ({...form, proofOfLocation: active}))

    const changeDocumentOutputType = documentOutputType => {
        setForm(form => ({...form, documentOutputType}))
    }

    const changeNotifyOthers = notify =>
        setForm(form => ({
            ...form,
            notifyOthers: {...form.notifyOthers, enabled: notify},
        }))

    const clearAll = f => f

    const saveProperties = async (onSuccess) => {
        try {
            setState(state => ({...state, saving: true}))

            if (!validate()) {
                setState(state => ({...state, saving: false}))
                return
            }

            await API.put(`forms/${params.id}`, {
                name: form.name,
                category: form.category,
                description: form.description,
                font_color: form.fontColor,
                font_face: form.fontFace,
                background_color: form.backgroundColor,
                logo: form.formLogo ? await readObjectUrl(form.formLogo) : null,
                notification_recipients: {
                    ...form.notificationRecipients,
                    others: [
                        ...form.notifyOthers.others,
                        ...(form.notifyOthers.other.trim().length > 0
                            ? [form.notifyOthers.other]
                            : []),
                    ],
                },
                public_availability: form.public_availability,
                approvers: form.approvers,
                approvalForm: form.approvalForm,
                proofOfLocation: form.proofOfLocation,
                documentOutputType: form.documentOutputType,
                activation: form.activation.active
                    ? {
                        title: form.activation.title,
                        description: form.activation.description
                    }
                    : null,
            })
            notification.cancel()
            setState(state => ({...state, saving: false, unsavedChanges: false}))
            onSuccess()
        } catch (err) {
            let errors = {}
            if (err.response && err.response.data && err.response.data.error && err.response.data.error === "name_taken") {
                errors.name = "A form with this name already exists";
            }
            if (err.response && err.response.data && err.response.data.error && err.response.data.error === "customUrl_taken") {
                errors.customId = "A form with this customId already exists";
            }
            setState(state => ({...state, saving: false, errors}))
            scrollToError(errors);
        }
    }

    return (
        <>
            <Navbar/>
            {(state.loadingForm || state.saving) && <Loading/>}
            <PageNotFownd active={!!state.pageNotFound}>
                <div className="wrapper new-form-page" style={{padding: "7rem 0"}}>

                    {!state.loadingForm && !state.saving && state.form && (
                        <div className="content">
                            <div className="toolbox toolbox-1">
                                <h3 className="tb-header-title">Look and feel</h3>
                                <div className="scrollbar">
                                    <h6>Form logo</h6>
                                    <div className="tb-upload-logo">
                                        <div className="tb-uploaded-img">
                                            <img src={state.form.formLogo || formLogoImg} alt=""/>
                                        </div>
                                        <input
                                            className="tb-upload-file"
                                            type="file"
                                            name=""
                                            onChange={onLogoChange}
                                            accept="image/*"
                                        />
                                    </div>
                                    <h6>Font color</h6>
                                    <div
                                        css={`
                                            margin: 0.5rem 0 1rem;
                                            > .block-picker > div:nth-child(2) {
                                                border: 2px solid white;
                                            }
                                        `}
                                    >
                                        <BlockPicker
                                            triangle="hide"
                                            width="100%"
                                            colors={[
                                                "#1abc9c",
                                                "#2ecc71",
                                                "#3498db",
                                                "#9b59b6",
                                                "#000000",
                                                "#16a085",
                                                "#27ae60",
                                                "#2980b9",
                                                "#8e44ad",
                                                "#7f8c8d",
                                                "#f1c40f",
                                                "#e67e22",
                                                "#fafbfe",
                                                "#fea540",
                                                "#95a5a6",
                                            ]}
                                            color={state.form.fontColor}
                                            onChange={color =>
                                                setForm(form => ({
                                                    ...form,
                                                    fontColor: color.hex,
                                                }))
                                            }
                                        />
                                    </div>
                                    <h6>Background color</h6>
                                    <div
                                        css={`
                                            margin: 0.5rem 0 1rem;
                                            > .block-picker > div:nth-child(2) {
                                                border: 2px solid white;
                                            }
                                        `}
                                    >
                                        <BlockPicker
                                            triangle="hide"
                                            width="100%"
                                            colors={[
                                                "#1abc9c",
                                                "#2ecc71",
                                                "#3498db",
                                                "#9b59b6",
                                                "#000000",
                                                "#16a085",
                                                "#27ae60",
                                                "#2980b9",
                                                "#8e44ad",
                                                "#7f8c8d",
                                                "#f1c40f",
                                                "#e67e22",
                                                "#fafbfe",
                                                "#fea540",
                                                "#95a5a6",
                                            ]}
                                            color={state.form.backgroundColor}
                                            onChange={color =>
                                                setForm(form => ({
                                                    ...form,
                                                    backgroundColor: color.hex,
                                                }))
                                            }
                                        />
                                    </div>
                                    <h6>Font face</h6>
                                    <div className="choose-font">
                                        <select
                                            name="font_face"
                                            className="input"
                                            onChange={({target: {value}}) =>
                                                setForm(form => ({...form, fontFace: value}))
                                            }
                                            value={state.form.fontFace}
                                        >
                                            <option value="Open Sans">Open Sans</option>
                                            <option value="Sans Serif">Sans Serif</option>
                                            <option value="Calibri">Calibri</option>
                                        </select>
                                    </div>
                                    <button
                                        type="button"
                                        className="button button-outline"
                                        style={{width: "100%", marginBottom: "2rem"}}
                                        onClick={reset}
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>

                            <div
                                className={`content-box ${
                                    Object.keys(state.errors).length !== 0 && "error-anim"
                                }`}
                            >
                                <form className="form form-step-1">
                                    <div className="scrollbar">
                                        <div className="form-box-header">
                                            <h6 className="supTitle">EDIT FORM</h6>
                                            <div ref={ref => elements_references["name"] = ref}
                                                className={`form-row ${state.errors.name ? "error" : ""}`}>
                                                <input
                                                    id="form-name"
                                                    type="text"
                                                    placeholder="Form name"
                                                    name="name"
                                                    value={form.name}
                                                    onChange={({target: {value}}) => setFormName(value)}
                                                />
                                                {state.errors.name && (
                                                    <span className="form-row-error-msg">{state.errors.name}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-box-body">
                                            <fieldset>
                                                <div ref={ref => elements_references["category"] = ref}
                                                    className={`form-row ${state.errors.category ? "error" : ""}`}>
                                                    <label htmlFor="form-categ">Category</label>
                                                    <select
                                                        id="form-categ"
                                                        value={form.category}
                                                        onChange={({target: {value}}) => changeCategory(value)}
                                                    >
                                                        <option></option>
                                                        {state.formCategories.map((formCategory, index) => (
                                                            <option value={formCategory._id} key={index}>
                                                                {formCategory.name}
                                                            </option>
                                                        ))}
                                                        {form.category &&
                                                        !state.formCategories.find(
                                                            ({_id}) => _id === form.category
                                                        ) && <option value={form.category}>Deleted</option>}
                                                    </select>
                                                    {state.formCategories.length === 0 && (
                                                        <a
                                                            href="EditForm#"
                                                            onClick={e => {
                                                                e.preventDefault()
                                                                history.push("/categories")
                                                            }}
                                                        >
                                                            Create a category
                                                        </a>
                                                    )}
                                                    {state.errors.category && (
                                                        <span className="form-row-error-msg">{state.errors.category}</span>
                                                    )}
                                                </div>
                                                <div ref={ref => elements_references["description"] = ref}
                                                    className={`form-row ${state.errors.description ? "error" : ""}`}
                                                >
                                                    <label htmlFor="form-description">Form description</label>
                                                    <textarea
                                                        className="scrollbar"
                                                        value={form.description}
                                                        name="description"
                                                        onChange={({target: {value}}) => changeDescription(value)}
                                                    />
                                                    {state.errors.description && (
                                                        <span className="form-row-error-msg">
                                                            {state.errors.description}
                                                        </span>
                                                    )}
                                                </div>

                                                <div className="mBox">
                                                    <h4>Output type</h4>
                                                    <div className="form-row-checkbox">
                                                        {(id => (
                                                            <>
                                                                <input
                                                                    id={id}
                                                                    type="checkbox"
                                                                    onChange={({target: {checked}}) =>
                                                                        changeDocumentOutputType({...form.documentOutputType, pdf: checked})
                                                                    }
                                                                    checked={form.documentOutputType['pdf']}
                                                                />
                                                                <label htmlFor={id}>PDF</label>
                                                            </>
                                                        ))(uuid())}
                                                    </div>
                                                    <div className="form-row-checkbox">
                                                        {(id => (
                                                            <>
                                                                <input
                                                                    id={id}
                                                                    type="checkbox"
                                                                    onChange={({target: {checked}}) =>
                                                                        changeDocumentOutputType({...form.documentOutputType, originalFormat: checked})
                                                                    }
                                                                    checked={form.documentOutputType['originalFormat']}
                                                                />
                                                                <label htmlFor={id}>DOCX / XLSX</label>
                                                            </>
                                                        ))(uuid())}
                                                    </div>
                                                </div>

                                                <h4>Notification recipients</h4>
                                                <div className="notif-recipients-box">
                                                    <div className="form-row-checkbox">
                                                        <input
                                                            id="form-nr-agent"
                                                            type="checkbox"
                                                            checked={form.notificationRecipients.agent}
                                                            onChange={({target: {checked}}) => changeNotifyAgent(checked)}
                                                        />
                                                        <label htmlFor="form-nr-agent">User</label>
                                                    </div>
                                                    <div className="form-row-checkbox">
                                                        <input
                                                            id="form-nr-client"
                                                            type="checkbox"
                                                            onChange={({target: {checked}}) =>
                                                                changeNotifyClient(checked)
                                                            }
                                                            checked={form.notificationRecipients.client}
                                                        />
                                                        <label htmlFor="form-nr-client">Recipient</label>
                                                    </div>
                                                </div>

                                                <div
                                                    className={`mBox notify-others-box ${
                                                        form.notifyOthers.enabled ? "view-hidden-box" : ""
                                                    }`}
                                                >
                                                    <div ref={ref => elements_references["others"] = ref}
                                                        className={`form-row-checkbox ${
                                                            state.errors.others ? "error" : ""
                                                        }`}
                                                    >
                                                        <input
                                                            id="notify-others"
                                                            type="checkbox"
                                                            onChange={({target: {checked}}) =>
                                                                changeNotifyOthers(checked)
                                                            }
                                                            checked={form.notifyOthers.enabled}
                                                        />
                                                        <label htmlFor="notify-others">Others:</label>
                                                        {state.errors.others && (
                                                            <span
                                                                className="form-row-error-msg">{state.errors.others}</span>
                                                        )}
                                                    </div>
                                                    <div className="mBox-hidden">
                                                        <AnotifyOthersList
                                                            value={form.notifyOthers}
                                                            changeValue={callback =>
                                                                setForm(form => ({
                                                                    ...form,
                                                                    notifyOthers: {
                                                                        ...form.notifyOthers,
                                                                        ...callback(state.form.notifyOthers),
                                                                    },
                                                                }))
                                                            }
                                                            error={state.errors.notifyOthers}
                                                        />
                                                    </div>
                                                </div>

                                                <Approvers form={form} setForm={setForm}/>

                                                <Activation 
                                                    form={form} 
                                                    setForm={setForm}
                                                    elements_references={elements_references}
                                                    errors={state.errors}
                                                />

                                                <Availability
                                                    changeValue={onAvailabilityChange}
                                                    publicAvailabilityObject={form.public_availability}
                                                    formId={form._id}
                                                    errors={state.errors}
                                                    elements_references={elements_references}
                                                />

                                                <div className="mBox">
                                                    <h4>Location</h4>
                                                    <div className="form-row-checkbox">
                                                        <input
                                                            id="location"
                                                            type="checkbox"
                                                            onChange={({target: {checked}}) =>
                                                                changeProofOfLocation(checked)
                                                            }
                                                            checked={form.proofOfLocation}
                                                        />
                                                        <label htmlFor="location">Include proof of location</label>
                                                    </div>
                                                </div>
                                                
                                            </fieldset>

                                            <div className="form-box-actions">
                                                <a
                                                    className="button button-outline"
                                                    href="EditForm#"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        saveProperties(() => {
                                                            history.push("/forms")
                                                        })
                                                    }}
                                                >
                                                    <i className="icon-ia-arrow-left"/>
                                                    <span>Back</span>
                                                </a>
                                                <span className="button button-outline" onClick={clearAll}>
                                                    <i className="icon-refresh-cw"/>
                                                    <span>Clear All</span>
                                                </span>
                                                <a
                                                    className="button"
                                                    href="EditForm#"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        saveProperties(() => {
                                                            if (history.location.state && history.location.state.newForm) {
                                                                history.push(`/forms/${params.id}/edit/definition`, {newForm: true})
                                                            } else {
                                                                history.push('/forms')
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <i className="icon-ia-checked-outline"/>
                                                    <span>Save</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    {!state.pageNotFound &&
                        <div className="sidebar sidebar-preview-tablet">
                            <div className="sidebar-title">
                                <h3 className="sidebar-title sidebar-title-fixed">Preview</h3>
                            </div>
                            <div className="pts-top">
                                <div className="swiper-slide">
                                    <div className="tablet-preview">
                                        <img src="img/tablet.svg"/>
                                        {/* <iframe src="../instantAdventureFront/preview/new-form-1.html"></iframe> */}
                                        {!state.loadingForm && state.form && (
                                            <ThemeProvider theme={{
                                                mode: 'dark',
                                                backgroundColor: state.form.backgroundColor,
                                                fontColor: state.form.fontColor,
                                                fontFace: state.form.fontFace,
                                                
                                                defaultLook: {
                                                    fontColor: "#5D2560",
                                                    backgroundColor: "#F1F1F1",
                                                    fontFace: 'Open Sans'
                                                }
                                            }}>
                                                <AtabletPreview>
                                                    <div className="wrapper form-preview-page" style={{padding: "7rem 0 0"}}
                                                        css={`                
                                                        ${look}
                                                        && {
                                                            .wrapper-content {
                                                                background: unset;
                                                            }
                                                            .form-preview  {
                                                                display: grid;
                                                                grid-template-rows: min-content auto min-content;
                                                                header {
                                                                    position: unset;
                                                                    padding: 0;
                                                                }
                                                                footer {
                                                                    position: unset;
                                                                }
                                                                * {pointer-events: none!important;}
                                                            }
                                                            .button:not(.button-outline) {
                                                                background: #2C2457;
                                                            }
                                                            .button:not(.button-link):hover {
                                                                background: #2C2457;
                                                                color: #FFF;
                                                            }
                                                            .content {
                                                                height: 100%;
                                                                min-height: unset;
                                                            }
                                                            .content-preview {
                                                                min-height: unset;
                                                            }
                                                        }
                                                    `}>
                                                        <div className="wrapper-content">
                                                            <div className="content">
                                                                <div className="form-preview">
                                                                    <header>
                                                                        <div className="form-logo">
                                                                            <img src={form.formLogo || formLogoImg}/>
                                                                        </div>
                                                                        <h6 className="sup-title">{form.category && form.category.name}</h6>
                                                                        <h1 className="main-title">{form.name}</h1>
                                                                    </header>

                                                                    <div className="content-preview">
                                                                        <div className="table">
                                                                            <div className="table-cell">
                                                                            <pre style={{whiteSpace: "pre-wrap"}}>
                                                                                {form.description}
                                                                            </pre>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <footer>
                                                                        <a className="button" title="">
                                                                            Start
                                                                        </a>
                                                                    </footer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <header className="header">
                                                            <div className="logo">
                                                                <img className="white-logo"
                                                                    src={formLogoImg}/>
                                                            </div>
                                                        </header>
                                                    </div>
                                                </AtabletPreview>
                                            </ThemeProvider>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
 
                    <NavigationPrompt
                        when={state.unsavedChanges}
                        promptCallback={({onConfirm, onCancel}) => notification.warning({
                            message:
                                <>
                                    <p>Are you sure you want to leave the page? You have unsaved changes.</p>
                                    <span className="button button-outline" onClick={() => {
                                        onConfirm()
                                        notification.cancel()
                                    }}>Leave</span>
                                    <span className="tbl-btn close-subheader" onClick={() => {
                                        onCancel()
                                        notification.cancel()
                                    }}>x</span>
                                </>, duration: 0
                        })}
                    />
                        
                </div>
            </PageNotFownd>
            <Portrait />
        </>
    )
}

const Activation = ({form, setForm, elements_references, errors}) => {

    const onNeedsActivationChange = active => {
        if (
            !form.steps.reduce((acc, step) => [...acc, ...step.elements], [])
                .find(element => element.type === elements.CONTRACT_COMMUNICATION_OPTIONS)
        ) {
            notification.info({
                message: (
                    <>
                        <p>
                            Contract communication options is mandatory for this feature to be
                            activated. Please add it in your form and then activate this.
                        </p>
                        <span className="button button-outline" onClick={() => {
                            notification.cancel()
                        }}>Got it</span>
                    </>
                ),
                duration: 0,
                closeOnPageChange: true
            })
        } else {
            setForm(form => ({...form, activation: {...form.activation, active}}))
        }
    }

    const changeActivationTitle = title => {
        setForm(form => ({
            ...form, 
            activation: {...form.activation, title}
        }))
    }

    const changeActivationDescription = description => {
        setForm(form => ({
            ...form,
            activation: {...form.activation, description},
        }))
    }

    return (
        <div className="mBox">
            <h4>Validation</h4>
            <div className="form-row-checkbox">
                <input
                    id="activation"
                    type="checkbox"
                    onChange={({target: {checked}}) =>
                        onNeedsActivationChange(checked)
                    }
                    checked={!!form.activation.active}
                />
                <label htmlFor="activation">Enable final validation from the recipient</label>
            </div>

            {form.activation.active && (
                <>
                    <div ref={ref => elements_references["activationTitle"] = ref}
                        className={`form-row ${errors.activationTitle && "error"}`}
                    >
                        <label>Activation page title</label>
                        <input
                            type="text"
                            value={form.activation.title}
                            onChange={({target: {value}}) =>
                                changeActivationTitle(value)
                            }
                            placeholder=""
                        />
                        {errors.activationTitle && (
                            <span className="form-row-error-msg">
                                {errors.activationTitle}
                            </span>
                        )}
                    </div>
                    <div
                        ref={ref => elements_references["activationDescription"] = ref}
                        className={`form-row ${
                            errors.activationDescription ? "error" : ""
                        }`}
                    >
                        <label>Activation page content</label>
                        <textarea
                            value={form.activation.description}
                            onChange={({target: {value}}) =>
                                changeActivationDescription(value)
                            }
                        />
                        {errors.activationDescription && (
                            <span className="form-row-error-msg">
                                {errors.activationDescription}
                            </span>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default FormProperties
