import React from "react"
import {css} from "styled-components"

import API from "../../../api"
import Loading from "../../../components/Loading"
import ApopUp from "../../../components/ApopUp"

const Approvers = ({
	form,
	setForm,
}) => {


	const [state, setState] = React.useState({
		users: null,
		modal: false,
		loading: false,
		draftApprovers: [...form.approvers]
	}, 'FormPropertiesApprovers')

	React.useEffect(() => {
		getUsers()
	}, [])

	const getUsers = async () => {
		try {
			setState(state => ({ ...state, loading: true }))
			const {
				data: { data: users },
			} = await API.get("users", {
				params: { all: true, roles: ["admin", "manager"] },
			})
			setState(state => ({ ...state, users, loading: false }))
		} catch (err) {
			setState(state => ({ ...state, loading: false }))
		}
	}


	const onUserToggle = userId => {
		let newApproversList = state.draftApprovers

		if (state.draftApprovers.find(({ user }) => user === userId)) {
			newApproversList = state.draftApprovers.filter(({ user }) => user !== userId)
		} else {
			newApproversList = [...state.draftApprovers, { user: userId, obligatory: true }]
		}
		setDraftApprovers(newApproversList)
	}

	const assignAllToggle = checked => {
		let newApproversList = checked
			? state.users.map(({ _id }) => ({ user: _id, obligatory: true }))
			: []
		setDraftApprovers(newApproversList)
	}

	const onMandatoryToggle = approverIndex => {
		let newApproversList = [...form.approvers]
		newApproversList[approverIndex].obligatory = !newApproversList[approverIndex]
			.obligatory
		setDraftApprovers(newApproversList)
	}

	const changeNotifyAgentAboutApproval = notify =>
		setForm(form => ({
			...form,
			approvalForm: { ...form.approvalForm, notifyAgents: notify },
		}))

	const changeIncludeApprovalForm = include =>
		setForm(form => ({
			...form,
			approvalForm: { ...form.approvalForm, active: include },
		}))
	
	const setApprovers = approvers => setForm(form => ({...form, approvers}))

	/*These are the modal approvers*/
	const setDraftApprovers = draftApprovers => setState(state => ({...state, draftApprovers}))

	const removeApprover = id => {
		setApprovers(form.approvers.filter(({user}) => id !== user))
		setDraftApprovers(form.approvers.filter(({user}) => id !== user))
	}

	const applyChanges = () => {
		setApprovers(state.draftApprovers)
		setState(state => ({ ...state, modal: false }))
	}

	if (state.loading) return <Loading />
	if (!state.users) return null

	return (
		<>
			<div
				className={`mBox approval-box ${
					form.approvers.length > 0 ? "view-hidden-box" : ""
				}`}
			>
				<div className="approval-box-header">
					<h4>Approval</h4>
					<span
						className="button button-outline with-icon-right trigger-overlay-add-approvers"
						onClick={() => setState(state => ({ ...state, modal: true }))}
					>
						<i className="icon-ia-plus"></i>Add approvers
					</span>
				</div>
				<div className="mBox-hidden">
					<div className="form-row autocomplete-tags-box">
						<div className="autocomplete-tags">
							<div className="scrollbar">
								{form.approvers.map(approver => (
									<span className="at-tag" key={approver.user}>
										<i className="icon-ia-close" onClick={() => removeApprover(approver.user)}></i>
										{(() => {
											const approverUser = state.users.find(user => user._id === approver.user)
											if(!!approverUser) {
												const {name, email} = approverUser
												return `${name}/${email}`
											} else {
												return "DeletedUser"
											}
										
									
										})()}
									</span>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="form-row-checkbox include-approval-checkbox">
					<input
						id="approval-form"
						type="checkbox"
						checked={form.approvalForm.active}
						onChange={({ target: { checked } }) => changeIncludeApprovalForm(checked)}
					/>
					<label htmlFor="approval-form">Include an approval form</label>
				</div>

				{form.approvalForm.active && (
					<div className="form-row-checkbox include-approval-checkbox">
						<input
							id="notify-agents-approval-form"
							type="checkbox"
							checked={form.approvalForm.notifyAgents}
							onChange={({ target: { checked } }) =>
								changeNotifyAgentAboutApproval(checked)
							}
						/>
						<label htmlFor="notify-agents-approval-form">
							Notify users when the session is approved
						</label>
					</div>
				)}
			</div>

			<ApopUp
				visible={state.modal}
				title="Select approvers"
				rawCss={css`
					.overlay-content {
						width: 45rem;
					}
					.overlay-body {
						padding: 0;
					}
                    .scrollbar {max-height: 29.5rem;}
				`}
				onCancel={() => setState(state => ({...state, modal: false, draftApprovers: [...form.approvers]}))}
				okText="Add approvers"
				overLayClass="overlay-add-approvers"
				onConfirm={applyChanges}
			>
				<div className="form fixed-label">
					<ul className="send-mail-list send-mail-list-fixed">
						<li>
							<div className="form-row-checkbox">
								<input id="oaa-0" type="checkbox"
									onChange={({target:{checked}}) => assignAllToggle(checked)}
									checked={state.users.reduce(
										(acc, { _id }) =>
											acc &&
											state.draftApprovers.find(({ user: userId }) => userId === _id) !== undefined,
										true
									)}
								/>
								<label htmlFor="oaa-0">
									<span>Add all</span>
								</label>
							</div>
						</li>
					</ul>
				</div>
				<div className="scrollbar">
					<div className="form">
						<ul className="send-mail-list">

							{state.users.map(user => 
								
								<li key={user._id}>
									<div className="form-row-checkbox">
										<input id={`approver-${user._id}`} type="checkbox"
											checked={
												state.draftApprovers.find(({ user: userId }) => userId === user._id) !==
												undefined
											}
											onChange={() => onUserToggle(user._id)}
										/>
										<label htmlFor={`approver-${user._id}`}>
											<span>{user.name}<em>{user.email}</em></span>
										</label>
									</div>
								</li>
							
							)}

						</ul>
					</div>
				</div>
			</ApopUp>
		</>
	)
}

export default Approvers

