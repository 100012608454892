import {css} from 'styled-components'
import Color from 'color';

export default css`


${({theme: {backgroundColor, fontColor, defaultLook}}) => (backgroundColor !== defaultLook.backgroundColor || fontColor !== defaultLook.fontColor) 
        ?   css`

                --primary-color: ${fontColor};
                --background-color: ${backgroundColor};

                .content {
                    background-color: ${backgroundColor} !important;
                    color: ${fontColor};
                }
                
                .button:not(.button-link) {
                    background-color: ${fontColor} !important;
                    color: ${backgroundColor};
                }

                .button.button-link {
                    color: ${fontColor} !important;
                }

                .button:hover {
                    opacity: 1 !important;
                }

                .form-row-radio label:after {
                    color: var(--background-color) !important;
                    background: var(--background-color) !important;
                }

                .form-row-checkbox label:after {
                    color: var(--background-color) !important;
                    background: var(--background-color) !important;
                }
                
                input {
                    color: var(--primary-color) !important;
                    background: var(--background-color) !important;
                }
                
                select {
                    color: var(--primary-color) !important;
                    background: var(--background-color) !important;
                }
                
                textarea {
                    color: var(--primary-color) !important;
                    background: var(--background-color) !important;
                }
                
                .add-block-input.mar2b {
                    color: var(--primary-color) !important;
                    background: var(--background-color) !important;
                }
                
                .add-block-input.mar2b:hover {
                    color: var(--background-color) !important;
                    background: var(--primary-color) !important;
                }
                
                .upload-image-box {
                    background-color: var(--background-color) !important;
                }
                
                .upload-image-box:before, .upload-image-box:after {
                    color: var(--primary-color) !important;
                }
                
                .sigCanvas {
                    background: var(--background-color) !important;
                }
                
                .signaturePadContainerButtons button {
                    color: var(--background-color) !important;
                    background-color: var(--primary-color) !important;
                }

                .signaturePadContainerButtons button:hover {
                    color: var(--primary-color) !important;
                    background-color: var(--background-color) !important;
                }
                
                .file {
                    color: var(--primary-color) !important;
                }

                .offer-row.selected {
                    background: transparent;
                }

                .offer-row .icon-check {
                    color: var(--background-color);
                }

                .image-upload-background {
                    background-color: ${Color(backgroundColor).lighten(.3).rgb().string()};
                }

                .ant-calendar-picker-clear, .ant-time-picker-clear {
                    background: var(--background-color) !important;
                }

                .complete-form-button {
                    color: var(--primary-color) !important;
                    background-color: var(--background-color) !important;
                }
            `
            
        :   ""
    }

    ${({theme: {fontFace}}) => 
        css`
            --font: ${fontFace};
            .button {
                font-family: var(--font) !important;
            }
            
            input {
                font-family: var(--font) !important;
            }
            
            select {
                font-family: var(--font) !important;
            }
            
            textarea {
                font-family: var(--font) !important;
            }
            
            .add-block-input.mar2b {
                font-family: var(--font) !important;
            }
        `
    }
`
