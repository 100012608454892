import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText";

const Time = ({
    element,
    answers_by_code,
    value = ''
}) => {

    return (
        <div className="fieldset-row">
            <div className="session-form-row">
                {element.text &&
                <label>{synchronizeText(element.text, answers_by_code)}</label>
                }

                <span className="input-box">{value}</span>
            </div>
        </div>
    );
};

export default Time;