import React from "react"

import { GoogleMap } from '@react-google-maps/api';
import { useAppContext } from "../../context"

import GoogleMapsLoader from "../../components/GoogleMapsLoader"
import Loading from '../../components/Loading'

const defaultLocation = {
    lat: 45.9237859,
    lng: 22.7775086
}

const MapComponent = () => {
	const {
		company: [company],
	} = useAppContext()


	return (
		<GoogleMapsLoader googleMapsApiKey={company.google_maps_api_key} loader={<Loading />}>
			<GoogleMap
				mapContainerStyle={{
					height: "100%",
					width: "100%",
				}}
				zoom={12}
				center={defaultLocation}
				options={{
					mapTypeControl: false,
                }}
                
			/>
	
		</GoogleMapsLoader>
	)
}

export default ({ element }) => {

	return (
		<div className="fieldset-row">
			<p className="form-box-wrap-title">{element.title}</p>
			<p className="form-box-wrap-description">{element.text}</p>
			<div className="location-box">
				<MapComponent />
				<div className="search-location">
					<input type="text" name="" placeholder="Search location" />
				</div>
			</div>
		</div>
	)
}
