// TODO: remove fucking redux

import React, { useEffect, useState } from "react"
import {useHistory} from 'react-router-dom'

import ApopUp from "../../../components/ApopUp"
import Ipagination from '../../../components/Ipagination'

import { useRedux } from "../../../utils/useRedux"

import { initialState, reducer } from "./CategoriesData.reducer"
import {
	getCategories,
	storeCategory,
	updateCategory,
	deleteCategory,
	getCategoryToDeleteInformation,
	setPage,
	setSort,
	setSearch,
} from "./CategoriesData.actions"
import Loading from "../../../components/Loading"
import AdeleteConfirm from "../../../components/AdeleteConfirm"
import debounce from "lodash/debounce"

const getCategoriesAfterSearch = debounce(
	(dispatch, state, value) =>
		getCategories(dispatch, {
			...state,
			search: value,
			page: 1,
		}),
	1000
)

const CategoriesData = ({categoriesStore:[, setCategoriesState]}) => {

	const history = useHistory()
	const page = new URLSearchParams(history.location.search).get('page')
	
	const [showModal, setShowModal] = useState(false)
	const [name, setName] = useState("")
	const [categoryEdited, setCategoryEdited] = useState(null)
	const [editModal, setEditModal] = useState(false)
	const [editName, setEditName] = useState("")
	const [state, dispatch] = useRedux(reducer, {
		...initialState,
		page: page || 1
	})

	const [error, setError] = useState(null)

	const validate = () => {
		setError(null)
		if (state.data.find(category => category.name === name) !== undefined) {
			setError("You already added a category with this name")
			return false
		}
		return true
	}

	useEffect(() => {
		getCategories(dispatch, state, () => {
			setCategoriesState(categoriesState => ({...categoriesState, loadedCategoriesOnce: true}))
		})
	}, [])

	const removeCategory = id => async () => {
		await deleteCategory(dispatch, id)
		await getCategories(dispatch, state)
	}

	const createCategory = () => {
		if (validate()) {
			storeCategory(dispatch, name, () => {
				getCategories(dispatch, state)
				setName("")
				setShowModal(false)
			})
		}
	}

	const openEditModal = id => () => {
		setEditModal(true)
		setCategoryEdited(id)
		setEditName(state.data.find(category => category._id === id).name)
	}

	const editCategory = () => {
		updateCategory(dispatch, categoryEdited, { name: editName }, () => {
			setEditModal(false)
			setCategoryEdited(null)
			setEditName("")
			getCategories(dispatch, state)
		})
	}

	const changePage = page => {
		history.push({
            pathname: history.location.pathname,
            search: `?page=${page}`
        })
		setPage(dispatch, page)
		getCategories(dispatch, {
			...state,
			page,
		})
	}

	const setColumnSorting = (sort, order) => () => {
		setSort(dispatch, { sort, order })

		getCategories(dispatch, {
			...state,
			page: 1,
			sort,
			order,
		})
	}

	const changeSearch = e => {
		setSearch(dispatch, e.target.value)
		setPage(dispatch, 1)

		getCategoriesAfterSearch(dispatch, state, e.target.value)
	}

	const renderSortIcon = column => {
		if (column !== state.sort)
			return (
				<span className="cntrl-box">
					<i
						className="cntrl-down icon-arrow-down"
						onClick={setColumnSorting(column, "desc")}
					/>
					<i
						className="cntrl-up icon-arrow-up"
						onClick={setColumnSorting(column, "asc")}
					/>
				</span>
			)

		if (state.order === "desc")
			return (
				<span className="cntrl-box">
					<i
						className="cntrl-down icon-arrow-down selected"
						onClick={setColumnSorting(null, null)}
					/>
					<i
						className="cntrl-up icon-arrow-up"
						onClick={setColumnSorting(column, "asc")}
					/>
				</span>
			)

		return (
			<span className="cntrl-box">
				<i
					className="cntrl-down icon-arrow-down"
					onClick={setColumnSorting(column, "desc")}
				/>
				<i
					className="cntrl-up icon-arrow-up selected"
					onClick={setColumnSorting(null, null)}
				/>
			</span>
		)
	}

	return (
		<div className="content-box">
			<div className="content-box-header">
				<h3>Categories</h3>
				<div className="content-box-options">
					<div className="content-box-header-search">
						<div className="content-search">
							<fieldset>
								<input
									type="text"
									value={state.search}
									onChange={changeSearch}
									placeholder="Search"
								/>
								<button className="tbl-btn" />
							</fieldset>
						</div>
					</div>
				</div>
			</div>
			<div className="content-box-body content-box-body-fixed-elements">
				{/*<div className="swiper-slide">*/}
				<div className="box-body box-body-fixed">
					<div className="table-fixed-header">
						<ul className="table-ul categories-list">
							<li className="table-ul-header">
								<ul>
									<li className="with-cntrl-box">
										<strong>Name</strong>
										{renderSortIcon("name")}
									</li>
									<li>
										<strong />
									</li>
									<li>
										<strong />
									</li>
								</ul>
							</li>
						</ul>
					</div>

					<div className="scrollbar">
						{state.loading && <Loading />}
						{!state.loading && state.data.length === 0 && (
							<div className="empty-list">This list is empty</div>
						)}
						{!state.loading && state.data.length > 0 && (
							<ul className="table-ul category-list">
								{state.data.length === 0 && (
									<div className="empty-list">This list is empty</div>
								)}
								{state.data.map((category, index) => (
									<li key={category._id}>
										<ul>
											<li>
												<strong>{category.name}</strong>
											</li>
											<li>
												<div className="table-actions">
													<AdeleteConfirm
														title={
															<>
																Are you sure you want to delete this category?
																{state.deletingCategoryForms > 0 && (
																	<>
																		<br />
																		{state.deletingCategoryForms} forms will be automatically
																		deleted.
																	</>
																)}
															</>
														}
														onConfirm={removeCategory(category._id)}
														overlayClassName="tooltip-overlay"
														okText="Delete"
														cancelText="Cancel"
														onOpen={() =>
															getCategoryToDeleteInformation(dispatch, category._id)
														}
													>
														<div
															className="tbl-btn trigger-overlay-delete-category"
															title="Delete Category"
														>
															<i className="icon-ia-trash-bold" />
															<div
																className={`tooltip ${
																	index === 0 ? "tooltip-bottom" : "tooltip-top"
																}`}
															>
																Delete
															</div>
														</div>
													</AdeleteConfirm>
													<div
														className="tbl-btn trigger-overlay-edit-category"
														onClick={openEditModal(category._id)}
													>
														<i className="icon-ia-edit-bold" />
														<div
															className={`tooltip ${
																index === 0 ? "tooltip-bottom" : "tooltip-top"
															}`}
														>
															Change Category Information
														</div>
													</div>
												</div>
											</li>
										</ul>
									</li>
								))}
							</ul>
						)}
					</div>
				</div>

				<div className="box-action table-action-fixed with-pagination">
					<span className="empty" />
                        <Ipagination
                            page={state.page}
                            pages={state.pages}
                            onChange={changePage}
                        />
	
					<div
						className="button with-icon-right trigger-overlay-add-category"
						onClick={() => setShowModal(true)}
					>
						Create Category <i className="icon-plus" />
					</div>
				</div>

				<ApopUp
					title="Create Category"
					visible={showModal}
					onCancel={() => setShowModal(false)}
					disableHeader
					disableFooter
					overLayClass="overlay-template overlay-add-category"
				>
					<div className="form">
						<div className="overlay-icon">
							<img src="img/add-category.svg" alt="" />
						</div>
						<h3>Create category</h3>
						<div className={`form-row ${error ? "error" : ""}`}>
							<input
								type="text"
								name=""
								placeholder="Category name"
								value={name}
								maxLength={40}
								onChange={e => setName(e.target.value)}
							/>
							{error && <span className="form-row-error-msg">{error}</span>}
						</div>
						<div className="overlay-actions">
							<button
								type="button"
								onClick={createCategory}
								className="button with-icon-right close-overlay"
							>
								<i className="icon-ia-checked-outline"></i>Save
							</button>
							<button
								type="button"
								onClick={() => setShowModal(false)}
								className="button button-link close-overlay"
							>
								Cancel
							</button>
						</div>
					</div>
				</ApopUp>

				<ApopUp
					disableFooter
					disableHeader
					visible={editModal}
					onCancel={() => setEditModal(false)}
					overLayClass="overlay-template overlay-edit-category"
				>
					<div className="form">
						<div className="overlay-icon">
							<img src="img/edit-category.svg" alt="" />
						</div>
						<h3>Change category name</h3>
						<div className="form-row">
							<input
								type="text"
								value="Raportari"
								placeholder="Category name"
								value={editName}
								maxLength={40}
								onChange={e => setEditName(e.target.value)}
							/>
						</div>
						<div className="overlay-actions">
							<button type="button" onClick={editCategory} className="button with-icon-right close-overlay">
								<i className="icon-ia-checked-outline"></i>Save
							</button>
							<button type="button" onClick={() => setEditModal(false)} className="button button-link close-overlay">Cancel</button>
						</div>
					</div>
				</ApopUp>
			</div>
		</div>
	)
}

export default CategoriesData
