import React from 'react'
import ApopUp from './ApopUp'

const AdeleteConfirm = ({
    children,
    onConfirm = f=>f,
    okText="Delete",
    title=null
}) => {

    const [open, setOpen] = React.useState(false)

    return(
        <>
            {React.cloneElement(children, { 
                onClick: e => {
                    e.preventDefault()
                    setOpen(true)
                }
            })}
            <ApopUp
                visible={open}
                disableFooter
                disableHeader
                onCancel={() => setOpen(false)}
                overLayClass="overlay-template"
            >
                <form className="form" onSubmit={e => e.preventDefault()}>
                    <div className="overlay-icon">
                        <img src="img/delete-form.svg" alt="" />
                    </div>
                    <h3>{title}</h3>
                    <div className="overlay-actions">
                        <button type="button" onClick={() => {
                            onConfirm()
                            setOpen(false)
                        }} className="button with-icon-right close-overlay"><i className="icon-ia-trash"></i>{okText}</button>
                        <button type="button" onClick={() => setOpen(false)} className="button button-link close-overlay">Cancel</button>
                    </div>
                </form>
            </ApopUp>
        </>
    )
}

export default AdeleteConfirm