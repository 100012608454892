import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {useAppContext} from '../context'
import Lnotification from './Lnotification'

const Navbar = () => {

    const {auth:[, setAuth]} = useAppContext()
    const history = useHistory()
    const location = useLocation()

    return (
        <>
            <header className="header">
                <div className="logo" onClick={() => history.push('/')}>
                    <img className="white-logo" src="img/Alchemist_Identity_Final-08.png" alt="" />
                </div>
                <nav className="menu">
                    <a 
                        title=""
                        href="#"
                        className={location.pathname === '/' ? 'selected' : ''} 
                        onClick={e => {
                            e.preventDefault();
                            history.push('/')
                        }}
                    ><strong><span>Dashboard</span></strong></a>
                    <a 
                        title=""
                        href="#"
                        className={location.pathname === '/forms' ? 'selected' : ''} 
                        onClick={e => {
                            e.preventDefault();
                            history.push('/forms')
                        }}
                    ><strong><span>Forms</span></strong></a>
                    <a 
                        title=""
                        href="#"
                        className={location.pathname === '/sessions' ? 'selected' : ''} 
                        onClick={e => {
                            e.preventDefault();
                            history.push('/sessions')
                        }}
                    ><strong><span>Sessions</span></strong></a>
                    <a 
                        title=""
                        href="#"
                        className={location.pathname === '/users' ? 'selected' : ''} 
                        onClick={e => {
                            e.preventDefault();
                            history.push('/users')
                        }}
                    ><strong><span>Users</span></strong></a>
                    {/* <a 
                        title=""
                        href="#"
                        className={location.pathname === '/clients' ? 'selected' : ''} 
                        onClick={e => {
                            e.preventDefault();
                            history.push('/clients')
                        }}
                    ><strong><span>Clients</span></strong></a> */}
                    <a 
                        title=""
                        href="#"
                        className={location.pathname === '/categories' ? 'selected' : ''} 
                        onClick={e => {
                            e.preventDefault();
                            history.push('/categories')
                        }}
                    ><strong><span>Categories</span></strong></a>
                </nav>

                <div className="menu-right">
                    <a
                        title=""
                        href="#"
                        className={`settings ${location.pathname === '/settings' ? 'selected' : ''}`} 
                        onClick={e => {
                            e.preventDefault();
                            history.push('/settings')
                        }} 
                    ><div className="tooltip tooltip-bottom">Settings</div></a>
                    <a
                        title=""
                        href="#"
                        className={`user ${location.pathname === '/profile' ? 'selected' : ''}`} 
                        onClick={e => {
                            e.preventDefault();
                            history.push('/profile')
                        }} 
                    ><div className="tooltip tooltip-bottom">Profile</div></a>
                    <a 
                        title=""
                        href="#"
                        className="logout" 
                        onClick={e => {
                            e.preventDefault();
                            setAuth(null)
                        }} 
                    ><div className="tooltip tooltip-bottom">Logout</div></a>
                </div>
            </header>
            <Lnotification />
        </>
    )
};

export default Navbar