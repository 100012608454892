import React from "react"
import ReactDOM from "react-dom"

const ApopUp = ({
	children,
	title = "",
	visible = false,
	footer = null,
	disableFooter = false,
	disableHeader = false,
	disableCancel = false,
	disableMask = false,
	onCancel = f => f,
	okText = "Confirm",
	cancelText = "Cancel",
	onConfirm = f => f,
	rawCss = ``,
	overLayClass = "",
	className=""
}) => {
	return (
		<>
			{ReactDOM.createPortal(
				<>
					{visible && (
						<div
							className={className}
							css={`
								&& {
									.overlay {
										animation: overlay-anim 0.5s ease forwards;
									}

									.overlay-content {
										opacity: 1;
										box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
										transform: translate3d(-50%, -50%, 0) scale(1);
									}

									.button.with-icon-right {
										min-width: 11rem;
										width: unset;
									}
								}
								${rawCss}
							`}
						>
							<div
								className={`overlay ${overLayClass}`}
								onClick={() => {
									if (!disableMask) {
										onCancel()
									}
								}}
							>
								<div
									className={`overlay-content ${!disableHeader ? "with-header" : ""} ${
										!disableFooter ? "with-footer" : ""
									}`}
									onClick={e => e.stopPropagation()}
								>
									{!disableHeader && (
										<div className="overlay-header">
											<h3>{title}</h3>
										</div>
									)}
									<div className="overlay-body">{children}</div>
									{!disableFooter && (
										<div className="overlay-footer overlay-actions">
											{footer === null ? (
												<>
													<div
														className="button with-icon-right close-overlay"
														onClick={onConfirm}
													>
														<i className="icon-ia-checked-outline" />
														{okText}
													</div>
													{!disableCancel && (
														<div
															className="button button-link close-overlay"
															onClick={onCancel}
														>
															{cancelText}
														</div>
													)}
												</>
											) : (
												footer
											)}
										</div>
									)}
									<i className="icon-x close-button-overlay" onClick={onCancel} />
								</div>
							</div>
						</div>
					)}
				</>,
				document.querySelector("#modal")
			)}
		</>
	)
}

export {ApopUp as default}
