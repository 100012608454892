import React from 'react'
import {isEmail} from 'validator'
import styled from 'styled-components'

const LemailList = ({
    value={
        emails: [],
        email: ''
    },
    changeValue=f=>f,
    placeholder="Add email addresses separated with a comma, semicolon, space or enter.",
    className='',
    label="",
    validateAsync,
}) => {



    const [state, setState] = React.useState({
        invalid: false
    })

    React.useEffect(() => {
        setState(state => {
            return ({
                ...state,
                invalid: (
                    (value.email.trim().length !== 0 && !isEmail(value.email)) ||
                    value.emails.includes(value.email)
                )
            });
        })
    }, [value.email])
    const {error} = value;

    const onAddEmail = async () => {
        changeValue(value => ({...value, error: null}))
        if(!isEmail(value.email)) {
            return
        }

        if(value.emails.includes(value.email)) {
            changeValue(value => ({...value, error: `${value.email} already on the list!`}))
            return
        }


        if(validateAsync) {
            const valid = await validateAsync(value.email)
            if(!valid) {
                setState(state => ({...state, invalid: true}))
                return
            }
        }

        changeValue(value => ({...value, emails: [...value.emails, value.email], email: ''}))
    }

    const removeEmail = emailIndex => {
        changeValue(value => ({
            ...value,
            emails: value.emails.filter((_, i) => emailIndex !== i)
        }))
    }

    return(
        <div className={className}>
            <div className={`form-row autocomplete-tags-box ${(error || state.invalid) ? "error" : ""}`}>
                <label>{label}</label>
                <div className="autocomplete-tags">
                    <div className="scrollbar">
                        {value.emails.map((email, i) => (
                            <span className="at-tag" key={email} onDoubleClick={() => {
                                changeValue(value => ({
                                    ...value,
                                    email,
                                    emails: value.emails.filter(email => email !== email)
                                }))
                            }}>
                            <i className="icon-ia-close" onClick={() => removeEmail(i)}/>
                                {email}
                        </span>
                        ))}
                        <input
                            required
                            className="at-input"
                            type="email"
                            value={value.email}
                            onChange={({target:{value}}) => changeValue(v => ({...v, email: value, error: null}))}
                            placeholder={placeholder}
                            onKeyDown={e => {
                                if(e.keyCode === 13 || e.keyCode === 188 || e.keyCode === 32 || e.keyCode === 186) {
                                    e.preventDefault()
                                    onAddEmail()
                                }
                            }}
                        />
                    </div>
                </div>
                {error && <span className="form-row-error-msg">{error}</span>}
            </div>
        </div>
    )
}

const LemailListStyled = styled(LemailList)`
  .autocomplete-tags .at-input {
    width: 100% !important;
  }
`

export {LemailListStyled as LemailList}