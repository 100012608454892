import React from 'react'
export default ({links = [], children, loader=null}) => {
    const [state, setState] = React.useState(links.map(() => false))

    const onLoad = index => setState(state => state.map((s, i) => i !== index ? s : true))

    const onError = (index, err) => {
        console.error(err)
        setState(state => state.map((s, i) => i !== index ? s : true))
    }

    return (
        <>
            {
                links.map((link, index) => 
                    <link 
                        type="text/css" href={link} rel="stylesheet" 
                        onLoad={() => onLoad(index)} 
                        key={index}
                        onError={(err) => onError(index, err)}
                    />
                )
            }
            {state.reduce((acc, val) => val && acc,true) ? children : loader}
        </>
    )
}