import React from 'react'
import AdeleteConfirm from '../../components/AdeleteConfirm';
import CodeCopy from '../../components/Editor.fragmentCodeCopy'
import {TopBarContainer} from "../comonComponents/ElementEditor.TopBarContainer";

export const PrivacyAgreementEditor = ({
    element,
    remove,
    editElement,
    changeElementEditorMode,
    dragHandleProps = {},
    VisibilityAffected,
    formElements,
    addElementOnPosition,
    index
}) => {

    const [state, setState] = React.useState({
        localElement: {
            ...element
        },
        errors: {}
    })

    const localElement = state.localElement

    const setLocalElement = callback => setState(state => ({
        ...state,
        localElement: {...callback(state.localElement)}
    }))

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        const errors = {};
        if (localElement.text.length > 600) {
            errors.text = "Maximum 600 characters allowed"
        }
        if (localElement.title.length > 60) {
            errors.title = "Maximum 60 characters allowed"
        }
        setState(state => ({
            ...state,
            errors
        }))
        if (Object.keys(errors).length === 0) {
            editElement({
                ...state.localElement
            })
            changeElementEditorMode("DEFAULT")
        }
    };

    const onTitleChange = ({target: {value}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                title: value
            }
        ))
    };

    const onPinnedChange = ({target: {checked}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                pinned: {
                    status: checked
                }
            }
        ))
    };

    const onDescriptionChange = ({target: {value}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                text: value
            }
        ))
    };

    const duplicateComponent = () => {
        addElementOnPosition(index + 1, {
            ...element,
            editorMode: 'DEFAULT'
        })
    }

    const openEditMode = () => {
        changeElementEditorMode("EDIT")
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center">
                <div className="form-box-wrap-center-content" style={{cursor: "pointer"}} onClick={openEditMode}>
                    <TopBarContainer>
                        <VisibilityAffected element={element} formElements={formElements}/>
                    </TopBarContainer>
                    <h6 className="supTitle">Privacy / GDPR Agreement</h6>
                    <p className="form-box-wrap-descrition">{localElement.title}</p>
                    <p className="form-box-wrap-descrition">{localElement.text}</p>
                    <div className="form-grid-row">
                        <div className="form-row">
                            <label>Name</label>
                            <CodeCopy code={`${localElement.code}_first_name`}>
                                <input type="text"/>
                            </CodeCopy>
                        </div>
                        <div className="form-row">
                            <label>Surname</label>
                            <CodeCopy code={`${localElement.code}_last_name`}>
                                <input type="text"/>
                            </CodeCopy>
                        </div>
                    </div>
                    <div className="signature-pad"/>
                    <p className="form-row-validation-text">{localElement.validations.map(v => `[ ${v.validation} ]  `)}{localElement.pinned && localElement.pinned.status ? "[ show on top ]" : ""}</p>
                    {/* <span class="form-box-wrap-id">{element.code}</span> */}
                </div>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <AdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </AdeleteConfirm>
                        <span onClick={duplicateComponent}><i className="icon-copy"/>Duplicate</span>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className={`form-row ${state.errors.title ? "error" : ""}`}>
                    <input type="text" name="" placeholder="Privacy / GDPR Agreement Title"
                           value={localElement.title} onChange={onTitleChange}/>
                    {state.errors.title &&
                    <span className="form-row-error-msg">{state.errors.title}</span>
                    }
                </div>
                <div className={`form-row ${state.errors.text ? "error" : ""}`}>
                    <input type="text" name="" placeholder="Privacy / GDPR Agreement Text"
                           value={localElement.text} onChange={onDescriptionChange}/>
                    {state.errors.text &&
                    <span className="form-row-error-msg">{state.errors.text}</span>
                    }
                </div>
                <div className="frc-box">
                    <div className="form-row-checkbox">
                        <input id={`pinned-${localElement.code}`} type="checkbox"
                               checked={localElement.pinned && localElement.pinned.status} onChange={onPinnedChange}/>
                        <label htmlFor={`pinned-${localElement.code}`}>Show on top in saved session</label>
                    </div>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}