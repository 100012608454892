import React from 'react'
import jwt_decode from 'jwt-decode'
import {useHistory} from 'react-router-dom'
import axios from 'axios'

import Loading from '../../components/Loading'
import {useAppContext} from '../../context'

const LoginComponent = props => {

    const [state, setState] = React.useState({
        loading: false,
        errors: {},
        username: '',
        password: ''
    }, "LoginForm")

    const {auth: [auth, setAuth]} = useAppContext()

    const history = useHistory();

    React.useEffect(() => {
        if (auth)
            if (history.location.pathname)
                history.push("/")
    }, [auth])


    const login = async () => {
        try {
            const errors = {}
            setState(state => ({...state, errors, loading: true}))
            if (state.username === '') errors.username = "Invalid username"
            if (state.password === '') errors.password = "Invalid password"

            if (Object.keys(errors).length > 0) {
                setTimeout(() => setState(state => ({...state, errors, loading: false})), 10);
                return
            }

            const {data} = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/token`, {
                grant_type: 'password',
                username: state.username,
                password: state.password
            }, {
                auth: {username: process.env.REACT_APP_API_KEY, password: process.env.REACT_APP_API_SECRET}
            })

            const {access_token, refresh_token} = data;
            const expirationTime = Date.now() + data.expires_in * 1000;
            const refreshExpirationTime = Date.now() + data.refresh_token_expires_in * 1000;
            const decodedToken = jwt_decode(access_token);
            // setState(state => ({...state, loading: false}))
            setAuth({
                access_token,
                expirationTime,
                refreshExpirationTime,
                refresh_token,
                decodedToken
            })
        } catch (err) {
            const {error} = (err.response && err.response.data) || {response: {data: {}}};
            setState(state => ({
                ...state, loading: false, errors: {
                    username: true,
                    password: true,
                    api: {
                        incorrect_credentials: 'Wrong username or password',
                        access_denied: 'Access Denied',
                        invalid_request: 'Username and Password are required'
                    }[error] || 'Something went wrong'
                }
            }))

        }
    }

    return (
        <div className="login-box">
            <div className="login-tabs-content">
                <div className="login-tab-content">
                    <div className="scrollbar">
                        <div className="table">
                            <div className="table-cell">
                                <form
                                    className={`form login-form ${Object.keys(state.errors).length > 0 ? 'error-anim' : ''}`}
                                    onSubmit={async e => {
                                        e.preventDefault()
                                        await login()
                                    }}
                                >
                                    <h3>Login into your Alchemist account</h3>

                                    {/* <!--<div className="form-row">
                                        <button className="button button-outline with-icon-left"><i className="icon-google-round"></i>Use your Google Account</button>
                                    </div>
                                    <span className="or">- or -</span>--> */}
                                    {state.loading && <Loading/>}
                                    <div className={`form-row ${state.errors.username ? 'error' : ''}`}>
                                        <input
                                            type="text"
                                            placeholder="Email address"
                                            value={state.username}
                                            onChange={({target: {value: username}}) => setState(state => ({
                                                ...state,
                                                username
                                            }))}
                                        />
                                    </div>
                                    <div className={`form-row ${state.errors.password ? 'error' : ''}`}>
                                        <input
                                            type="password"
                                            placeholder="Password"
                                            value={state.password}
                                            onChange={({target: {value: password}}) => setState(state => ({
                                                ...state,
                                                password
                                            }))}
                                        />
                                    </div>
                                    <p className="forgot-pass-link"><i className="icon-lock"/>
                                        <a
                                            href="#"
                                            onClick={e => {
                                                e.preventDefault();
                                                props.onForgotPassword()
                                            }}
                                        >Forgot password</a>
                                    </p>
                                    {(state.errors.username || state.errors.password) && !state.errors.api &&
                                    <div className="error-msg">Invalid username or password</div>
                                    }
                                    {(state.errors.api) &&
                                    <div className="error-msg">{state.errors.api}</div>
                                    }
                                    <div className="form-row last">
                                        <button
                                            className="button with-icon-right"
                                            onClick={e => {
                                                e.preventDefault()
                                                login()
                                            }}
                                        ><i className="icon-ia-arrow-right"/>Login
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginComponent