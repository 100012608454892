import React from "react"

import Navbar from "../../components/Navbar"
import {UsersData} from "./UsersData/UsersData"
import UsersStats from "./UsersStats/UsersStats"
import Portrait from "../../components/Portrait"
import Loading from "../../components/Loading"

const Users = () => {
	const [state, setState] = React.useState({
        filtersVisibility: false,
        
		usersLoadedOnce: false,
		statsLoadedOnce: false,
	})

	const toggleFilters = () =>
		setState(state => ({ ...state, filtersVisibility: !state.filtersVisibility }))
	return (
		<>
			<Navbar />
			{(!state.usersLoadedOnce || !state.statsLoadedOnce) && <Loading />}
			<div
				className={`wrapper ${
					state.filtersVisibility ? "view-filters" : ""
				} agents-page`}
				css={`
					display: ${!state.usersLoadedOnce || !state.statsLoadedOnce
						? "none"
						: "visible"};
				`}
			>
				<UsersData toggleFilters={toggleFilters} usersStore={[state, setState]} />
				<UsersStats usersStore={[state, setState]} />
			</div>
			<Portrait />
		</>
	)
}

export default Users
