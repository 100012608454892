import React from 'react'
import {withRouter} from 'react-router-dom'

import API from '../../api'
import Navbar from '../../components/Navbar'
import {isEmail} from "validator";
import Portrait from "../../components/Portrait"
import {phoneValidator, addPlusPrefix} from "../../utils/phoneValidator"
import {getError} from "../../utils/getError"
import {useAppContext} from "../../context";
import AdeleteConfirm from "../../components/AdeleteConfirm";


const CreateUser = (props) => {
    const {auth: [auth]} = useAppContext();
    const auth_role = auth.decodedToken.scope[0];

    const [state, setState] = React.useState({
        success: false,
        errors: [],
        username: '',
        name: '',
        email: '',
        phone: '',
        role: 'agent',
        department: "",
        departments: []
    }, 'new-user');
    React.useEffect(() => {
        getCompanyDepartments()
    }, []);

    const getCompanyDepartments = async () => {
        try {
            setState(state => ({...state, loading: true}));
            const {data} = await API.get(`company/departments`);
            const {departments} = data;


            setState(state => ({
                ...state,
                loading: false,
                departments
            }))
        } catch (err) {
            setState(state => ({...state, loading: false, error: {code: err.response.status}}))
        }
    }

    const setName = ({target: {value: name}}) => setState(state => ({...state, name}));
    const setEmail = ({target: {value: email}}) => setState(state => ({...state, email}));
    const setUsername = ({target: {value: username}}) => setState(state => ({...state, username}));
    const setRole = ({target: {value: role}}) => setState(state => ({...state, role}));
    const setPhone = phone => {
        setState(state => ({...state, phone: addPlusPrefix(phone)}));
    }

    const createUser = async (e) => {
        e.preventDefault();
        const errors = [];

        if (state.name === '') errors.push('name_required');
        if (state.username === '') errors.push('username_required');
        if (state.email === '') errors.push('email_required');
        else if (!isEmail(state.email)) errors.push('email_invalid');

        if (state.role === 'agent') {
            if (state.phone === '') {
                errors.push('phone_required');
            }
        }

        if (state.phone !== '' && phoneValidator(state.phone) === false) errors.push('phone_invalid');

        if (errors.length !== 0) {
            setState(state => ({...state, errors}));
            return
        }

        try {
            setState(state => ({...state, loading: true, success: false, errors: []}));
            const {name, username, email, password, role, phone, department} = state;
            await API.post('/users', {
                name,
                username,
                email,
                password,
                role,
                phone, ...(department ? {department} : {})
            });
            props.history.push('/users')
        } catch (err) {
            setState(state => ({...state, errors: getError(err.response)}))
        }
    };


    const errorsContain = (...args) => {
        for (let i = 0; i < args.length; i++) {
            if (state.errors.includes(args[i]))
                return true;
        }
        return false
    };

    return (
        <>
            <div className="wrapper client-page">

                <Navbar/>

                <div className="content">

                    {state.success &&
                    <>
                        <div className="succes-msg">User Created</div>
                        <br/>
                    </>
                    }

                    <form className="content-box form agent-form">
                        <div className="scrollbar">
                            <div className="form-box-body">
                                <h2>Create User</h2>
                                <fieldset>
                                    <div className="form-grid-row">
                                        <div className={`form-row ${errorsContain('name_required') ? 'error' : ''}`}>
                                            <label>Name and Surname</label>
                                            <input type="text" name="name" maxLength={40} value={state.name}
                                                   onChange={setName}/>
                                            {errorsContain('name_required') &&
                                            <span className="form-row-error-msg">Name required</span>
                                            }
                                        </div>
                                        <div
                                            className={`form-row ${errorsContain('username_required', 'username_taken') ? 'error' : ''}`}>
                                            <label>Username</label>
                                            <input type="text" value={state.username} maxLength={50}
                                                   onChange={setUsername}/>
                                            {errorsContain('username_required') &&
                                            <span className="form-row-error-msg">Username required</span>
                                            }
                                            {errorsContain('username_taken') &&
                                            <span className="form-row-error-msg">Username taken</span>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-grid-row">
                                        <div
                                            className={`form-row ${errorsContain('email_required', 'email_invalid', 'email_taken') ? 'error' : ''}`}>
                                            <label>Email</label>
                                            <input type="text" value={state.email} maxLength={255} onChange={setEmail}/>
                                            {errorsContain('email_required') &&
                                            <span className="form-row-error-msg">Email required</span>
                                            }
                                            {errorsContain('email_invalid') &&
                                            <span className="form-row-error-msg">The email must be a valid email</span>
                                            }
                                            {errorsContain('email_taken') &&
                                            <span
                                                className="form-row-error-msg">This email is already used by another user.</span>
                                            }
                                        </div>
                                        <div
                                            className={`form-row ${errorsContain('phone_required', 'phone_invalid') ? 'error' : ''}`}>
                                            <label>Phone</label>
                                            <input type="text" name="name" maxLength={20} value={state.phone}
                                                   onChange={({target:{value}}) => setPhone(value)}/>
                                            {errorsContain('phone_required') &&
                                            <span className="form-row-error-msg">Phone required</span>
                                            }

                                            {errorsContain('phone_invalid') &&
                                            <span className="form-row-error-msg">Invalid phone number</span>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-grid-row">
                                        <div className="form-row">
                                            <label>Department</label>
                                            <select value={state.department} onChange={e => {
                                                const departmentDropdown = e.target;
                                                return setState(state => ({
                                                    ...state,
                                                    department: departmentDropdown.value
                                                }))
                                            }}>>
                                                <option value=""/>
                                                {state.departments.map(department => (
                                                    <option key={department}
                                                            value={department}>{department}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-row">
                                            <label>Role</label>
                                            <select value={state.role} onChange={setRole}>
                                                <option value="agent">User</option>

                                                {auth_role === 'admin' &&
                                                <option value="manager">Manager</option>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="form-box-actions">

                                    <AdeleteConfirm
                                        title="Are you sure you want to reset the data?"
                                        onConfirm={() => {
                                            return setState(state => ({
                                                ...state,
                                                username: '',
                                                name: '',
                                                email: '',
                                                phone: '',
                                                role: 'agent',
                                                department: "",
                                                errors: []
                                            }))
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <button className="button button-outline" type="button"><i
                                            className="icon-refresh-cw"/><span> Discard</span></button>
                                    </AdeleteConfirm>
                                    <button className="button" onClick={createUser}><i
                                        className="icon-ia-checked-outline"/><span>Save</span></button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

            <Portrait/>
        </>
    )
};

export default (withRouter(CreateUser))