import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText"

export default ({
                    element,
                    answers_by_code
                }) => {

    return (
        <h1 className="main-title">{synchronizeText(element.text, answers_by_code)}</h1>
    )
}