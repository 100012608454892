import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({ 
    element
}) => {
    return (
        <div className="fieldset-row">
            <div className="form-row">
                <CodeCopy text={`${element.code}`}>
                    <label>{element.text}</label>
                    <select>
                        <option/>
                    </select>
                </CodeCopy>
            </div>
        </div>
    )
}
