import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({
    element
}) => {
    return(
        <div className="fieldset-row">
            <p>{element.title}</p>
            <p>{element.text}</p>
            <div className="form-grid-row">
                <div className="form-row">
                    <label>Name</label>
                    <CodeCopy text={`${element.code}_first_name`}>
                        <input type="text" value="" onChange={f=>f}/>
                    </CodeCopy>
                </div>
                <div className="form-row">
                    <label>Surname</label>
                    <CodeCopy text={`${element.code}_last_name`}>
                        <input type="text" value="" onChange={f=>f} />
                    </CodeCopy>
                </div>
            </div>
            <CodeCopy text={`%${element.code}_signature`}>
                <div className="signature-pad"></div>
            </CodeCopy>
        </div>
    )
}