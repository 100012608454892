import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText";

const RegistrationNumber = ({
                         element,
                         answers_by_code,
                         value = ''
                     }) => {
    return (
        <div className="fieldset-row">
            <div className="form-row">
                {element.text &&
                <label>{synchronizeText(element.text, answers_by_code)}</label>
                }
                <span className="input-box">{value.toUpperCase()}</span>
            </div>
        </div>
    );
};

export default RegistrationNumber;