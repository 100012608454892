import React from 'react'

export default ({
                    element
                }) => {
    return (
        <>
            <div className="fieldset-row fieldset-row-title">
                <p className="paragraph">{element.title}</p>
            </div>

            <div className="fieldset-row">
                <p className="paragraph">{element.text}</p>
            </div>
            <div className="fieldset-row">

                <ul className="feedback-component">
                    <li><i className="icon-feedback-icon-1"/><span>{element.worst}</span></li>
                    <li><i className="icon-feedback-icon-2"/><span>{element.poor}</span></li>
                    <li><i className="icon-feedback-icon-3"/><span>{element.average}</span></li>
                    <li><i className="icon-feedback-icon-4"/><span>{element.good}</span></li>
                    <li><i className="icon-feedback-icon-5"/><span>{element.excellent}</span></li>
                </ul>
            </div>

            <div className="fieldset-row">
                <div className="form-row">
                    <p className="">{element.descriptionTitle}</p>
                    <textarea disabled/>
                </div>
            </div>
        </>
    )
}

