import React from 'react'
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import {css} from 'styled-components'

const cssFixes = css`
    .math-symbols-list {
        display: none;
    }

    .view-math-symbols .math-symbols-list {
        display: block;
    }
`

const Formula = ({value, changeValue}) => {

    const tokenPatern = /({{\d+\}})|(\d\.\d+)|(\d+)|([-+*/])/g
  

    const [state, setState] = React.useState({
        formulaTokens: value ? value.match(tokenPatern).filter(i => i) : [],
        addingOperator: false,
    }, "formula");


    React.useEffect(() => {
        const newFormula = convertFormulaTokensToString()
        if (newFormula !== value) {
            changeValue(newFormula)
        }
    }, [state.formulaTokens])

    const convertFormulaTokensToString = () => state.formulaTokens.join('')
        
    //
    const removeItem = index => {
        console.log("removing item with index", index)
        setState(state => ({
            ...state,
            formulaTokens: state.formulaTokens.filter((_, i) => index !== i)
        }))
    }

    const setItem = (index, editedItem) => setState(state => ({
        ...state,
        formulaTokens: state.formulaTokens.map((item, i) => index !== i ? item : editedItem)
    }))

    const addToken = token => {
        console.log("adding token:", token)
        setState(state => ({
            ...state,
            formulaTokens: [...state.formulaTokens, token]
        }))
    }

    const moveToken = (fromIndex, toIndex) => {
        setState(state => {
            Array.prototype.move = function (from, to) {
                this.splice(to, 0, this.splice(from, 1)[0])
                return this
            }

            return {
                ...state,
                formulaTokens: [...state.formulaTokens].move(fromIndex, toIndex)
            }
        })
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        moveToken(oldIndex, newIndex)
    }

    const SortableItem = SortableElement(({value, valueIndex}) =>

        <Token
            removeItem={() => removeItem(valueIndex)}
            setItem={(item) => setItem(valueIndex, item)}
            token={value}
            index={valueIndex}
            state={state}
            setState={setState}
        />
    )

    const SortableList = SortableContainer(({items}) => {
        return (
            <ul>
                {items.map((value, index) => (
                    <SortableItem key={`item-${value}-${index}`} index={index} valueIndex={index} value={value}/>
                ))}
            </ul>
        )
    })

    return (
        <div className="formula-box" css={cssFixes}>
            <label>Formula</label>
            <div className="autocomplete-tags" style={{marginBottom: 0}}>
                <SortableList distance={2} items={state.formulaTokens} onSortEnd={onSortEnd} axis="xy"/>
                <Insertor
                    addToken={addToken}
                    formulaTokens={state.formulaTokens}
                />
            </div>
        </div>
    )
};

export default Formula

const Insertor = props => {
    return (
        <>
            {(props.formulaTokens.length === 0 || ['+', '-', '/', '*'].includes(
                props.formulaTokens[props.formulaTokens.length - 1]
            ))
                ? <CodeInsertor {...props}/>
                : <OperatorInsertor {...props}/>
            }
        </>
    )
}

const CodeInsertor = ({addToken}) => {

    const [code, setCode] = React.useState('')
    const [inputRef, setInputRef] = React.useState(undefined)

    React.useEffect(() => {
        inputRef && inputRef.focus()
    }, [inputRef])

    return (
        <>
            <input
                ref={ref => setInputRef(ref)}
                css={`&&&&{width:100%!important;}`}
                className="at-input"
                type="text"
                value={code}
                onChange={e => {
                    const value = e.target.value //.replace(/{|}/g, '')
                    setCode(value)
                }}
                onKeyDown={async e => {
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        addToken(code)
                    }
                }}
                placeholder="Paste component code and press Enter"
            />
        </>
    )
}

const OperatorInsertor = ({addToken}) => {
    const [addingOperator, setAddingOperator] = React.useState(false)

    const addOperator = operator => {
        addToken(operator)
    }

    return (

        <div
            className={`math-symbols ${(addingOperator ? 'view-math-symbols' : '')}`}
            tabIndex="-1"
            onBlur={() => {
                setAddingOperator(false)
            }}
        >
            <div
                className="tbl-btn add-math-symbol trigger-math-symbols"
                onClick={() =>
                    setAddingOperator(addingOperator => !addingOperator)
                }
            ><i className="icon-ia-math"/>
                <div className="tooltip tooltip-top">Add mathemathical symbols</div>
            </div>
            <ul className="math-symbols-list">
                <li onClick={() => addOperator("+")}><i className="icon-plus"/></li>
                <li onClick={() => addOperator("-")}><i className="icon-minus"/></li>
                <li onClick={() => addOperator("*")}><i className="icon-x"/></li>
                <li onClick={() => addOperator("/")}><i className="icon-ia-division"/></li>
            </ul>
        </div>

    )
}


const Token = ({token, ...rest}) => {

    return (
        <>
            {['+', '-', '/', '*'].includes(token)
                ? <Operator {...{token, ...rest}}/>
                : <Code {...{token, ...rest}}/>
            }
        </>
    )
}

const Operator = ({token, index, removeItem, setItem}) => {

    const [operatorState, setOperatorState] = React.useState({
        editingOperator: false
    })

    return (
        <div
            key={token + index}
            className={`math-symbols view-active-symbol ${(operatorState.editingOperator ? 'view-math-symbols' : '')}`}
            onBlur={() => {
                setOperatorState(operatorState => ({
                    ...operatorState,
                    editingOperator: false
                }))
            }}
            tabIndex="-1"

        >
            <div className="tbl-btn active-math-symbol trigger-math-symbols"
                 onClick={() => {
                     setOperatorState(operatorState => ({
                         ...operatorState,
                         editingOperator: !operatorState.editingOperator
                     }))
                 }}
            >
                <i className={`${(token === "-" ? "icon-minus" : "")} ${(token === "+" ? "icon-plus" : "")} ${(token === "*" ? "icon-x" : "")} ${(token === "/" ? "icon-ia-division" : "")} `}/>
                <div className="tooltip tooltip-top">Edit mathemathical symbol</div>
            </div>
            <ul className="math-symbols-list" style={{width: "15rem"}}
                onMouseLeave={() => {
                    setOperatorState(operatorState => ({
                        ...operatorState,
                        editingOperator: false
                    }))
                }}
            >
                <li onClick={() => setItem("+")}><i className="icon-plus"/></li>
                <li onClick={() => setItem("-")}><i className="icon-minus"/></li>
                <li onClick={() => setItem("*")}><i className="icon-x"/></li>
                <li onClick={() => setItem("/")}><i className="icon-ia-division"/></li>
                <li onClick={removeItem}><i className="icon-ia-trash"/></li>
            </ul>
        </div>
    )
}

const Code = ({token, removeItem, setItem}) => {
    const [state, setState] = React.useState({
        mode: token === '$' ? "edit" : 'view',
        code: token === '$' ? '' : token
    })

    const [inputRef, setInputRef] = React.useState(undefined)

    React.useEffect(() => {
        if (token === '$' && inputRef) {
            inputRef.focus()
        }
    }, [inputRef])

    React.useEffect(() => {
        if (state.mode === 'edit' && inputRef) {
            inputRef.focus()
        }
    }, [state.mode, inputRef])

    return (
        <div
            css={`
                display: inline-block;
                outline: none;
            `}
        >
            {state.mode === "view" &&
            <span
                css={`&&&{display: grid; grid-template-columns: max-content max-content;}`}
                className="at-tag" key={token}
                onDoubleClick={() => {
                    setState(state => ({
                        ...state,
                        mode: 'edit',
                        code: token
                    }))
                }}
            >
                    <span>{token}</span>
                    <i className="icon-ia-close" onClick={removeItem}/>
                </span>
            }

            {state.mode === "edit" &&
            <input
                ref={ref => setInputRef(ref)}
                className="at-input"
                type="text"
                value={state.code}
                onChange={(e) => {
                    const value = e.target.value //.replace(/{|}/g, '');
                    setState(state => ({
                        ...state,
                        code: value
                    }))
                }}
                onKeyDown={async e => {
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        setItem(state.code)
                        setState(state => ({
                            ...state,
                            mode: "view"
                        }))
                    }
                }}
                onBlur={() => {
                    setItem(state.code)
                    setState(state => ({
                        ...state,
                        mode: "view"
                    }))
                }}
                placeholder="Paste component code and press Enter"
            />
            }
        </div>
    )
}
