import React  from "react"
import { useHistory } from "react-router-dom"
import moment from "moment"
import {css} from 'styled-components'

import Navbar from "../../components/Navbar"
import Loading from "../../components/Loading"
import Portrait from "../../components/Portrait"
import API from "../../api"
import { getError } from "../../utils/getError"
import { useAppContext } from "../../context"

import Sidebar from "./Forms.Sidebar"
import Form from "./Forms.Form"
import Ipagination from "../../components/Ipagination"

const cssFixes = css`
    .table-ul.forms-list ul {
        grid-template-columns: minmax(5rem, 1fr) minmax(8rem, 1.5fr) minmax(5rem, 1fr) minmax(5rem, 1fr) 22.2rem;
    }
`

const Forms = () => {

	const history = useHistory()
	const page = new URLSearchParams(history.location.search).get('page')
	
	const [state, setState] = React.useState(
		{
			filtersCollapsed: true,
			loading: false,
			loading_form_details: false,
			error: null,
			form_details_error: null,
			data: [],
			loading_forms: false,
			pages: 0,
			page: page || 1,
			categories: [],
			selected_categories: [],
			categoriesFilter: "",
			names: [],
			selected_names: [],
			formsFilter: "",
			sort: null,
			order: null,
			users: [],
			form_details: {
				id: null,
				agents_no: "-",
				best_agent: null,
				conversions: "-",
				conversions_by_week: [7, 6, 5, 4, 3, 2, 1].map(week => ({
					conversions: 0,
					week: moment().subtract(week, "isoweeks").week(),
				})),
				last_conversion: null,
				last_session: null,
				total_sessions: "-",
			},
			selectedForm: null,
			loadedFormsOnce: false,
			loadedFormDetailsOnce: false,
		},
		"forms"
	);

	const {
		auth: [auth],
	} = useAppContext();

	React.useEffect(() => {
		getFormsList(state);
		getCategories();
		getForms();
		getAgents()
	}, []);

	React.useEffect(() => {}, [
		state
	])

	const getForms = async () => {
		try {
			const response = await API.get("/forms/list/all?notDeleted=true");
			setState(state => ({ ...state, loading: false, names: response.data }))
		} catch (err) {
			setState(state => ({
				...state,
				loading: false,
				error: getError(err.response),
			}))
		}
	};

	const getCategories = async () => {
		try {
			const response = await API.get("/categories/list/all");
			setState(state => ({ ...state, loading: false, categories: response.data }))
		} catch (err) {
			setState(state => ({
				...state,
				loading: false,
				error: getError(err.response),
			}))
		}
	};

	const selectForm = formId =>
		setState(state => ({ ...state, selectedForm: formId }));

	const getAgents = async () => {
		try {
			setState(state => ({ ...state, users: []}));
			const {
				data: users,
			} = await API.get(`/forms/users`);
			setState(state => ({
				...state,
				users
			}))
		} catch (err) {}
	};

	const deleteForm = async id => {
		try {
			await API.delete(`/forms/${id}`);
			setState(state => ({
				...state,
				data: state.data.filter(form => form._id !== id),
			}))
		} catch (err) {}
	};

	const getFormsList = async state => {
		try {
			setState(state => ({ ...state, error: null, loading_forms: true }));
			let url = "/forms";
			if (state) {
				const filters = [];
				if (state.selected_names.length > 0)
					filters.push(
						`names=${state.selected_names.map(selected_name => selected_name.name)}`
					);
				if (state.selected_categories.length > 0)
					filters.push(
						`categories=${state.selected_categories.map(
							selected_name => selected_name._id
						)}`
					);
				// if (state.search) filters.push(`search=${state.search}`);
				if (state.sort) filters.push(`sort=${state.sort}&order=${state.order}`);
				if (state.page) filters.push(`page=${state.page}`);
				if (filters.length) url += "?" + filters.join("&")
			}
			const {
				data: { data, pages },
			} = await API.get(url);
			setState(state => ({ ...state, loading_forms: false, data, pages, loadedFormsOnce: true }))
			// if(!state.loadedFormsOnce) setState(state => ({...state, loadedFormsOnce: true}))
			if (data.length !== 0) {
				selectForm(data[0]._id)
			} else {
				setState(state => ({ ...state, loadedFormDetailsOnce: true }))
			}
			if (state && state.page) setPage(state.page)
			
		} catch (err) {
			setState(state => ({
				...state,
				loading_forms: false,
				error: getError(err.response),
			}))
		}
	};

	const setPage = page => setState(state => ({ ...state, page }));

	const toggleShowFilters = () =>
		setState(state => ({ ...state, filtersCollapsed: !state.filtersCollapsed }));

	const removeForm = async id => {
		await deleteForm(id);
        getForms();
        getCategories();
        getFormsList()
	};

	const toggleForm = async form => {
		if (state.selected_names.find(({_id}) => form._id === _id)) {
			setState(state => ({
				...state, 
				selected_names: state.selected_names.filter(({_id}) => form._id !== _id)
			}))
		} else {
			setState(state => ({
				...state, 
				selected_names: [...state.selected_names, form]
			}))
		}

		// setState(state => ({...state, formsDropdown: false}))

		await getFormsList({
			...state,
			selected_names: state.selected_names,
			page: 1,
		})
	};

	const toggleCategory = async category => {
		if (state.selected_categories.find(({_id}) => category._id === _id)) {
			setState(state => ({
				...state, 
				selected_categories: state.selected_categories.filter(({_id}) => category._id !== _id)
			}))

			state.selected_categories.push(category)
		} else {
			setState(state => ({
				...state, 
				selected_categories: [...state.selected_categories, category]
			}))
		}

		await getFormsList({
			...state,
			selected_categories: state.selected_categories,
			page: 1,
		})
	}

	const changePage = page => {
		history.push({
            pathname: history.location.pathname,
            search: `?page=${page}`
        })
		getFormsList({ ...state, page })
	}

	const renderSortIcon = column => {
		const setColumnSorting = (sort, order) => () => {
			setState(state => ({ ...state, sort, order }));
			getFormsList({ ...state, page: 1, sort, order })
		};

		if (column !== state.sort)
			return (
				<span className="cntrl-box">
					<i
						className="cntrl-down icon-arrow-down"
						onClick={setColumnSorting(column, "desc")}
					/>
					<i
						className="cntrl-up icon-arrow-up"
						onClick={setColumnSorting(column, "asc")}
					/>
				</span>
			);

		if (state.order === "desc")
			return (
				<span className="cntrl-box">
					<i
						className="cntrl-down icon-arrow-down selected"
						onClick={setColumnSorting(null, null)}
					/>
					<i
						className="cntrl-up icon-arrow-up"
						onClick={setColumnSorting(column, "asc")}
					/>
				</span>
			);

		return (
			<span className="cntrl-box">
				<i
					className="cntrl-down icon-arrow-down"
					onClick={setColumnSorting(column, "desc")}
				/>
				<i
					className="cntrl-up icon-arrow-up selected"
					onClick={setColumnSorting(null, null)}
				/>
			</span>
		)
	};

	return (
		<>
			<Navbar />
			{(!state.loadedFormsOnce || !state.loadedFormDetailsOnce) && <Loading />}
	
				<div
					className={`wrapper forms-page ${
						!state.filtersCollapsed ? "view-filters" : ""
					}`}
					css={`
						${cssFixes}
						display: ${(state.loadedFormsOnce && state.loadedFormDetailsOnce) ? 'visible' : 'none'};
					`}
				>

					<div className="content">
						<div className="content-box">
							<div className="content-box-header">
								<h3>Forms</h3>
								<div className="content-box-options">
									<span
										className="button button-outline with-icon-right trigger-filters"
										onClick={toggleShowFilters}
									>
										<i className="icon-ia-arrow" />
										Filters
									</span>
								</div>
							</div>

							<div
								className="content-box-subheader filters"
								style={{ overflow: "visible" }}
							>
								<h4>filters</h4>
								{(state.selected_categories.length > 0 ||
									state.selected_names.length > 0) /*|| state.search*/ && (
									<ul className="applied-filters" style={{ padding: "0 8rem 1rem 0" }}>
										<li style={{ position: "inherit" }}>
											<span>Filters:</span>
										</li>
										{state.selected_categories.map((category, i) => (
											<li key={category._id}>
												<i
													className="delete-filter"
													onClick={async () => {
														state.selected_categories.splice(i, 1);
														setState(state => ({
															...state,
															selected_categories: state.selected_categories,
														}));
														await getFormsList({
															...state,
															selected_categories: state.selected_categories,
														})
													}}
												/>
												<span>{category.name}</span>
											</li>
										))}
										{state.selected_names.map((name, i) => (
											<li key={name._id}>
												<i
													className="delete-filter"
													onClick={async () => {
														state.selected_names.splice(i, 1);
														setState(state => ({
															...state,
															selected_names: state.selected_names,
														}));
														await getFormsList({
															...state,
															selected_names: state.selected_names,
														})
													}}
												/>
												<span>{name.name}</span>
											</li>
										))}

										<li className="applied-filters-actions">
											<div
												className="tbl-btn"
												onClick={async () => {
													setState(state => ({
														...state,
														selected_names: [],
														selected_categories: [],
														// search: ""
													}));
													await getFormsList({
														...state,
														selected_names: [],
														selected_categories: [],
														// search: ""
													})
												}}
											>
												<i className="icon-refresh-ccw" />
												<div className="tooltip tooltip-top">Clear all</div>
											</div>
										</li>
									</ul>
								)}

								<div className="form">
									<div
										onBlur={() => {
											setState(state => ({...state, formsDropdown: false}))
										}}
										tabIndex="0"
										className={`form-row ${state.formsDropdown ? "view-dropdown" : ""}`}
										style={{ overflow: "inherit" }}
									>
										<label htmlFor="">Form(s)</label>
										<input
											type="text"
											placeholder="Filter forms"
											value={state.formsFilter}
											onChange={e => {
												const value = e.target.value;
												setState(state => ({
													...state,
													formsFilter: value,
												}))
											}}
											onFocus={() => {
												setState(state => ({
													...state,
													formsDropdown: true,
												}))
											}}
										/>
										<div className="dropdown">
											<div className="scrollbar">
												<ul>
													{state.names.map(form => {
														if (
															form.name.toLowerCase().includes(state.formsFilter.toLowerCase())
														) {
															return (
																<li
																	className={`${
																		state.selected_names.some(
																			formInList => formInList._id === form._id
																		)
																			? "selected"
																			: ""
																	}`}
																	key={form._id}
																	onMouseDown={e =>  {
																		e.preventDefault()
																		toggleForm(form)
																	}}
																>
																	{form.name}
																</li>
															)
														} else {
															return null
														}
													})}
												</ul>
											</div>
										</div>
									</div>
									<div
										onBlur={() => {
											setState(state => ({
												...state,
												categoriesDropdown: false,
											}))
										}}
										tabIndex="-1"
										className={`form-row ${
											state.categoriesDropdown ? "view-dropdown" : ""
										}`}
										style={{ overflow: "inherit" }}
									>
										<label htmlFor="">Category(ies)</label>
										<input
											type="text"
											placeholder="Filter categories"
											value={state.categoriesFilter}
											onChange={e => {
												const value = e.target.value;
												setState(state => ({
													...state,
													categoriesFilter: value,
												}))
											}}
											onFocus={() => {
												setState(state => ({
													...state,
													categoriesDropdown: true,
												}))
											}}
										/>
										<div className="dropdown">
											<div className="scrollbar">
												<ul>
													{state.categories.map(category => {
														if (
															category.name
																.toLowerCase()
																.includes(state.categoriesFilter.toLowerCase())
														) {
															return (
																<li
																	className={`${
																		state.selected_categories.some(
																			categoryInList => categoryInList._id === category._id
																		)
																			? "selected"
																			: ""
																	}`}
																	key={category._id}
																	onMouseDown={e => {
																		e.preventDefault()
																		toggleCategory(category)
																	}}
																>
																	{category.name}
																</li>
															)
														} else {
															return null
														}
													})}
												</ul>
											</div>
										</div>
									</div>
								</div>
								<span className="tbl-btn close-subheader" onClick={toggleShowFilters}>
									<i className="icon-x" />
								</span>
							</div>
							{state.loading_forms && <Loading />}
							{!state.loading_forms  &&									
								<div className="content-box-body content-box-body-fixed-elements">
									<div className="box-body box-body-fixed">
										<div className="table-fixed-header">
											<ul className="table-ul forms-list">
												<li className="table-ul-header">
													<ul>
														<li className="with-cntrl-box">
															<strong>Name</strong>
															{renderSortIcon("name")}
														</li>
														<li className="with-cntrl-box">
															<strong>Category</strong>
															{renderSortIcon("category_name")}
														</li>
														<li className="with-cntrl-box">
															<strong>No. of sessions</strong>
															{renderSortIcon("no_sessions")}
														</li>
														<li className="with-cntrl-box">
															<strong>Last session</strong>
															{renderSortIcon("last_session")}
														</li>
														<li></li>
													</ul>
												</li>
											</ul>
										</div>
										

										<div className="scrollbar">
											{state.data.length === 0 && (
												<div className="empty-list">This list is empty</div>
											)}

											{state.data.length > 0 && (
												<ul className="table-ul forms-list">
													{state.data.map((form, index) => (
														<Form
															form={form}
															formId={form._id}
															agents={state.users}
															selectedForm={state.selectedForm}
															selectForm={selectForm}
															index={index}
															removeForm={() => removeForm(form._id)}
															key={form._id}
															getForms={() => getFormsList(state)}
														/>
													))}
												</ul>
											)}
										</div>
									</div>

									<div className="box-action table-action-fixed with-pagination">
										
										<div className="export-options" />
										<Ipagination
											page={state.page}
											pages={state.pages}
											onChange={changePage}
										/>
										<a
											href="#"
											onClick={e => {
												e.preventDefault();
												history.push("/forms/new")
											}}
											className="button with-icon-right"
										>
											<i className="icon-plus"></i>Add New Form
										</a>

									</div>

								</div>
							}
							
						</div>
					</div>

					<Sidebar selectedForm={state.selectedForm} formsStore={[state, setState]}/>
				</div>
			

			<Portrait />
		</>
	)
}


export default Forms