import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import {StyleSheetManager} from 'styled-components'


class Frame extends Component {
    componentDidMount() {
      this.iframeHead = this.node.contentDocument.head
      this.iframeRoot = this.node.contentDocument.body
      this.forceUpdate()
      this.node.addEventListener("load", this.handleLoad);
    }

    componentWillUnmount () {
      this.node.removeEventListener("load", this.handleLoad)
    }

    handleLoad = () => {
      this.iframeRoot = this.node.contentDocument.body;
      this.forceUpdate();
    }
  

    render() {

      const { children, head, ...rest } = this.props
      return (
          <iframe {...rest} ref={node => (this.node = node)}>
            {this.iframeHead && ReactDOM.createPortal(head, this.iframeHead)}
            {this.iframeRoot && ReactDOM.createPortal(
              <StyleSheetManager target={this.iframeHead}>
                <div style={{overflow: "hidden"}}>
                  {children}
                </div>
              </StyleSheetManager>
            , this.iframeRoot)}
          </iframe>

      )
    }
  }

  export default Frame