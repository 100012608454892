import React from 'react'

import Stats from './Dashboard.Stats'

import Navbar from '../../components/Navbar'
import Portrait from '../../components/Portrait'
import Tabs from './Dashboard.Tabs'
import Loading from "../../components/Loading";

const Dashboard = () => {
    const [state, setState] = React.useState({
        loadedTabsStatsOnce: false,
        loadedStatsOnce: false,
        loadedSelectedTabOnce: false
    })



    return (
        <>
            <Navbar/>
            {(!state.loadedTabsStatsOnce || !state.loadedStatsOnce || !state.loadedSelectedTabOnce) && <Loading/>}
            <div className="wrapper" 
                css={`
                    display: ${(!state.loadedTabsStatsOnce || !state.loadedStatsOnce || !state.loadedSelectedTabOnce)  ? 'none' : 'visible'};
                `}
            >
                <Tabs dashboardStore={[state, setState]}/>
                <Stats dashboardStore={[state, setState]}/>
            </div>
            <Portrait/>
        </>
    )
}

export default Dashboard