import React from 'react'
import styled from "styled-components";
import {Abutton} from "./Abutton";
import {Aicon} from "./Aicon";

export const AiconButton = styled(({icon, className, children, ...rest}) => {

    return (
        <Abutton
            iconRight
            className={className}
            {...rest}
        >
            <Aicon icon={icon}/>
            {children}
        </Abutton>
    )
})`

`