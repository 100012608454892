import React from "react"
import Imodal from "../../components/ApopUp"
import supportedElements from "../../formComponents/supportedElements"
import { notification } from "../../components/Lnotification"

import root from "react-shadow"

import { getFormErrors } from "../../utils/getFormErrors"
import Loading from "../../components/Loading"
import API from "../../api"
import LoadAfter from "../../components/LoadAfter"
import { Oppression } from "../../formComponents/Oppression"
import { css } from "styled-components"

const cssFixes = css`
	/* input date icon fix */
	.form-row.with-calendar .calendar-btn:hover {
		color: var(--primary-color);
		background: transparent;
		cursor: default;
		box-shadow: none;
	}
`

export default ({
	visible = false,
	session,
	onClose,
	setSession,
	props,
	auth,
}) => {
	const [state, setState] = React.useState(
		{
			formData: {},
			validation_errors: {},
			loading: false,
			hiddenElements: {},
		},
		"approve-session"
	)
	const elements_references = React.useRef({})
	const { form, active } = session._form.approvalForm
	const answers_by_code = Object.entries(state.formData).reduce(
		(acc, [key, value]) => ({
			...acc,
			[form.elements.find(e => e._id === key).code]: value,
		}),
		{}
	)

	const changeValue = (key, value) => {
		const validation_errors = {...state.validation_errors};

		delete validation_errors[key];

		if (
			value === null ||
			(Array.isArray(value) && value.length === 0)
		) {

			const formDataCopy = {...state.formData};
			delete formDataCopy[key];

			setState(state => ({
				...state,
				formData: formDataCopy,
				validation_errors,
			}))
			return
		}

		setState(state => ({
			...state,
			formData: { ...state.formData, [key]: value },
		}))
	}

	const setHiddenElement = element =>
		setState(state => ({
			...state,
			hiddenElements: { ...state.hiddenElements, ...element },
		}))
	const getValidationErrors = async () => {

		const elements = form.elements

		const values = Object.entries(state.formData).map(([key, value]) => ({
			element_id: key,
			value: value,
		}))

		const validation_errors = await getFormErrors({form_elements: elements, values})
		return validation_errors || {}
	}

	const approveSession = async () => {
		let formData = {}
		try {
			setState(state => ({ ...state, loading: true }))
			if (form && active) {
				const validation_errors = await getValidationErrors()

				if (Object.keys(validation_errors).length > 0) {
					setState(state => ({ ...state, validation_errors, loading: false }))
					setTimeout(() => {
						scrollToError(validation_errors)
					}, 200)
					return
				}
				const elements = form.elements
				const file_uploads = {}
				let values = { ...state.formData }
				Object.keys(values).forEach(id => {
					const element = elements.find(element => id === element._id)
					if (element && element.type === "file upload") {
						file_uploads[element._id] = values[id]
						delete values[id]
					}
				})

				formData = new FormData()
				formData.append(
					"values",
					JSON.stringify(
						Object.entries(values).map(([key, value]) => ({
							element_id: key,
							value: value,
						}))
					)
				)
				const file_uploads_element_ids = Object.keys(file_uploads)
				file_uploads_element_ids.forEach(element_id => {
					for (let i = 0; i < file_uploads[element_id].length; i++) {
						formData.append(element_id, file_uploads[element_id][i])
					}
				})
			}

			const { data } = await API.post(
				`sessions/${session._id}/approve?approval=approve`,
				formData,
				Object.keys(formData).length
					? { headers: { "content-type": "multipart/form-data" } }
					: null
			)

			setState(state => ({ ...state, loading: false }))
			onClose()
			setSession(data)
		} catch (err) {
			console.log(err)
			notification.warning({
				message: "Something went wrong with session approval status change",
			})
			setState(state => ({ ...state, loading: false }))
		}
	}

	const onDecline = async () => {
		try {
			setState(state => ({ ...state, loading: true }))
			const { data } = await API.post(
				`sessions/${session._id}/approve?approval=decline`
			)
			setState(state => ({ ...state, loading: false }))
			onClose()
			setSession(data)
		} catch (err) {
			notification.warning({
				message: "Something went wrong with session approval status change",
			})
			setState(state => ({ ...state, loading: false }))
		}
	}



	const scrollToError = validation_errors => {
	
		let y,
			ref = null
		const validation_errors_keys = Object.keys(validation_errors)
		const elements_references_keys = Object.keys(
			elements_references.current
		).filter(ref => validation_errors_keys.includes(ref))
		elements_references_keys.forEach(key => {
			if (!y) {
				y = elements_references.current[key].getBoundingClientRect().y
				ref = elements_references.current[key]
			} else {
				if (y > elements_references.current[key].getBoundingClientRect().y) {
					y = elements_references.current[key].getBoundingClientRect().y
					ref = elements_references.current[key]
				}
			}
		})
		// setTimeout(() => {
		if (ref) {
			ref.scrollIntoView()
		}
		// }, 1000)
	}

	return (
		<>
			<Imodal
				visible={visible}
				onCancel={onClose}
				okText="Save & Approve"
				cancelText="Decline"
				overLayClass="overlay-approve-session"
				disableMask
				footer={
					<>
						<div
							className="button with-icon-right close-overlay"
							onClick={approveSession}
						>
							<i className="icon-ia-checked-outline"></i>Approve
						</div>
						{session.userApproval.users.find(
							({ user }) => user._id === auth.decodedToken.userid
						) &&
							session.userApproval.users.find(
								({ user }) => user._id === auth.decodedToken.userid
							).approved !== false && (
								<div className="button button-link close-overlay" onClick={onDecline}>
									Decline
								</div>
							)}
					</>
				}
				title={(form && form.heading) || "Approve session"}
			>
				<div className="scrollbar">
					{state.loading && <Loading />}
					{!state.loading && form && active && (
						<root.div style={{ height: "100%" }}>
							<LoadAfter
								links={[
									"AlchemistAdmin/client/icons.css",
									"AlchemistAdmin/client/global.css",
								]}
								loader={<Loading />}
							>
								<div
									className={`form ${
										Object.keys(state.validation_errors).length > 0 ? "error-anim" : ""
									}`}
									css={cssFixes}
								>
									{form.elements.map((element, elementIndex) => {
										if (supportedElements[element.type]) {
											return (
												<Oppression
													key={`${element._id}-element`}
													{...{
														answers_by_code,
														form,
														setHiddenElement,
														hiddenElements: state.hiddenElements,
														element,
													}}
												>
													<div ref={ref => (elements_references.current[element._id] = ref)}>
														{React.createElement(supportedElements[element.type].input, {
															element,
															value: state.formData[element._id],
															changeValue: value => changeValue(element._id, value),
															errors: state.validation_errors[element._id],
															setErrors: errors => {
																const validation_errors = {...state.validation_errors}
																if(errors === undefined) delete validation_errors[element._id]
																else validation_errors[element._id] = errors
																setState(state => ({...state, validation_errors}))
															},
															answers_by_code,
															formElements: form.elements,
														})}
													</div>
												</Oppression>
											)
										}
										return null
									})}
								</div>
							</LoadAfter>
						</root.div>
					)}
				</div>
			</Imodal>
		</>
	)
}
