import React from 'react';
import {notification} from '../../components/Lnotification'
import FileSvg from "../../images/file.svg";

import {downloadFile} from "./View.fileUpload.actions";

const FileUpload = ({
    element,
    answers_by_code,
    value = [],
    approval,
    session_id,
    secret
}) => {

    const downloadFileRequest = (file_id, filename) => () => {
        downloadFile(session_id, file_id, secret, filename, approval, () => {
            notification.error({
                message: 'There was a problem downloading the file'
            });
        });
    };

    return (
        <div className="fieldset-row">
            <div className="form-grid files-list" style={{gridTemplateColumns: 'repeat(3, 1fr)'}}>
                {value.length === 0 &&
                    <div>No Files Uploaded</div>
                }

                {value.map(file => (
                    <div key={file.id} className="file" style={{cursor:'pointer'}} onClick={downloadFileRequest(file.id, file.filename)}>
                        <img src={FileSvg} alt="" />
                        <strong>{file.filename}</strong>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default FileUpload;