import React from 'react'
import {useHistory} from 'react-router-dom'
import moment from 'moment'

import API from '../../api'
import {getError} from "../../utils/getError"
import Loading from '../../components/Loading'

const Agents = () => {

    const [state, setState] = React.useState({
        loading: false,
        error: null,
        data: []
    })

    const history = useHistory()

    React.useEffect(() => {
        loadAgents()
    }, [])

    const loadAgents = async () => {
        try {
            setState(state => ({...state, loading: true, error: null}))
            const {data:{data}} = await API.get('/users?roles[]=agent&sort=no_sessions&order=desc&dashboard=true')
            setState(state => ({...state, loading: false, data}))
        } catch (err) {
            setState(state => ({...state, loading: false, error: getError(err.response)}))
        }
    }

    return(
        <>
            {state.loading && <Loading />}
            {!state.loading && 
                <div className="swiper-slide">
                    <h3 className="box-title box-title-fixed">Documents Stream</h3>

                    <div className="box-body box-body-fixed">
                        <div className="scrollbar">
                            <ul className="table-ul docs-list">

                                <li className="table-ul-header">
                                    <ul>
                                        <li><strong>User</strong></li>
                                        <li><strong>Associated Forms</strong></li>
                                        <li><strong>Associated Categories</strong></li>
                                        <li><strong>No. of Sessions</strong></li>
                                        <li><strong>Last Login</strong></li>
                                    </ul>
                                </li>

                                {state.data.map(agent => (
                                    <li key={agent._id}>
                                        <ul>
                                            <li className="tbl-li-img">
                                                <div className="tbl-img tbl-agent-img">
                                                    <div>
                                                        {(agent.image !== null) ? <img src={`${process.env.REACT_APP_API_BASE_URL}/images/users/image_thumbnail/${agent.image}`}/> : '' }

                                                        {
                                                            !!agent.image
                                                                ? <img src={
                                                                    agent.image?.startsWith('http')
                                                                        ? agent.image
                                                                        : `${process.env.REACT_APP_API_BASE_URL}/images/users/image/${agent.image}`
                                                                } />
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <strong>{agent.name}</strong>
                                            </li>
                                            <li><strong>{agent.no_forms}</strong></li>
                                            <li><strong>{agent.no_categories}</strong></li>
                                            <li><strong>{agent.no_sessions}</strong></li>
                                            <li>
                                                { agent.lastLogin &&
                                                    <strong>{moment(agent.lastLogin).format('D MMM, HH:mm')}<span>({moment(agent.lastLogin).format('YYYY')})</span></strong>
                                                }
                                            </li>
                                        </ul>
                                    </li>
                                ))}
                                
                            </ul>
                        </div>
                    </div>

                    <div className="box-action box-action-fixed">
                        <a className="button button-outline with-icon-right" href="#" title=""
                            onClick={e => {
                                e.preventDefault()
                                history.push('/users')
                            }}
                        >View All<i className="icon-ia-arrow-right"></i></a>
                    </div>
                </div>
            }
        </>
    )
}

export default Agents