import React, { useState } from "react"
import styled from "styled-components"
import { dataURLToBlob } from "blob-util"
import ApopUp from "../components/ApopUp"
import {StyleSheetManager} from 'styled-components'

const TakePicture = ({ onCapture = f => f, children }) => {
	const [open, setOpen] = useState(false)
	const [image, setImage] = useState(null)
	const [size, setSize] = useState({ height: 0, width: 0 })

	const getMedia = async () => {
		let constraints = {
			video: { width: { ideal: 1150 }, height: { ideal: 720 } },
		}

		navigator.mediaDevices
			.getUserMedia(constraints)
			.then(function (mediaStream) {
				setOpen(true)
				let video = document.querySelector("#video")
				video.srcObject = mediaStream
				setSize({
					height: mediaStream.getVideoTracks()[0].getSettings().height,
					width: mediaStream.getVideoTracks()[0].getSettings().width,
				})
				video.onloadedmetadata = function (e) {
					video.play()
				}
			})
			.catch(function (err) {
				console.log(err.name + ": " + err.message)
			})
	}

	const takePicture = () => {
		const canvas = document.getElementById("canvas")
		const context = canvas.getContext("2d")
		console.log(canvas)
		context.drawImage(
			document.getElementById("video"),
			0,
			0,
			canvas.width,
			canvas.height
		)
		setImage(canvas.toDataURL())
	}

	const close = () => {
		document
			.getElementById("video")
			.srcObject.getVideoTracks()
			.forEach(track => track.stop())
		setImage(null)
		setOpen(false)
	}

	const onSave = () => {
		onCapture(dataURLToBlob(image))

		document
			.getElementById("video")
			.srcObject.getVideoTracks()
			.forEach(track => track.stop())
		setImage(null)
		setOpen(false)
	}

	const onClear = () => {
		setImage(null)
	}

	return (
		<>
			{React.cloneElement(children, { onClick: getMedia })}
            <StyleSheetManager target={document.querySelector("#modal")}>
                <ApopUp
                    visible={open}
                    onCancel={close}
                    footer={
                        <>
                            {!!image ? (
                                <>
                                    <div className="button with-icon-right" onClick={onSave}>
                                        <i className="icon-ia-checked-outline" />
                                        Save
                                    </div>
                                    <div className="button button-link" onClick={onClear}>
                                        Clear
                                    </div>
                                </>
                            ) : (
                                <div className="button with-icon-right" onClick={takePicture}>
                                    <i className="icon-ia-checked-outline" />
                                    Take picture
                                </div>
                            )}
                        </>
                    }
                >
                    <Screen id="video" autoplay display={!image} />
                    <Canvas
                        id="canvas"
                        width={size.width}
                        height={size.height}
                        display={!!image}
                    />
                </ApopUp>
            </StyleSheetManager>
		</>
	)
}

export default TakePicture

const Canvas = styled.canvas`
	width: 100%;
	height: auto;
	display: ${({ display }) => (display ? "block" : "none")};
	z-index: 3;
`
const Screen = styled.video`
	display: ${({ display }) => (display ? "block" : "none")};
	height: auto;
	width: 100%;
	z-index: 2;
	transform: rotateY(180deg);
`
