import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({
    element
}) => {
    return (
        <CodeCopy text={element.code}>
            <div className="fieldset-row fieldset-row-title">
                <h1 className="main-title">{element.text}</h1>
            </div>

            <div className="fieldset-row">
                <p className="paragraph">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
            </div>

            <ul className="feedback-component">
                <li><i className="icon-feedback-icon-1"></i><span>Worst</span></li>
                <li><i className="icon-feedback-icon-2"></i><span>Poor</span></li>
                <li><i className="icon-feedback-icon-3"></i><span>Average</span></li>
                <li className="active"><i className="icon-feedback-icon-4"></i><span>Good</span></li>
                <li><i className="icon-feedback-icon-5"></i><span>Excelent</span></li>
            </ul>

            <div className="fieldset-row">
                <div className="form-row">
                    <p className="">Mentiuni</p>
                    <textarea />
                </div>
            </div>
        </CodeCopy>
    )
}

