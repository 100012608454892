import React from "react"
import { Droppable, Draggable } from "react-beautiful-dnd"

import allElements from '../../../formComponents/supportedElements'

const Toolbox = ({addElement, elements=allElements}) => {
    const [tab, setTab] = React.useState('GENERAL')
	const toggleTab = tTab => setTab(tab === tTab ? null : tTab)
	
	const generalComponents = Object.keys(elements).filter(key => elements[key].group === "GENERAL")
	const specialisedComponents = Object.keys(elements).filter(key => elements[key].group === "ADVANCED")
	const automotiveComponents = Object.keys(elements).filter(key => elements[key].group === "AUTOMOTIVE")
	const financialComponents = Object.keys(elements).filter(key => elements[key].group === "FINANCIAL")

	const onClick = name => addElement({ ...elements[name].default(), code: Date.now().toString() })

	return (
		<div className="toolbox toolbox-2">
			<h3 className="tb-header-title">ToolBox</h3>
			<div className="scrollbar">

				<div className={`toolbox-components ${tab === 'GENERAL' ? 'active' : ''}`}>
					<h4 onClick={() => toggleTab('GENERAL')}>
						<i className="icon-ia-arrow"></i>General
					</h4>
					<Droppable droppableId="GENERAL_COMPONENT" isDropDisabled={true}>
						{(provided, snapshot) => (
							<ul 
								className="components-list"
								ref={provided.innerRef}
								// isDraggingOver={snapshot.isDraggingOver}
							>
								{generalComponents.map((key, keyIndex) => (
									<Draggable
										key={keyIndex}
										draggableId={key}
										index={Object.keys(elements).findIndex(name => name === key)}
									>
										{(provided, snapshot) => (
											<>
												<li 
													onClick={() => onClick(key)} 
													key={key}
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													// isDragging={snapshot.isDragging}
													style={provided.draggableProps.style}
												>
													<i className={elements[key].icon}></i>
													<span>{elements[key].name}</span>
												</li>
												{snapshot.isDragging && (
													<li>
														<i className={elements[key].icon}></i>
														<span>{elements[key].name}</span>
													</li>
												)}
											</>
										)}
									</Draggable>
								))}
							</ul>
						)}
					</Droppable>
				</div>

				<div className={`toolbox-components ${tab === 'ADVANCED' ? 'active' : ''}`} style={tab === 'ADVANCED' && Object.keys(elements).length !== Object.keys(allElements).length ? {height: "17.5rem"} : {}}>
					<h4 onClick={() => toggleTab('ADVANCED')}>
						<i className="icon-ia-arrow"></i>Advanced
					</h4>
					<Droppable droppableId="ADVANCED_COMPONENT" isDropDisabled={true}>
						{(provided, snapshot) => (
							<ul 
								className="components-list"
								ref={provided.innerRef}
								// isDraggingOver={snapshot.isDraggingOver}
							>
								{specialisedComponents.map((key, keyIndex) => (
									<Draggable
										key={keyIndex}
										draggableId={key}
										index={Object.keys(elements).findIndex(name => name === key)}
									>
										{(provided, snapshot) => (
											<>
												<li 
													onClick={() => onClick(key)} 
													key={key}
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													// isDragging={snapshot.isDragging}
													style={provided.draggableProps.style}
												>
													<i className={elements[key].icon}></i>
													<span>{elements[key].name}</span>
												</li>
												{snapshot.isDragging && (
													<li>
														<i className={elements[key].icon}></i>
														<span>{elements[key].name}</span>
													</li>
												)}
											</>
										)}
									</Draggable>
								))}
							</ul>
						)}
					</Droppable>
				</div>

				<div className={`toolbox-components ${tab === 'AUTOMOTIVE' ? 'active' : ''}`} style={tab === 'AUTOMOTIVE' && Object.keys(elements).length !== Object.keys(allElements).length ? {height: "10.3rem"} : {}}>
					<h4 onClick={() => toggleTab('AUTOMOTIVE')}>
						<i className="icon-ia-arrow"></i>Automotive
					</h4>
					<Droppable droppableId="AUTOMOTIVE_COMPONENT" isDropDisabled={true}>
						{(provided, snapshot) => (
							<ul 
								className="components-list"
								ref={provided.innerRef}
								// isDraggingOver={snapshot.isDraggingOver}
							>
								{automotiveComponents.map((key, keyIndex) => (
									<Draggable
										key={keyIndex}
										draggableId={key}
										index={Object.keys(elements).findIndex(name => name === key)}
									>
										{(provided, snapshot) => (
											<>
												<li 
													onClick={() => onClick(key)} 
													key={key}
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													// isDragging={snapshot.isDragging}
													style={provided.draggableProps.style}
												>
													<i className={elements[key].icon}></i>
													<span>{elements[key].name}</span>
												</li>
												{snapshot.isDragging && (
													<li>
														<i className={elements[key].icon}></i>
														<span>{elements[key].name}</span>
													</li>
												)}
											</>
										)}
									</Draggable>
								))}
							</ul>
						)}
					</Droppable>
				</div>

				<div className={`toolbox-components ${tab === 'FINANCIAL' ? 'active' : ''}`} style={tab === 'FINANCIAL' ? {height: ""} : {}}>
					<h4 onClick={() => toggleTab('FINANCIAL')}>
						<i className="icon-ia-arrow"></i>Financial
					</h4>
					<Droppable droppableId="FINANCIAL_COMPONENT" isDropDisabled={true}>
						{(provided, snapshot) => (
							<ul 
								className="components-list"
								ref={provided.innerRef}
								// isDraggingOver={snapshot.isDraggingOver}
							>
								{financialComponents.map((key, keyIndex) => (
									<Draggable
										key={keyIndex}
										draggableId={key}
										index={Object.keys(elements).findIndex(name => name === key)}
									>
										{(provided, snapshot) => (
											<>
												<li 
													onClick={() => onClick(key)} 
													key={key}
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													// isDragging={snapshot.isDragging}
													style={provided.draggableProps.style}
												>
													<i className={elements[key].icon}></i>
													<span>{elements[key].name}</span>
												</li>
												{snapshot.isDragging && (
													<li>
														<i className={elements[key].icon}></i>
														<span>{elements[key].name}</span>
													</li>
												)}
											</>
										)}
									</Draggable>
								))}
							</ul>
						)}
					</Droppable>
				</div>
			</div>
		</div>
	)
}

export default Toolbox
