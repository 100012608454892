import React from 'react'

import LivePreview from './LivePreview.dropDown'
import {DropdownEditor} from './Editor.dropDwon'
import CodeCopy from './CodeCopy.dropDown'
import View from './View.dropDown'
import Input from './Input.dropDown'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <DropdownEditor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,

    default: () => ({
        type: "dropdown",
        text: "Dropdown Label",
        items: ['Option'],
        pinned: {
            status: false
        },
        validations: []
    }),

    availableValidations: ['required'],
    validate: () => false,
    group: "GENERAL",
    icon: "icon-ia-select-box",
    name: "Dropdown",
    view: args => <View {...args}/>
}