import React from 'react'

import {synchronizeText} from "../../utils/synchronizeText"
import IsignaturePad from '../../components/LsignaturePad'

const PrivacyAgreement = ({
    element,
    answers_by_code,
    value = {
        first_name: '',
        last_name: '',
        signature: null
    }
}) => {


    return (
        <div className="fieldset-row">
            {element.title && <p>{synchronizeText(element.title, answers_by_code)}</p>}
            {element.text && <p>{synchronizeText(element.text, answers_by_code)}</p>}
            <div className="session-form-grid-row">
                <div className="session-form-row">
                    <label>Name</label>
                    <span className="input-box">{value.first_name}</span>
                </div>
                <div className="session-form-row">
                    <label>Surname</label>
                    <span className="input-box">{value.last_name}</span>
                </div>
            </div>
            <IsignaturePad value={value.signature}/>
        </div>
    )
}

export default PrivacyAgreement;