import React, { useEffect } from "react"

import { Pie } from "react-chartjs-2"

import { ListItem } from "./ClientsStats.styles"

import available_colors from "../../../styles/availableColors"
import Loading from "../../../components/Loading"
import API from "../../../api"
import { notification } from "../../../components/Lnotification"

const ClientsStats = ({ parentData, clientsStore: [, setClientsState] }) => {
	const [state, setState] = React.useState({
		loading: true,
		error: null,
		data: [],
	})

	useEffect(() => {
		getClientsTopStats()
	}, [parentData])

	const getClientsTopStats = async () => {
		try {
			setState(state => ({
				...state,
				error: null,
				loading: true,
			}))

			const response = await API.get("/clients/top/statistics")

			setState(state => ({
				...state,
				loading: false,
				data: response.data,
            }))
            setClientsState(clientsState => ({...clientsState, loadedStatsOnce: true}))
		} catch (err) {
			console.log('server error place 2')
			setState(state => ({
				...state,
				loading: false,
			}))
			notification.error({
				message: "Something went wrong with the server",
			})
		}
	}

	let data

	if (state.data.length) {
		const donut_values = state.data.map(form => form.collected_clients)

		const donut_background_colors = []
		donut_values.forEach((value, i) => {
			const index = i % available_colors.length
			donut_background_colors.push(available_colors[index])
		})

		const donut_labels = state.data.map(form => form.name)

		data = {
			datasets: [
				{
					data: donut_values,
					backgroundColor: donut_background_colors,
					borderWidth: 0,
				},
			],

			labels: donut_labels,
		}
	}

	if (state.loading) {
		return <Loading />
	}

	return (
		<>
			<h3 className="sidebar-title">Top collected clients per form</h3>

			{!state.error && (
				<>
					{data !== undefined && (
						<Pie
							width={220}
							data={data}
							options={{
								legend: {
									display: false,
								},
								tooltips: {
									backgroundColor: "rgba(188, 192, 194, 0.79)",
									callbacks: {
										label: function (tooltipItem, data) {
											let label = " " + data.labels[tooltipItem.index] || " "
											if (label) {
												label += ": "
											}
											label +=
												data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
											return label
										},
									},
								},
							}}
						/>
					)}

					{data === undefined && (
						<>
							<Pie
								width={220}
								data={{
									datasets: [
										{
											data: [1],
											backgroundColor: "#FAF6FE",
											borderWidth: 0,
										},
									],

									labels: ["There are no forms to show at this time"],
								}}
								options={{
									legend: {
										display: false,
									},
								}}
							/>

							<br />
							<br />
							<p style={{ textAlign: "center" }}>
								There are no forms to show at this time
							</p>
						</>
					)}

					<br />
					<br />

					<ul className="activity-pie-list">
						{state.data.map((form, i) => {
							const color = available_colors[i % available_colors.length]

							return (
								<ListItem color={color} key={form._id}>
									<div>
										<h6>{form.category}</h6>
										<h4>{form.name}</h4>
									</div>
									<div>
										<strong>
											{form.collected_clients} <em>clients</em>
										</strong>
									</div>
								</ListItem>
							)
						})}
					</ul>
				</>
			)}
		</>
	)
}

export default ClientsStats
