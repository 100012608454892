import React from 'react';
import styled, {css} from 'styled-components';
import Loader from 'react-loader-spinner';

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Loading = ({color="#5D2460", rawCss=``}) => (
    <LoadingContainer css={`${rawCss}`}>
        <Loader
            type="Bars"
            color={color}
            height="100"
            width="100"
        />
    </LoadingContainer>
)

export default Loading;