import styled from "styled-components";
import {v4 as uuid} from 'uuid'
import React from "react";

const RequiredValidationToggle = styled(({className, validationRequired, setValidationRequired}) => {
    return (
        <div className={`form-row-checkbox ${className}`}>
            {(id => (
                <>
                    <input
                        id={id}
                        type="checkbox"
                        checked={validationRequired}
                        onChange={() => setValidationRequired(!validationRequired)}
                    />
                    <label htmlFor={id}>Required</label>
                </>
            ))(uuid())}
        </div>
    )
})`
    position: absolute !important;
    right: 12rem;
    top: 50%;
    display: grid;
    transform: translateY(-50%);
    label {
      margin: 0;
    }
`;

export {RequiredValidationToggle}