import React from "react"
import {Redirect} from 'react-router-dom'
import moment from 'moment'
import {notification} from '../../components/Lnotification'
import bytes from 'bytes';
import uniq from 'lodash/uniq'
import {isEmail} from "validator";
import {useParams} from 'react-router-dom'
import {useHistory} from 'react-router-dom'
import Navbar from '../../components/Navbar'
import ApopConfirm from '../../components/ApopConfirm';
import AdeleteConfirm from '../../components/AdeleteConfirm'
import SignatureCanvasWrapper from '../../components/LsignaturePad'
import API from '../../api'
import Portrait from "../../components/Portrait"
import {phoneValidator, addPlusPrefix} from "../../utils/phoneValidator"
import Loading from "../../components/Loading"
import {getError} from "../../utils/getError"
import PageNotFound from '../../components/PageNotFound'
import {useAppContext} from "../../context";

const EditUser = () => {

    const [state, setState] = React.useState({
        loading: false,
        errors: [],
        redirect_to_users: false,
        uploaded_image: null,
        uploaded_image_src: null,
        user: {
            name: "",
            email: "",
            department: null,
            scopes: [],
            isDisabled: "",
            signature: null,
            forms: [],
            phone: "",
        },
        statistics: {
            last_session: null,
            last_conversion: null,
            top_form: null,
            no_forms: "-",
            sessions_count: "-",
            conversions_count: '-'
        },
        managers: [],
        category_forms: [],
        departments: [],
        error: null
    }, 'edit-user');

    const {auth: [auth]} = useAppContext();
    const history = useHistory()
    const params = useParams();

    const saveSignature = (signature) => {
        setState(state => ({
            ...state,
            user: {
                ...state.user,
                signature: signature
            }
        }))
    };

    const getUserData = async () => {
        try {
            setState(state => ({...state, loading: true}));
            const {data} = await API.get(`users/${params.id}`);
            const {name, email, scopes, phone, isDisabled, signature, _forms, department, lastLogin, image, _manager} = data.user;
            const {statistics, category_forms, departments, managers} = data;

            const manager_id = (_manager !== null) ? _manager._id : null;

            setState(state => ({
                ...state,
                loading: false,
                user: {
                    name,
                    email,
                    image: image
                        ? image?.startsWith('http')
                            ? image
                            : `${process.env.REACT_APP_API_BASE_URL}/images/users/image/${image}`
                        : null,
                    department,
                    manager: manager_id,
                    role: scopes[0],
                    phone,
                    isDisabled,
                    signature,
                    lastLogin,
                    forms: _forms,
                },
                statistics: {...state.statistics, ...statistics},
                category_forms, departments, managers
            }))
        } catch (err) {
            setState(state => ({
                ...state,
                loading: false,
                ...(err.response.status === 404 ? {pageNotFound: true} : {})
            }))
        }
    };

    React.useEffect(() => {
        getUserData()
    }, []);

    const onUserChange = e => {
        const {target: {name, value}} = e
        setState(state => ({...state, user: {...state.user, [name]: value}}))
    };

    const onPhoneChange = phone => {
        setState(state => ({...state, user: {
            ...state.user,
            phone: addPlusPrefix(phone)
        }}));
    }

    const onSave = async e => {
        e.preventDefault();

        setState(state => ({...state, loading: true, errors: []}));

        const dataForm = new FormData();

        const {name, email, phone, role, isDisabled, signature, forms, department, manager} = state.user;

        const data = {
            name,
            email,
            phone: phone || "",
            isDisabled,
            signature,
            forms: forms || []
        };

        const errors = [];

        if (email && !isEmail(email)) {
            errors.push('invalid_email')
        }

        if (phone && phoneValidator(phone) === false) {
            errors.push('invalid_phone')
        }

        if (errors.length > 0) {
            setState(state => ({...state, loading: false, errors}));
            return
        }

        if (auth.decodedToken.scope[0] === 'admin') {
            data.role = role;

            if (manager !== null) {
                data.manager = manager;
            }
        }

        if (department !== null) {
            data.department = department;
        }

        // user data
        Object.keys(data).forEach(key => {
            if (key === 'forms') {
                dataForm.append(key, data[key].join(','));
                return
            }

            dataForm.append(key, data[key])
        });

        if (state.pictureChanged) {
            if (state.user.image) {
                dataForm.append('image', await fetch(state.user.image).then(r => r.blob()))

            } else {
                dataForm.append('remove_image', 'true')
            }
        }

        if (state.uploaded_image) {
            dataForm.append('image', state.uploaded_image)
        }
        try {
            await API.put(`users/${params.id}`, dataForm, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
        } catch (err) {
            errors.push(getError(err.response));
            setState(state => ({...state, loading: false, errors}));
            return
        }

        if (!state.pictureChanged) {
            history.push('/users')
        }

        // if (state.uploaded_image && auth.decodedToken.userid === params.id) {
        //     getNavbarData()
        // }

        setState(state => ({...state, loading: false, pictureChanged: false}))

    };

    const categoryIsSelected = category_id => {
        const form_ids = state.category_forms.find(category => category._id === category_id).forms.map(form => form._id);

        if (form_ids.length === 0)
            return false;

        return form_ids.every(r => state.user.forms.includes(r))
    };

    const onCategoryClick = e => {
        const category_id = e.target.value;
        const {checked} = e.target;
        const form_ids = state.category_forms.find(category => category._id === category_id).forms.map(form => form._id);

        if (checked) {
            setState(state => ({
                ...state,
                user: {
                    ...state.user,
                    forms: uniq([...state.user.forms, ...form_ids])
                }
            }));
            return
        }

        setState(state => ({
            ...state,
            user: {
                ...state.user,
                forms: state.user.forms.filter(form_id => !form_ids.includes(form_id))
            }
        }))
    };

    const onFormClick = e => {
        const form = e.target;
        if (!state.user.forms.includes(form.value)) {
            setState(state => ({
                ...state,
                user: {
                    ...state.user,
                    forms: [...state.user.forms, form.value]
                }
            }))
        } else {
            setState(state => ({
                ...state,
                user: {
                    ...state.user,
                    forms: state.user.forms.filter(id => id !== form.value)
                }
            }))
        }
    };

    const deleteUser = () => {
        API.delete(`users/${params.id}`)
            .then(() => {
                setState(state => ({...state, redirect_to_users: true}))
            }).catch(console.error)
    };

    const removeImage = () => {
        setState(state => ({
            ...state,
            user: {
                ...state.user,
                image: null
            },
            pictureChanged: true
        }))
    };

    const onImageChange = e => {
        const image = e.target.files[0]
        e.target.value = null

        if (image > bytes('20MB')) {
            notification.error({
                message: 'Max file size 20MB.'
            });
            return
        }
        setState(state => ({
            ...state,
            user: {
                ...state.user,
                image: URL.createObjectURL(image)
            },
            pictureChanged: true
        }))
    }

    const errorsContain = (...args) => {
        for (let i = 0; i < args.length; i++) {
            if (state.errors && state.errors.includes(args[i])) {
                setTimeout(() => {
                    document.getElementsByClassName('form-box-body')[0].scrollIntoView({
                        behavior: "smooth",
                        block: "start"
                    });
                });
                return true
            }
        }
        return false;
    };

    const resetUsersPassword = async () => {
        await API.put(`/users/${params.id}/password`)
    };

    const auth_role = auth.decodedToken.scope[0];
    const auth_id = auth.decodedToken.userid;

    if (state.redirect_to_users)
        return <Redirect to="/users"/>;

    return (
        <>
            <Navbar/>
            {state.loading && <Loading/>}
   
            <PageNotFound active={!!state.pageNotFound}>

                {!state.loading &&
            
                    <div className="wrapper agent-page">
                        <div className="content">
                            <form className="content-box form agent-form">
                                <div className="scrollbar">
                                    <div className="profile-box">
                                        <div className="profile-details">
                                            <div className="profile-image">
                                                {(state.user.image || state.uploaded_image_src) &&
                                                <>
                                                    <div className="ils-uploaded-img"
                                                        style={{display: (state.user.image || state.uploaded_image_src) ? 'block' : 'none'}}>
                                                        {/*<img src={`${/^https?:\/\//i.test(state.user.image) ? state.uploaded_image_src : process.env.REACT_APP_API_BASE_URL +'/images/users/image/' + state.user.image}`} alt="" />*/}
                                                        {state.user.image &&
                                                        <img
                                                            src={state.user.image}
                                                            alt=""
                                                            style={{objectFit: state.user.image.split('.').pop() === "svg" || state.user.image.substring("data:image/".length, state.user.image.indexOf(";base64")) === "svg+xml" ? "unset" : "cover"}}
                                                        />
                                                        }
                                                    </div>
                                                    <AdeleteConfirm
                                                        title="Are you sure you want to remove the image?"
                                                        onConfirm={removeImage}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <div className="tbl-btn"><i className="icon-ia-trash-bold"/>
                                                            <div className="tooltip tooltip-bottom">Delete</div>
                                                        </div>
                                                    </AdeleteConfirm>
                                                </>
                                                }

                                                <input className="ils-upload-file" type="file" name="" accept="image/*"
                                                    onChange={onImageChange}/>
                                                <span>Upload photo</span>
                                            </div>
                                            <div>
                                                <h3>{state.user.name}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-box-body">
                                        <h4>Personal details</h4>
                                        <fieldset>
                                            <div className="form-row">
                                                <label>User name</label>
                                                <input type="text" name="name" value={state.user.name} maxLength={60}
                                                    onChange={onUserChange}/>
                                            </div>

                                            <div className="form-grid-row">
                                                <div className="form-row">
                                                    <label>Department / partner</label>
                                                    <select value={state.user.department || ''}
                                                            onChange={e => {
                                                                const departmentDropdown = e.target;
                                                                return setState(state => ({
                                                                    ...state,
                                                                    user: {
                                                                        ...state.user,
                                                                        department: departmentDropdown.value
                                                                    }
                                                                }))
                                                            }}>
                                                        <option value=""/>
                                                        {state.departments.map(department => (
                                                            <option key={department}
                                                                    value={department}>{department}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="form-row">
                                                    <label>User type</label>
                                                    <select name="role" value={state.user.role}
                                                            onChange={onUserChange}>
                                                        <option value="agent">User</option>
                                                        {auth_role === 'admin' &&
                                                        <>
                                                            <option value="manager">Manager</option>
                                                            <option value="admin">Admin</option>
                                                        </>
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-grid-row">
                                                <div
                                                    className={`form-row ${errorsContain('invalid_email') || errorsContain('email_taken') ? 'error' : ''}`}>
                                                    <label>Email</label>
                                                    <input name="email" value={state.user.email}
                                                        onChange={onUserChange}/>
                                                    {errorsContain('email_taken') &&
                                                    <span className="form-row-error-msg">This email address is currently used by another user</span>
                                                    }
                                                    {errorsContain('invalid_email') &&
                                                    <span className="form-row-error-msg">Value must be a valid email</span>
                                                    }
                                                </div>

                                                <div
                                                    className={`form-row ${errorsContain('invalid_phone') ? 'error' : ''}`}>
                                                    <label>Phone No</label>
                                                    <input name="phone" value={state.user.phone}
                                                        onChange={({target:{value}}) => onPhoneChange(value)}/>
                                                    {errorsContain('invalid_phone') &&
                                                    <span
                                                        className="form-row-error-msg">Value must be a valid phone number</span>
                                                    }
                                                </div>
                                            </div>

                                            <h4>Business associations</h4>
                                            {state.user.role === 'agent' && auth_role === 'admin' &&
                                            <div className="form-grid-row">
                                                <div className="form-row">
                                                    <label>Manager name</label>
                                                    <select value={state.user.manager || ''}
                                                            onChange={({target: {value: manager}}) => setState(state => ({
                                                                ...state,
                                                                user: {
                                                                    ...state.user,
                                                                    manager
                                                                }
                                                            }))}>
                                                        <option value=""/>
                                                        {state.managers.map(manager => (
                                                            <option key={manager._id}
                                                                    value={manager._id}>{manager.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            }

                                            <div className="form-grid-row">
                                                {state.user.role === 'agent' && state.category_forms &&
                                                <div className="form-row">
                                                    <label>Associated categories and forms</label>
                                                    <ul className="tree">
                                                        {state.category_forms.map((category, index) => (
                                                            <li key={index}>
                                                                <div className="form-row-checkbox">
                                                                    <input id={`category_${category._id}`}
                                                                        value={category._id} type="checkbox"
                                                                        checked={categoryIsSelected(category._id)}
                                                                        onChange={onCategoryClick}/>
                                                                    <label
                                                                        htmlFor={`category_${category._id}`}>{category.name}</label>
                                                                </div>

                                                                {category.forms.length === 0 &&
                                                                <label>No forms created for this category</label>
                                                                }

                                                                <ul>
                                                                    {category.forms.map((form, key) => (
                                                                        <li key={key}>
                                                                            <div className="form-row-checkbox">
                                                                                <input id={`category_form_${form._id}`}
                                                                                    value={form._id} type="checkbox"
                                                                                    checked={state.user.forms.includes(form._id)}
                                                                                    onChange={onFormClick}/>
                                                                                <label
                                                                                    htmlFor={`category_form_${form._id}`}>{form.name}</label>
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                }

                                                <div className="form-row">
                                                    <label>Signature</label>
                                                    <SignatureCanvasWrapper
                                                        onChange={saveSignature}
                                                        value={state.user.signature}
                                                    />
                                                </div>
                                            </div>

                                            <h4>Platform access</h4>
                                            <div className="form-grid-row" style={{gridTemplateColumns: 'repeat(3,1fr)'}}>
                                                <div className="form-row">
                                                    <label>Access is</label>
                                                    <select style={{width: '15rem', maxWidth: '100%'}} name="isDisabled"
                                                            value={state.user.isDisabled} onChange={onUserChange}>
                                                        <option value={false}>Enabled</option>
                                                        <option value={true}>Disabled</option>
                                                    </select>
                                                </div>

                                                <div className="form-row">
                                                    <label>Password reset</label>

                                                    <ApopConfirm
                                                        onConfirm={resetUsersPassword}
                                                        body={`${state.user.role && state.user.role.charAt(0).toUpperCase() + state.user.role.slice(1)} ${state.user.name} will receive an email containing the new password.`}
                                                        title="Password reset"
                                                        okText="Send"
                                                        cancelText="Cancel"
                                                    >
                                                        <a>Click here to reset users's password</a>
                                                    </ApopConfirm>
                                                </div>

                                                <div className="form-row">
                                                    <label>Access log</label>
                                                    <p>Last
                                                        login: {(state.user.lastLogin) ? moment(state.user.lastLogin).format('DD.MM.YYYY, H:mm') : 'Never Logged In'}</p>
                                                </div>
                                            </div>
                                        </fieldset>

                                        <div className="form-box-actions">
                                            {auth_role === 'admin' && params.id !== auth_id &&
                                            <AdeleteConfirm
                                                title="Are you want to delete this user?"
                                                onConfirm={deleteUser}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <button type="button" className="button button-outline"><i
                                                    className="icon-ia-trash"/><span>Delete user</span></button>
                                            </AdeleteConfirm>
                                            }
                                            <button className="button" onClick={onSave}><i
                                                className="icon-check"/><span>Save</span></button>
                                        </div>
                                    </div>

                                </div>

                            </form>

                        </div>

                        <div className="sidebar">

                            <div className="scrollbar">
                                {/*{state.user.role === "agent" &&*/}
                                {/*<>*/}
                                <h3 className="sidebar-title">Statistics</h3>

                                <ul className="sidebar-list">
                                    <li>
                                        <i className="icon-ia-session"/>
                                        <strong>Total sessions</strong>
                                        <span className="highlight">{state.statistics.sessions_count}</span>
                                    </li>
                                    <li className="alert">
                                        <i className="icon-ia-conversion"/>
                                        <strong>Conversions</strong>
                                        <span className="highlight">{state.statistics.conversions_count}</span>
                                    </li>
                                    <li>
                                        <i className="icon-ia-document"/>
                                        <strong>No. of forms</strong>
                                        <span className="highlight">{state.statistics.no_forms}</span>
                                    </li>

                                    {state.statistics.top_form &&
                                    <li>
                                        <i className="icon-ia-gdpr"/>
                                        <strong>Top Form</strong>
                                        <span>{state.statistics.top_form.name}<em>{state.statistics.top_form.conversions} conversions</em></span>
                                    </li>
                                    }
                                    {!state.statistics.top_form &&
                                    <li>
                                        <i className="icon-ia-gdpr"/>
                                        <strong>Top Form</strong>
                                        <span>-</span>
                                    </li>
                                    }

                                    {state.statistics.last_session &&
                                    <li>
                                        <i className="icon-ia-session-c-f"><span><i
                                            className="icon-ia-session-c-b"/></span></i>
                                        <strong>Last session</strong>
                                        <span>{moment(state.statistics.last_session).format('DD.MM.YYYY, H:mm')}</span>
                                    </li>
                                    }
                                    {!state.statistics.last_session &&
                                    <li>
                                        <i className="icon-ia-session-c-f"><span><i
                                            className="icon-ia-session-c-b"/></span></i>
                                        <strong>Last session</strong>
                                        <span>-</span>
                                    </li>
                                    }

                                    {state.statistics.last_conversion &&
                                    <li>
                                        <i className="icon-ia-conversion-c-f"><span><i
                                            className="icon-ia-conversion-c-b"/></span></i>
                                        <strong>Last conversion</strong>
                                        <span>{moment(state.statistics.last_conversion).format('DD.MM.YYYY, H:mm')}</span>
                                    </li>
                                    }
                                    {!state.statistics.last_conversion &&
                                    <li>
                                        <i className="icon-ia-conversion-c-f"><span><i
                                            className="icon-ia-conversion-c-b"/></span></i>
                                        <strong>Last conversion</strong>
                                        <span>-</span>
                                    </li>
                                    }
                                </ul>
                                {/*</>*/}
                                {/*}*/}
                            </div>
                        </div>

                    </div>
                }
            </PageNotFound>
            <Portrait/>
        </>
    )
};

export default EditUser