import React from 'react'
import AdeleteConfirm from '../../components/AdeleteConfirm';
import CodeCopy from '../../components/Editor.fragmentCodeCopy'
import {v4 as uuid} from 'uuid'
import Validations from '../Validations'
import supportedElements from "../supportedElements";

import {TopBarContainer} from "../comonComponents/ElementEditor.TopBarContainer";
import {RequiredValidationToggle} from "../comonComponents/ElementEditor.RequiredValidationToggle";
import {VALIDATION} from "../../constants/validations";

export const ContractCommunicationOptionsEditor = ({
    element,
    remove,
    editElement,
    changeElementEditorMode,
    dragHandleProps = {},
    form,
    VisibilityAffected,
    formElements,
    addElementOnPosition,
    index
}) => {

    const [state, setState] = React.useState({
        localElement: {
            ...element
        },
        errors: {}
    })

    const localElement = state.localElement

    const setLocalElement = callback => setState(state => ({
        ...state,
        localElement: {...callback(state.localElement)}
    }))

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onValidationsClick = () => {
        changeElementEditorMode("VALIDATIONS")
    };

    const onPinnedChange = ({target: {checked}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                pinned: {
                    status: checked
                }
            }
        ))
    };

    const onSaveClick = () => {
        const errors = {};
        if (localElement.text.length > 600) {
            errors.text = "Maximum 600 characters allowed"
        }
        if (localElement.title.length > 60) {
            errors.title = "Maximum 60 characters allowed"
        }
        setState(state => ({
            ...state,
            errors
        }))
        if (Object.keys(errors).length === 0) {
            editElement({
                ...state.localElement
            })
            changeElementEditorMode("DEFAULT")
        }
    };

    const onTitleChange = ({target: {value}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                title: value
            }
        ))
    };

    const onTextChange = ({target: {value}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                text: value
            }
        ))
    };

    const duplicateComponent = () => {
        addElementOnPosition(index + 1, {
            ...element,
            editorMode: 'DEFAULT'
        })
    }

    const setValidationsLocal = newValidations => {
        setLocalElement(localElement => (
            {
                ...localElement,
                validations: newValidations
            }
        ))
    };

    const setValidationRequired = required => {
        const validations = required
            ? [...localElement.validations, {validation: VALIDATION.REQUIRED}]
            : localElement.validations.filter((validation) => validation.validation !== VALIDATION.REQUIRED)

                if(element.editorMode !== "VALIDATIONS") {
            editElement({...element, validations})
        }
        setValidationsLocal(validations)
    }

    const openEditMode = () => {
        changeElementEditorMode("EDIT")
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center standard-postal-address-box" style={{cursor: "pointer"}} onClick={openEditMode}>
                <TopBarContainer>
                    <RequiredValidationToggle
                                                    validationRequired={
                                !!(element.editorMode === "VALIDATIONS"
                                    ? localElement
                                    : element
                                ).validations.find(v => v.validation === VALIDATION.REQUIRED)
                            }
                        setValidationRequired={setValidationRequired}
                    />
                    <VisibilityAffected element={element} formElements={formElements}/>
                </TopBarContainer>
                <h6 className="supTitle">Contract Communication Options</h6>
                {localElement.title &&
                <h3 className="form-box-wrap-title">{localElement.title}</h3>
                }
                {localElement.text &&
                <p className="form-box-wrap-descrition">{localElement.text}</p>
                }
                <div className="mco-cell-header">
                    <div className="form-row-radio">
                        {(id => (
                            <>
                                <input type="radio" id={id} defaultChecked={true} readOnly={true}/>
                                <label style={{fontWeight: "300"}} htmlFor={id}>By mail</label>
                            </>
                        ))(uuid())}
                    </div>
                </div>
                <fieldset className="form-grid">
                    <div className="form-grid-row">
                        <div className="form-row">
                            <label>Name</label>
                            <CodeCopy code={`${localElement.code}_first_name`}>
                                <input type="text" value="" onChange={f => f}/>
                            </CodeCopy>
                        </div>
                        <div className="form-row">
                            <label>Surname</label>
                            <CodeCopy code={`${localElement.code}_last_name`}>
                                <input type="text" value="" onChange={f => f}/>
                            </CodeCopy>
                        </div>
                    </div>
                    <div className="form-grid-row">
                        <div className="form-row">
                            <label>County</label>
                            <CodeCopy code={`${localElement.code}_county`}>
                                <select/>
                            </CodeCopy>
                        </div>
                        <div className="form-row">
                            <label>City</label>
                            <CodeCopy code={`${localElement.code}_city`}>
                                <select/>
                            </CodeCopy>
                        </div>
                    </div>
                    <div className="form-grid-row street-grid">
                        <div className="form-row">
                            <label>Street</label>
                            <CodeCopy code={`${localElement.code}_street`}>
                                <input type="text" value="" onChange={f => f}/>
                            </CodeCopy>
                        </div>
                        <div className="form-row">
                            <label>No</label>
                            <CodeCopy code={`${localElement.code}_nr`}>
                                <input type="text" value="" onChange={f => f}/>
                            </CodeCopy>
                        </div>
                    </div>
                    <div className="form-grid-row address-grid">
                        <div className="form-row">
                            <label>Building</label>
                            <CodeCopy code={`${localElement.code}_building`}>
                                <input type="text" value="" onChange={f => f}/>
                            </CodeCopy>
                        </div>
                        <div className="form-row">
                            <label>Entrance</label>
                            <CodeCopy code={`${localElement.code}_entry`}>
                                <input type="text" value="" onChange={f => f}/>
                            </CodeCopy>
                        </div>
                        <div className="form-row">
                            <label>Floor</label>
                            <CodeCopy code={`${localElement.code}_floor`}>
                                <input type="text" value="" onChange={f => f}/>
                            </CodeCopy>
                        </div>
                        <div className="form-row">
                            <label>Ap</label>
                            <CodeCopy code={`${localElement.code}_apartment`}>
                                <input type="text" value="" onChange={f => f}/>
                            </CodeCopy>
                        </div>
                    </div>
                    <div className="form-grid-row address-grid">
                        <div className="form-row">
                            <label>Postal code</label>
                            <CodeCopy code={`${localElement.code}_postal_code`}>
                                <input type="text" value="" onChange={f => f}/>
                            </CodeCopy>
                        </div>
                    </div>
                </fieldset>
                <div className="mco-cell-header">
                    <div className="form-row-radio">
                        {(id => (
                            <>
                                <input id={id} type="radio" checked={true} onChange={f => f}/>
                                <label style={{fontWeight: "300"}} htmlFor={id}>By email</label>
                            </>
                        ))(uuid())}
                    </div>
                    <CodeCopy code={`${localElement.code}_email`}>
                        <input type="text" value="" onChange={f => f} placeholder={localElement.emailPlaceholder}/>
                    </CodeCopy>
                </div>
                <div className="mco-cell-header">
                    <div className="form-row-radio">
                        {(id => (
                            <>
                                <input id={id} type="radio" checked={true} onChange={f => f}/>
                                <label style={{fontWeight: "300"}} htmlFor={id}>By text</label>
                            </>
                        ))(uuid())}
                    </div>
                    <CodeCopy code={`${element.code}_sms_number`}>
                        <input type="text" value="" onChange={f => f}
                               placeholder={localElement.phoneNumberPlaceholderSMS}/>
                    </CodeCopy>
                </div>
                {/* <span class="form-box-wrap-id">{element.code}</span> */}
                <p className="form-row-validation-text">{localElement.validations.map(v => `[ ${v.validation} ]  `)}{localElement.pinned && localElement.pinned.status ? "[ show on top ]" : ""}</p>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onValidationsClick}><i className="icon-check trigger-validation-box"/>Validation</span>
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <AdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </AdeleteConfirm>
                        <span onClick={duplicateComponent}><i className="icon-copy"/>Duplicate</span>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className={`form-row ${state.errors.title ? "error" : ""}`}>
                    <input type="text" value={localElement.title} onChange={onTitleChange}
                           placeholder="Contract Communication Options Title"/>
                    {state.errors.title &&
                    <span className="form-row-error-msg">{state.errors.title}</span>
                    }
                </div>
                <div className={`form-row ${state.errors.text ? "error" : ""}`}>
                    <input type="text" value={localElement.text} onChange={onTextChange}
                           placeholder="Contract Communication Options Text"/>
                    {state.errors.text &&
                    <span className="form-row-error-msg">{state.errors.text}</span>
                    }
                </div>
                <div className="frc-box">
                    <div className="form-row-checkbox">
                        <input id={`pinned-${localElement.code}`} type="checkbox"
                               checked={localElement.pinned && localElement.pinned.status} onChange={onPinnedChange}/>
                        <label htmlFor={`pinned-${localElement.code}`}>Show on top in saved session</label>
                    </div>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
            <div className="form-box-wrap-center-validation mco-box">
                <div className="mco-cell"><h4>Validations</h4></div>
                <Validations
                    availableValidations={supportedElements[localElement.type].availableValidations}
                    onChange={setValidationsLocal}
                    value={localElement.validations}
                    form={form}
                />
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}