import React from 'react';
import {Route, Switch, useHistory} from 'react-router-dom'
import {notification} from './components/Lnotification'


import socketIOClient from "socket.io-client";
import Dashboard from './pages/Dashboard/Dashboard'
import Users from './pages/Users/Users'
import CreateUser from './pages/CreateUser/CreateUser'
import CreateClient from './pages/Clients/CreateClient'
import Forms from './pages/Forms/Forms'
import Sessions from './pages/Sessions/Sessions';
import ViewSession from './pages/ViewSession/ViewSession';
import Categories from './pages/Categories/Categories'
import ReportsAndInsights from './pages/ReportsAndInsights'
import Profile from './pages/Profile/Profile'
import Settings from './pages/Settings/Settings'
import Clients from './pages/Clients/Clients'
import Login from './pages/Login/Login'
import EditUser from "./pages/EditUser/EditUser";
import NewForm from "./pages/NewForm/NewForm";
import FormPreview from "./pages/FormPreview/FormPreview";
import EditForm from './pages/EditForm/EditForm'
import ActivateSession from './pages/ActivateSession/ActivateSession'
import PageNotFound from './components/PageNotFound';
import {RedirectUri as AuthorizationRedirectPage} from './pages/Settings/SessionsStorage'

import EditClient from "./pages/Clients/EditClient";
import ViewSessionVersion from './ViewSessionVersion/ViewSessionVersion'
import API from './api'
import {useAppContext} from './context'
import SessionMaintainer from './components/SessionMaintainer'
import axios from 'axios'

import SwiperCore, { Navigation } from "swiper"

SwiperCore.use([Navigation])

const AuthorisedRoute = props => {
    const {auth: [auth]} = useAppContext()
    return auth ? <Route {...props} /> : <Login/>
}

const App = () => {

    const {
        company:[,setCompany]
    } = useAppContext()

    React.useEffect(() => {
        (async () => {
            const {data} = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/company/application-settings`)
            setCompany(data)
        })()
    }, [])


    return (
        <>
            <SessionMaintainer debug={false}/>
            <StorageErrorChecker/>
            <Switch>
                <Route path="/login" component={Login} exact/>
                <AuthorisedRoute path="/categories" component={Categories} exact/>
                <AuthorisedRoute path="/forms" exact component={Forms}/>
                <AuthorisedRoute path="/forms/new" exact component={NewForm}/>
                <AuthorisedRoute path="/forms/:id/preview" exact component={FormPreview}/>
                {/* <AuthorisedRoute path="/forms/:id/edit" exact component={EditForm}/> */}
                <AuthorisedRoute path="/forms/:id/edit/:part" exact component={EditForm}/>
                <AuthorisedRoute path="/sessions/:id/version" exact component={ViewSessionVersion}/>
                <AuthorisedRoute path="/sessions/:id" component={ViewSession} exact/>
                <Route path="/session/:token/email/activation" component={ActivateSession} exact/>
                <AuthorisedRoute path="/sessions" exact component={Sessions} />
                <AuthorisedRoute path="/reports-insights" component={ReportsAndInsights} exact/>
                <AuthorisedRoute path="/users" exact component={Users}/>
                <AuthorisedRoute path="/clients" exact component={Clients}/>
                <AuthorisedRoute path="/users/create/new" exact component={CreateUser}/>
                <AuthorisedRoute path="/clients/create/new" exact component={CreateClient}/>
                <AuthorisedRoute path="/clients/:clientId/edit" exact component={EditClient}/>
                <AuthorisedRoute path="/profile" component={Profile} exact/>
                <AuthorisedRoute path="/settings" component={Settings} exact/>
                <AuthorisedRoute path="/users/:id" component={EditUser} exact/>
                <AuthorisedRoute path="/" exact component={Dashboard} />
                <AuthorisedRoute path="/storage/:provider/redirect_uri" component={AuthorizationRedirectPage} exact/>
                <Route path="*" component={() => <PageNotFound active/>}/>
            </Switch>
        </>
    )

}

export default App

const StorageErrorChecker = () => {
    const {auth: [auth]} = useAppContext()

    const [state, setState] = React.useState({
        was: auth,
        is: auth
    })

    const history = useHistory()

    React.useEffect(() => {
        setState(state => ({was: state.is, is: auth}))
    }, [auth])

    React.useEffect(() => {
        if (!state.was && state.is) {
            (async () => {
                try {
                    const {data: {company}} = await API.get('company')
                    if (company.storageFailed) {
                        notification.warning({
                            duration: 0,
                            message: (
                                <>
                                    <p>
                                        We encountered problems in saving your generated documents to {{
                                        gdrive: "Google Drive",
                                        onedrive: "Microsoft OneDrive",
                                        dropbox: "Dropbox"
                                    }[company.storage]}. Please reconnect and check your account.
                                    </p>
                                    {auth && auth.decodedToken && auth.decodedToken.scope[0] === 'admin' &&
                                    <button className="button button-outline"
                                            onClick={() => history.push('/settings', {storageConfiguration: true})}>Configure</button>
                                    }
                                </>
                            ),
                            key: "storageFailure"
                        })
                    }
                } catch (err) {
                }
            })()
        }
    }, [state])

    React.useEffect(() => {
        const socket = socketIOClient(process.env.REACT_APP_API_BASE_URL);
        socket.on("storage_error", async () => {
            const {data: {company}} = await API.get('company');

            notification.warning({
                duration: 0,
                message: (
                    <>
                        <p>
                            We encountered problems in saving your generated documents to {{
                            gdrive: "Google Drive",
                            onedrive: "Microsoft OneDrive",
                            dropbox: "Dropbox"
                        }[company.storage]}. Please reconnect and check your account.
                        </p>
                        {auth && auth.decodedToken && auth.decodedToken.scope[0] === 'admin' &&
                        <button className="button button-outline"
                                onClick={() => history.push('/settings', {storageConfiguration: true})}>Configure</button>
                        }
                    </>
                ),
                key: "storageFailure"
            })
        });

    }, [])


    return null
}
