import React from "react"
import moment from 'moment'
import {Bar} from 'react-chartjs-2'

import API from '../../api'
import Loading from '../../components/Loading'
import {getError} from "../../utils/getError"

const Sidebar = ({selectedForm=null, formsStore}) => {
	
	const [state, setState] = React.useState({
		loading_form_details: false,
		form_details: {
            id: null,
            agents_no: '-',
            best_agent: null,
            conversions: '-',
            conversions_by_week: [7, 6, 5, 4, 3, 2, 1].map(week => ({
                conversions: 0,
                week: moment().subtract(week, 'isoweeks').week()
            })),
            last_conversion: null,
            last_session: null,
            total_sessions: '-'
        }
	}, 'forms-statistics')

	const [formsState ,setFormsState] = formsStore

	React.useEffect(() => {
		if(selectedForm) {
			getFormDetails(selectedForm)
		}
	}, [selectedForm])

	const getFormDetails = async id => {
        try {
            setState(state => ({...state, loading_form_details: true}));
            const response = await API.get(`/forms/${id}/details`);
			setFormsState(formsState => ({...formsState, loadedFormDetailsOnce: true}))
			setState(state => ({...state, loading_form_details: false, form_details: {id, ...response.data}}))
        } catch (err) {
            setState(state => ({
                ...state,
                loading_form_details: false,
                form_details_error: getError(err.response)
            }))
        }
	};
	
	let data = {}

    if (state.form_details) {
        const donut_values = state.form_details.conversions_by_week.map(week_conversion => week_conversion.conversions);

        const donut_labels = state.form_details.conversions_by_week.map(week_conversion => week_conversion.week);

        data = {
            datasets: [{
                data: donut_values,
                backgroundColor: '#727cf5'
            }],

            labels: donut_labels
        };
    }

	return (
		<div className="sidebar">
			<div className="scrollbar">
				{state.loading_form_details && <Loading />}
				{!state.loading_form_details && state.form_details && (
					<>
						<h3 className="sidebar-title">Form Details</h3>
						<ul className="sidebar-list">
							<li>
								<i className="icon-ia-session" />
								<strong>Total sessions</strong>
								<span className="highlight">{state.form_details.total_sessions}</span>
							</li>
							<li className="alert">
								<i className="icon-ia-conversion" />
								<strong>Conversions</strong>
								<span className="highlight">{state.form_details.conversions}</span>
							</li>
							<li>
								<i className="icon-ia-agents" />
								<strong>No. of users</strong>
								<span className="highlight">{state.form_details.agents_no}</span>
							</li>
							{state.form_details.best_agent && (
								<li>
									<i className="icon-ia-user" />
									<strong>Best user</strong>
									<span>
										{state.form_details.best_agent.name}
										<em>{state.form_details.best_agent.conversions} conversions</em>
									</span>
								</li>
							)}
							{!state.form_details.best_agent && (
								<li>
									<i className="icon-ia-user" />
									<strong>Best user</strong>
									<span>-</span>
								</li>
							)}
							{state.form_details.last_session && (
								<li>
									<i className="icon-ia-session-c-f">
										<span>
											<i className="icon-ia-session-c-b" />
										</span>
									</i>
									<strong>Last session</strong>
									<span>
										{moment(state.form_details.last_session).format("DD.MM.YYYY, H:mm")}
									</span>
								</li>
							)}
							{!state.form_details.last_session && (
								<li>
									<i className="icon-ia-session-c-f">
										<span>
											<i className="icon-ia-session-c-b" />
										</span>
									</i>
									<strong>Last session</strong>
									<span>-</span>
								</li>
							)}
							{state.form_details.last_conversion && (
								<li>
									<i className="icon-ia-conversion-c-f">
										<span>
											<i className="icon-ia-conversion-c-b" />
										</span>
									</i>
									<strong>Last conversion</strong>
									<span>
										{moment(state.form_details.last_conversion).format(
											"DD.MM.YYYY, H:mm"
										)}
									</span>
								</li>
							)}
							{!state.form_details.last_conversion && (
								<li>
									<i className="icon-ia-conversion-c-f">
										<span>
											<i className="icon-ia-conversion-c-b" />
										</span>
									</i>
									<strong>Last conversion</strong>
									<span>-</span>
								</li>
							)}
						</ul>
						<br />
						<br />
						<h3 className="sidebar-title-2">Conversions By Week</h3>
						<Bar
							data={data}
							options={{
								maintainAspectRatio: true,
								legend: {
									display: false,
								},
								scales: {
									yAxes: [
										{
											ticks: {
												beginAtZero: true,
												min: 0,
												stepSize: 1,
												autoSkip: true,
												maxTicksLimit: 4,
											},
											gridLines: {
												drawOnChartArea: false,
											},
										},
									],
									xAxes: [
										{
											gridLines: {
												drawOnChartArea: false,
											},
										},
									],
								},
							}}
						/>
					</>
				)}
			</div>
		</div>
	)
}

export default Sidebar
