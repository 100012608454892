import React from "react"
import {useHistory} from 'react-router-dom'
import {useAppContext, getStore} from "../context"

const Lnotification = () => {
    const {
        notifications: [notifications],
    } = useAppContext()

    const history = useHistory()

	const [state, setState] = React.useState({
		removeListener: null
	})

    React.useEffect(() => {
        if (notifications && notifications.closeOnPageChange && !state.removeListener) {
			const removeListener = history.listen(() => {
                setState(state => ({...state, removeListener: null}))
				notification.cancel()
			})
			setState(state => ({...state, removeListener}))

			return removeListener
        }

    }, [notifications.closeOnPageChange])

    return (
        <>
            {notifications && (
                <div
                    className={`main-notification ${
                        {
                            warning: "alert",
                            info: "info",
                            success: "succes",
                            error: "alert",
                        }[notifications.type]
                    }`}
                    css={`
						transform: ${notifications.open ? "translate(0,0)" : "translate(0,-101%)"};
					`}
                >
                    {notifications.message}
                </div>
            )}
        </>
    )
}

Lnotification.notify = ({
                            type = "success",
                            message = "",
                            duration = 3000,
                            closeOnPageChange = false
                        }) => {
    const {
        notifications: [state, setState],
    } = getStore()
    if (state.open) {
        state.cancel()
    }

    let timeoutId
    if (duration > 0) {
        timeoutId = setTimeout(() => {
            setState(state => ({...state, open: false}))
        }, duration)
    }

    const cancel = () => {
        if (duration > 0) clearTimeout(timeoutId)
        setState(state => ({...state, open: false, message: "", closeOnPageChange: false}))
    }

    setState({message, type, open: true, cancel, closeOnPageChange})
};
["info", "warning", "success", "error"].forEach(
    type => (Lnotification[type] = args => Lnotification.notify({...args, type}))
)
const notification = Object.entries(Lnotification).reduce(
    (acc, [key, value]) => {
        acc[key] = value
        return acc
    },
    {}
)

notification.cancel = () => {
    const {
        notifications: [state],
    } = getStore()
    if (state.open) {
        state.cancel()
    }
}

export {notification}
export default Lnotification
