import React from "react"
import { GoogleMap, Marker } from "@react-google-maps/api"

import CodeCopy from "../CodeCopy"
import { useAppContext } from "../../context"
import GoogleMapsLoader from "../../components/GoogleMapsLoader"
import Loading from "../../components/Loading"


const defaultLocation = {
	lat: 45.9237859,
	lng: 22.7775086,
}

const MapComponent = () => {
	const {
		company: [company],
	} = useAppContext()

	return (
		<GoogleMapsLoader
			googleMapsApiKey={company.google_maps_api_key}
			loader={<Loading />}
		>
			<GoogleMap
				mapContainerStyle={{
					height: "100%",
					width: "100%",
				}}
				zoom={12}
				center={defaultLocation}
				options={{
					mapTypeControl: false,
				}}
			>
				<Marker
					position={defaultLocation}
					icon={"img/pin.png"}
				>

				</Marker>
			</GoogleMap>
		</GoogleMapsLoader>
	)
}

export default ({ element }) => {
	const {
		company: [company],
	} = useAppContext()
	return (
		<div className="fieldset-row">
			<p className="form-box-wrap-title">{element.title}</p>
			<p className="form-box-wrap-description">{element.text}</p>
			<CodeCopy text={element.code}>
				<div className="location-box">
					{/* <Map
                        center={{lat: 44.439663, lng: 26.096306}}
                        zoom={12}
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${company.google_maps_api_key}`}
                        loadingElement={<div style={{height: `100%`}}/>}
                        containerElement={<div style={{height: `100%`}}/>}
                        mapElement={<div style={{height: `100%`}}/>}
                    /> */}
					<MapComponent />
					<div className="search-location">
						<input type="text" name="" placeholder="Search location" />
					</div>
				</div>
			</CodeCopy>
		</div>
	)
}
