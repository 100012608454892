import React, { useEffect } from 'react'
import {Pie} from 'react-chartjs-2'

import available_colors from '../../../styles/availableColors'
import Loading from "../../../components/Loading"
import API from '../../../api'
import {notification} from "../../../components/Lnotification";

const FormsStats = ({sessionsPageStore:[,setSessionsPageStore]}) => {

    const [state, setState] = React.useState({
        loading: true,
        error: null,
        data: []
    })

    useEffect(() => {
        getFormStats()
    }, [])

    const getFormStats = async ()  => {
        try {
            setState(state => ({...state, loading: true}))
            const {data} = await API.get('/forms/statistics/top/conversions')
            setState(state => ({...state, loading: false, data}))
            setSessionsPageStore(sessionsPageSate => ({...sessionsPageSate, loadedStatsOnce: true}))
        } catch(err) {
            console.log('server error place 4')
            setState(state => ({...state, loading: false}))
            notification.error({
                message: 'Something went wrong with the server'
            })
        }
    }

    let data;

    if (state.data.length) {
        const donut_values = state.data.map(category => category.conversions);

        const donut_background_colors = [];
        donut_values.forEach((value, i) => {
            const index = i % available_colors.length;
            donut_background_colors.push(available_colors[index])
        });

        const donut_labels = state.data.map(category => category.name);

        data = {
            datasets: [{
                data: donut_values,
                backgroundColor: donut_background_colors,
                borderWidth: 0
            }],

            labels: donut_labels,
        }
    }

    if (state.loading) return <Loading/>
    

    return (
 
        <div className="sidebar">
            <div className="scrollbar">

                <h3 className="sidebar-title">Top Form Conversions</h3>

                {!state.error &&
                    <>
                        {data !== undefined
                            ?   (
                                <Pie 
                                    width={220} 
                                    data={data} 
                                    options={{
                                        legend: {
                                            display: false,
                                        },
                                        tooltips: {
                                            backgroundColor: 'rgba(188, 192, 194, 0.79)',
                                            callbacks: {
                                                label: function (tooltipItem, data) {
                                                    let label = ' ' + data.labels[tooltipItem.index] || ' ';
                                                    if (label) {
                                                        label += ': ';
                                                    }
                                                    label += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                                    return label;
                                                }
                                            }
                                        }
                                    }} 
                                />
                            )
                            : (
                                <>
                                    <Pie 
                                        width={220} 
                                        data={{
                                            datasets: [{
                                                data: [1],
                                                backgroundColor: '#FAF6FE',
                                                borderWidth: 0
                                            }],

                                            labels: ['There are no conversions to show at this time']
                                        }} 
                                        options={{
                                            legend: {
                                                display: false,
                                            }
                                        }} 
                                    />

                                    <br/><br/>
                                    <p style={{textAlign: 'center'}}>There are no conversions to show at this time</p>
                                </>
                            )
                            
                        }

                        <br/><br/>

                        <ul className="activity-pie-list">
                            { state.data.map((form, i) => {
                                const color = available_colors[i % available_colors.length];
                                return (
                                    <li key={form._id} css={`:before: {background: ${color};}`}>
                                        <div>
                                            <h6>{form.category}</h6>
                                            <h4>{form.name}</h4>
                                        </div>
                                        <div>
                                            <strong>{form.conversions} <em>contracts</em></strong>
                                            <span>{form.sessions - form.conversions} pending</span>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </>
                }
            </div>
        </div>
    )
}

export default FormsStats