import React from 'react'
import AdeleteConfirm from '../../components/AdeleteConfirm';
import mapImg from '../../images/map.svg'
import CodeCopy from '../../components/Editor.codeCopy'
import {TopBarContainer} from "../comonComponents/ElementEditor.TopBarContainer";

export const MapEditor = ({
    element,
    remove,
    editElement,
    changeElementEditorMode,
    dragHandleProps = {},
    VisibilityAffected,
    formElements,
    addElementOnPosition,
    index
}) => {

    const [state, setState] = React.useState({
        localElement: {
            ...element
        },
        errors: {}
    })

    const localElement = state.localElement

    const setLocalElement = callback => setState(state => ({
        ...state,
        localElement: {...callback(state.localElement)}
    }))

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        editElement({
            ...state.localElement
        })
        changeElementEditorMode("DEFAULT")
    };

    const onPinnedChange = ({target: {checked}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                pinned: {
                    status: checked
                }
            }
        ))
    };

    const duplicateComponent = () => {
        addElementOnPosition(index + 1, {
            ...element,
            editorMode: 'DEFAULT'
        })
    }

    const openEditMode = () => {
        changeElementEditorMode("EDIT")
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center">
                <div className="form-box-wrap-center-content" onClick={openEditMode} style={{cursor: "pointer"}}>
                    <h6 className="supTitle">Map</h6>
                    <p className="form-box-wrap-title">{localElement.title}</p>
                    <p className="form-box-wrap-description">{localElement.text}</p>
                    <div className="form-row-image-cell map-box">
                        <img src={mapImg}/>
                    </div>
                    <TopBarContainer>
                        <VisibilityAffected element={element} formElements={formElements}/>
                    </TopBarContainer>
                    <p className="form-row-validation-text">{localElement.validations.map(v => `[ ${v.validation} ]  `)}{localElement.pinned && localElement.pinned.status ? "[ show on top ]" : ""}</p>
                </div>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        {/*<span onClick={onValidationsClick}><i className="icon-check trigger-validation-box"/>Validation</span>*/}
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <AdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </AdeleteConfirm>
                        <span onClick={duplicateComponent}><i className="icon-copy"/>Duplicate</span>
                    </div>
                </div>
                <CodeCopy code={element.code}>
                    <span className="form-box-wrap-id"/>
                </CodeCopy>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className="form-row">
                    <input type="text" name="" placeholder="Map Title" value={localElement.title}
                           onChange={({target: {value}}) => {
                               setLocalElement(localElement => (
                                   {
                                       ...localElement,
                                       title: value
                                   }
                               ))
                           }}/>
                </div>
                <div className="form-row">
                    <input type="text" name="" placeholder="Map Placeholder" value={localElement.text || "Map Placeholder"}
                           onChange={({target: {value}}) => {
                               setLocalElement(localElement => (
                                   {
                                       ...localElement,
                                       text: value
                                   }
                               ))
                           }}/>
                </div>
                <div className="frc-box">
                    <div className="form-row-checkbox">
                        <input id={`pinned-${element.code}`} type="checkbox"
                               checked={localElement.pinned && localElement.pinned.status} onChange={onPinnedChange}/>
                        <label htmlFor={`pinned-${element.code}`}>Show on top in saved session</label>
                    </div>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}
