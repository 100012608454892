import React from "react"
import styled, { css } from "styled-components"
import ApopUp from "../components/ApopUp"
import RichTextViewer from "../components/RichTextViewer"

import supportedElements from "./supportedElements"
import { elements } from "../constants/elements"
import Atooltip from "../components/Atooltip"

export default ({
	oppressorElement,
	oppressorItemIndex,
	formElements = [],
	editElementByCode,
}) => {
	const [state, setState] = React.useState({
		show: false,
	})

	const showEditor = () => setState(state => ({ ...state, show: true }))

	const hideEditor = () => setState(state => ({ ...state, show: false }))

	const not_affected_components_filter = element =>
		(
			!!supportedElements[element.type] 
			 && element.code !== oppressorElement.code  // not me
			 && !element.dependency  // unoppressed ones
		)

		|| 

		(
			element.dependency 
			&& element.dependency.element_code === oppressorElement.code 
			&& element.dependency.option === oppressorItemIndex
		) // oppresed by me

	const subjectElements = formElements.filter(not_affected_components_filter)

	return (
		<>
			<EditButton
				className="icon-ia-eye-view"
				onClick={showEditor}
				title="Show/hide components based on answer"
			/>
			<ApopUp
				visible={state.show}
				onCancel={hideEditor}
				styles={{ body: { padding: 0 } }}
				onConfirm={hideEditor}
				okText="Save"
				title="Conditional visibility"
			>
				<div
					className="scrollbar"
					style={{ maxHeight: "70vh", padding: "2rem 3rem" }}
				>
					{subjectElements.map(element => (
						<SubjectElement
							key={element.code}
							editElement={edited => editElementByCode(element.code, edited)}
							element={element}
							oppressorElement={oppressorElement}
							oppressorItemIndex={oppressorItemIndex}
						/>
					))}
					{subjectElements.length === 0 && (
						<p>
							You don’t have any components available in the form for this setting.
							Please add some extra components.{" "}
						</p>
					)}
				</div>
			</ApopUp>
		</>
	)
}

const SubjectElement = ({
	editElement = f => f,
	element,
	oppressorElement,
	oppressorItemIndex,
}) => {
	const status = !element.dependency ? 0 : element.dependency.visibility ? 1 : 2

	const setRuleShow = () =>
		editElement({
			...element,
			dependency: {
				visibility: true,
				element_code: oppressorElement.code,
				option: oppressorItemIndex,
			},
		})

	const setRuleHide = () =>
		editElement({
			...element,
			dependency: {
				visibility: false,
				element_code: oppressorElement.code,
				option: oppressorItemIndex,
			},
		})

	const setRuleNoAction = () =>
		editElement({
			...element,
			dependency: undefined,
		})

	const activeStyle = css`
		&& {
			background: var(--primary-color);
			color: #fff;
			box-shadow: 0 0.1rem 0.2rem rgba(114, 124, 245, 0);
			> i {
				color: #fff;
			}
		}
	`

	return (
		<>
			<div className="form-box-wrap">
				<div
					className="form-box-wrap-center"
					css={`
						display: grid;
						grid-template-columns: auto min-content;
						padding: 0;
					`}
				>
					<div
						className="form-box-wrap-center-content scrollbar"
						css={`
							padding: 1rem;
							overflow-x: auto;
							::-webkit-scrollbar {
								height: 0.4rem;
							}
						`}
					>
						<RichTextViewer
							css={`
								display: grid;
								align-items: center;
							`}
						>
							{
								element.text 
								|| {'agent signature': 'Agent Signature', 'id card extractor': 'ID Card Extractor'}[element.type] 
								|| ""
							}
						</RichTextViewer>
					</div>

					<>
						<div
							className="form-box-wrap-options-buttons"
							css={`
								display: grid;
								grid-template-columns: min-content min-content min-content;
								position: inherit;
							`}
						>
							<span
								onClick={setRuleShow}
								className="txt2rows"
								css={status === 1 && activeStyle}
							>
								<i className="icon-ia-eye-view" />
								Show
								<br />
								Components
							</span>
							<span
								onClick={setRuleHide}
								className="txt2rows"
								css={status === 2 && activeStyle}
							>
								<i className="icon-ia-eye-hide" />
								Hide
								<br />
								Components
							</span>
							<span onClick={setRuleNoAction} css={status === 0 && activeStyle}>
								<i className="icon-ia-close trigger-close-box" />
								No action
							</span>
						</div>
					</>
				</div>
			</div>
		</>
	)
}

export const VisibilityAffected = ({ formElements, element }) => {
	return (
		<>
			{element.dependency && (
				<>
					<Atooltip
						text={`Visibility affected by rules set on: ${
							formElements.find(e => e.code === element.dependency.element_code)?.text
						}`}
					>
						<i
							className="icon-ia-eye-view visibility-affected"
							css={`
								position: absolute;
								right: 13.1rem;
								font-size: 2.1rem;
								cursor: pointer;
								top: 50%;
    							transform: translateY(-50%);
							`}
						/>
					</Atooltip>
				</>
			)}
		</>
	)
}

const EditButton = styled.i`
	position: absolute;
	right: 3.6rem;
	top: 1.8rem;
	transform: translate(0, -50%);
	margin-top: 0;
	opacity: 0.5;
	width: 3.6rem;
	height: 3.6rem;
	display: inline-block;
	font-size: 2rem;
	line-height: 3.4rem;
	border-radius: 0.3rem;
	border: 1px solid transparent;
	cursor: pointer;
	color: var(--primary-color);
	background: transparent;
	transition: all 0.3s ease-in-out;
	text-align: center;

	:active {
		color: #fff;
		background: var(--primary-color);
		box-shadow: 0 0.1rem 0.2rem rgba(114, 124, 245, 0.2);
		text-shadow: rgba(1, 12, 118, 0.3);
		text-decoration: none;
	}
	:hover {
		opacity: 1;
	}
`
