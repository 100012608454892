import React from 'react'
import styled from 'styled-components'

export default ({ element }) => {
    return (
        <div className="fieldset-row form-grid">
            <div className="multiple-files-upload">
            <p>{element.text}</p>
                <div className="upload-image-grid">
                    <UploadButton className="upload-image-box doc-upload" uploadButton={element.uploadButton}/>
                    <div className="form-row with-delete-btn">
                        <label>{element.fileName}</label>
                        <input type="text" value="doc.pdf" readOnly={true}/>
                        <i className="icon-ia-trash-bold delete-form-row-btn"/>
                        <span className="file-size">size: 25 KB</span>
                    </div>
                </div>
                <div className="upload-image-grid">
                    <UploadButton className="upload-image-box doc-upload" uploadButton={element.uploadButton}/>
                    <div className="form-row with-delete-btn">
                        <label>{element.fileName}</label>
                        <input type="text" value="doc.pdf" readOnly={true}/>
                        <i className="icon-ia-trash-bold delete-form-row-btn"/>
                        <span className="file-size">size: 25 KB</span>
                    </div>
                </div>
            </div>
            <span className="add-block-input mar2b">{element.addFileButton}</span>
        </div>
    )
}

const UploadButton = styled.div`
    ::before {
        content: "${({ uploadButton }) => uploadButton}" !important;
    }
`