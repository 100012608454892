import React from 'react'
import {isEmail} from "validator";
import {phoneValidator, addPlusPrefix} from "../../utils/phoneValidator";
import API from "../../api";
import {useAppContext} from "../../context";
import Loading from "../../components/Loading";
import {notification} from "../../components/Lnotification";

const ChangeDetails = ({profileStore, toggleTab}) => {

    const [profileState, setProfileState] = profileStore

    const {auth: [auth, setAuth]} = useAppContext();

    const [state, setState] = React.useState({
        loading: false,
        name: profileState.name,
        phone: profileState.phone,
        email: profileState.email,
        errors: {}
    });

    const setName = name => setState(state => ({...state, name}));
    const setEmail = email => setState(state => ({...state, email}));
    const setPhone = phone => {
        setState(state => ({...state, phone: addPlusPrefix(phone)}));
    }

    const changeEmail = async () => {
        try {
            setState(state => ({...state, loading: true}));
            const errors = {};

            if (!isEmail(state.email)) errors.newEmail = "Invalid email";
            if (!state.name) errors.newName = "Name can not be empty";
            if (state.phone && phoneValidator(state.phone) === false) errors.phone = "Invalid phone";


            if (Object.keys(errors).length > 0) {
                setState(state => ({...state, errors, loading: false}));
                return
            }

            await API.put('users', {
                email: state.email,
                name: state.name,
                ...(state.phone ? {phone: state.phone} : {})
            });
            setState(state => ({...state, loading: false, errors}));
            setProfileState(profileState => ({
                ...profileState,
                name: state.name,
                email: state.email,
                phone: state.phone
            }));
            setAuth(auth => ({
                ...auth,
                decodedToken: {
                    ...auth.decodedToken,
                    email: state.email,
                    name: state.name,
                    phone: state.phone
                }
            }));
            toggleTab()
        } catch (err) {
            const errors = {};
            if (err.response && err.response.data && err.response.data.error) {
                const error = err.response.data.error;
                if (error === 'email_taken') {
                    errors.api = "This email is not available"
                }
            }
            setState(state => ({...state, errors, loading: false}))
            if (Object.keys(errors).length === 0) {
                notification.error({message: 'Something went wrong'})
            }
        }
    };

    return (
        <div className="edit-details">
            <div className="titleDesc">
                <h3>Edit details</h3>
            </div>
            {state.loading && <Loading/>}
            {!state.loading &&
            <form className={`form ${Object.keys(state.errors).length > 0 ? 'error-anim' : ''}`}
                  onSubmit={async e => {
                      e.preventDefault();
                      await changeEmail()
                  }}>
                <div className="fieldset-row">
                    <div className={`form-row ${state.errors.newName ? 'error' : ''}`}>
                        <label>Name</label>
                        <input maxLength={60} value={state.name}
                               onChange={(e) => setName(e.target.value)}/>
                        {state.errors.newName &&
                        <span
                            className="form-row-error-msg">{state.errors.newName}</span>
                        }
                    </div>
                    <div className={`form-row ${state.errors.newEmail ? 'error' : ''}`}>
                        <label htmlFor="">Email</label>
                        <input maxLength={255} value={state.email}
                               onChange={(e) => setEmail(e.target.value)}/>
                        {state.errors.newEmail &&
                        <span
                            className="form-row-error-msg">{state.errors.newEmail}</span>
                        }
                    </div>
                    <div className={`form-row ${state.errors.phone ? 'error' : ''}`}>
                        <label htmlFor="">Phone No</label>
                        <input maxLength={20} value={state.phone || ""}
                               onChange={(e) => setPhone(e.target.value)}/>
                        {state.errors.phone &&
                        <span
                            className="form-row-error-msg">{state.errors.phone}</span>
                        }
                    </div>
                </div>
                <div className="form-actions">
                    <button className="button with-icon-right close-box"><i
                        className="icon-ia-checked"/>Save
                    </button>
                    <span className="button button-link close-box" onClick={toggleTab}>Cancel</span>
                </div>
            </form>
            }
        </div>
    )
}

export default ChangeDetails