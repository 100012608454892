import React, {useEffect} from 'react'
import {Pie} from 'react-chartjs-2'

import Loading from "../Loading"
import available_colors from '../../styles/availableColors'
import API from '../../api'
import {notification} from "../Lnotification";

const CategoriesTopStats = ({categoriesStore: [, setCategoriesState]}) => {

    const [state, setState] = React.useState({
        loading: true,
        error: null,
        data: []
    }, 'dashboard statistics');

    useEffect(() => {
        loadCategoryData()
    }, []);

    const loadCategoryData = async () => {
        try {
            setState(state => ({...state, error: null, loading: true}));
            const {data} = await API.get('/categories/statistics/sessions');
            setState(state => ({...state, loading: false, data}))
            setCategoriesState(categoriesState => ({...categoriesState, loadedStatsOnce: true}))
        } catch (err) {
            console.log('server error place 1')
            setState(state => ({...state, loading: false}))
            notification.error({
                message: 'Something went wrong with the server'
            })
        }
    };

    let data;

    if (state.data.length) {
        const donut_values = state.data.map(category => category.conversions);

        const donut_background_colors = [];
        donut_values.forEach((value, i) => {
            const index = i % available_colors.length;
            donut_background_colors.push(available_colors[index])
        });

        const donut_labels = state.data.map(category => category.name);

        data = {
            datasets: [{
                data: donut_values,
                backgroundColor: donut_background_colors,
                borderWidth: 0
            }],

            labels: donut_labels,
        };
    }

    if (state.loading) return <Loading/>;

    return (
        <>
            <h3 className="sidebar-title">Top Category Conversions</h3>

            {!state.error &&
            <>
                {data &&
                <Pie
                    width={220}
                    data={data}
                    options={{
                        legend: {
                            display: false,
                        },
                        tooltips: {
                            backgroundColor: 'rgba(188, 192, 194, 0.79)',
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    let label = ' ' + data.labels[tooltipItem.index] || ' ';
                                    if (label) {
                                        label += ': ';
                                    }
                                    label += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                    return label;
                                }
                            }
                        }
                    }}
                />
                }

                {!data &&
                <>
                    <Pie
                        width={220}
                        data={{
                            datasets: [{
                                data: [1],
                                backgroundColor: '#FAF6FE',
                                borderWidth: 0
                            }],
                            labels: ['There are no conversions to show at this time']
                        }}
                        options={{
                            legend: {
                                display: false,
                            },
                        }}
                    />

                    <br/><br/>
                    <p style={{textAlign: 'center'}}>There are no conversions to show at this time</p>
                </>
                }

                <br/><br/>
                <ul className="activity-pie-list">
                    {state.data.map((category, i) => (
                        <li key={category._id}
                            css={`:before {background-color: ${available_colors[i % available_colors.length]} !important;}`}>
                            <h6>{category.name}</h6>
                            <div>
                                <strong>{category.conversions} <em>contracts</em></strong>
                                <span>{category.sessions - category.conversions} pending</span>
                            </div>
                        </li>
                    ))}
                </ul>
            </>
            }
        </>
    )
};

export default CategoriesTopStats