import React from 'react'

import API from '../../api'
import Loading from '../../components/Loading'

const ForgotPassword = ({backToLogin, checkInbox}) => {
    const [state, setState] = React.useState({
        email: '',
        loading: false,
        sent: false,
        errors: {}
    })

    React.useEffect(() => {
        if(state.sent) checkInbox()
    }, [state.sent])

    const onRecoverClick = async () => {
        try {
            setState(state => ({...state, loading: true}));
            const errors = {};
            if(state.email === '') errors.email = "Invalid Email";
            setState(state => ({...state, errors}));
            if(Object.keys(errors).length > 0) {
                setState(state => ({...state, loading: false}));
                return
            }

            await API.post('auth/forgot-password', {email: state.email});
            setState(state => ({...state, loading: false, sent: true}))
            
        }catch(err) {
            const {error} = err.response.data || {response: {data:{}}};
            const errors = {};
            if(error === 'user_not_found') errors.email = "User not found"
            else errors.api = 'Something went wrong'
            setState(state => ({...state, loading: false, errors}));
        }
    }

    return (
        <>
            {state.loading && <Loading />}
            {!state.loading &&       
                <div className="forgot-pass-box">
                    <div className="forgot-pass-header">
                        <span className="button button-link with-icon-left hide-forgot-pass" onClick={backToLogin}><i className="icon-ia-arrow-bottom"></i>Back to login</span>
                    </div>
                    <div className="forgot-pass-content">
                        <div className="forgot-pass-content-wrapper">
                            <div className="forgot-pass-content-tab">
                                <div className="scrollbar">
                                    <div className="table">
                                        <div className="table-cell">
                                            <form 
                                                className={`form login-form ${Object.keys(state.errors).length > 0 ? 'error-anim' : ''}`}
                                                onSubmit={e => {
                                                    e.preventDefault()
                                                    onRecoverClick()
                                                }}
                                            >
                                                <h3>Forgot your password?</h3>
                                                <p>Enter the email address associated with your account. We’ll email you a link to reset your password.</p>
                                                <div className={`form-row ${Object.keys(state.errors).length > 0 ? 'error' : ''}`}>
                                                    <input 
                                                        type="email"
                                                        placeholder="Email address"
                                                        value={state.email}
                                                        onChange={({target: {value}}) => setState(state => ({
                                                            ...state,
                                                            email: value
                                                        }))}
                                                    />
                                                </div>
                                                {Object.keys(state.errors).length > 0 && <div className="error-msg">{Object.values(state.errors)[0]}</div>}
                                                <div className="form-row last">
                                                    <button className="button with-icon-right"><i className="icon-ia-arrow-right"></i>Recover</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="forgot-pass-content-tab">
                                <div className="table">
                                    <div className="table-cell">
                                        <div className="form login-form forgot-pass-message">
                                            <h3><i className="icon-mail"/>Check your inbox</h3>
                                            <p>A message has been sent to <strong>{state.email}</strong> with a link to change your password.</p>
                                            <p onClick={backToLogin}><span className="hide-forgot-pass">Back to login</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>      
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ForgotPassword