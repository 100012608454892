import React from 'react'

import styled, {css} from "styled-components";

export const Abutton = styled(({iconRight='', buttonLink='', className, children, ...rest}) => {

    const buttonLinkClassName = buttonLink ? 'button-link' : '';
    const iconRightClassName = iconRight ? 'with-icon-right' : '';


    return (
        <button
            disabled={true}
            className={`${className}  ${buttonLinkClassName} ${iconRightClassName} button  close-overlay`}
            {...rest}
        >
            {children}
        </button>
    )
})`

    ${props => props.disabled && css`
      background-color: gray !important;
      pointer-events: none;
      user-select: none;
      
      &.button-link {
          background-color: initial !important; 
          color: gray !important;
      }
    `}
  
 

`