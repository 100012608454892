import React from 'react'
import AdeleteConfirm from '../../components/AdeleteConfirm';
import supportedElements from '../supportedElements'
import Validations from '../Validations'
import CodeCopy from '../../components/Editor.codeCopy'
import {TopBarContainer} from "../comonComponents/ElementEditor.TopBarContainer";
import {AdvancedValidationsButton} from "../comonComponents/ElementEditor.AdvancedValidationButton";
import {RequiredValidationToggle} from "../comonComponents/ElementEditor.RequiredValidationToggle";
import {VALIDATION} from "../../constants/validations";


export const VinEditor = ({
      element,
      remove,
      editElement,
      changeElementEditorMode,
      dragHandleProps = {},
      VisibilityAffected,
      formElements,
      form,
      isApprovalForm,
      addElementOnPosition,
      index
  }) => {

    const [state, setState] = React.useState({
        localElement: {
            ...element
        },
        errors: {}
    })

    const localElement = state.localElement

    const setLocalElement = callback => setState(state => ({
        ...state,
        localElement: {...callback(state.localElement)}
    }))

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        const errors = {};
        if (localElement.text.length > 60) {
            errors.text = "Maximum 60 characters allowed"
        }
        if (localElement.placeholder.length > 60) {
            errors.placeholder = "Maximum 60 characters allowed"
        }
        setState(state => ({
            ...state,
            errors
        }))
        if (Object.keys(errors).length === 0) {
            editElement({
                ...state.localElement
            })
            changeElementEditorMode("DEFAULT")
        }
    };

    const onValidationsClick = () => {
        changeElementEditorMode("VALIDATIONS")
    };

    const onPlaceholderChange = ({target: {value}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                placeholder: value
            }
        ))
    };

    const onPinnedChange = ({target: {checked}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                pinned: {
                    status: checked
                }
            }
        ))
    };

    const duplicateComponent = () => {
        addElementOnPosition(index + 1, {
            ...element,
            editorMode: 'DEFAULT'
        })
    }

    const setValidationsLocal = newValidations => {
        setLocalElement(localElement => (
            {
                ...localElement,
                validations: newValidations
            }
        ))
    };

    const setValidationRequired = required => {
        const validations = required
            ? [...localElement.validations, {validation: VALIDATION.REQUIRED}]
            : localElement.validations.filter((validation) => validation.validation !== VALIDATION.REQUIRED)

                if(element.editorMode !== "VALIDATIONS") {
            editElement({...element, validations})
        }
        setValidationsLocal(validations)
    }

    const openEditMode = () => {
        changeElementEditorMode("EDIT")
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center">
                <div className="form-box-wrap-center-content" style={{cursor: "pointer"}} onClick={openEditMode}>
                    <div className="form-row">
                        <label>{localElement.text}</label>
                        <input style={{pointerEvents: "none"}} type="text" placeholder={localElement.placeholder} value="" onChange={f => f}
                               disabled/>
                    </div>
                    <TopBarContainer>
                        <RequiredValidationToggle
                                                        validationRequired={
                                !!(element.editorMode === "VALIDATIONS"
                                    ? localElement
                                    : element
                                ).validations.find(v => v.validation === VALIDATION.REQUIRED)
                            }
                            setValidationRequired={setValidationRequired}
                        />
                        <AdvancedValidationsButton onClick={onValidationsClick}/>
                        <VisibilityAffected element={element} formElements={formElements}/>
                    </TopBarContainer>
                    <p className="form-row-validation-text">{localElement.validations.map(v => `[ ${v.validation} ]  `)}{localElement.pinned && localElement.pinned.status ? "[ show on top ]" : ""}</p>
                    <CodeCopy code={localElement.code}>
                        <span className="form-box-wrap-id"/>
                    </CodeCopy>

                </div>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onValidationsClick}><i className="icon-check trigger-validation-box"/>Validation</span>
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <AdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </AdeleteConfirm>
                        <span onClick={duplicateComponent}><i className="icon-copy"/>Duplicate</span>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className={`form-row ${state.errors.text ? "error" : ""}`}>
                    <input id="tbl-e" type="text" value={localElement.text}
                           onChange={({target: {value}}) => {
                               setLocalElement(localElement => (
                                   {
                                       ...localElement,
                                       text: value
                                   }
                               ))
                           }} placeholder="VIN Title"/>
                    {state.errors.text &&
                    <span className="form-row-error-msg">{state.errors.text}</span>
                    }
                </div>
                <div className={`form-row ${state.errors.placeholder ? "error" : ""}`}>
                    <input type="text" value={localElement.placeholder || "VIN Placeholder"} placeholder="VIN Placeholder" onChange={onPlaceholderChange}/>
                    {state.errors.placeholder &&
                    <span className="form-row-error-msg">{state.errors.placeholder}</span>
                    }
                </div>
                {!isApprovalForm &&
                    <div className="frc-box">
                        <div className="form-row-checkbox">
                            <input id={`pinned-${localElement.code}`} type="checkbox"
                                checked={localElement.pinned && localElement.pinned.status} onChange={onPinnedChange}/>
                            <label htmlFor={`pinned-${localElement.code}`}>Show on top in saved session</label>
                        </div>
                    </div>
                }
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
            <div className="form-box-wrap-center-validation mco-box">
                <div className="mco-cell"><h4>Validations</h4></div>
                <Validations
                    availableValidations={
                        isApprovalForm
                            ? supportedElements[localElement.type].availableValidations
                                .filter((validation) => ![VALIDATION.UNIQUE].includes(validation))
                            : supportedElements[localElement.type].availableValidations
                    }
                    onChange={setValidationsLocal}
                    value={localElement.validations}
                    form={form}
                />
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}