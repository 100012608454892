import React from 'react'

import {useParams} from 'react-router-dom'
import FormProperties from './Formproperties/FormProperties'
import FormDefinition from './FormDefinition/FormDefinition'
import FormMapping from './FormMapping/FormMapping'
import ApprovationForm from './ApprovationForm/ApprovationForm'

const EditForm = () => {
    const params = useParams()
    return(
        <>
            {
                {
                    properties: <FormProperties />,
                    definition: <FormDefinition />,
                    mapping: <FormMapping />,
                    approval: <ApprovationForm />
                }[params.part]
            }
        </>
    )
}

export default EditForm