import React from 'react'
import {css} from 'styled-components'

import ApopUp from '../../../components/ApopUp'
import Loading from '../../../components/Loading'
import API from '../../../api'

const ExtraColumnsConfig = ({onUpdate = undefined}) => {

    const [state, setState] = React.useState({
        showModal: false,
        extraColumns: [],
        errorColumns: [],
        titles: [],
        loading: false,
        openedOnce: false,
        duplicatedTitles: {}
    }, "extra columns")

    let scrollbar;

    React.useEffect(() => {
        if (!state.openedOnce && state.showModal) {
            getConfig()
            getTitles()
            setState(state => ({...state, openedOnce: true}))
        }
    }, [state.showModal])

    React.useEffect(() => {
        const duplicatedTitles = {}
        state.extraColumns.forEach((e, ei) => {
            if (state.extraColumns.map(({title}) => title).includes(e.title, ei + 1)) duplicatedTitles[e.title] = true
        })

        setState(state => ({...state, duplicatedTitles}))
    }, [state.extraColumns])

    const getConfig = async () => {
        try {
            setState(state => ({...state, loading: true}))
            const {data: extraColumns} = await API.get('users/sessions/columns')
            setState(state => ({...state, extraColumns, loading: false}))
        } catch (err) {
            setState(state => ({...state, loading: false}))
            console.log(err)
        }
    }

    const getTitles = async () => {
        try {
            setState(state => ({...state, loading: true}))
            const {data: titles} = await API.get('/sessions/columns')
            setState(state => ({...state, titles, loading: false}))
        } catch (err) {
            setState(state => ({...state, loading: false}))
            console.log(err)
        }
    }

    const updateConfig = async config => {
        try {
            const errorColumns = [];
            setState(state => ({...state, loading: true}))
            for (let i = 0; i < config.length; i++) {
                if (!config[i].title || [...Object.keys(state.duplicatedTitles)].includes(config[i].title)) {
                    errorColumns.push(i);
                }
            }
            if (errorColumns.length > 0) {
                return setState(state => ({...state, errorColumns, loading: false}))
            }
            await API.put('users/sessions/columns', config.map(({title, matchTitles}) => ({title, matchTitles})))
            setState(state => ({...state, loading: false, errorColumns: [], showModal: false}))
            if (onUpdate) onUpdate()
        } catch (err) {
            setState(state => ({...state, loading: false}))
            console.log(err)
        }
    }

    const openModal = () => setState(state => ({...state, showModal: true}))
    const closeModal = () => setState(state => ({...state, showModal: false}))
    const changeExtraColumn = (columnIndex, editedColumn) => setState(state => ({
        ...state,
        extraColumns: state.extraColumns.map((e, i) => i !== columnIndex ? e : editedColumn)
    }))
    const toggleEditExtraColumn = columnIndex => setState(state => ({
        ...state,
        extraColumns: state.extraColumns.map((e, i) => i !== columnIndex ? {...e, editMode: false} : {
            ...e,
            editMode: !e.editMode
        })
    }))
    const addColumn = () => {
        setState(state => ({
            ...state,
            extraColumns: [...state.extraColumns, {title: "", matchTitles: [], editMode: true}]
        }))
        setTimeout(() => {
            if (scrollbar) {
                scrollbar.scrollTop = scrollbar.scrollHeight - scrollbar.clientHeight;
            }
        }, 100)
    }
    const deleteColumn = columnIndex => setState(state => ({
        ...state,
        extraColumns: state.extraColumns.filter((_, i) => i !== columnIndex)
    }))
    const unusedTitles = state.titles.filter(title => !state.extraColumns.reduce((acc, {matchTitles}) => [...acc, ...matchTitles], []).includes(title)).sort()

    return (
        <>
            <div onClick={openModal} className="tbl-btn trigger-overlay-table-layout">
                <i className="icon-layout"/>
                <div className="tooltip tooltip-bottom">Edit table</div>
            </div>
            <ApopUp
                title="Extra columns configuration"
                visible={state.showModal}
                onCancel={closeModal}
                okText="Save"
                onConfirm={() => updateConfig(state.extraColumns)}
                rawCss={css`
                    .overlay-content { width:45rem; }
                    .overlay-body { padding: 0; }
                    .scrollbar {max-height: 29.5rem;}
                `}
            >
                <div className="scrollbar" ref={ref => {if(ref) scrollbar = ref}}>
                    {state.loading && <Loading/>}
                    {state.extraColumns.map((extraColumn, extraColumnIndex) => (
                        <ExtraColumn
                            error={state.errorColumns.includes(extraColumnIndex)}
                            extraColumn={extraColumn}
                            key={extraColumnIndex}
                            changeExtraColumn={editedColumn => changeExtraColumn(extraColumnIndex, editedColumn)}
                            toggleEditExtraColumn={() => toggleEditExtraColumn(extraColumnIndex)}
                            deleteColumn={() => deleteColumn(extraColumnIndex)}
                            titles={unusedTitles}
                            duplicatedTitles={state.duplicatedTitles}
                        />
                    ))}
                    <span className="add-component" onClick={addColumn}>Add column</span>
                </div>
            </ApopUp>
        </>
    )
}

export default ExtraColumnsConfig

const ExtraColumn = ({
                         extraColumn,
                         changeExtraColumn,
                         toggleEditExtraColumn,
                         deleteColumn,
                         titles,
                         error,
                         duplicatedTitles
                     }) => {
    const [dropdown, setDropdown] = React.useState(false)
    const [search, setSearch] = React.useState("")
    let dropdownRef = React.useRef()

    React.useEffect(() => {
        if (extraColumn.matchTitles.length > 0 && (extraColumn.title === '' || extraColumn.title !== extraColumn.matchTitles[0])) {
            onTitleChange(extraColumn.matchTitles[0])
        }
        if (extraColumn.matchTitles.length === 0 && extraColumn.title !== '') {
            onTitleChange('')
        }
    }, [extraColumn.matchTitles])

    const {title, editMode} = extraColumn

    const saveChanges = () => changeExtraColumn({...extraColumn, editMode: false})
    const onTitleChange = newTitle => changeExtraColumn({...extraColumn, title: newTitle})
    const onMatchTitlesChange = newMatchTitles => changeExtraColumn({...extraColumn, matchTitles: newMatchTitles})

    const removeMatchTitle = name => onMatchTitlesChange(extraColumn.matchTitles.filter(m => m !== name))
    const addMatchTitle = newMatchTitle => {
        onMatchTitlesChange([...extraColumn.matchTitles, newMatchTitle]);
        setSearch("");
    }

    return (
        <div className={`column-box-wrap ${editMode ? 'view-column-box-edit' : ''}`}
             style={(error ? {borderColor: "red"} : {})}>
            <div className="column-box-details">
                <div>
                    <h3>{title}</h3>
                    {extraColumn.matchTitles.length > 0 &&
                    <p className="column-categories">
                        with
                        [ <span>{extraColumn.matchTitles.map((match, matchIndex) => `${match}${matchIndex < (extraColumn.matchTitles.length - 1) ? ',' : ''} `)}</span> ]
                    </p>
                    }
                </div>
                <div css={`
                    display: grid;
                    justify-items: end;
                    grid-auto-flow: column;
                 `}>
                    {!editMode &&
                    <div className="tbl-btn trigger-edit-column-box" onClick={toggleEditExtraColumn}><i
                        className="icon-ia-edit-bold"/>
                        <div className="tooltip tooltip-top">Edit</div>
                    </div>
                    }
                    <div className="tbl-btn trigger-delete-column-box" onClick={deleteColumn}><i
                        className="icon-ia-trash-bold"/>
                        <div className="tooltip tooltip-top">Delete</div>
                    </div>
                </div>
            </div>
            <div className={`column-box-edit ${dropdown ? 'view-dropdown' : ''}`}>
                <h6>Add component</h6>
                <div ref={dropdownRef} className="autocomplete-tags" onFocus={() => setDropdown(true)}
                     style={{cursor: 'text', outline: 'none'}} tabIndex="1" onBlur={() => setDropdown(false)}>
                    {extraColumn.matchTitles.map((match, matchIndex) => (
                        <span className="at-tag" key={matchIndex}>
                            <i className="icon-ia-close" onClick={() => removeMatchTitle(match)}
                               onMouseDown={e => e.preventDefault()}/>{match}
                        </span>
                    ))}
                    <input type="text" className="at-tag" value={search}
                           onChange={({target: {value}}) => setSearch(value)}/>
                    <div className="dropdown">
                        <div className="scrollbar">
                            <ul>
                                {titles.filter((title) => (title.toLowerCase().indexOf(search.toLowerCase()) > -1)).map((title, titleIndex) => (
                                    <li key={titleIndex}
                                        onClick={() => {
                                            addMatchTitle(title)
                                            if (dropdownRef.current) {
                                                dropdownRef.current.blur()
                                            }
                                        }}
                                    >{title}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <button className="column-box-save-button" onClick={saveChanges}>Save</button>
            </div>
        </div>
    )
}