import React from 'react'
import {Editor} from '@tinymce/tinymce-react';
import Loading from "../../components/Loading";

import { createGlobalStyle } from 'styled-components'

const GlobalCss = createGlobalStyle`
    .tox .tox-menu {
        min-width: fit-content;
    }

    .mce-content-body p {
        display: block;
    }

    .tox.tox-tinymce:hover {
        box-shadow: var(--shadow) !important;
        outline: none !important;
        border-color: var(--primary-color) !important;
    }

    .tox.tox-tinymce .tox-edit-area__iframe {
        background-color: transparent;
    }

    .tox.tox-tinymce .tox-statusbar {
        background-color: transparent;
    }

    .mce-content-body p {
        display: block;
    }

    .mce-content-body ul, .mce-content-body ol {
        line-height: 1.8rem;
        margin: 1.2rem 0
    }

    .mce-content-body ul, .mce-content-body ol, .mce-content-body li {
        list-style-type: unset;
        padding-left: 4rem;
    }
    .mce-content-body ol {
        list-style-type: numeric;
    }
    .mce-content-body li {
        padding: unset;
    }

    .tox-dialog-wrap {
        &&&&&&&&&& {
            .tox-button {
            
                width: auto;
                height: 4rem;
                display: inline-block;
                font-size: 1.1rem;
                line-height: 3.8rem;
                font-weight: 400;
                text-transform: uppercase;
                font-family: 'Lato', Arial, sans-serif;
                border: 1px solid transparent;
                padding: 0 2rem;
                cursor: pointer;
                color: #fff;
                background: #2C2457;
                border-radius: 2rem;
                text-align: center;
                box-shadow: 0 0.1rem 0.2rem rgba(114,124,245,0);
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                -webkit-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
                outline: none;

                &.tox-button--secondary  {
                    color: #39193b;
                    background: transparent!important;
                    border-color: transparent!important;
                    padding: 0!important;
                    box-shadow: none;
                    :hover {
                        color: #39193b;
                        background: transparent!important;
                        border-color: transparent!important;
                        padding: 0!important;
                        box-shadow: none;
                    }
                }

                &.tox-button--naked {
                    width: 4rem;
                    height: 4rem;
                    position: absolute;
                    top: .6rem;
                    right: .6rem;
                    display: block;
                    border-radius: 0.3rem;
                    font-size: 1.4rem;
                    line-height: 4rem;
                    text-align: center;
                    color: rgba(0,0,0,0.3);
                    cursor: pointer;
                    -webkit-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
                    -moz-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
                    -o-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
                    -ms-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
                    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
                    background: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    :hover {
                        color: #fff;
                        background: var(--primary-color);
                    }
                }
            }

            &&&& {
                .tox-dialog__header {
                    padding: 3rem 10% 0 10%;
                }
            }

            
            .tox-dialog__body-content {
                padding-right: 10%;
                padding-left: 10%;
    
            }

            .tox-dialog__footer {
                border: none;
                padding: 0 10% 3rem 10%;
                .tox-dialog__footer-end {
                    flex-direction: row-reverse;
                }
            }

            .tox-form {
                row-gap: 16px;
                .tox-textfield, .tox-listbox {
                    width: 100%;
                    height: 3.6rem;
                    color: var(--dark);
                    font-size: 1.2rem;
                    font-weight: normal;
                    font-family: 'Lato', Arial, sans-serif;
                    border-radius: .3rem!important;
                    box-shadow: none!important;
                    border: 1px solid #d1d1d1!important;
                    background: #f8f8f8!important;
                    padding: 0 1.2rem;
                    margin: 0;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    -ms-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                    user-select: text;
                }
            }
 
        }
    }
`

export default ({value = "", onChange = f => f, code}) => {

    const [state, setState] = React.useState({
        loading: true
    })

    return (
        <div className="paragraph">
            {state.loading && <Loading/>}
            <GlobalCss />
            <Editor
                init={{
                    placeholder: "This is a paragraph",
                    plugins: ['table', 'lists', 'link'],
                    menubar: false,
                    setup: editor => {
                        editor.on('blur', function () {
                            return false;
                        });
                        editor.on('init', function (e) {
                            setState(() => ({
                                loading: false
                            }));
                            editor.execCommand('mceFocus');
                        });
                        editor.ui.registry.addIcon('bold', '<svg width="24" height="24"><path fill-rule="nonzero" d="M7.875 6c0-.621.504-1.125 1.125-1.125h4a3.625 3.625 0 012.446 6.3A3.625 3.625 0 0114 18.125H8.5a.625.625 0 01-.625-.625V6zM14 12.125H9.125v4.75H14a2.375 2.375 0 100-4.75zm-1-6H9.125v4.75H13a2.375 2.375 0 100-4.75z"></path></svg>');
                        editor.ui.registry.addIcon('italic', '<svg width="24" height="24"><path fill-rule="nonzero" d="M17.13 4.88v1.25h-3.14L11.3 16.88h2.83v1.25H6.88v-1.25h3.13L12.7 6.13H9.88V4.88z"></path></svg>');
                        editor.ui.registry.addIcon('underline', '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M15.875 4.875V10.5a3.375 3.375 0 01-6.75 0V4.875h-1.25V10.5a4.625 4.625 0 109.25 0V4.875h-1.25zM5.88 16.88v1.25h13.25v-1.25z"></path></g></svg>');
                        editor.ui.registry.addIcon('unordered-list', '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M5.5 7.875a1.375 1.375 0 100-2.75 1.375 1.375 0 000 2.75zm0 5a1.375 1.375 0 100-2.75 1.375 1.375 0 000 2.75zm0 5a1.375 1.375 0 100-2.75 1.375 1.375 0 000 2.75zM8.88 15.88v1.25h10.25v-1.25zM8.88 10.88v1.25h10.25v-1.25zM8.88 5.88v1.25h10.25V5.88z"></path></g></svg>');
                        editor.ui.registry.addIcon('ordered-list', '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M9.88 15.88v1.25h9.25v-1.25zM9.88 10.88v1.25h9.25v-1.25zM9.88 5.88v1.25h9.25V5.88zM5.636 16.343l-.18.169c-.4.386-.581.627-.581.988 0 .345.28.625.625.625h3v-1.25H6.918l.25-.223c.256-.23.457-.422.636-.61.529-.555.821-1.028.821-1.542 0-1.23-.672-2.125-1.625-2.125-1.23 0-2.125.672-2.125 1.625h1.25c0-.172.27-.375.875-.375.172 0 .375.27.375.875 0 .092-.156.344-.476.68l-.198.2-.235.221-.83.742zM6.375 10.5h1.25v-5a.625.625 0 00-1.067-.442l-1.5 1.5.884.884.433-.433V10.5z"></path></g></svg>');
                        editor.ui.registry.addIcon('text-color', '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M12 19.625A5.625 5.625 0 0017.625 14c0-3.097-1.732-6.562-5.158-10.415a.625.625 0 00-.934 0C8.107 7.438 6.375 10.903 6.375 14c0 2.93 2.6 5.625 5.625 5.625zM16.375 14A4.375 4.375 0 0112 18.375c-2.324 0-4.375-2.125-4.375-4.375 0-2.512 1.349-5.416 4.077-8.696L12 4.95c2.928 3.425 4.375 6.447 4.375 9.049z"></path><path id="tox-icon-text-color__color" d="M12 17.186c1.732 0 3.125-1.695 3.125-3.772 0-2.57-6.25-2.57-6.25 0 0 2.077 1.393 3.772 3.125 3.772z"></path></g></svg>');
                        editor.ui.registry.addIcon('text-color', '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M12 19.625A5.625 5.625 0 0017.625 14c0-3.097-1.732-6.562-5.158-10.415a.625.625 0 00-.934 0C8.107 7.438 6.375 10.903 6.375 14c0 2.93 2.6 5.625 5.625 5.625zM16.375 14A4.375 4.375 0 0112 18.375c-2.324 0-4.375-2.125-4.375-4.375 0-2.512 1.349-5.416 4.077-8.696L12 4.95c2.928 3.425 4.375 6.447 4.375 9.049z"></path><path id="tox-icon-text-color__color" d="M12 17.186c1.732 0 3.125-1.695 3.125-3.772 0-2.57-6.25-2.57-6.25 0 0 2.077 1.393 3.772 3.125 3.772z"></path></g></svg>');
                        editor.ui.registry.addIcon('color-picker', '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M12 2.875c5.025 0 9.125 3.644 9.125 8.18 0 2.88-2.578 5.07-5.625 5.07H14c-.655 0-.875.22-.875.875 0 .106.048.18.27.341l.351.24c.62.434.879.768.879 1.419 0 1.328-1.196 2.125-2.625 2.125a9.126 9.126 0 010-18.25zm0 1.25a7.876 7.876 0 000 15.75c.82 0 1.375-.37 1.375-.875 0-.106-.048-.18-.27-.341l-.351-.24c-.62-.434-.879-.768-.879-1.419 0-1.345.78-2.125 2.125-2.125h1.5c2.397 0 4.375-1.68 4.375-3.82 0-3.81-3.51-6.93-7.875-6.93z"></path><path d="M7.5 11.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5S9 9.17 9 10s-.67 1.5-1.5 1.5zm2.5-3c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm3 3c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path></g></svg>');
                        editor.ui.registry.addIcon('table', '<svg width="24" height="24"><path fill-rule="nonzero" d="M18 3.875c1.174 0 2.125.951 2.125 2.125v12A2.125 2.125 0 0118 20.125H6A2.125 2.125 0 013.875 18V6c0-1.174.951-2.125 2.125-2.125h12zm-9.125 12.25h-3.75V18c0 .483.392.875.875.875h2.875v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75H18a.875.875 0 00.875-.875v-1.875zm-10-4h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm-10-4h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm-.875-3H6A.875.875 0 005.125 6v.875h13.75V6A.875.875 0 0018 5.125z"></path></svg>');
                        editor.ui.registry.addIcon('table-cell-properties', '<svg width="24" height="24"><path fill-rule="nonzero" d="M18 3.875c1.174 0 2.125.951 2.125 2.125v12A2.125 2.125 0 0118 20.125H6A2.125 2.125 0 013.875 18V6c0-1.174.951-2.125 2.125-2.125h12zm-9.125 12.25h-3.75V18c0 .483.392.875.875.875h2.875v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75H18a.875.875 0 00.875-.875v-1.875zm-10-4h-3.75v2.75h3.75v-2.75zm10 0h-3.75v2.75h3.75v-2.75zm-10-4h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm-.875-3H6A.875.875 0 005.125 6v.875h13.75V6A.875.875 0 0018 5.125z"></path></svg>');
                        editor.ui.registry.addIcon('table-merge-cells', '<svg width="24" height="24"><path fill-rule="nonzero" d="M18 3.875c1.174 0 2.125.951 2.125 2.125v12A2.125 2.125 0 0118 20.125H6A2.125 2.125 0 013.875 18V6c0-1.174.951-2.125 2.125-2.125h12zm-9.125 12.25h-3.75V18c0 .483.392.875.875.875h2.875v-2.75zm10-4h-8.75v6.75H18a.875.875 0 00.875-.875v-5.875zm-10 0h-3.75v2.75h3.75v-2.75zm0-4h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm-.875-3H6A.875.875 0 005.125 6v.875h13.75V6A.875.875 0 0018 5.125z"></path></svg>');
                        editor.ui.registry.addIcon('table-split-cells', '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M18 3.875c1.174 0 2.125.951 2.125 2.125v12A2.125 2.125 0 0118 20.125H6A2.125 2.125 0 013.875 18V6c0-1.174.951-2.125 2.125-2.125h12zm-9.125 12.25h-3.75V18c0 .483.392.875.875.875h2.875v-2.75zm10-4h-8.75v6.75H18a.875.875 0 00.875-.875v-5.875zm-10 0h-3.75v2.75h3.75v-2.75zm0-4h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm-.875-3H6A.875.875 0 005.125 6v.875h13.75V6A.875.875 0 0018 5.125z"></path><path d="M16.06 13.06l.88.88-1.56 1.56 1.56 1.56-.88.88-1.56-1.56-1.56 1.56-.88-.88 1.56-1.56-1.56-1.56.88-.88 1.56 1.56z"></path></g></svg>');
                        editor.ui.registry.addIcon('table-insert-row-above', '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M18 3.875c1.174 0 2.125.951 2.125 2.125v12A2.125 2.125 0 0118 20.125H6A2.125 2.125 0 013.875 18V6c0-1.174.951-2.125 2.125-2.125h12zm-9.125 12.25h-3.75V18c0 .483.392.875.875.875h2.875v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75H18a.875.875 0 00.875-.875v-1.875zm0-8H5.125v6.75h13.75v-6.75zm-.875-3H6A.875.875 0 005.125 6v.875h13.75V6A.875.875 0 0018 5.125z"></path><path d="M12.63 8.88v2h2v1.25h-2v2h-1.25v-2h-2v-1.25h2v-2z"></path></g></svg>');
                        editor.ui.registry.addIcon('table-insert-row-after', '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M18 3.875c1.174 0 2.125.951 2.125 2.125v12A2.125 2.125 0 0118 20.125H6A2.125 2.125 0 013.875 18V6c0-1.174.951-2.125 2.125-2.125h12zm.875 9.25H5.125V18c0 .483.392.875.875.875h12a.875.875 0 00.875-.875v-4.875zm-10-5h-3.75v3.75h3.75v-3.75zm5 0h-3.75v3.75h3.75v-3.75zm5 0h-3.75v3.75h3.75v-3.75zm-.875-3H6A.875.875 0 005.125 6v.875h13.75V6A.875.875 0 0018 5.125z"></path><path d="M12.63 13.38v2h2v1.25h-2v2h-1.25v-2h-2v-1.25h2v-2z"></path></g></svg>');
                        editor.ui.registry.addIcon('table-delete-row', '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M18 3.875c1.174 0 2.125.951 2.125 2.125v12A2.125 2.125 0 0118 20.125H6A2.125 2.125 0 013.875 18V6c0-1.174.951-2.125 2.125-2.125h12zm.875 4.25H5.125v2.75H7.5v1.25H5.125v1.75H7.5v1.25H5.125V18c0 .483.392.875.875.875h12a.875.875 0 00.875-.875v-2.875H16.5v-1.25h2.375v-1.75H16.5v-1.25h2.375v-2.75zm-.875-3H6A.875.875 0 005.125 6v.875h13.75V6A.875.875 0 0018 5.125z"></path><path d="M15.06 9.56l.88.88-3.06 3.06 3.06 3.06-.88.88L12 14.38l-3.06 3.06-.88-.88 3.06-3.06-3.06-3.06.88-.88L12 12.62z"></path></g></svg>');
                        editor.ui.registry.addIcon('table-row-properties', '<svg width="24" height="24"><path fill-rule="nonzero" d="M18 3.875c1.174 0 2.125.951 2.125 2.125v12A2.125 2.125 0 0118 20.125H6A2.125 2.125 0 013.875 18V6c0-1.174.951-2.125 2.125-2.125h12zm-9.125 12.25h-3.75V18c0 .483.392.875.875.875h2.875v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75H18a.875.875 0 00.875-.875v-1.875zm-10-8h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm5 0h-3.75v2.75h3.75v-2.75zm-.875-3H6A.875.875 0 005.125 6v.875h13.75V6A.875.875 0 0018 5.125z"></path></svg>');
                        editor.ui.registry.addIcon('table-insert-column-before', '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M20.125 18A2.125 2.125 0 0118 20.125H6A2.125 2.125 0 013.875 18V6c0-1.174.951-2.125 2.125-2.125h12c1.174 0 2.125.951 2.125 2.125v12zm-15 0c0 .483.392.875.875.875h7.875V8.125h-8.75V18zm13.75-1.875h-3.75v2.75H18a.875.875 0 00.875-.875v-1.875zm0-1.25v-2.75h-3.75v2.75h3.75zm0-4v-2.75h-3.75v2.75h3.75zM18 5.125H6A.875.875 0 005.125 6v.875h13.75V6A.875.875 0 0018 5.125z"></path><path d="M10.13 9.88v3h3v1.25h-3v3H8.88v-3h-3v-1.25h3v-3z"></path></g></svg>');
                        editor.ui.registry.addIcon('table-insert-column-after', '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M18 3.875c1.174 0 2.125.951 2.125 2.125v12A2.125 2.125 0 0118 20.125H6A2.125 2.125 0 013.875 18V6c0-1.174.951-2.125 2.125-2.125h12zm-9.125 12.25h-3.75V18c0 .483.392.875.875.875h2.875v-2.75zm10-8h-8.75v10.75H18a.875.875 0 00.875-.875V8.125zm-10 4h-3.75v2.75h3.75v-2.75zm0-4h-3.75v2.75h3.75v-2.75zm9.125-3H6A.875.875 0 005.125 6v.875h13.75V6A.875.875 0 0018 5.125z"></path><path d="M15.13 9.88v3h3v1.25h-3v3h-1.25v-3h-3v-1.25h3v-3z"></path></g></svg>');
                        editor.ui.registry.addIcon('table-delete-column', '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M18 3.875c1.174 0 2.125.951 2.125 2.125v12A2.125 2.125 0 0118 20.125H6A2.125 2.125 0 013.875 18V6c0-1.174.951-2.125 2.125-2.125h12zm0 1.25H6A.875.875 0 005.125 6v12c0 .483.392.875.875.875h12a.875.875 0 00.875-.875V6A.875.875 0 0018 5.125z"></path><path d="M4.5 8.13h15V6.88h-15z"></path><path d="M8.56 10.94l6 6 .88-.88-6-6z"></path><path d="M14.56 10.06l-6 6 .88.88 6-6z"></path><path d="M8.06 10.44l7 7 .88-.88-7-7z"></path><path d="M15.06 9.56l-7 7 .88.88 7-7zM9.88 7.5v2h1.25v-2zM12.88 8v1.5h1.25V8zM9.88 17.5v2h1.25v-2zM12.88 17.5v2h1.25v-2z"></path></g></svg>');
                        editor.ui.registry.addIcon('table-delete-table', '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M20.125 18A2.125 2.125 0 0118 20.125H6A2.125 2.125 0 013.875 18V6c0-1.174.951-2.125 2.125-2.125h12c1.174 0 2.125.951 2.125 2.125v12zM8.875 8.125h-3.75v2.75h1v1.25h-1v2.75h1v1.25h-1V18c0 .483.392.875.875.875h2.875v-1h1.25v1h3.75v-.5h1.25v.5H18a.875.875 0 00.875-.875v-1.875h-.929v-1.25h.929v-2.75h-.929v-1.25h.929v-2.75h-3.75v1h-1.25v-1h-3.75v1h-1.25v-1zm9.125-3H6A.875.875 0 005.125 6v.875h13.75V6A.875.875 0 0018 5.125z"></path><path d="M14.56 10.06l.88.88-2.56 2.56 2.56 2.56-.88.88L12 14.38l-2.56 2.56-.88-.88 2.56-2.56-2.56-2.56.88-.88L12 12.62z"></path></g></svg>');
                    },
                    // autofocus: true,
                    inline: true,
                    // fixed_toolbar_container: ".form-box-body > div:nth-child(" + (index + 1) + ") .form-box-wrap-center-edit > div",
                    fixed_toolbar_container: `#fixed_toolbar_container-${code}`,
                    toolbar: "bold italic underline bullist numlist fontsizeselect forecolor table link",
                    fontsize_formats: '0.7rem 0.8rem 0.9rem 1rem 1.2rem 1.5rem 1.9rem 2.4rem'
                }}
                onEditorChange={onChange}
                value={value}
                initialValue={value}
            />
        </div>
    )
}