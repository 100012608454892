import React from 'react'
import defaultPhoto from '../../images/photo.svg'

export default ({ element }) => {
    return (
        <div className="fieldset-row img-fieldset-row">
            <div className="form-grid" style={{ gridTemplateColumns: "repeat(1, 1fr)" }}>
                <div className="form-row-image">
                    {element.image 
                        ? <img src={element.image}/>
                        : <img src={defaultPhoto}/>
                    }
                    <p className="image-caption">{element.text}</p>
                </div>
            </div>
        </div> 
    )
}
