import API from '../../../api';
import {
    GET_CATEGORIES_FULFILLED, GET_CATEGORIES_PENDING, GET_CATEGORIES_REJECTED,
    DELETE_CATEGORY_PENDING, DELETE_CATEGORY_REJECTED, DELETE_CATEGORY_FULFILLED,
    STORE_CATEGORY_PENDING, STORE_CATEGORY_REJECTED, STORE_CATEGORY_FULFILLED,
    UPDATE_CATEGORY_PENDING, UPDATE_CATEGORY_REJECTED, UPDATE_CATEGORY_FULFILLED,
    GET_CATEGORY_TO_DELETE_INFO_FULFILLED,
    SET_PAGE, SET_SEARCH, SET_SORT
} from "./CategoriesData.reducer";
import {getError} from "../../../utils/getError";

export const getCategories = async (dispatch, state, callback) => {
    try {
        dispatch({ type: GET_CATEGORIES_PENDING });

        let url = '/categories';

        const filters = [];

        if (state) {
            if (state.search) {
                filters.push(`search=${state.search}`);
            }

            if (state.sort) {
                filters.push(`sort=${state.sort}&order=${state.order}`);
            }

            if (state.page) {
                filters.push(`page=${state.page}`);
            }
        }

        if (filters.length) {
            url += '?' + filters.join('&');
        }

        const response = await API.get(url);

        dispatch({ type: GET_CATEGORIES_FULFILLED, payload: response.data });
        callback()
    } catch (err) {
        dispatch({ type: GET_CATEGORIES_REJECTED, payload: getError(err.response) });
    }

};

export const setPage = (dispatch, page) => {
    dispatch({ type: SET_PAGE, payload: page });
};

export const setSearch = (dispatch, search) => {
    dispatch({ type: SET_SEARCH, payload: search });
};

export const setSort = (dispatch, sort) => {
    dispatch({ type: SET_SORT, payload: sort });
};

export const storeCategory = async (dispatch, name, callback) => {
    try {
        dispatch({ type: STORE_CATEGORY_PENDING });

        const response = await API.post('/categories', { name });

        dispatch({ type: STORE_CATEGORY_FULFILLED, payload: response.data.data });

        callback();
    } catch (err) {
        dispatch({ type: STORE_CATEGORY_REJECTED, payload: getError(err.response) });
    }
};

export const updateCategory = async (dispatch, id, data, callback) => {
    try {
        dispatch({ type: UPDATE_CATEGORY_PENDING });

        const response = await API.put(`/categories/${id}`, data);

        dispatch({ type: UPDATE_CATEGORY_FULFILLED, payload: response.data.data });

        callback();
    } catch (err) {
        dispatch({ type: UPDATE_CATEGORY_REJECTED, payload: getError(err.response) });
    }
};

export const deleteCategory = async (dispatch, id) => {
    try {
        dispatch({ type: DELETE_CATEGORY_PENDING });

        await API.delete(`/categories/${id}`);

        dispatch({ type: DELETE_CATEGORY_FULFILLED, payload: id });
    } catch (err) {
        dispatch({ type: DELETE_CATEGORY_REJECTED, payload: getError(err.response) });
    }
};

export const getCategoryToDeleteInformation = async (dispatch, id) => {
    try {
        const response = await API.get(`/categories/${id}/delete`);

        dispatch({ type: GET_CATEGORY_TO_DELETE_INFO_FULFILLED, payload: response.data });
    } catch (err) {
        // Do nothing
    }
};