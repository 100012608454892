import React from "react"
import API from "../../api"
import { css } from "styled-components"
import {v4 as uuid} from 'uuid'

import ApopUp from "../../components/ApopUp"
import Loading from "../../components/Loading"
import { getError } from "../../utils/getError"

const Assign = ({ index, form, agents }) => {
	const [state, setState] = React.useState({
		modal: false,
		loadingUsers: false,
		assigned_users: form.assigned_users
	})

	const closeModal = () => setState(state => ({ ...state, modal: false }))
	const openModal = () => setState(state => ({ ...state, modal: true }))

	const updateAssignedUsers = async () => {
		try {
			setState(state => ({
				...state,
				assign_users_error: null,
			}))

			await API.put(`/forms/${form._id}/users`, { users_ids: state.assigned_users })

			setState(state => ({ ...state, modal: false }))
		} catch (err) {
			setState(state => ({
				...state,
				assign_users_error: getError(err.response),
				modal: false
			}))
		}
	}

	const setAssignedUsers = assigned_users =>
		setState(state => ({ ...state, assigned_users }))

	const onAgentSelection = agent_id => {
		if (state.assigned_users.includes(agent_id)) {
			setAssignedUsers(
				state.assigned_users.filter(user_id => user_id !== agent_id)
			)
			return
		}
		setAssignedUsers([...state.assigned_users, agent_id])
	}

	const toggleSelectAllAgents = ({ target: { checked } }) =>
		setState(state => ({
			...state,
			assigned_users: checked ? agents.map(user => user._id) : [],
		}))

	return (
		<>
			<div className="tbl-btn trigger-overlay-assign-agents" onClick={openModal}>
				<i className="icon-ia-user-rights-bold" />
				<div
					className={`tooltip ${index === 0 ? "tooltip-bottom" : "tooltip-top"}`}
				>
					Assign Users
				</div>
			</div>

			<ApopUp
				title="Assign Users"
				visible={state.modal}
				onCancel={closeModal}
				rawCss={css`
					.overlay-content {
						width: 45rem;
					}
					.overlay-body {
						padding: 0;
					}
                    .scrollbar {max-height: 29.5rem;}
				`}
				okText="Assign"
				onConfirm={updateAssignedUsers}
				disableFooter={state.loadingUsers || agents.length === 0}
			>
				{(state.loadingUsers) && <Loading />}
				{!state.loadingUsers && (
					<>
						{agents.length === 0 ? (
							<h3 style={{padding: '3rem', fontSize: "1.1rem"}}>No users to assign to</h3>
						) : (
							<>
								<div className="form fixed-label">
									<ul className="assign-agents-list assign-agents-list-fixed">
										<li>
											{(id => (
												<div className="form-row-checkbox">
													<input
														id={id}
														type="checkbox"
														onChange={toggleSelectAllAgents}
														checked={agents
															.map(user => user._id)
															.every(r => state.assigned_users.includes(r))}
													/>
													<label htmlFor={id}>
														<span>Assign all</span>
													</label>
												</div>
											))(uuid())}
										</li>
									</ul>
								</div>
								<div className="scrollbar">
									<div className="form">
										<ul className="assign-agents-list">
											{agents.map(user => (
												<li key={user._id}>
													{(id => (
														<div className="form-row-checkbox">
															<input
																id={id}
																type="checkbox"
																checked={state.assigned_users.includes(user._id)}
																onChange={() => onAgentSelection(user._id)}
															/>
															<label htmlFor={id}>
																<div className="tbl-img tbl-agent-img">
																	<div>
																		{
																			!!user.image
																				? <img src={
																					user.image?.startsWith('http')
																						? user.image
																						: `${process.env.REACT_APP_API_BASE_URL}/images/users/image/${user.image}`
																				} />
																				: null
																		}
																	</div>
																</div>
																<span>{user.name}</span>
															</label>
														</div>
													))(uuid())}
												</li>
											))}
										</ul>
									</div>
								</div>
							</>
						)}
					</>
				)}
			</ApopUp>
		</>
	)
}

export default Assign