import React from 'react'

import LivePreview from './LivePreview.StandardPostalAddress'
import {StandardPostalAddressEditor} from './Editor.StandardPostalAddress'
import CodeCopy from './CodeCopy.StandardPostalAddress'
import View from './View.StandardPostalAddress'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <StandardPostalAddressEditor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,

    default: () => ({
        type: "standard address",
        text: "Standard Postal Address Group Label",
        pinned: {
            status: false
        },
        validations: []
    }),

    validate: () => false,
    group: "ADVANCED",
    icon: "icon-ia-mail",
    name: "Standard Postal Address",
    view: args => <View {...args}/>
}