import React from 'react'
import AdeleteConfirm from '../../components/AdeleteConfirm';
import supportedElements from '../supportedElements'
import Validations from '../Validations'
import {VALIDATION} from '../../constants/validations'
import CodeCopy from '../../components/Editor.codeCopy'
import Formula from './TextBox.Formula'
import ApopUp from "../../components/ApopUp";
import {notification} from "../../components/Lnotification";

import {TopBarContainer} from "../comonComponents/ElementEditor.TopBarContainer";
import {AdvancedValidationsButton} from "../comonComponents/ElementEditor.AdvancedValidationButton";
import {RequiredValidationToggle} from "../comonComponents/ElementEditor.RequiredValidationToggle";

export const ShortAnswerEditor = ({
    element,
    remove,
    editElement,
    changeElementEditorMode,
    dragHandleProps = {},
    form,
    formElements,
    isApprovalForm,
    VisibilityAffected,
    addElementOnPosition,
    index
}) => {

    const [state, setState] = React.useState({
        localElement: {
            ...element
        },
        editModal: false,
        errors: {}
    })

    const localElement = state.localElement

    const setLocalElement = callback => setState(state => ({
        ...state,
        localElement: {...callback(state.localElement)}
    }))

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        if (localElement.formula && localElement.constant && localElement.constant.value) {
            return setState(state => ({
                ...state,
                editModal: true
            }));
        }

        const errors = {};
        if (localElement.text.length > 60) {
            errors.text = "Maximum 60 characters allowed"
        }
        if (localElement.placeholder.length > 60) {
            errors.placeholder = "Maximum 60 characters allowed"
        }

        const formulaMatch = localElement.formula && localElement.formula.match(/^([{]{2}?(\d+)[}]{2}|(\d+\.?\d*))([-+*\/])([{]{2}?(\d+)[}]{2}|(\d+\.?\d*))(([-+*\/])([{]{2}?(\d+)[}]{2}|(\d+\.?\d*)))*$/) && localElement.formula.match(/{{[\d]+}}/g);
        const formulaIDs = formulaMatch ? [...new Set(localElement.formula.match(/{{[\d]+}}/g).map(formulaId => formulaId.replace(/{{|}}/g, "")))] : null;
        let disallowedElement = null;
        if (formulaIDs) {
            const formSteps = form ? form.steps : [{elements: formElements}];
            for (let i = 0; i < formSteps.length; i++) {
                for (let j = 0; j < formSteps[i].elements.length; j++) {
                    if (formulaIDs.includes(formSteps[i].elements[j].code)) {
                        const validations = formSteps[i].elements[j].validations.map((validation) => validation.validation);
                        if (!validations.includes(VALIDATION.POSITIVE)
                            && !validations.includes(VALIDATION.NUMBER)
                            && !validations.includes(VALIDATION.INTEGER)
                            && !formSteps[i].elements[j].formula
                            && formSteps[i].elements[j].code !== localElement.code) {
                            disallowedElement = formSteps[i].elements[j].code;
                        }
                        formulaIDs.splice(formulaIDs.indexOf(formSteps[i].elements[j].code), 1);
                    }
                }
            }
        }
        if (localElement.validations.length > 0 && (localElement.constant && localElement.constant.value === 'username')) {
            editElement({
                ...localElement,
                validations: []
            })
            setLocalElement(localElement => (
                {
                    ...localElement,
                    validations: []
                }
            ))
        } else if (!localElement.formula || (formulaMatch && formulaIDs.length === 0 && !disallowedElement)) {
            if (localElement.validations.length > 0 && localElement.formula) {
                editElement({
                    ...localElement,
                    validations: []
                })
                setLocalElement(localElement => (
                    {
                        ...localElement,
                        validations: []
                    }
                ))
            }
            if (Object.keys(errors).length === 0) {
                editElement({
                    ...state.localElement
                })
                changeElementEditorMode("DEFAULT")
            }
        } else {
            if (!formulaMatch) {
                errors.formula = "Formula has an incorrect format";
            } else if (disallowedElement) {
                errors.formula = `Element ${disallowedElement} is not a valid formula element`;
            } else if (formulaIDs.length > 0) {
                errors.formula = `Element ${formulaIDs[0]} does not exist in the form`;
            }
        }
        setState(state => ({
            ...state,
            errors
        }))
    };

    const onValidationsClick = () => {
        changeElementEditorMode("VALIDATIONS")
    };

    const onPlaceholderChange = ({target: {value}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                placeholder: value
            }
        ))
    };

    const onPinnedChange = ({target: {checked}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                pinned: {
                    status: checked
                }
            }
        ))
    };

    const onSetAsReadOnlyDefault = ({target: {checked}}) => {
        if (localElement.constant && checked && !localElement.constant.value) {
            return setState(state => ({
                ...state,
                errors: {
                    ...state.errors,
                    default: "Add a default value first"
                }
            }))
        }
        setLocalElement(localElement => (
            {
                ...localElement,
                constant: {
                    ...localElement.constant,
                    readOnly: checked
                }
            }
        ))
    };

    const onConstantChange = ({target: {value}}) => {
        if (localElement.constant && value && state.errors.default) {
            return setState(state => ({
                ...state,
                errors: {
                    ...state.errors,
                    default: null
                }
            }))
        }
        setLocalElement(localElement => (
            {
                ...localElement,
                constant: {
                    ...localElement.constant,
                    value
                }
            }
        ))
    };

    const onFormulaChange = value => {
        setLocalElement(localElement => (
            {
                ...localElement,
                formula: value
            }
        ))
    };

    const setValidationsLocal = newValidations => {

        if(localElement.multipleValues) {
            if(newValidations.find(validation => validation.validation === VALIDATION.EMAIL_TWO_STEP_VALIDATION)) {
                notification.warning({
                    message: 'Email two step validation can’t be set for this text box. Remove ‘Allow multiple instances’ first.'
                });
                return
            }

            if(newValidations.find(validation => validation.validation === VALIDATION.PHONE_TWO_STEP_VALIDATION)) {
                notification.warning({
                    message: 'Phone number two step validation can’t be set for this text box. Remove ‘Allow multiple instances’ first.'
                });
                return
            }
        }

        setLocalElement(localElement => (
            {
                ...localElement,
                validations: newValidations
            }
        ))
    };

    const duplicateComponent = () => {
        addElementOnPosition(index + 1, {
            ...element,
            editorMode: 'DEFAULT'
        })
    }

    const setValidationRequired = required => {
        if (element.formula) {
            notification.error({
                message: "Formula doesn’t permit validations to this component",
                duration: 5000
            });
            return;
        }

        if (element.constant && element.constant.value === 'username') {
            notification.error({
                message: "Default value doesn’t permit validations to this component",
                duration: 5000
            });
            return;
        }

        const validations = required
            ? [...localElement.validations, {validation: VALIDATION.REQUIRED}]
            : localElement.validations.filter((validation) => validation.validation !== VALIDATION.REQUIRED)

        if(element.editorMode !== "VALIDATIONS") {
            editElement({...element, validations})
        }

        setValidationsLocal(validations)
    }

    const openEditMode = () => {
        changeElementEditorMode("EDIT")
    };

    const setMultipleValues = (multipleValues) => {

        if(multipleValues) {
            if(localElement.validations.find(validation => validation.validation === VALIDATION.EMAIL_TWO_STEP_VALIDATION)) {
                notification.warning({
                    message: 'Multiple instances can’t be generated for this text box. Remove email two step validation first..'
                });
                return
            }

            if(localElement.validations.find(validation => validation.validation === VALIDATION.PHONE_TWO_STEP_VALIDATION)) {
                notification.warning({
                    message: 'Multiple instances can’t be generated for this text box. Remove phone number two step validation first.'
                });
                return
            }
        }

        setLocalElement(element => ({
            ...element,
            multipleValues
        }))
    }
    return (
        <div
            className={`form-box-wrap${element.editorMode === "OPTIONS" ? " view-box-options" : ""}${element.editorMode === "EDIT" ? " view-box-edit" : ""}${element.editorMode === "VALIDATIONS" ? " view-box-validation" : ""}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center">
                <div className="form-box-wrap-center-content" style={{cursor: "pointer"}} onClick={openEditMode}>
                    <div className="form-row">
                        <label>{localElement.text}</label>
                        <input
                            style={{pointerEvents: "none"}}
                            type="text"
                            placeholder={localElement.placeholder}
                            value=""
                            onChange={f => f}
                            disabled
                        />
                    </div>
                    <TopBarContainer>
                        <RequiredValidationToggle
                            validationRequired={
                                !!(element.editorMode === "VALIDATIONS"
                                    ? localElement
                                    : element
                                ).validations.find(v => v.validation === VALIDATION.REQUIRED)
                            }
                            setValidationRequired={setValidationRequired}
                        />
                        <AdvancedValidationsButton onClick={onValidationsClick}/>
                        <VisibilityAffected element={element} formElements={formElements}/>
                    </TopBarContainer>

                    <p className="form-row-validation-text">
                        {localElement.validations.map(v => `[ ${v.validation} ]  `)}
                        {localElement.formula ? `[ formula ]  ` : ""}
                        {localElement.constant.value && localElement.constant.value === 'username' ? `[ default value ]  ` : ""}
                        {localElement.multipleValues && "[ multiple instances ] "}
                        {localElement.pinned && localElement.pinned.status ? "[ show on top ]" : ""}
                    </p>
                    <CodeCopy code={localElement.code}>
                        <span className="form-box-wrap-id"/>
                    </CodeCopy>

                </div>

                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onValidationsClick}><i className="icon-check trigger-validation-box"/>Validation</span>
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <AdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </AdeleteConfirm>
                        <span onClick={duplicateComponent}><i className="icon-copy"/>Duplicate</span>
                    </div>
                </div>

            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className={`form-row ${state.errors.text ? "error" : ""}`}>
                    <input id="tbl-e" type="text" value={localElement.text}
                           onChange={({target: {value}}) => setLocalElement(localElement => (
                               {
                                   ...localElement,
                                   text: value
                               }
                           ))} placeholder="Text Box Title"/>
                    {state.errors.text &&
                    <span className="form-row-error-msg">{state.errors.text}</span>
                    }
                </div>
                <div className={`form-row ${state.errors.placeholder ? "error" : ""}`}>
                    <input type="text" value={localElement.placeholder || "Text Box Placeholder"} placeholder="Text Box Placeholder"
                           onChange={onPlaceholderChange}/>
                    {state.errors.placeholder &&
                    <span className="form-row-error-msg">{state.errors.placeholder}</span>
                    }
                </div>
                <div className={`${state.errors.formula ? "error" : ""}`}>
                    <Formula changeValue={onFormulaChange} value={localElement.formula}/>
                    {state.errors.formula &&
                    <span className="form-row-error-msg">{state.errors.formula}</span>
                    }
                </div>
                {!isApprovalForm &&
                    <div className="frc-box">
                        <div className={`form-row ${state.errors.default ? "error" : ""}`}>
                            <label>Default value</label>
                            <input type="text" value={localElement.constant && localElement.constant.value}
                                placeholder="username"
                                onChange={onConstantChange}/>
                            {state.errors.default &&
                            <span className="form-row-error-msg">{state.errors.default}</span>
                            }
                        </div>
                        <div className="form-row-checkbox">
                            <input id={`readOnly-${localElement.code}`} type="checkbox"
                                checked={localElement.constant && localElement.constant.readOnly}
                                onChange={onSetAsReadOnlyDefault}/>
                            <label htmlFor={`readOnly-${localElement.code}`}>Make default value read only</label>
                        </div>
                    </div>
                }

                {!isApprovalForm &&
                    <div className="frc-box">
                        <div className="form-row-checkbox">
                            <input id={`pinned-${localElement.code}`} type="checkbox"
                                checked={localElement.pinned && localElement.pinned.status}
                                onChange={onPinnedChange}/>
                            <label htmlFor={`pinned-${localElement.code}`}>Show on top in saved session</label>
                        </div>
                    </div>
                }
                <div className="frc-box">
                    <div className="form-row-checkbox">
                        <input
                            id={`multiple-instances-${localElement.code}`}
                            type="checkbox"
                            checked={!!localElement.multipleValues}
                            onChange={({target:{checked}}) => setMultipleValues(checked)}
                        />
                        <label htmlFor={`multiple-instances-${localElement.code}`}>Allow multiple instances</label>
                    </div>
                </div>
                <a className="form-box-wrap-button" onClick={() => {
                    if (localElement.validations.length > 0 && localElement.formula) {
                        return notification.error({
                            // message: "Formula doesn’t permit validations to this component",
                            message: (
                                <>
                                    <p>
                                        Formula doesn’t permit validations to this component
                                    </p>
                                    <span className="button button-outline" onClick={() => {
                                        notification.cancel();
                                        onSaveClick()
                                    }}>SAVE AND DELETE VALIDATIONS</span>
                                </>
                            ),
                            duration: 5000
                        })
                    } else if (localElement.validations.length > 0 && localElement.constant && localElement.constant.value === 'username') {
                        return notification.error({
                            message: (
                                <>
                                    <p>
                                        Default value doesn’t permit validations to this component
                                    </p>
                                    <span className="button button-outline" onClick={() => {
                                        notification.cancel();
                                        onSaveClick()
                                    }}>SAVE AND DELETE VALIDATIONS</span>
                                </>
                            ),
                            duration: 5000
                        })
                    }
                    onSaveClick()
                }}>Save</a>
                <ApopUp
                    visible={state.editModal}
                    title=""
                    onCancel={() => {
                        setState(state => ({...state, editModal: false}));
                    }}
                    okText="OK"
                    onConfirm={() => {
                        setState(state => ({...state, editModal: false}));
                    }}
                    disableCancel
                    overLayClass="overlay-template"
                >
                    <p>Formula and Default value can’t be set simultaneously</p>
                </ApopUp>
            </div>
            <div className="form-box-wrap-center-validation mco-box">
                <div className="mco-cell"><h4>Validations</h4></div>
                <Validations
                    availableValidations={
                        isApprovalForm
                            ? supportedElements[localElement.type].availableValidations
                                .filter((validation) => ![VALIDATION.PHONE_TWO_STEP_VALIDATION, VALIDATION.EMAIL_TWO_STEP_VALIDATION, VALIDATION.UNIQUE].includes(validation))
                            : supportedElements[localElement.type].availableValidations
                    }
                    onChange={setValidationsLocal}
                    value={localElement.validations}
                    form={form}
                    localElement={localElement}
                />
                <a className="form-box-wrap-button" onClick={() => {
                    if (localElement.validations.length > 0 && localElement.formula) {
                        return notification.error({
                            message: "Formula doesn’t permit validations to this component",
                            duration: 5000
                        })
                    } else if (localElement.validations.length > 0 && localElement.constant && localElement.constant.value === 'username') {
                        return notification.error({
                            message: "Default value doesn’t permit validations to this component",
                            duration: 5000
                        })
                    }
                    onSaveClick()
                }}>Save</a>
            </div>
        </div>
    )
}


