import React from 'react'

const PageNotFound = ({children, active=false}) => {

    if(active) {
        return (
            <div className="wrapper page-404">
                <div className="content">
                    <div className="content-404">
                        <div className="table">
                            <div className="table-cell">
                                <h6>404</h6>
                                <h2>This page was not found....</h2>
                            </div>
                        </div>
                        <div className="content-404-icon"></div>
                    </div>
                </div>
            </div>
        )
    } else return children || null
    
}

export default PageNotFound