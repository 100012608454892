import React from "react"
import {css} from "styled-components"
import {v4 as uuid} from 'uuid'

import ApopUp from "../../components/ApopUp"
import API from "../../api"
import {notification} from '../../components/Lnotification'
import Loading from "../../components/Loading"

export default ({formId, index}) => {
    const [state, setState] = React.useState({
        modal: false,
        openedOnce: false,
        clients: [],
        loading: false,

        selectedWithEmail: {},
        selectedWithSms: {},

        tab: "EMAIL",
        sendingWithEmail: false,
        sendingWithSms: false,
    })

    React.useEffect(() => {
        if (state.modal && !state.openedOnce) {
            getClients()
            setState(state => ({...state, openedOnce: true}))
        }
    }, [state.modal])

    const openModal = () => setState(state => ({...state, modal: true}))

    const closeModal = () =>
        setState(state => ({
            ...state,
            modal: false,
            selectedWithEmail: {},
            selectedWithSms: {},
            tab: "EMAIL",
        }))

    const getClients = async () => {
        try {
            setState(state, {...state, loading: true})
            const {data: clients} = await API.get("clients/all")
            setState(state => ({
                ...state,
                clients,
                loading: false,
            }))
        } catch (err) {
            notification.warning({message: "There was an error getting clients!"})
            setState(state, {...state, loading: false})
        }
    }

    const selectTab = tab => setState(state => ({...state, tab}))

    const toggleSelectWithEmail = id =>
        setState(state => ({
            ...state,
            selectedWithEmail: {
                ...state.selectedWithEmail,
                [id]: !state.selectedWithEmail[id],
            },
        }))

    const toggleSelectWithSms = id =>
        setState(state => ({
            ...state,
            selectedWithSms: {
                ...state.selectedWithSms,
                [id]: !state.selectedWithSms[id],
            },
        }))

    const toggleSelectAllWithEmail = ({target: {checked}}) =>
        setState(state => ({
            ...state,
            selectedWithEmail: checked
                ? state.clients
                    .filter(({email}) => email)
                    .reduce((acc, client) => ({...acc, [client._id]: true}), {})
                : {},
        }))

    const toggleSelectAllWithSms = ({target: {checked}}) =>
        setState(state => ({
            ...state,
            selectedWithSms: checked
                ? state.clients
                    .filter(({phone}) => phone)
                    .reduce((acc, client) => ({...acc, [client._id]: true}), {})
                : {},
        }))

    const sendFormToClientsWithEmail = async () => {
        try {
            setState(state => ({...state, sendingWithEmail: true}))

            const clients = Object.entries(state.selectedWithEmail)
                .filter(([_id, selected]) => selected)
                .map(([_id]) => ({_id, communication: "email"}))

            await API.post(`sessions/form/${formId}/sendToClient`, {clients})

            notification.success({message: "The form was sent successfully"})
            setState(state => ({
                ...state,
                modal: false,
                sendingWithEmail: false,
                selectedWithEmail: {},
            }))
        } catch (err) {
            notification.warning({message: "There was an error on sending forms!"})
            setState(state => ({...state, sendingWithEmail: false}))
        }
    }

    const sendFormToClientsWithSms = async () => {
        try {
            setState(state => ({...state, sendingWithSms: true}))

            const clients = Object.entries(state.selectedWithSms)
                .filter(([_id, selected]) => selected)
                .map(([_id]) => ({_id, communication: "phone"}))

            await API.post(`sessions/form/${formId}/sendToClient`, {clients})

            notification.success({message: "The form was sent successfully"})

            setState(state => ({
                ...state,
                modal: false,
                sendingWithSms: false,
                selectedWithSms: {},
            }))
        } catch (err) {
            notification.warning({message: "There was an error on sending forms!"})
            setState(state => ({...state, sendingWithSms: false}))
        }
    }

    return (
        <>
            <div onClick={openModal} className="tbl-btn trigger-overlay-email-form">
                <i className="icon-ia-mail1"/>
                <div
                    className={`tooltip ${index === 0 ? "tooltip-bottom" : "tooltip-top"}`}
                >
                    Send form to clients
                </div>
            </div>

            <ApopUp
                visible={state.modal}
                onCancel={closeModal}
                title="Send form to clients"
                rawCss={css`
					.overlay-header {
						background: #f8f8f8;
					}
					.overlay-body {
						padding: 0;
					}
					.overlay-content {
						width: 45rem;
					}

					.overlay-footer {
						background: #f8f8f8;
					}
				`}
                overLayClass={state.tab === "SMS" ? "view-sms-tab" : ""}
                disableFooter
            >
                {state.loading && <Loading/>}
                {!state.loading && (
                    <>
                        <div className="oef-tabs">
                            <span onClick={() => selectTab("EMAIL")}>Email</span>
                            <span onClick={() => selectTab("SMS")}>SMS</span>
                        </div>
                        <div className="oef-tabs-content">
                            <div className="oef-tabs-content-wrapper">
                                <div className="oef-tab-content">
                                    {state.sendingWithEmail && <Loading/>}
                                    {!state.sendingWithEmail && state.clients.filter(({email}) => email).length > 0 &&
                                    (
                                        <>
                                            <div className="form fixed-label">
                                                <ul className="send-mail-list send-mail-list-fixed">
                                                    <li>
                                                        <div className="form-row-checkbox">
                                                            <input
                                                                id="oef1-0"
                                                                type="checkbox"
                                                                checked={
                                                                    Object.entries(state.selectedWithEmail).filter(([, s]) => s)
                                                                        .length ===
                                                                    state.clients.filter(({email}) => email).length
                                                                }
                                                                onChange={toggleSelectAllWithEmail}
                                                            />
                                                            <label for="oef1-0">
                                                                <span>Send to all</span>
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="scrollbar">
                                                <div className="form">
                                                    <ul className="send-mail-list">
                                                        {state.clients
                                                            .filter(({email}) => email)
                                                            .map((client, clientIndex) => (
                                                                <li key={clientIndex}>
                                                                    {(id => (
                                                                        <div className="form-row-checkbox">
                                                                            <input
                                                                                id={id}
                                                                                type="checkbox"
                                                                                checked={!!state.selectedWithEmail[client._id]}
                                                                                onChange={() => toggleSelectWithEmail(client._id)}
                                                                            />
                                                                            <label htmlFor={id}>
																				<span>
																					{`${client.first_name || ""}${
                                                                                        client.first_name && client.last_name ? " " : ""
                                                                                    }${client.last_name || ""}`}
                                                                                    <em css={`
																						${(!client.first_name && !client.last_name) ? `
																							&&&&{
																								padding-left: unset;
																								::before {
																									background: unset;
																								}
																							}
																						` : ''}
																						
																						`}>{client.email}</em>
																				</span>
                                                                            </label>
                                                                        </div>
                                                                    ))(uuid())}
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="overlay-footer overlay-actions">
                                                {state.clients.filter(({email}) => email).length > 0 && (
                                                    <button
                                                        onClick={sendFormToClientsWithEmail}
                                                        type="button"
                                                        className="button with-icon-right close-overlay"
                                                    >
                                                        <i className="icon-ia-checked-outline"></i>Send email
                                                    </button>
                                                )}
                                                <button
                                                    onClick={closeModal}
                                                    type="button"
                                                    className="button button-link close-overlay"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    {!state.sendingWithEmail && state.clients.filter(({email}) => email).length === 0 &&
                                    <div className="empty-list">
                                        This list is empty
                                    </div>
                                    }
                                </div>
                                <div className="oef-tab-content">
                                    {state.sendingWithSms && <Loading/>}
                                    {!state.sendingWithSms && state.clients.filter(({phone}) => phone).length > 0 && (
                                        <>
                                            <div className="form fixed-label">
                                                <ul className="send-mail-list send-mail-list-fixed">
                                                    <li>
                                                        {(id => (
                                                            <div className="form-row-checkbox">
                                                                <input
                                                                    id={id}
                                                                    type="checkbox"
                                                                    checked={
                                                                        Object.entries(state.selectedWithSms).filter(([, s]) => s)
                                                                            .length ===
                                                                        state.clients.filter(({phone}) => phone).length
                                                                    }
                                                                    onChange={toggleSelectAllWithSms}
                                                                />
                                                                <label htmlFor={id}>
                                                                    <span>Send to all</span>
                                                                </label>
                                                            </div>
                                                        ))(uuid())}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="scrollbar">
                                                <div className="form">
                                                    <ul className="send-mail-list">
                                                        {state.clients
                                                            .filter(({phone}) => phone)
                                                            .map((client, clientIndex) => (
                                                                <li key={clientIndex}>
                                                                    {(id => (
                                                                        <div className="form-row-checkbox">
                                                                            <input
                                                                                id={id}
                                                                                type="checkbox"
                                                                                checked={!!state.selectedWithSms[client._id]}
                                                                                onChange={() => toggleSelectWithSms(client._id)}
                                                                            />
                                                                            <label htmlFor={id}>
																				<span>
																					{`${client.first_name || ""}${
                                                                                        client.first_name && client.last_name ? " " : ""
                                                                                    }${client.last_name || ""}`}
                                                                                    <em css={`
																						${(!client.first_name && !client.last_name) ? `
																							&&&&{
																								padding-left: unset;
																								::before {
																									background: unset;
																								}
																							}
																						` : ''}
																						
																						`}>{client.phone}</em>
																				</span>
                                                                            </label>
                                                                        </div>
                                                                    ))(uuid())}
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="overlay-footer overlay-actions">
                                                {state.clients.filter(({phone}) => phone).length > 0 && (
                                                    <button
                                                        onClick={sendFormToClientsWithSms}
                                                        type="button"
                                                        className="button with-icon-right close-overlay"
                                                    >
                                                        <i className="icon-ia-checked-outline"/>Send SMS
                                                    </button>
                                                )}
                                                <button
                                                    onClick={closeModal}
                                                    type="button"
                                                    className="button button-link close-overlay"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    {!state.sendingWithSms && state.clients.filter(({phone}) => phone).length === 0 &&
                                    <div className="empty-list">
                                        This list is empty
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </ApopUp>
        </>
    )
}
