import React from 'react';
import Loading from '../../components/Loading';

import Navbar from "../../components/Navbar"
import Portrait from "../../components/Portrait";

import ClientsData from './ClientsData/ClientsData';

const Clients = () => {
    const [state, setState] = React.useState({
        loadedClientsOnce: false,
        loadedStatsOnce: false
    })
    return (
        <>
            <Navbar />
            {(!state.loadedClientsOnce || !state.loadedStatsOnce) && <Loading />}
            <div className="wrapper clients-page"
                css={`
                    display: ${(!state.loadedClientsOnce || !state.loadedStatsOnce) ? 'none' : 'visible'};
                `}
            >
                <ClientsData clientsStore={[state, setState]}/>
            </div>
            <Portrait/>
        </>
    )
};

export default Clients;