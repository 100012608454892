import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText"

import IsignaturePad from '../../components/LsignaturePad'

const ClientSignature = ({
    element,
    answers_by_code,
    value = null
}) => {

    return (
        <div className="fieldset-row">
            {element.text && <p>{synchronizeText(element.text, answers_by_code)}</p>}
            <IsignaturePad value={value}/>
        </div>
    )
}

export default ClientSignature