import React from "react"

import { synchronizeText } from "../../utils/synchronizeText"

const exteriorModal = {
	zgariat: false,
	crapat: false,
	indoit: false,
	ruginit: false,
	cost: 0,
}

const interiorModal = {
	zgariat: false,
	patat: false,
	rupt: false,
	foarte: false,
	cost: 0,
}

const initialValue = {
	exterior: {
		baraFata: exteriorModal,
		baraFataStanga: exteriorModal,
		baraFataDreapta: exteriorModal,
		grilaFata: exteriorModal,
		capotaFata: exteriorModal,
		farDreapta: exteriorModal,
		farStanga: exteriorModal,

		parbriz: exteriorModal,
		aripaStangaFata: exteriorModal,
		jantaStangaFata: exteriorModal,
		oglindaStangaFata: exteriorModal,
		portieraStangaFata: exteriorModal,

		portieraStangaSpate: exteriorModal,
		pragaStanga: exteriorModal,
		jantaStangaSpate: exteriorModal,
		aripaStangaSpate: exteriorModal,
		baraSpate: exteriorModal,
		baraSpateStanga: exteriorModal,
		baraSpateDreapta: exteriorModal,

		capotaSpate: exteriorModal,
		luneta: exteriorModal,
		stopStanga: exteriorModal,
		stopDreapta: exteriorModal,
		aripaDreaptaSpate: exteriorModal,

		jantaDreaptaSpate: exteriorModal,
		portieraDreapta: exteriorModal,
		portieraDreaptaFata: exteriorModal,
		pragDreapta: exteriorModal,
		plafonDreapta: exteriorModal,
		plafonStanga: exteriorModal,

		oglindaDreapta: exteriorModal,
		jantaDreaptaFata: exteriorModal,
		aripaDreaptaFata: exteriorModal,
		stalpi: {
			checked: false,
			text: "",
		},

		lonjeroane: {
			checked: false,
			text: "",
		},

		observatii: {
			checked: false,
			text: "",
		},
	},
	interior: {
		pedale: interiorModal,
		volan: interiorModal,
		schimbatorViteze: interiorModal,
		butoanePlansaBord: interiorModal,
		butoaneMultimedia: interiorModal,

		butoanePortiere: interiorModal,
		cotiera: interiorModal,
		plansaBord: interiorModal,
		plafon: interiorModal,
		scaunSofer: interiorModal,

		scaunPasager: interiorModal,
		banchetaSpate: interiorModal,
		centuriSiguranta: interiorModal,
		capitonajeUsi: interiorModal,
		covorase: interiorModal,

		mirosNeplacut: {
			checked: false,
			text: "",
		},
		podea: {
			checked: false,
			text: "",
		},
		tapiserie: {
			checked: false,
			text: "",
		},
		observatii: {
			checked: false,
			text: "",
		},
	},
	pornireMotor: {
		martoriImportanti: {
			checked: false,
			text: "",
		},
		alteAlerteActive: {
			checked: false,
			text: "",
		},
		zgomoteMotor: {
			checked: false,
			text: "",
		},
		zgomoteTransmisie: {
			checked: false,
			text: "",
		},
		observatii: {
			checked: false,
			text: "",
		},
	},
	accesorii: {
		bareTransversale: {
			checked: false,
			text: "",
		},
		bullBar: {
			checked: false,
			text: "",
		},
		cameraVideoParbiz: {
			checked: false,
			text: "",
		},
		carligTractare: {
			checked: false,
			text: "",
		},
		pachetSiguranta: {
			checked: false,
			text: "",
		},
		portBagajSuplimentar: {
			checked: false,
			text: "",
		},
		praguriInox: {
			checked: false,
			text: "",
		},
		suportBiciclete: {
			checked: false,
			text: "",
		},
		suportSchiuri: {
			checked: false,
			text: "",
		},
		alteAcessorii: {
			checked: false,
			text: "",
		},
	},
}

export default ({ element, answers_by_code, value = initialValue }) => {

	return (
		<div className="fieldset-row">
			<div className="session-form-row">
				{element.title && (
					<h6 className="sup-title">
						{synchronizeText(element.title, answers_by_code)}
					</h6>
				)}
				<Exterior value={value.exterior} />
				<Interior value={value.interior} />
				<Motor value={value.pornireMotor} />
				<Accesori value={value.accesorii} />
			</div>
		</div>
	)
}

const Accesori = ({ value }) => {
	const {
		bareTransversale,
		bullBar,
		cameraVideoParbiz,
		carligTractare,
		pachetSiguranta,
		portBagajSuplimentar,
		praguriInox,
		suportBiciclete,
		suportSchiuri,
		alteAcessorii,
	} = value
	return (
		<>
			<h4 className="sup-title">Accesorii</h4>
			<ul className="table-ul preview-table preview-table-2cols">
				<li>
					<ul>
						<li>
							<strong>Bare transversale ( inclusiv cheie )</strong>
						</li>
						<li>
							<strong>
								{bareTransversale.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{bareTransversale.checked && (
						<p className="paragraph">{bareTransversale.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Bull bar</strong>
						</li>
						<li>
							<strong>{bullBar.checked && <i className="icon-ia-checked" />}</strong>
						</li>
					</ul>
					{bullBar.checked && <p className="paragraph">{bullBar.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>Camera video parbriz</strong>
						</li>
						<li>
							<strong>
								{cameraVideoParbiz.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{cameraVideoParbiz.checked && (
						<p className="paragraph">{cameraVideoParbiz.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Carlig tractare</strong>
						</li>
						<li>
							<strong>
								{carligTractare.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{carligTractare.checked && (
						<p className="paragraph">{carligTractare.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Carlig tractare</strong>
						</li>
						<li>
							<strong>
								{carligTractare.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{carligTractare.checked && (
						<p className="paragraph">{carligTractare.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Pachet siguranta ( extinctor, trusa medicala )</strong>
						</li>
						<li>
							<strong>
								{pachetSiguranta.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{pachetSiguranta.checked && (
						<p className="paragraph">{pachetSiguranta.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Portbagaj suplimentar ( inclusiv cheie )</strong>
						</li>
						<li>
							<strong>
								{portBagajSuplimentar.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{portBagajSuplimentar.checked && (
						<p className="paragraph">{portBagajSuplimentar.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Praguri inox</strong>
						</li>
						<li>
							<strong>
								{praguriInox.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{praguriInox.checked && <p className="paragraph">{praguriInox.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>Suport biciclete</strong>
						</li>
						<li>
							<strong>
								{suportBiciclete.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{suportBiciclete.checked && (
						<p className="paragraph">{suportBiciclete.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Suport schiuri</strong>
						</li>
						<li>
							<strong>
								{suportSchiuri.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{suportSchiuri.checked && (
						<p className="paragraph">{suportSchiuri.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Alte accesorii importante</strong>
						</li>
						<li>
							<strong>
								{alteAcessorii.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{alteAcessorii.checked && (
						<p className="paragraph">{alteAcessorii.text}</p>
					)}
				</li>
			</ul>
		</>
		// <SectionContainer>
		//     <label style={{marginBottom: "30px"}}>Accesorii</label>
		//     <Row>
		//         <Checkbox onChange={f => f} checked={bareTransversale}>Bare transversale ( inclusiv cheie )</Checkbox>
		//     </Row>
		//     <Row>
		//         <Checkbox onChange={f => f} checked={bullBar}>Bull bar</Checkbox>
		//     </Row>
		//     <Row>
		//         <Checkbox onChange={f => f} checked={cameraVideoParbiz}>Camera video parbriz</Checkbox>
		//     </Row>
		//     <Row>
		//         <Checkbox onChange={f => f} checked={carligTractare}>Carlig tractare</Checkbox>
		//     </Row>
		//     <Row>
		//         <Checkbox onChange={f => f} checked={pachetSiguranta}>Pachet siguranta ( extinctor, trusa medicala
		//             )</Checkbox>
		//     </Row>
		//     <Row>
		//         <Checkbox onChange={f => f} checked={portBagajSuplimentar}>Portbagaj suplimentar ( inclusiv cheie
		//             )</Checkbox>
		//     </Row>
		//     <Row>
		//         <Checkbox onChange={f => f} checked={praguriInox}>Praguri inox</Checkbox>
		//     </Row>
		//     <Row>
		//         <Checkbox onChange={f => f} checked={suportBiciclete}>Suport biciclete</Checkbox>
		//     </Row>
		//     <Row>
		//         <Checkbox onChange={f => f} checked={suportSchiuri}>Suport schiuri</Checkbox>
		//     </Row>
		//     <Row>
		//         <Checkbox onChange={f => f} checked={alteAcessorii.checked}>Alte accesorii importante</Checkbox>
		//     </Row>
		//     {alteAcessorii.checked &&
		//     <p>{alteAcessorii.text}</p>
		//     }
		// </SectionContainer>
	)
}

const Motor = ({ value }) => {
	const {
		martoriImportanti,
		alteAlerteActive,
		zgomoteMotor,
		zgomoteTransmisie,
		observatii,
	} = value
	return (
		<>
			<h4 className="sup-title">Pornire Motor</h4>
			<ul className="table-ul preview-table preview-table-2cols">
				<li>
					<ul>
						<li>
							<strong>Martori importanti aprinsi</strong>
						</li>
						<li>
							<strong>
								{martoriImportanti.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{martoriImportanti.checked && (
						<p className="paragraph">{martoriImportanti.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Alte alerte active</strong>
						</li>
						<li>
							<strong>
								{alteAlerteActive.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{alteAlerteActive.checked && (
						<p className="paragraph">{alteAlerteActive.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Probleme / Zgomote motor</strong>
						</li>
						<li>
							<strong>
								{zgomoteMotor.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{zgomoteMotor.checked && <p className="paragraph">{zgomoteMotor.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>
								Probleme / Zgomote transmisie ( cutie de viteze si diferential )
							</strong>
						</li>
						<li>
							<strong>
								{zgomoteTransmisie.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{zgomoteTransmisie.checked && (
						<p className="paragraph">{zgomoteTransmisie.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Alte avarii / Observatii</strong>
						</li>
						<li>
							<strong>
								{observatii.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{observatii.checked && <p className="paragraph">{observatii.text}</p>}
				</li>
			</ul>
		</>
	)
}

const Interior = ({ value }) => {
	const { mirosNeplacut, podea, tapiserie, observatii, ...others } = value

	others.pedale.name = "Pedale"
	others.volan.name = "Volan"
	others.schimbatorViteze.name = "Schimbator viteze"
	others.butoanePlansaBord.name = "Butoane plansa bord"
	others.butoaneMultimedia.name = "Butoane multimedia"

	others.butoanePortiere.name = "Butoane portiere / geamuri"
	others.cotiera.name = "Cotiera"
	others.plansaBord.name = "Plansa bord ( incl .torpedou )"
	others.plafon.name = "Plafon"
	others.scaunSofer.name = "Scaun sofer"

	others.scaunPasager.name = "Scaun pasager"
	others.banchetaSpate.name = "Bancheta spate"
	others.centuriSiguranta.name = "Centuri siguranta"
	others.capitonajeUsi.name = "Capitonaje usi"
	others.covorase.name = "Covorase"

	return (
		<>
			<h4 className="sup-title">Interior</h4>
			<ul className="table-ul preview-table">
				<li className="table-ul-header">
					<ul>
						<li>
							<strong>Avariat</strong>
						</li>
						<li>
							<strong>Zgariat</strong>
						</li>
						<li>
							<strong>Crapat</strong>
						</li>
						<li>
							<strong>Indoit</strong>
						</li>
						<li>
							<strong>Ruginit</strong>
						</li>
						<li>
							<strong>Cost</strong>
						</li>
					</ul>
				</li>
				{Object.values(others).map(
					({ name, zgariat, patat, rupt, foarte, cost }) => {
						return (
							<li>
								<ul>
									<li>
										<strong>{name}</strong>
									</li>
									<li>
										<strong>{zgariat && <i className="icon-ia-checked" />}</strong>
									</li>
									<li>
										<strong>{patat && <i className="icon-ia-checked" />}</strong>
									</li>
									<li>
										<strong>{rupt && <i className="icon-ia-checked" />}</strong>
									</li>
									<li>
										<strong>{foarte && <i className="icon-ia-checked" />}</strong>
									</li>
									<li>{cost > 0 && <strong>{cost}€</strong>}</li>
								</ul>
							</li>
						)
					}
				)}
			</ul>
			<ul className="table-ul preview-table preview-table-2cols">
				<li>
					<ul>
						<li>
							<strong>Miros neplacut</strong>
						</li>
						<li>
							<strong>
								{mirosNeplacut.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{mirosNeplacut.checked && (
						<p className="paragraph">{mirosNeplacut.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Podea / Tapiserie cu urme de umezeala</strong>
						</li>
						<li>
							<strong>{podea.checked && <i className="icon-ia-checked" />}</strong>
						</li>
					</ul>
					{podea.checked && <p className="paragraph">{podea.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>Stare generala tapiserie</strong>
						</li>
						<li>
							<strong>{tapiserie.checked && <i className="icon-ia-checked" />}</strong>
						</li>
					</ul>
					{tapiserie.checked && <p className="paragraph">{tapiserie.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>Alte avarii / Observatii</strong>
						</li>
						<li>
							<strong>
								{observatii.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{observatii.checked && <p className="paragraph">{observatii.text}</p>}
				</li>
			</ul>
		</>
	)
}

const Exterior = ({ value }) => {
	const { stalpi, lonjeroane, observatii, ...others } = value

	const exteriorModal = {
		zgariat: false,
		crapat: false,
		indoit: false,
		ruginit: false,
		cost: 0,
	}

	;[
		"baraFataStanga",
		"baraFataDreapta",
		"baraSpateStanga",
		"baraSpateDreapta",
		"plafonDreapta",
		"plafonStanga",
	].forEach(key => {
		if (!others[key]) others[key] = { ...exteriorModal }
	})

	others.baraFata.name = "Bara fata"
	others.baraFataStanga.name = "Bara fata stanga"

	others.baraFataDreapta.name = "Bara fata dreapta"
	others.grilaFata.name = "Grila fata"
	others.capotaFata.name = "Capota fata"
	others.farDreapta.name = "Far dreapta"
	others.farStanga.name = "Far stanga"

	others.parbriz.name = "Parbriz"
	others.aripaStangaFata.name = "Aripa stanga fata"
	others.jantaStangaFata.name = "Janta stanga fata"
	others.oglindaStangaFata.name = "Oglinda stanga fata"
	others.portieraStangaFata.name = "Portiera stanga fata"

	others.portieraStangaSpate.name = "Portiera stanga spate"
	others.pragaStanga.name = "Prag stanga"
	others.jantaStangaSpate.name = "Janta stanga spate"
	others.aripaStangaSpate.name = "Aripa stanga spate"
	others.baraSpate.name = "Bara spate"
	others.baraSpateStanga.name = "Bara spate stanga"
	others.baraSpateDreapta.name = "Bara spate dreapta"

	others.capotaSpate.name = "Capota spate"
	others.luneta.name = "Luneta"
	others.stopStanga.name = "Stop stanga"
	others.stopDreapta.name = "Stop dreapta"
	others.aripaDreaptaSpate.name = "Aripa dreapta spate"

	others.jantaDreaptaSpate.name = "Janta dreapta spate"
	others.portieraDreapta.name = "Portiera dreapta spate"
	others.portieraDreaptaFata.name = "Portiera dreapta fata"
	others.pragDreapta.name = "Prag dreapta"
	others.plafonDreapta.name = "Plafon Dreapta"
	others.plafonStanga.name = "Plafon Stanga"

	others.oglindaDreapta.name = "Oglinda dreapta"
	others.jantaDreaptaFata.name = "Janta dreapta fata"
	others.aripaDreaptaFata.name = "Aripa dreapta fata"

	return (
		<>
			<h4 className="sup-title">Exterior</h4>
			<ul className="table-ul preview-table">
				<li className="table-ul-header">
					<ul>
						<li>
							<strong>Avariat</strong>
						</li>
						<li>
							<strong>Zgariat</strong>
						</li>
						<li>
							<strong>Crapat</strong>
						</li>
						<li>
							<strong>Indoit</strong>
						</li>
						<li>
							<strong>Ruginit</strong>
						</li>
						<li>
							<strong>Cost</strong>
						</li>
					</ul>
				</li>
				{Object.values(others).map(
					({ name, zgariat, crapat, indoit, ruginit, cost }) => {
						return (
							<li>
								<ul>
									<li>
										<strong>{name}</strong>
									</li>
									<li>
										<strong>{zgariat && <i className="icon-ia-checked" />}</strong>
									</li>
									<li>
										<strong>{crapat && <i className="icon-ia-checked" />}</strong>
									</li>
									<li>
										<strong>{indoit && <i className="icon-ia-checked" />}</strong>
									</li>
									<li>
										<strong>{ruginit && <i className="icon-ia-checked" />}</strong>
									</li>
									<li>{cost > 0 && <strong>{cost}€</strong>}</li>
								</ul>
							</li>
						)
					}
				)}
			</ul>
			<ul className="table-ul preview-table preview-table-2cols">
				<li>
					<ul>
						<li>
							<strong>Stalpi / Montanti</strong>
						</li>
						<li>
							<strong>{stalpi.checked && <i className="icon-ia-checked" />}</strong>
						</li>
					</ul>
					{stalpi.checked && <p className="paragraph">{stalpi.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>Lonjeroane</strong>
						</li>
						<li>
							<strong>
								{lonjeroane.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{lonjeroane.checked && <p className="paragraph">{lonjeroane.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>Alte avarii / Observatii</strong>
						</li>
						<li>
							<strong>
								{observatii.checked && <i className="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{observatii.checked && <p className="paragraph">{observatii.text}</p>}
				</li>
			</ul>
		</>
	)
}
