import React from 'react'
import AdeleteConfirm from '../../components/AdeleteConfirm';
import CardImg from '../../images/id-card.svg'
import CodeCopy from '../../components/Editor.fragmentCodeCopy'
import Validations from "../Validations";
import supportedElements from "../supportedElements";

import {TopBarContainer} from "../comonComponents/ElementEditor.TopBarContainer";
import {VALIDATION} from "../../constants/validations";
import styled from "styled-components";
import {v4 as uuid} from 'uuid'

export const IdCardExtractorEditor = ({
    element,
    remove,
    editElement,
    changeElementEditorMode,
    dragHandleProps = {},
    form,
    isApprovalForm,
    VisibilityAffected,
	formElements,
    addElementOnPosition,
    index
}) => {

    const [state, setState] = React.useState({
        localElement: {
            ...element
        },
        errors: {}
    })

    const localElement = state.localElement

    const setLocalElement = callback => setState(state => ({
        ...state,
        localElement: {...callback(state.localElement)}
    }))

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onValidationsClick = () => {
        changeElementEditorMode("VALIDATIONS")
    };

    const onPinnedChange = ({target: {checked}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                pinned: {
                    status: checked
                }
            }
        ))
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        const errors = {};
        if (localElement.step1.length > 60) {
            errors.step1 = "Maximum 60 characters allowed"
        }
        if (localElement.uploadTakePictureLabel.length > 60) {
            errors.uploadTakePictureLabel = "Maximum 60 characters allowed"
        }
        if (localElement.uploadImageButton.length > 60) {
            errors.uploadImageButton = "Maximum 60 characters allowed"
        }
        if (localElement.cameraImageButton.length > 60) {
            errors.cameraImageButton = "Maximum 60 characters allowed"
        }
        if (localElement.step2.length > 60) {
            errors.step2 = "Maximum 60 characters allowed"
        }
        if (localElement.previewUploadedImage.length > 60) {
            errors.previewUploadedImage = "Maximum 60 characters allowed"
        }
        if (localElement.uploadImageAgainButton.length > 60) {
            errors.uploadImageAgainButton = "Maximum 60 characters allowed"
        }
        if (localElement.getIdInformationButton.length > 60) {
            errors.getIdInformationButton = "Maximum 60 characters allowed"
        }
        if (localElement.step3.length > 60) {
            errors.step3 = "Maximum 60 characters allowed"
        }
        if (localElement.cardInformation.length > 60) {
            errors.cardInformation = "Maximum 60 characters allowed"
        }
        if (localElement.uploadAnotherImageButton.length > 60) {
            errors.uploadAnotherImageButton = "Maximum 60 characters allowed"
        }
        setState(state => ({
            ...state,
            errors
        }))
        if (Object.keys(errors).length === 0) {
            editElement({
                ...state.localElement
            })
            changeElementEditorMode("DEFAULT")
        }
    };

    const duplicateComponent = () => {
        addElementOnPosition(index + 1, {
            ...element,
            editorMode: 'DEFAULT'
        })
    }

    const setValidationsLocal = newValidations => {
        setLocalElement(localElement => (
            {
                ...localElement,
                validations: newValidations
            }
        ))
    };

    const setSkipFirstTwoSteps = skip => {
        const validations = skip
            ? [...localElement.validations, {validation: VALIDATION.SKIP_FIRST_TWO_STEPS}]
            : localElement.validations.filter((validation) => validation.validation !== VALIDATION.SKIP_FIRST_TWO_STEPS)

        if(element.editorMode !== "VALIDATIONS") {
            editElement({...element, validations})
        }

        setValidationsLocal(validations)
    }

    const openEditMode = () => {
        changeElementEditorMode("EDIT")
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" && "view-box-options"} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
            <TopBarContainerStyled>
                <SkipFirstTwoStepsButton
                    skipFirstTwoSteps={
                        !!(element.editorMode === "VALIDATIONS"
                                ? localElement
                                : element
                        ).validations.find(v => v.validation === VALIDATION.SKIP_FIRST_TWO_STEPS)
                    }
                    setSkipFirstTwoSteps={setSkipFirstTwoSteps}
                />
                <VisibilityAffected element={element} formElements={formElements}/>
            </TopBarContainerStyled>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center id-card-box">
                <div className="id-card-extractor" style={{paddingTop: "2rem", cursor: "pointer"}} onClick={openEditMode}>
                    <div className="id-card-extractor-cell">
                        <p><span>{`${localElement.step1} `}</span>{localElement.uploadTakePictureLabel}</p>
                        <div className="step1-icons">
                            <i className="icon-ia-camera"/>
                            <i className="icon-ia-upload"/>
                        </div>
                        <p>Please take a photo or upload your Identity Card</p>
                    </div>
                    <div className="id-card-extractor-cell">
                        <p><span>{`${localElement.step2} `}</span>{localElement.previewUploadedImage}</p>
                        <div className="id-card-image"><img src={CardImg}/></div>
                    </div>
                    <div className="id-card-extractor-cell">
                        <p><span>{`${localElement.step3} `}</span>{localElement.cardInformation}</p>
                        <fieldset className="form-grid">
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>Name</label>
                                    <CodeCopy code={`${localElement.code}_first_name`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Surname</label>
                                    <CodeCopy code={`${localElement.code}_last_name`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>Email</label>
                                    <CodeCopy code={`${localElement.code}_email`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Phone</label>
                                    <CodeCopy code={`${localElement.code}_phone`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>CNP</label>
                                    <CodeCopy code={`${localElement.code}_cnp`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row with-calendar">
                                    <label>Date of birth</label>
                                    <CodeCopy code={`${localElement.code}_date_of_birth`}>
                                        <input type="text" name="" value="DD / MM / YYYY" onChange={f => f}/>
                                    </CodeCopy>
                                    <i className="calendar-btn icon-ia-calendar"/>
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>County</label>
                                    <CodeCopy code={`${localElement.code}_county`}>
                                        <select/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>City</label>
                                    <CodeCopy code={`${localElement.code}_city`}>
                                        <select/>
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row street-grid">
                                <div className="form-row">
                                    <label>Street</label>
                                    <CodeCopy code={`${localElement.code}_street`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>No</label>
                                    <CodeCopy code={`${localElement.code}_nr`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row address-grid">
                                <div className="form-row">
                                    <label>Building</label>
                                    <CodeCopy code={`${localElement.code}_building`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Entrance</label>
                                    <CodeCopy code={`${localElement.code}_entry`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Floor</label>
                                    <CodeCopy code={`${localElement.code}_floor`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Ap</label>
                                    <CodeCopy code={`${localElement.code}_apartment`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row id-grid">
                                <div className="form-row">
                                    <label>ID Series</label>
                                    <CodeCopy code={`${localElement.code}_series_ci`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>ID No</label>
                                    <CodeCopy code={`${localElement.code}_nr_ci`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                                <div className="form-row">
                                    <label>Emitted by</label>
                                    <CodeCopy code={`${localElement.code}_emitted_by`}>
                                        <input type="text"/>
                                    </CodeCopy>
                                </div>
                            </div>
                            <div className="form-grid-row">
                                <div className="form-row with-calendar">
                                    <label>Valid from</label>
                                    <CodeCopy code={`${localElement.code}_available_from`}>
                                        <input type="text" name="" value="DD / MM / YYYY" onChange={f => f}/>
                                    </CodeCopy>
                                    <i className="calendar-btn icon-ia-calendar"/>
                                </div>
                                <div className="form-row with-calendar">
                                    <label>Valid until</label>
                                    <CodeCopy code={`${localElement.code}_available_to`}>
                                        <input type="text" name="" value="DD / MM / YYYY" onChange={f => f}/>
                                    </CodeCopy>
                                    <i className="calendar-btn icon-ia-calendar"/>
                                </div>
                            </div>
                        </fieldset>
                        <p className="form-row-validation-text">{localElement.validations.map(v => `[ ${v.validation} ]  `)}{localElement.pinned && localElement.pinned.status ? "[ show on top ]" : ""}</p>
                    </div>
                    {/* <span class="form-box-wrap-id">{element.code}</span> */}
                </div>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onValidationsClick}><i className="icon-check trigger-validation-box"/>Validation</span>
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <AdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </AdeleteConfirm>
                        <span onClick={duplicateComponent}><i className="icon-copy"/>Duplicate</span>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                {!localElement.validations.map((validation) => validation.validation).includes("skip first two steps") &&
                <>
                    <div className={`form-row ${state.errors.step1 ? "error" : ""}`}>
                        <input type="text" name="" placeholder="STEP 1" value={localElement.step1}
                               onChange={({target: {value}}) => {
                                   setLocalElement(localElement => (
                                       {
                                           ...localElement,
                                           step1: value
                                       }
                                   ))
                               }}/>
                        {state.errors.step1 &&
                        <span className="form-row-error-msg">{state.errors.step1}</span>
                        }
                    </div>
                    <div className={`form-row ${state.errors.uploadTakePictureLabel ? "error" : ""}`}>
                        <input type="text" name="" placeholder="UPLOAD / TAKE PICTURE"
                               value={localElement.uploadTakePictureLabel}
                               onChange={({target: {value}}) => {
                                   setLocalElement(localElement => (
                                       {
                                           ...localElement,
                                           uploadTakePictureLabel: value
                                       }
                                   ))
                               }}/>
                        {state.errors.uploadTakePictureLabel &&
                        <span className="form-row-error-msg">{state.errors.uploadTakePictureLabel}</span>
                        }
                    </div>
                    <div className={`form-row ${state.errors.uploadImageButton ? "error" : ""}`}>
                        <input type="text" name="" placeholder="Upload Image Button"
                               value={localElement.uploadImageButton}
                               onChange={({target: {value}}) => {
                                   setLocalElement(localElement => (
                                       {
                                           ...localElement,
                                           uploadImageButton: value
                                       }
                                   ))
                               }}/>
                        {state.errors.uploadImageButton &&
                        <span className="form-row-error-msg">{state.errors.uploadImageButton}</span>
                        }
                    </div>
                    <div className={`form-row ${state.errors.cameraImageButton ? "error" : ""}`}>
                        <input type="text" name="" placeholder="Camera Image Button"
                               value={localElement.cameraImageButton}
                               onChange={({target: {value}}) => {
                                   setLocalElement(localElement => (
                                       {
                                           ...localElement,
                                           cameraImageButton: value
                                       }
                                   ))
                               }}/>
                        {state.errors.cameraImageButton &&
                        <span className="form-row-error-msg">{state.errors.cameraImageButton}</span>
                        }
                    </div>
                    <div className={`form-row ${state.errors.step2 ? "error" : ""}`}>
                        <input type="text" name="" placeholder="STEP 2" value={localElement.step2}
                               onChange={({target: {value}}) => {
                                   setLocalElement(localElement => (
                                       {
                                           ...localElement,
                                           step2: value
                                       }
                                   ))
                               }}/>
                        {state.errors.step2 &&
                        <span className="form-row-error-msg">{state.errors.step2}</span>
                        }
                    </div>
                    <div className={`form-row ${state.errors.previewUploadedImage ? "error" : ""}`}>
                        <input type="text" name="" placeholder="PREVIEW UPLOADED IMAGE"
                               value={localElement.previewUploadedImage}
                               onChange={({target: {value}}) => {
                                   setLocalElement(localElement => (
                                       {
                                           ...localElement,
                                           previewUploadedImage: value
                                       }
                                   ))
                               }}/>
                        {state.errors.previewUploadedImage &&
                        <span className="form-row-error-msg">{state.errors.previewUploadedImage}</span>
                        }
                    </div>
                    <div className={`form-row ${state.errors.uploadImageAgainButton ? "error" : ""}`}>
                        <input type="text" name="" placeholder="Upload Image Again Button"
                               value={localElement.uploadImageAgainButton}
                               onChange={({target: {value}}) => {
                                   setLocalElement(localElement => (
                                       {
                                           ...localElement,
                                           uploadImageAgainButton: value
                                       }
                                   ))
                               }}/>
                        {state.errors.uploadImageAgainButton &&
                        <span className="form-row-error-msg">{state.errors.uploadImageAgainButton}</span>
                        }
                    </div>
                    <div className={`form-row ${state.errors.getIdInformationButton ? "error" : ""}`}>
                        <input type="text" name="" placeholder="Get Id Information Button"
                               value={localElement.getIdInformationButton}
                               onChange={({target: {value}}) => {
                                   setLocalElement(localElement => (
                                       {
                                           ...localElement,
                                           getIdInformationButton: value
                                       }
                                   ))
                               }}/>
                        {state.errors.getIdInformationButton &&
                        <span className="form-row-error-msg">{state.errors.getIdInformationButton}</span>
                        }
                    </div>
                </>
                }
                <div className={`form-row ${state.errors.step3 ? "error" : ""}`}>
                    <input type="text" name="" placeholder="STEP 3" value={localElement.step3}
                           onChange={({target: {value}}) => {
                               setLocalElement(localElement => (
                                   {
                                       ...localElement,
                                       step3: value
                                   }
                               ))
                           }}/>
                    {state.errors.step3 &&
                    <span className="form-row-error-msg">{state.errors.step3}</span>
                    }
                </div>
                <div className={`form-row ${state.errors.cardInformation ? "error" : ""}`}>
                    <input type="text" name="" placeholder="CARD INFORMATION" value={localElement.cardInformation}
                           onChange={({target: {value}}) => {
                               setLocalElement(localElement => (
                                   {
                                       ...localElement,
                                       cardInformation: value
                                   }
                               ))
                           }}/>
                    {state.errors.cardInformation &&
                    <span className="form-row-error-msg">{state.errors.cardInformation}</span>
                    }
                </div>
                <div className={`form-row ${state.errors.uploadAnotherImageButton ? "error" : ""}`}>
                    <input type="text" name="" placeholder="Upload Another Image Button"
                           value={localElement.uploadAnotherImageButton}
                           onChange={({target: {value}}) => {
                               setLocalElement(localElement => (
                                   {
                                       ...localElement,
                                       uploadAnotherImageButton: value
                                   }
                               ))
                           }}/>
                    {state.errors.uploadAnotherImageButton &&
                    <span className="form-row-error-msg">{state.errors.uploadAnotherImageButton}</span>
                    }
                </div>
                {!isApprovalForm &&
                    <div className="frc-box">
                        <div className="form-row-checkbox">
                            <input id={`pinned-${localElement.code}`} type="checkbox"
                                checked={localElement.pinned && localElement.pinned.status} onChange={onPinnedChange}/>
                            <label htmlFor={`pinned-${localElement.code}`}>Show on top in saved session</label>
                        </div>
                    </div>
                }
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
            <div className="form-box-wrap-center-validation mco-box">
                <div className="mco-cell"><h4>Validations</h4></div>
                <Validations
                    availableValidations={supportedElements[localElement.type].availableValidations}
                    onChange={setValidationsLocal}
                    value={localElement.validations}
                    form={form}
                />
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}

const TopBarContainerStyled = styled(TopBarContainer)`
  .visibility-affected {
    right: 14.1rem;
  }
`

const SkipFirstTwoStepsButton = styled(({className, skipFirstTwoSteps, setSkipFirstTwoSteps}) => {
    return (
        <div className={className}>
            <div className="form-row-checkbox">
                {(id => (
                    <>
                        <input
                            id={id}
                            type="checkbox"
                            checked={skipFirstTwoSteps}
                            onChange={() => setSkipFirstTwoSteps(!skipFirstTwoSteps)}
                        />
                        <label htmlFor={id}>Skip first two steps</label>
                    </>
                ))(uuid())}
            </div>
        </div>
    )
})`

  position: absolute !important;
  width: max-content;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  
    label {
      margin: 0;
    }
`;