import React from 'react';

import {synchronizeText} from "../../utils/synchronizeText"

const ImageUpload = ({
    element,
    answers_by_code,
    value = []
}) => {

    return (
        <div className="fieldset-row">
            {element.text &&
                <p>{synchronizeText(element.text, answers_by_code)}</p>
            }

            {value.length > 0 &&
                value.map((image, imageIndex) => (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, minHeight: '300px'}} key={imageIndex}>
                        <img style={{ maxWidth: '100%' }} src={image} alt="" />
                    </div>
                ))
            }

            {value.length === 0 &&
                <div>No image was uploaded</div>
            }
        </div>
    )
};

export default ImageUpload;