import React from 'react'

export default ({ 
    element, 
    onChange = f => f,
    value = {
        first_name: '',
        last_name: '',
        country: '',
        locality: '',
        street: '',
        nr: '',
        block_value: '',
        entry: '',
        floor: '',
        apartment: '',
        postal_code: ''
    } 
}) => {

    return(
        <div className="fieldset-row">
            <p>{element.text}</p>
            <div className="form-grid">
                <div className="form-grid-row">
                    <div className="form-row">
                        <label>Name</label>
                        <input type="text"/>
                    </div>
                    <div className="form-row">
                        <label>Surname</label>
                        <input type="text"/>
                    </div>
                </div>
                <div className="form-grid-row">
                    <div className="form-row">
                        <label>County</label>
                        <select>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                        </select>
                    </div>
                    <div className="form-row">
                        <label>City</label>
                        <select>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                            <option value="">Select Option</option>
                        </select>
                    </div>
                </div>
                <div className="form-grid-row street-grid">
                    <div className="form-row">
                        <label>Street</label>
                        <input type="text"/>
                    </div>
                    <div className="form-row">
                        <label>No</label>
                        <input type="text"/>
                    </div>
                </div>
                <div className="form-grid-row address-grid">
                    <div className="form-row">
                        <label>Building</label>
                        <input type="text"/>
                    </div>
                    <div className="form-row">
                        <label>Entrance</label>
                        <input type="text"/>
                    </div>
                    <div className="form-row">
                        <label>Floor</label>
                        <input type="text"/>
                    </div>
                    <div className="form-row">
                        <label>Ap</label>
                        <input type="text"/>
                    </div>
                </div>
                <div className="form-grid-row address-grid">
                    <div className="form-row">
                        <label>Postal code</label>
                        <input type="text"/>
                    </div>
                </div>
            </div>
        </div>
    )
}