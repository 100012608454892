import React from "react"
import AdeleteConfirm from "../../components/AdeleteConfirm"
import Validations from "../Validations"
import CodeCopy from "../../components/Editor.codeCopy"
import supportedElements from "../supportedElements"
import {css} from 'styled-components'

import {TopBarContainer} from "../comonComponents/ElementEditor.TopBarContainer";
import {RequiredValidationToggle} from "../comonComponents/ElementEditor.RequiredValidationToggle";
import {VALIDATION} from "../../constants/validations";

const cssFixes = css`
    .form-row .tbl-btn {
        top: 1.8rem;
    }

	.mcl-row {
		height: unset;
	}

	&&&& {
        .mcl-row.with-delete-btn input {
            padding-right: 7.5rem;
        }
    }
	
	&&& {
		.default-option-button {
			right: 7.5rem;
			&.checked {
				opacity: 1!important;
			}
		}
	}

`

export const CheckBoxEditor = ({
	element,
	remove,
	editElement,
	changeElementEditorMode,
	dragHandleProps = {},
	form,
	formElements,
	VisibilityAffected,
	ConditionalValidation,
	editElementByCode,
	clearOppressedElements,
	clearOppressedAndShiftAffectedIndexes,
	isApprovalForm,
	addElementOnPosition,
	index
}) => {
	const [state, setState] = React.useState({
		duplicates: [],
		localElement: {
			...element,
		},
		errors: {},
	})

	const localElement = state.localElement

	const setLocalElement = callback =>
		setState(state => ({
			...state,
			localElement: { ...callback(state.localElement) },
		}))

	React.useEffect(() => {
		const duplicates = localElement.items.reduce(
			(acc, item, itemIndex) =>
				localElement.items.includes(item, itemIndex + 1) ? [...acc, item] : acc,
			[]
		)
		if (state.duplicates !== duplicates)
			setState(state => ({ ...state, duplicates }))
	}, [localElement.items])

	const removeItem = index => {
		clearOppressedAndShiftAffectedIndexes(localElement.code, index)
		setLocalElement(localElement => ({
			...localElement,
			items: localElement.items.filter((_, i) => i !== index),
		}))
	}

	const editItem = (index, newValue) => {
		setLocalElement(localElement => ({
			...localElement,
			items: localElement.items.map((el, i) => (i !== index ? el : newValue)),
			defaultValue: localElement.defaultValue === localElement.items[index] ? newValue : localElement.defaultValue
		}))
	}

	const onAddOptionClick = () => {
		setLocalElement(localElement => ({
			...localElement,
			items: [...localElement.items, `Option`],
		}))
	}

	const onOptionClick = () => {
		changeElementEditorMode(
			element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS"
		)
	}

	const onEditClick = () => {
		changeElementEditorMode("EDIT")
	}

	const onValidationsClick = () => {
		changeElementEditorMode("VALIDATIONS")
	}

	const onSaveClick = () => {
		const errors = {}
		if (localElement.text.length > 400) {
			errors.text = "Maximum 400 characters allowed"
		}
		if (localElement.items.length > 300) {
			errors.items = "Maximum 300 items allowed"
		}
		if (state.duplicates.length > 0) {
			errors.duplicates = "Some of the checkbox data is duplicated"
		}

		const itemMaxLength = localElement.items.reduce((acc, item, index) => {
			if (item.length > 300) acc[index] = true
			return acc
		}, {})
		if (Object.keys(itemMaxLength).length > 0) {
			errors.itemMaxLength = itemMaxLength
		}

		setState(state => ({
			...state,
			errors,
		}))
		if (Object.keys(errors).length === 0) {
			editElement({
				...state.localElement,
			})
			changeElementEditorMode("DEFAULT")
		}
	}

	const onPinnedChange = ({ target: { checked } }) => {
		setLocalElement(localElement => ({
			...localElement,
			pinned: {
				status: checked,
			},
		}))
	}

	const onGridClick = () => {
		changeElementEditorMode("GRID")
	}

	const onGridMinus = () => {
		localElement.grid_columns > 1 &&
			setLocalElement(localElement => ({
				...localElement,
				grid_columns: localElement.grid_columns - 1,
			}))
	}

	const onGridPlus = () => {
		localElement.grid_columns < 3 &&
			setLocalElement(localElement => ({
				...localElement,
				grid_columns: localElement.grid_columns + 1,
			}))
	}

	const duplicateComponent = () => {
		addElementOnPosition(index + 1, {
			...element,
			editorMode: 'DEFAULT'
		})
	}

	const toggleDefaultValue = (item) => {
		setLocalElement(localElement => ({
			...localElement,
			defaultValue: localElement.defaultValue === item ? undefined : item
		}))
	}

	const setValidationsLocal = newValidations => {
		setLocalElement(localElement => (
			{
				...localElement,
				validations: newValidations
			}
		))
	};

	const setValidationRequired = required => {
		const validations = required
			? [...localElement.validations, {validation: VALIDATION.REQUIRED}]
			: localElement.validations.filter((validation) => validation.validation !== VALIDATION.REQUIRED)

		if(element.editorMode !== "VALIDATIONS") {
            editElement({...element, validations})
        }
		setValidationsLocal(validations)
	}

	const openEditMode = () => {
		changeElementEditorMode("EDIT")
	};

	return (
		<div
			className={`form-box-wrap ${
				element.editorMode === "OPTIONS" && "view-box-options"
			} ${element.editorMode === "EDIT" && "view-box-edit"} ${
				element.editorMode === "GRID" && "view-box-grid"
			} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}
			css={cssFixes}
		>
			<div className="form-box-wrap-drag-btn" {...dragHandleProps}>
				<i className="icon-ia-drag" />
			</div>
			<div className="form-box-wrap-center">
				<div className="form-box-wrap-center-content" style={{cursor: "pointer"}} onClick={openEditMode}>
					<p className="form-box-wrap-descrition">{localElement.text}</p>
					<div
						className="form-grid"
						style={{
							gridTemplateColumns: `repeat(${localElement.grid_columns}, 1fr)`,
						}}
					>
						{localElement.items.map((item, itemIndex) => (
							<div className="form-row-checkbox" key={itemIndex} style={{pointerEvents: "none"}}>
								<input type="checkbox" name="" checked={false} onChange={f => f} />
								<label>{item}</label>
							</div>
						))}
					</div>
					<TopBarContainer>
						<RequiredValidationToggle
							                            validationRequired={
                                !!(element.editorMode === "VALIDATIONS"
                                    ? localElement
                                    : element
                                ).validations.find(v => v.validation === VALIDATION.REQUIRED)
                            }
							setValidationRequired={setValidationRequired}
						/>
						<VisibilityAffected element={element} formElements={formElements}/>
					</TopBarContainer>
					<p className="form-row-validation-text">
						{localElement.validations.map(v => `[ ${v.validation} ]  `)}
						{localElement.pinned && localElement.pinned.status
							? "[ show on top ]"
							: ""}
					</p>
					<CodeCopy code={localElement.code}>
						<span className="form-box-wrap-id" />
					</CodeCopy>
				</div>
				<div className="form-box-wrap-options">
					<div className="form-box-wrap-options-buttons">
						<span onClick={onValidationsClick}>
							<i className="icon-check trigger-validation-box" />
							Validation
						</span>
						<span onClick={onGridClick}>
							<i className="icon-ia-grid trigger-grid-box" />
							Grid
						</span>
						<span onClick={onEditClick}>
							<i className="icon-ia-edit-bold trigger-edit-box" />
							Edit
						</span>
						<AdeleteConfirm
							onConfirm={() => {
								clearOppressedElements(localElement.code)
								remove()
							}}
							okText="Delete"
							cancelText="Cancel"
							title="Are you sure you want to delete this component? All data will be lost. "
						>
							<span>
								<i className="icon-ia-trash-bold trigger-delete-box" />
								Delete
							</span>
						</AdeleteConfirm>
						<span onClick={duplicateComponent}><i className="icon-copy"/>Duplicate</span>
					</div>
				</div>
			</div>
			<div className="form-box-wrap-options-btn" onClick={onOptionClick}>
				<i className="icon-ia-more" />
			</div>
			<div className="form-box-wrap-center-edit">
				<div className={`form-row ${state.errors.text ? "error" : ""}`}>
					<input
						id=""
						type="text"
						name=""
						placeholder="Checkbox Group Label"
						value={localElement.text}
						onChange={({ target: { value } }) =>
							setLocalElement(localElement => ({ ...localElement, text: value }))
						}
					/>
					{state.errors.text && (
						<span className="form-row-error-msg">{state.errors.text}</span>
					)}
				</div>
				<div className="frc-box">
					<div
						className={`form-row multiple-radio-checkbox-list ${
							state.errors.items ? "error" : ""
						}`}
					>
						<label>Options List</label>
						{localElement.items.map((item, itemIndex) => (
							<div
								key={itemIndex}
								className={`mcl-row with-delete-btn ${
									(state.duplicates.find(i => i === item) || state.errors?.itemMaxLength?.[itemIndex]) ? "error" : ""
								}`}
							>
								<input
									type="text"
									value={item}
									onChange={e => editItem(itemIndex, e.target.value)}
								/>

								<DefaultOption
									onClick={() => toggleDefaultValue(item)}
									checked={!!localElement.defaultValue &&localElement.defaultValue === item}
								/>

								<ConditionalValidation
									oppressorItemIndex={itemIndex}
									formElements={formElements}
									oppressorElement={localElement}
									editElementByCode={editElementByCode}
								/>
								<AdeleteConfirm
									onConfirm={() => removeItem(itemIndex)}
									okText="Delete"
									cancelText="Cancel"
									title="Are you sure you want to delete this option? All data will be lost. "
								>
									<div className=" tbl-btn">
										<i
											className="icon-ia-trash-bold delete-form-row-btn"
											title="Delete"
										/>
										{/*<div className="tooltip tooltip-top">Delete</div>*/}
									</div>
								</AdeleteConfirm>
								{state.errors?.itemMaxLength?.[itemIndex] && (
									<span className="form-row-error-msg">
										Maximum 300 characters allowed
									</span>
								)}
							</div>
						))}
						{state.errors.duplicates && (
							<span style={{ display: "unset" }} className="form-row-error-msg">
								{state.errors.duplicates}
							</span>
						)}
						{state.errors.items && (
							<span className="form-row-error-msg">{state.errors.items}</span>
						)}
						<span className="add-block-input" onClick={onAddOptionClick}>
							Add Option
						</span>
					</div>
				</div>
                {!isApprovalForm &&
                    <div className="frc-box">
                        <div className="form-row-checkbox">
                            <input
                                id={`pinned-${localElement.code}`}
                                type="checkbox"
                                checked={localElement.pinned && localElement.pinned.status}
                                onChange={onPinnedChange}
                            />
                            <label htmlFor={`pinned-${localElement.code}`}>
                                Show on top in saved session
                            </label>
                        </div>
                    </div>
                }
				<a className="form-box-wrap-button" onClick={onSaveClick}>
					Save
				</a>
			</div>
			<div className="form-box-wrap-grid-edit">
				<div className="set-grid-box">
					<label>Grid columns</label>
					<div className="set-grid">
						<i className="icon-ia-minus" onClick={onGridMinus} />
						<input
							type="text"
							name=""
							value={localElement.grid_columns}
							onChange={f => f}
						/>
						<i className="icon-ia-plus" onClick={onGridPlus} />
					</div>
				</div>
				<a className="form-box-wrap-button" onClick={onSaveClick}>
					Save
				</a>
			</div>
			<div className="form-box-wrap-center-validation mco-box">
				<div className="mco-cell">
					<h4>Validations</h4>
				</div>
				<Validations
					availableValidations={
						supportedElements[localElement.type].availableValidations
					}
					onChange={setValidationsLocal}
					value={localElement.validations}
					form={form}
				/>
				<a className="form-box-wrap-button" onClick={onSaveClick}>
					Save
				</a>
			</div>
		</div>
	)
};

const DefaultOption = ({onClick, checked}) => {

	return (
		<div className={`tbl-btn default-option-button${checked ? ' checked' : ''}`} onClick={onClick}>
			<i
				className="icon-check-circle"
				title="Set default option"
			/>
		</div>
	)
}

