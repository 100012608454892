import React from 'react'
import parse from 'html-react-parser'
import root from 'react-shadow'

export default ({children, ...rest}) => {
    return(
        <root.div style={{height: '100%'}} {...rest}>
            {parse(children)}
        </root.div>
    )
}