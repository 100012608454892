import React from 'react'
import {isEmail} from 'validator'

const AnotifyOthersList = ({value={others: [], other: ''}, changeValue=f=>f, error=undefined}) => {

    const [state, setState] = React.useState({
        invalid: false,
        duplicate: ""
    });

    React.useEffect(() => {
        setState(state => ({
            ...state, 
            invalid: value.other.trim().length === 0 
                ? false 
                : !isEmail(value.other)
        }))
    }, [value.other]);

    const onAddOther = () => {
        if(!isEmail(value.other)) {
            return
        }

        if(value.others.includes(value.other)) {
            setState(state => ({
                ...state,
                duplicate: value.other
            }))
            return
        }
        setState(state => ({
            ...state,
            duplicate: ""
        }))
        changeValue(value => ({others: [...value.others, value.other], other: ''}))
    };

    const removeOther = otherIndex => {
        changeValue(value => ({
            ...value,
            others: value.others.filter((_, i) => otherIndex !== i)
        }))
    };

    return(
        <div className={`form-row autocomplete-tags-box ${(error || state.invalid) ? "error" : ""}`}>
            <div className="autocomplete-tags">
                <div className="scrollbar">
                    {value.others.map((other, i) => (
                        <span className="at-tag" style={(state.duplicate === other ? {background: "#ff000078"} : {})} key={i} onDoubleClick={() => {
                            changeValue(value => ({
                                other,
                                others: value.others.filter(email => email !== other)
                            }))
                        }}>
                            <i className="icon-ia-close" onClick={() => removeOther(i)}/>
                            {other}
                        </span>
                    ))}
                    <input 
                        required
                        className="at-input" 
                        type="email" 
                        value={value.other}
                        onChange={({target:{value}}) => changeValue(v => ({...v, other: value}))} 
                        placeholder="Add email addresses separated with a comma, semicolon, space or enter."
                        onKeyDown={e => {
                            if(e.keyCode === 13 || e.keyCode === 188 || e.keyCode === 32 || e.keyCode === 186) {
                                e.preventDefault();
                                onAddOther()
                            }   
                        }}
                        css={`&&{width: 100% !important;}`}
                    />
                </div>
            </div>
            {error && <span className="form-row-error-msg">{error}</span>}
        </div>
    )
}

export default AnotifyOthersList