import React from 'react'
import supportedElements from '../../../formComponents/supportedElements'
import {Droppable, Draggable} from "react-beautiful-dnd"
import ConditionalValidation, {VisibilityAffected} from '../../../formComponents/ConditionalValidation'

export default React.forwardRef(({
                                     elements = [],
                                     changeElementEditorMode = f => f,
                                     onNext,
                                     removeElement = f => f,
                                     editElement = f => f,
                                     onBack = f => f,
                                     changeTitle = f => f,
                                     title = "",
                                     setForm = f => f,
                                     editElementByCode,
                                     clearOppressedElements,
                                     clearOppressedAndShiftAffectedIndexes,
                                     addElementOnPosition,
                                     deleteChain
                                 }, ref) => {

    return (
        <div className="content-box">
            <form className="form form-step-2">
                <div className="scrollbar">

                    <div className="form-box-header">
                        <h6 className="supTitle">Approval form</h6>

                        <div className="form-row">
                            <input id="form-name" type="text" value={title}
                                   onChange={({target: {value: newTitle}}) => changeTitle(newTitle)}
                                   placeholder="Form Title"/>
                        </div>

                    </div>

                    <Droppable droppableId="editor">
                        {provided => (
                            <div className="form-box-body" ref={el => {
                                provided.innerRef(el);
                                ref(el);
                            }} {...provided.droppableProps}>
                                {elements.map((element, elementIndex) => {
                                    if(!supportedElements[element.type]) return null
                                 
                                        return (
                                            <Draggable 
                                                draggableId={elementIndex.toString()} 
                                                index={elementIndex}
                                                key={element.code}
                                            >
                                                {provided => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        {React.createElement(supportedElements[element.type].editor, {
                                                            element,
                                                            index: elementIndex,
                                                            isApprovalForm: true,
                                                            editElement: data => editElement(elementIndex, data),
                                                            remove: () => removeElement(elementIndex),
                                                            changeElementEditorMode: mode => changeElementEditorMode(elementIndex, mode),
                                                            dragHandleProps: provided.dragHandleProps,
                                                            formElements: elements,
                                                            VisibilityAffected,
                                                            ConditionalValidation,
                                                            editElementByCode,
                                                            clearOppressedElements,
                                                            clearOppressedAndShiftAffectedIndexes,
                                                            addElementOnPosition,
                                                            deleteChain
                                                        })}
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                             
                                })}
                                {provided.placeholder}

                                {elements.length === 0 &&
                                <div className="form-box-wrap drag-zone-box">
                                    <div>
                                        <i className="icon-ia-drag-out"/>
                                        <span>Drag a Form Component<br/>or a specialised Component</span>
                                    </div>
                                </div>
                                }
                                <div className="form-box-actions">
                                    <button onClick={onBack} type="button button-outline" className="button">
                                        <i className="icon-ia-arrow-left"/><span>Back</span>
                                    </button>

                                    <button onClick={onNext} type="button" className="button">
                                        <i className="icon-ia-checked-outline"/><span>SAVE</span>
                                    </button>

                                </div>
                            </div>
                        )}
                    </Droppable>
                </div>
            </form>
        </div>
    )
})