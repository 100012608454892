import React from 'react'

import LivePreview from './LivePreview.TextBox'
import {ShortAnswerEditor} from './Editor.TextBox'
import CodeCopy from './CodeCopy.TextBox'
import View from './View.TextBox'
import {TextBoxInput} from './Input.TextBox'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <ShortAnswerEditor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <TextBoxInput {...args}/>,

    default: () => ({
        type: "short answer",
        text: "Text Box Title",
        pinned: {
            status: false
        },
        placeholder: "",
        formula: "",
        constant: {value: "", readOnly: false},
        validations: []
    }),

    availableValidations: ['required', 'phone two step validation', 'phone', 'email two step validation', 'email', 'number', 'positive', 'integer', 'min length', 'max length', 'min value',  'max value', 'unique'],
    validate: () => false,
    group: "GENERAL",
    icon: "icon-ia-input-box",
    name: "Text Box",
    view: args => <View {...args}/>
}

