import {isValidPhoneNumber} from 'libphonenumber-js'


const phoneValidator = (phoneNumber) => {
    return isValidPhoneNumber(phoneNumber)
}

const addPlusPrefix = (phone) => {
    if(phone !== '') {
        if(!phone.trimStart().startsWith('+')) {
            return `+${phone}`;
        }
    }
    return phone;
}

export {phoneValidator, addPlusPrefix}