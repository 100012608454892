import React from 'react'
import { message } from 'antd'
import styled from 'styled-components'

export default ({ children, code}) => {
    let delimitedCode = `{{${code}}}`
    return(
        <Wrapper>
            {React.cloneElement(
                children, 
                { 
                    disabled: true
                } 
            )}
            <Code
                onClick={e => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(delimitedCode)
                    message.success(`${delimitedCode} Copied`, 5);
                }} 
            >{code}</Code>
        </Wrapper>
    )
}

const Code = styled.span`
    width:auto; 
    display:inline-block; 
    position:absolute; 
    top:1px; 
    right:1px; 
    background:#eef0fa; 
    color:#8f75db; 
    padding:.3rem 1rem; 
    font-size:1rem; 
    line-height:1.4rem; 
    transform: translate3d(0,100%,0); 
    opacity:0; 
    transition: transform .3s ease-out, opacity .3s ease-in-out; 
    user-select:text;
    cursor: copy;
`

const Wrapper = styled.div`
    position: relative;
    :hover {
        ${Code} {
            transform: translate3d(0,0,0); opacity:1;
        }
    }
`

