import React from 'react'

import LivePreview from './LivePreview.CarDamageEvaludation'
import {CarDamageEvaludationEditor} from './Editor.CarDamageEvaludation'
import CodeCopy from './CodeCopy.CarDamageEvaludation'
import View from './View.CarDamageEvaludation'

export default {
  livePreview: args => <LivePreview {...args}/>,
  editor: args => <CarDamageEvaludationEditor {...args}/>,
  codeCopy: args => <CodeCopy {...args}/>,
  view: args => <View {...args}/>,

  default: () => ({
    type: "vehicle evaluation",
    title: "Car Damage Evaluation Sheet Title",
    text: "Car Damage Evaluation Sheet Description",
    pinned: {
      status: false
    },
    validations: []
  }),
  
  availableValidations: [],
  validate: () => false,
  group: "AUTOMOTIVE",
  icon: "icon-ia-car",
  name: "Car Damage Evaluation Sheet"
}