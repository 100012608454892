import React from 'react'
import fileImg from '../../images/file.svg'

export default ({ 
    element
}) => {
    return (
        <div className="fieldset-row">
            <p>{element.title}</p>
            <p>{element.text}</p>
            <div className="form-grid files-list" style={{ gridTemplateColumns: "repeat(3, 1fr)" }}>
                <div className="file">
                    <img src={fileImg} />
                    <strong>File Title</strong>
                </div>
                <div className="file">
                    <img src={fileImg} />
                    <strong>File Title</strong>
                </div>
                <div className="file">
                    <img src={fileImg} />
                    <strong>File Title</strong>
                </div>
            </div>
        </div>
    )
}


