export const GET_CATEGORIES_PENDING = 'GET_CATEGORIES_PENDING';
export const GET_CATEGORIES_REJECTED = 'GET_CATEGORIES_REJECTED';
export const GET_CATEGORIES_FULFILLED = 'GET_CATEGORIES_FULFILLED';
export const SET_PAGE = 'SET_PAGE';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_SORT = 'SET_SORT';
export const STORE_CATEGORY_PENDING = 'STORE_CATEGORY_PENDING';
export const STORE_CATEGORY_REJECTED = 'STORE_CATEGORY_REJECTED';
export const STORE_CATEGORY_FULFILLED = 'STORE_CATEGORY_FULFILLED';
export const UPDATE_CATEGORY_PENDING = 'UPDATE_CATEGORY_PENDING';
export const UPDATE_CATEGORY_REJECTED = 'UPDATE_CATEGORY_REJECTED';
export const UPDATE_CATEGORY_FULFILLED = 'UPDATE_CATEGORY_FULFILLED';
export const DELETE_CATEGORY_PENDING = 'DELETE_CATEGORY_PENDING';
export const DELETE_CATEGORY_REJECTED = 'DELETE_CATEGORY_REJECTED';
export const DELETE_CATEGORY_FULFILLED = 'DELETE_CATEGORY_FULFILLED';
export const GET_CATEGORY_TO_DELETE_INFO_FULFILLED = 'GET_CATEGORY_TO_DELETE_INFO_FULFILLED';

export const initialState = {
    loading: false,
    deletingCategory: false,
    deletingCategoryForms: 0,
    error: null,
    data: [],
    pages: 0,
    sort: null,
    order: null,
    page: 1,
    search: ''
};

export const reducer = (state, { type, payload }) => {
    switch (type) {
        case GET_CATEGORIES_PENDING:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_CATEGORIES_REJECTED:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case GET_CATEGORIES_FULFILLED:
            return {
                ...state,
                loading: false,
                data: payload.data,
                pages: payload.pages
            };

        case SET_PAGE:
            return {
                ...state,
                page: payload
            };
        case SET_SEARCH:
            return {
                ...state,
                search: payload
            };
        case SET_SORT:
            return {
                ...state,
                sort: payload.sort,
                order: payload.order
            };

        case STORE_CATEGORY_PENDING:
            return {
                ...state,
                loading: true,
                error: null
            };
        case STORE_CATEGORY_REJECTED:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case STORE_CATEGORY_FULFILLED:
            return {
                ...state,
                loading: false
            };

        case GET_CATEGORY_TO_DELETE_INFO_FULFILLED:
            return {
                ...state,
                deletingCategoryForms: payload.forms_count
            };

        case DELETE_CATEGORY_PENDING:
            return {
                ...state,
                deletingCategory: true
            };
        case DELETE_CATEGORY_REJECTED:
            return {
                ...state,
                deletingCategory: false,
                error: payload
            };
        case DELETE_CATEGORY_FULFILLED:
            return {
                ...state,
                deletingCategory: false,
                data: state.data.filter(category => category._id !== payload)
            };

        default:
            return state;
    }
};