import React from 'react'
import CodeCopy from "../CodeCopy";

export default ({
                    element,
                    onChange = f => f,
                    value = "",
                }) => {
    return(

        <div className="fieldset-row">
            <div className="form-row with-calendar">
                <label>{element.text}</label>
                <CodeCopy text={element.code}>
                    <input type="text" value={value} onChange={onChange} placeholder={element.placeholder} />
                </CodeCopy>
                <i className="calendar-btn icon-clock"/>
            </div>
        </div>
    )
}