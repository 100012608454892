import styled from "styled-components";
import React from "react";

const AdvancedValidationsButton = styled(({className, ...props}) => (
    <div className={`tbl-btn ${className}`} {...props}>
        <i
            className="icon-settings"
            title="Advanced validations"
        />
    </div>
))`
    opacity: 1 !important;
    height: 2.5rem;
    width: 2.5rem;
    border: unset !important;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    
    &:hover {
        opacity: 1!important;
        background: var(--primary-color)!important;
        border-color: var(--primary-color)!important;
        color: #f8f8f8!important;
        text-decoration: none;
        box-shadow: 0 0.1rem 0.2rem rgb(125 125 125 / 20%);
    }
    
    i {
        line-height: 2.5rem;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1.5rem;
    }
`;

export {AdvancedValidationsButton}