import React from 'react'
import {v4 as uuid} from 'uuid'
import AdeleteConfirm from '../../components/AdeleteConfirm'
import {TopBarContainer} from "../comonComponents/ElementEditor.TopBarContainer";


export const CustomerSatisfactionEditor = ({
    element,
    remove,
    editElement,
    changeElementEditorMode,
    dragHandleProps = {},
    VisibilityAffected,
    formElements,
    addElementOnPosition,
    index
}) => {

    const [state, setState] = React.useState({
        localElement: {
            ...element
        },
        errors: {}
    })

    const localElement = state.localElement

    const setLocalElement = callback => setState(state => ({
        ...state,
        localElement: {...callback(state.localElement)}
    }))

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        const errors = {};
        if (localElement.descriptionTitle.length > 100) {
            errors.descriptionTitle = "Maximum 60 characters allowed"
        }
        if (localElement.title.length > 100) {
            errors.title = "Maximum 60 characters allowed"
        }
        if (localElement.text.length > 600) {
            errors.text = "Maximum 600 characters allowed"
        }
        if (localElement.worst.length > 60) {
            errors.worst = "Maximum 60 characters allowed"
        }
        if (localElement.poor.length > 60) {
            errors.poor = "Maximum 60 characters allowed"
        }
        if (localElement.average.length > 60) {
            errors.average = "Maximum 60 characters allowed"
        }
        if (localElement.good.length > 60) {
            errors.good = "Maximum 60 characters allowed"
        }
        if (localElement.excellent.length > 60) {
            errors.excellent = "Maximum 60 characters allowed"
        }
        setState(state => ({
            ...state,
            errors
        }))
        if (Object.keys(errors).length === 0) {
            editElement({
                ...state.localElement
            })
            changeElementEditorMode("DEFAULT")
        }
    };

    const onPinnedChange = ({target: {checked}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                pinned: {
                    status: checked
                }
            }
        ))
    };

    const onTextChange = key => ({target: {value: text}}) => setLocalElement(localElement => (
        {
            ...localElement,
            [key]: text
        }
    ));

    const duplicateComponent = () => {
        addElementOnPosition(index + 1, {
            ...element,
            editorMode: 'DEFAULT'
        })
    }

    const openEditMode = () => {
        changeElementEditorMode("EDIT")
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" ? "view-box-options" : ""} ${element.editorMode === "EDIT" ? "view-box-edit" : ""} ${element.editorMode === "VALIDATIONS" ? "view-box-validation" : ""}`}>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center" style={{cursor: "pointer"}} onClick={openEditMode}>
                <div className="form-box-wrap-center-content">
                    <TopBarContainer>
                        <VisibilityAffected element={element} formElements={formElements}/>
                    </TopBarContainer>
                    <h6 className="supTitle">Recipient Satisfaction</h6>
                    <h3 className="form-box-wrap-title">{localElement.title}</h3>
                    <p className="form-box-wrap-descrition">{localElement.text}</p>
                </div>
                <ul className="feedback-component">
                    <li><i className="icon-feedback-icon-1"/><span>{localElement.worst}</span></li>
                    <li><i className="icon-feedback-icon-2"/><span>{localElement.poor}</span></li>
                    <li><i className="icon-feedback-icon-3"/><span>{localElement.average}</span></li>
                    <li><i className="icon-feedback-icon-4"/><span>{localElement.good}</span></li>
                    <li><i className="icon-feedback-icon-5"/><span>{localElement.excellent}</span></li>
                </ul>
                <div className="form-row">
                    <p className="">{localElement.descriptionTitle}</p>
                    <textarea disabled/>
                </div>
                {/* <span className="form-box-wrap-id">#s456te</span> */}
                <p className="form-row-validation-text">{localElement.validations.map(v => `[ ${v.validation} ]  `)}{localElement.pinned && localElement.pinned.status ? "[ show on top ]" : ""}</p>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <AdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </AdeleteConfirm>
                        <span onClick={duplicateComponent}><i className="icon-copy"/>Duplicate</span>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                {(id => (
                    <div className={`form-row ${state.errors.title ? "error" : ""}`}>
                        <input id={id} onChange={onTextChange("title")} value={localElement.title} placeholder="Customer Satisfaction Title"/>
                        {state.errors.title &&
                        <span className="form-row-error-msg">{state.errors.title}</span>
                        }
                    </div>
                ))(uuid())}

                {(id => (
                    <div className={`form-row ${state.errors.text ? "error" : ""}`}>
                        <input id={id} onChange={onTextChange("text")} value={localElement.text}
                               placeholder="Customer Satisfaction Description"/>
                        {state.errors.text &&
                        <span className="form-row-error-msg">{state.errors.text}</span>
                        }
                    </div>
                ))(uuid())}

                {(id => (
                    <div className={`form-row ${state.errors.descriptionTitle ? "error" : ""}`}>
                        <input id={id} onChange={onTextChange("descriptionTitle")} value={localElement.descriptionTitle}
                               placeholder="Details"/>
                        {state.errors.descriptionTitle &&
                        <span className="form-row-error-msg">{state.errors.descriptionTitle}</span>
                        }
                    </div>
                ))(uuid())}

                {(id => (
                    <div className={`form-row ${state.errors.worst ? "error" : ""}`}>
                        <input id={id} onChange={onTextChange("worst")} value={localElement.worst} placeholder="Worst"/>
                        {state.errors.worst &&
                        <span className="form-row-error-msg">{state.errors.worst}</span>
                        }
                    </div>
                ))(uuid())}

                {(id => (
                    <div className={`form-row ${state.errors.poor ? "error" : ""}`}>
                        <input id={id} onChange={onTextChange("poor")} value={localElement.poor} placeholder="Poor"/>
                        {state.errors.poor &&
                        <span className="form-row-error-msg">{state.errors.poor}</span>
                        }
                    </div>
                ))(uuid())}

                {(id => (
                    <div className={`form-row ${state.errors.average ? "error" : ""}`}>
                        <input id={id} onChange={onTextChange("average")} value={localElement.average}
                               placeholder="Average"/>
                        {state.errors.average &&
                        <span className="form-row-error-msg">{state.errors.average}</span>
                        }
                    </div>
                ))(uuid())}

                {(id => (
                    <div className={`form-row ${state.errors.good ? "error" : ""}`}>
                        <input id={id} onChange={onTextChange("good")} value={localElement.good} placeholder="Good"/>
                        {state.errors.good &&
                        <span className="form-row-error-msg">{state.errors.good}</span>
                        }
                    </div>
                ))(uuid())}

                {(id => (
                    <div className={`form-row ${state.errors.excellent ? "error" : ""}`}>
                        <input id={id} onChange={onTextChange("excellent")} value={localElement.excellent}
                               placeholder="Excellent"/>
                        {state.errors.excellent &&
                        <span className="form-row-error-msg">{state.errors.excellent}</span>
                        }
                    </div>
                ))(uuid())}
                <div className="frc-box">
                    <div className="form-row-checkbox">
                        <input id={`pinned-${localElement.code}`} type="checkbox"
                               checked={localElement.pinned && localElement.pinned.status} onChange={onPinnedChange}/>
                        <label htmlFor={`pinned-${localElement.code}`}>Show on top in saved session</label>
                    </div>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}
