import React from 'react'
import moment from 'moment'
import {synchronizeText} from "../../utils/synchronizeText"
import AdatePicker from '../../components/AdatePicker/AdatePicker'

export default ({ 
    element,
    value = null,
    changeValue,
    errors = [],
    answers_by_code
}) => {

    const readonly = (
        element.validations.find(({validation}) => validation === 'no after session date') !== undefined && 
        element.validations.find(({validation}) => validation === 'no before session date') !== undefined
    )

    React.useEffect(() => {
        if(readonly) changeValue(moment(Date()).format("DD/MM/YYYY"))
    }, [readonly])

    return (
   

            <div className={`form-row with-calendar ${(errors.length > 0) ? 'error' : '' }`}>
                {element.text &&  <label>{synchronizeText(element.text, answers_by_code)}</label>}

                <AdatePicker
                    disabled={readonly}
                    dateFormat={'dd/MM/yyyy'}
                    onChange={date => changeValue(date ? moment(date).format('DD/MM/YYYY') : date)}
                    value={value ? moment(value, 'DD/MM/YYYY').toDate() : null}
                    placeholder={element.placeholder}
                />

                {renderErrors(errors, element)}
                <i class="calendar-btn icon-ia-calendar"></i>
            </div>


    )
}

const renderErrors = (errors, element) => {
    if (errors.length === 0)
        return null;

    return errors.map(error => {
        if (error === 'required') {
            return <span key={error} className="form-row-error-msg">This field is required</span>;
        } else if (error === 'no past date') {
            let date = element.validations.find(validation => validation.validation === "no past date").value
            return <span key={error} className="form-row-error-msg">No date before {date}</span>;
        } else if (error === 'no future date') {
            let date = element.validations.find(validation => validation.validation === "no future date").value
            return <span key={error} className="form-row-error-msg">No date past {date}</span>;
        } else if (error === 'no after session date') {
            return <span key={error} className="form-row-error-msg">No future dates</span>
        } else if (error === 'no before session date') {
            return <span key={error} className="form-row-error-msg">No past dates</span>
        }

        return null;
    })
}