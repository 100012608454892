import React from 'react'
import {useParams} from 'react-router-dom'
import API from '../../api'
import Navbar from '../../components/Navbar'
import {isEmail} from "validator";
import Portrait from "../../components/Portrait"
import {phoneValidator} from "../../utils/phoneValidator"
import {getError} from "../../utils/getError"
import {Country, City} from '../../components/CountryCity'
import IPopConfirm from "../../components/AdeleteConfirm";
import PageNotFound from '../../components/PageNotFound'
import Loading from '../../components/Loading'

const EditClient = props => {

    const [state, setState] = React.useState({
        loadingClient: false,
        loadingClientSuccess: false,
        success: false,
        errors: [],
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        country: '',
        locality: '',
        street: '',
        nr: '',
        block: '',
        entry: '',
        floor: '',
        apartment: ''
    });

    const params = useParams()

    React.useEffect(() => {
        getClient()
    }, [])

    const getClient = async () => {
        try {
            setState(state => ({...state, loadingClient: true}))
            const {data} = await API.get(`clients/${params.clientId}`)
            setState(state => ({
                ...state,
                loadingClient: false,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                phone: data.phone,
                country: data.country,
                locality: data.locality,
                street: data.street,
                nr: data.nr,
                block: data.block,
                entry: data.entry,
                floor: data.floor,
                apartment: data.apartment,
                loadingClientSuccess: true
            }))
        } catch(err) {
            setState(state => ({
                ...state,
                loadingClient: false,
                ...(err.response.status === 404 ? {pageNotFound: true} : {})
            }))
        }
    }

    const setName = ({target: {value: first_name}}) => setState(state => ({...state, first_name}));
    const setLastName = ({target: {value: last_name}}) => setState(state => ({...state, last_name}));
    const setEmail = ({target: {value: email}}) => setState(state => ({...state, email}));
    const setPhone = ({target: {value: phone}}) => setState(state => ({...state, phone}));
    const setCountry = ({target: {value: country}}) => setState(state => ({...state, country}));
    const setLocality = ({target: {value: locality}}) => setState(state => ({...state, locality}));
    const setStreet = ({target: {value: street}}) => setState(state => ({...state, street}));
    const setNr = ({target: {value: nr}}) => setState(state => ({...state, nr}));
    const setBlock = ({target: {value: block}}) => setState(state => ({...state, block}));
    const setEntry = ({target: {value: entry}}) => setState(state => ({...state, entry}));
    const setFloor = ({target: {value: floor}}) => setState(state => ({...state, floor}));
    const setApartment = ({target: {value: apartment}}) => setState(state => ({...state, apartment}));

    const updateClient = async () => {
        const errors = [];

        if (state.first_name === '') errors.push('name_required');
        if (state.last_name === '') errors.push('surname_required');
        if (!isEmail(state.email)) errors.push('invalid_email');

        if (state.phone !== '' && phoneValidator(state.phone) === false) errors.push('invalid_phone');

        if (errors.length > 0) {
            setState(state => ({...state, errors}));
            return
        }

        try {
            setState(state => ({...state, loading: true, success: false, errors: []}));
            const {
                first_name,
                last_name,
                phone,
                email,
                country,
                locality,
                street,
                nr,
                block,
                entry,
                floor,
                apartment
            } = state;
            await API.put(`clients/${params.clientId}`, {
                first_name,
                last_name,
                phone,
                email,
                country,
                locality,
                street,
                nr,
                block,
                entry,
                floor,
                apartment
            });
            props.history.push('/clients')

        } catch (err) {
            setState(state => ({...state, errors: getError(err.response)}))
        }
    };


    const errorsContain = (...args) => {
        for (let i = 0; i < args.length; i++) {
            if (state.errors.includes(args[i]))
                return true;
        }
        return false
    };

    return (
        <>
            <Navbar/>
            {state.loadingClient && <Loading />}
            <PageNotFound active={!!state.pageNotFound}>

                {!state.loadingClient && state.loadingClientSuccess &&
                    <div className="wrapper client-page">
                        <div className="content">
                            <form className="content-box form agent-form">
                                <div className="scrollbar">

                                    <div className="form-box-body">
                                        <h2>Update Client</h2>
                                        {state.success &&
                                            <>
                                                <div className="succes-msg">Client Updated</div>
                                                <br/>
                                            </>
                                        }
                                        {!state.loadingClient &&
                                        <fieldset>
                                            <div className="form-grid-row">
                                                <div
                                                    className={`form-row ${errorsContain('name_required') ? 'error' : ''}`}>
                                                    <label>Name</label>
                                                    <input name="first_name" value={state.first_name} maxLength={40}
                                                        onChange={(e) => setName(e)}/>
                                                    {errorsContain('name_required') &&
                                                    <span className="form-row-error-msg">Name required</span>
                                                    }
                                                </div>
                                                <div
                                                    className={`form-row ${errorsContain('surname_required') ? 'error' : ''}`}>
                                                    <label>Surname</label>
                                                    <input name="last_name" value={state.last_name} maxLength={40}
                                                        onChange={(e) => setLastName(e)}/>
                                                    {errorsContain('surname_required') &&
                                                    <span className="form-row-error-msg">Surname required</span>
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-grid-row">
                                                <div
                                                    className={`form-row ${errorsContain('invalid_phone') ? 'error' : ''}`}>
                                                    <label>Phone</label>
                                                    <input value={state.phone} maxLength={20} name="phone"
                                                        onChange={(e) => setPhone(e)}/>
                                                    {errorsContain('invalid_phone') &&
                                                    <span
                                                        className="form-row-error-msg">Value must be a valid phone number</span>
                                                    }
                                                </div>

                                                <div
                                                    className={`form-row ${errorsContain('invalid_email') ? 'error' : ''}`}>
                                                    <label htmlFor="">Email</label>
                                                    <input value={state.email} maxLength={255} name="email"
                                                        onChange={(e) => setEmail(e)}/>
                                                    {errorsContain('invalid_email') &&
                                                    <span className="form-row-error-msg">Value must be a valid email</span>
                                                    }
                                                </div>
                                            </div>
                                            <h4>Client details</h4>
                                            <div className="form-grid-row">
                                                <div className="form-row">
                                                    <label>County</label>
                                                    <Country
                                                        value={state.country}
                                                        onChange={(e) => setCountry(e)}
                                                        name="country"
                                                    />
                                                </div>

                                                <div className="form-row">
                                                    <label>City</label>
                                                    <City value={state.locality}
                                                        onChange={(e) => setLocality(e)} name="locality"
                                                        selectedCountry={state.country}/>
                                                </div>
                                            </div>

                                            <div className="form-grid-row street-grid">
                                                <div className="form-row">
                                                    <label>Street</label>
                                                    <input value={state.street} maxLength={150} name="street"
                                                        onChange={(e) => setStreet(e)}/>
                                                </div>

                                                <div className="form-row">
                                                    <label>Nr</label>
                                                    <input value={state.nr} maxLength={10} name="nr"
                                                        onChange={(e) => setNr(e)}/>
                                                </div>
                                            </div>

                                            <div className="form-grid-row address-grid">
                                                <div className="form-row">
                                                    <label>BL</label>
                                                    <input value={state.block} maxLength={150} name="block"
                                                        onChange={(e) => setBlock(e)}/>
                                                </div>

                                                <div className="form-row">
                                                    <label>SC</label>
                                                    <input value={state.entry} maxLength={10} name="entry"
                                                        onChange={(e) => setEntry(e)}/>
                                                </div>

                                                <div className="form-row">
                                                    <label>ET</label>
                                                    <input value={state.floor} maxLength={10} name="floor"
                                                        onChange={(e) => setFloor(e)}/>
                                                </div>

                                                <div className="form-row">
                                                    <label>Ap</label>
                                                    <input value={state.apartment} maxLength={10} name="apartment"
                                                        onChange={(e) => setApartment(e)}/>
                                                </div>
                                            </div>
                                        </fieldset>
                                        }
                                        <div className="form-box-actions">

                                            <IPopConfirm
                                                title="Are you sure you want to reset the data?"
                                                onConfirm={() => {
                                                    return setState(state => ({
                                                        ...state,
                                                        first_name: '',
                                                        last_name: '',
                                                        email: '',
                                                        phone: '',
                                                        country: '',
                                                        locality: '',
                                                        street: '',
                                                        nr: '',
                                                        block: '',
                                                        entry: '',
                                                        floor: '',
                                                        apartment: ''
                                                    }))
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <button className="button button-outline" type="button"><i
                                                    className="icon-refresh-cw"/><span> Discard</span></button>
                                            </IPopConfirm>
                                            <button className="button" type="button" onClick={updateClient}><i
                                                className="icon-ia-checked-outline"/><span>Save</span></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                }
            </PageNotFound>
            <Portrait/>
        </>
    )
};

export default EditClient