import React from 'react'
import CodeCopy from '../CodeCopy'

export default ({ 
    element, 
    onChange = f => f, 
    value = ""
}) => {
    return (
        <div className="fieldset-row">
            <div className="form-row">
                <label>{element.text}</label>
                <CodeCopy text={element.code}>
                    <input type="text" value={value} onChange={e => onChange(e.target.value)} placeholder={element.placeholder}/>
                </CodeCopy>
            </div>
        </div>
    )
}