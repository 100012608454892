import React from 'react'
import {notification} from '../../components/Lnotification'
import bytes from 'bytes'

import API from '../../api'
import AdeleteConfirm from "../../components/AdeleteConfirm";
import capitalize from "lodash/capitalize";
import moment from "moment";

const ProfileChangePicture = ({profileStore}) => {

    const [profileState] = profileStore

    const [state, setState] = React.useState({
        image: profileState.image
    });

    const removeImage = async () => {
        try {
            if (state.image) {
                await API.put('/users', {remove_image: true});
            }
            setState(state => ({...state, image: null}))
        } catch (err) {
            notification.error({message: 'Something went wrong'})
        }
    };

    const onImageChange = async e => {
        try {
            if (e.target.files.length === 0) return;
            if (e.target.files[0].size > bytes('20MB')) {
                notification.warning({message: 'Max file size 20MB.'});
                e.target.value = null;
                return
            }

            const dataForm = new FormData();
            dataForm.append('image', e.target.files[0]);
            const {data} = await API.put('/users', dataForm);
            setState(state => ({...state, image: data.user.image}))
        } catch (err) {
            notification.error({message: 'Something went wrong'})
        }
    };

    return (
        <div className="profile-details">
            <div className="profile-image">
                {state.image &&
                <>
                    <div className="ils-uploaded-img"
                         style={{display: state.image ? 'block' : 'none'}}>
                        <img
                            src={`${/^https?:\/\//i.test(state.image) ? state.image : process.env.REACT_APP_API_BASE_URL + '/images/users/image/' + state.image}`}
                            alt=""/>
                    </div>
                    <AdeleteConfirm
                        title="Are you sure you want to remove the image?"
                        onConfirm={removeImage}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className="tbl-btn"><i className="icon-ia-trash-bold"/>
                            <div className="tooltip tooltip-bottom">Delete</div>
                        </div>
                    </AdeleteConfirm>
                </>
                }
                <input className="ils-upload-file" type="file" accept="image/*"
                       onChange={onImageChange}/>
                <span>Upload photo</span>
            </div>

            <div>
                <h3>{profileState.name}</h3>
                <p className="user-type"><strong>{capitalize(profileState.role)}</strong></p>
                <p>{profileState.email}</p>
                <p>{profileState.phone}</p>
                <p className="last-login">Last
                    login: {(profileState.lastLogin) ? moment(profileState.lastLogin).format('D MMM hh:mm A (YYYY)') : 'Never Logged In'}</p>
            </div>
        </div>
    )
};

export default ProfileChangePicture