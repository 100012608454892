import React from 'react'

import Frame from './Frame'
import LoadAfter from './LoadAfter'
import Loading from "./Loading"

const AtabletPreview = ({children=null}) => {
    return (
        <Frame
            head={
                <>
                    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                    <meta name="viewport" content="user-scalable=no, initial-scale=1.0, maximum-scale=1.0, width=device-width" />
                    <meta name="robots" content="index, follow" />
                    <meta name="language" content="ro" />
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="mobile-web-app-capable" content="yes" />
                    <base href="/" />
                    <title>IA</title>
                    <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i" rel="stylesheet" />
                    <link type="text/css" href="AlchemistAdmin/client/icons.css" rel="stylesheet" />
                    <link type="text/css" href="AlchemistAdmin/client/swiper.min.css" rel="stylesheet" />
                    <link type="text/css" href="AlchemistAdmin/client/global.css" rel="stylesheet" />
                    <link type="text/css" href="AlchemistAdmin/client/preview.css" rel="stylesheet" />
                </>
            }
        >
            <LoadAfter
                links={[
                    'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i',
                    'AlchemistAdmin/client/icons.css',
                    'AlchemistAdmin/client/swiper.min.cs',
                    'AlchemistAdmin/client/global.css',
                    'AlchemistAdmin/client/preview.css'
                ]}
                loader={<Loading/>}
            >
                {children}
            </LoadAfter>

        </Frame>
    )   
}

export default AtabletPreview