import React, { useState } from 'react'
import ApopUp from './ApopUp'
import {css} from 'styled-components'

const ApopConfirm = ({
    children,
    onConfirm = f=>f,
    okText="Confirm",
    cancelText="Cancel",
    title=null,
    onOpen=null,
    body=null
}) => {
    const [open, setOpen] = useState(false)

    return(
        <>
            {React.cloneElement(children, { onClick: e => {
                e.preventDefault()
                setOpen(true)
                if (onOpen) {
                    onOpen();
                }
            } })}
            <ApopUp
                visible={open}
                onCancel={() => setOpen(false)}
                overLayClass="overlay-template"
                onConfirm={() => {
                    setOpen(false)
                    onConfirm()
                }}
                title={title}
                okText={okText}
                rawCss={css`
                  .overlay-content {
                    width: 45rem;
                  }
                `}
            >
                <form className="form" onSubmit={e => e.preventDefault()}>
                    <>{body}</>
                </form>
            </ApopUp>
        </>
    )
}

export default ApopConfirm