import React from 'react'
import {css} from 'styled-components'

import ApopUp from '../../components/ApopUp'
import {useLocation, useParams, useHistory} from 'react-router-dom'
import Loading from '../../components/Loading'
import {notification} from '../../components/Lnotification'
import API from '../../api'

export default ({
                    value = "",
                    setValue = f => f,
                    failed = false,
                    saveConfig = f => f,
                    reloadParent = f => f
                }) => {

    const [state, setState] = React.useState({
        modal: false,
        provider: '',
        permission: null,
        folder: null,
        saveConfigurationLoading: false
    }, 'session-storage')

    const location = useLocation()
    const history = useHistory()
    React.useEffect(() => {
        if (location.search || location.hash) {
            const script = document.createElement("script");
            script.async = true;
            script.src = 'https://js.live.net/v7.0/OneDrive.js'
            const element = document.head.appendChild(script);
            return () => element.remove()
        }
        if (history.location.state && history.location.state.storageConfiguration) {
            document.getElementById("storage-configuration").scrollIntoView();
        }
    }, [history.location])

    const onStorageClick = ({target: {value: provider}}) => {
        if (provider !== value) {
            if (provider === 'alchemist') {
                setValue(provider)
                saveConfig({storage: provider})
                return
            }
            setState(state => ({...state, modal: true, provider}))
        }
    }

    const onConfigureClick = provider => {
        setState(state => ({...state, modal: true, provider}))
    }

    const onPermission = permission => {
        setState(state => ({...state, permission}))
    }

    const onFolder = folder => setState(state => ({...state, folder}))

    const saveConfiguration = async () => {
        try {
            setState(state => ({...state, saveConfigurationLoading: true}))
            const {permission: {code}, provider, folder} = state
            await API.post('company/storage/offlineAuthorization', {code, folder, provider})
            setValue(provider)
            setState(state => ({
                ...state,
                modal: false,
                permission: null,
                provider: '',
                folder: null,
                saveConfigurationLoading: false
            }))
            notification.cancel()
            notification.success({message: "Documents storage updated successfully"})
            reloadParent()
        } catch (err) {
        }
    }

    return (
        <div id="storage-configuration">
            <h4>Documents Storage</h4>
            <ul className="radioCheckbox-list cols4">
                <li>
                    <div className="form-row-radio">
                        <input id="alchemist-storage" type="radio" value="alchemist" checked={value === 'alchemist'}
                               onChange={onStorageClick}/>
                        <label htmlFor="alchemist-storage">Alchemist</label>
                    </div>
                </li>
                <li className={`${failed === true && value === 'gdrive' ? "error" : ""}`}>
                    <div className="form-row-radio">
                        <input id="googledrive-storage" type="radio" value="gdrive" checked={value === 'gdrive'}
                               onChange={onStorageClick}/>
                        <label htmlFor="googledrive-storage">Google Drive</label>
                    </div>
                    {failed === true && value === 'gdrive' &&
                    <div className="form-row-radio-error-msg">
                        <p>We encountered problems in saving your generated documents to Google Drive. Please
                            reconnect and check your account.</p>
                        <span className="button button-outline reconect-button"
                              onClick={() => onConfigureClick("gdrive")}>reconnect</span>
                    </div>
                    }
                </li>
                <li className={`${failed === true && value === 'onedrive' ? "error" : ""}`}>
                    <div className="form-row-radio give-permission-popup">
                        <input id="onedrive-storage" type="radio" value="onedrive" checked={value === 'onedrive'}
                               onChange={onStorageClick}/>
                        <label htmlFor="onedrive-storage">Microsoft OneDrive</label>
                    </div>
                    {failed === true && value === 'onedrive' &&
                    <div className="form-row-radio-error-msg">
                        <p>We encountered problems in saving your generated documents to Microsoft Onedrive. Please
                            reconnect and check your account.</p>
                        <span className="button button-outline reconect-button"
                              onClick={() => onConfigureClick("onedrive")}>reconnect</span>
                    </div>
                    }
                </li>
                <li className={`${failed === true && value === 'dropbox' ? "error" : ""}`}>
                    <div className="form-row-radio give-permission-popup">
                        <input id="dropbox-storage" type="radio" value="dropbox" checked={value === 'dropbox'}
                               onChange={onStorageClick}/>
                        <label htmlFor="dropbox-storage">Dropbox</label>
                    </div>
                    {failed === true && value === 'dropbox' &&
                    <div className="form-row-radio-error-msg">
                        <p>We encountered problems in saving your generated documents to Dropbox. Please
                            reconnect and check your account.</p>
                        <span className="button button-outline reconect-button"
                              onClick={() => onConfigureClick("dropbox")}>reconnect</span>
                    </div>
                    }
                </li>
            </ul>

            <ApopUp
                visible={state.modal}
                onCancel={() => {
                    setState(state => ({...state, modal: false, permission: null, folder: null}))
                }}
                disableHeader
                disableFooter={!state.permission || !state.folder || state.saveConfigurationLoading}
                okText="Confirm"
                rawCss={css`
                    .overlay-content { width:56rem; }
                    .overlay-body { padding:5rem 0 6rem; }
                    h3 { text-align:center; margin:0 auto 5rem; }
                    .overlay-body { padding-top: 6rem; padding-bottom: 6rem; }

                    ${state.permission ? css`
                        .ads-steps:after { width:17rem; }
                        .ads-steps li:first-child:after { transform: translate(-50%,-50%) scale(1); }
                        .ads-steps li:last-child span { border-color:#5d2560; color:#fff; background:#5d2560; }
                        .ads-steps-content li:last-child { opacity:1; visibility:visible; }
                    ` : ''}
                `}
                onConfirm={saveConfiguration}
            >
                {state.modal && (
                    <>
                        <h3>Provider permission & folder</h3>
                        <ul className="ads-steps">
                            <li><span>1</span></li>
                            <li><span>2</span></li>
                        </ul>
                        <ul className="ads-steps-content">
                            <li>
                                <h6>Give permission</h6>
                                <p>Your Alchemist generated documents will be stored on your {{
                                    gdrive: "Google Drive",
                                    onedrive: "Microsoft OneDrive",
                                    dropbox: "Dropbox"
                                }[state.provider]} Account. You can always change these settings in your profile.</p>
                            </li>
                            <li>
                                {state.permission && !state.folder &&
                                <>
                                    <h6>Pick a folder</h6>
                                    <FolderPicker onFolder={onFolder} provider={state.provider}/>
                                </>
                                }
                            </li>
                        </ul>
                        {state.saveConfigurationLoading && <Loading/>}
                        {!state.saveConfigurationLoading && !state.permission &&
                        <PermissionCollector onPermission={onPermission} provider={state.provider}/>
                        }
                    </>
                )}
            </ApopUp>
        </div>
    )
}

const FolderPicker = ({provider, onFolder}) => {
    return (
        <>
            {({
                gdrive: <GoogleDriveFolderPicker onFolder={onFolder}/>,
                onedrive: <OndeDriveFolderPicker onFolder={onFolder}/>,
                dropbox: <DropboxFolderPicker onFolder={onFolder}/>
            })[provider]}
        </>
    )
}

const PermissionCollector = ({provider, onPermission}) => {
    return (
        <>
            {({
                gdrive: <GoogleDriveGetPermission onPermission={onPermission}/>,
                onedrive: <OneDrivePrmission onPermission={onPermission}/>,
                dropbox: <DropboxGetPermission onPermission={onPermission}/>
            })[provider]}
        </>
    )
}

export const RedirectUri = props => {
    const provider = useParams().provider
    const params = new URLSearchParams(useLocation().search)
    const code = params.get('code')
    React.useEffect(() => {
        window.opener.postMessage({code, provider})
        window.close()
    }, [])
    return null
}


const OneDrivePrmission = ({onPermission}) => {
    const redirect_uri = `${process.env.REACT_APP_DASHBOARD_URL}/storage/onedrive/redirect_uri`;
    const scope = 'Files.ReadWrite offline_access openid'
    const client_id = process.env.REACT_APP_STORAGE_ONEDRIVE_CLIENT_ID
    const response_type = 'code'
    const link = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${client_id}&scope=${scope}&response_type=${response_type}&redirect_uri=${redirect_uri}`

    React.useEffect(() => {
        window.open(link, '', 'height=600,width=450')
        window.addEventListener('message', handleCodemessage)
    }, [])

    const handleCodemessage = event => {
        if(event?.data?.code === undefined && event?.data?.provider === undefined) return
        const {code, provider} = event.data
        onPermission({code, provider})
        window.removeEventListener('message', handleCodemessage)
    }

    return null
}

const OndeDriveFolderPicker = ({onFolder}) => {

    React.useEffect(() => {
        if (!window.OneDrive) {
            const script = document.createElement("script");
            script.async = true;
            script.src = 'https://js.live.net/v7.0/OneDrive.js'
            const element = document.head.appendChild(script);
            return () => {
                element.remove()
            }
        }
    }, [])

    const load = () => {
        const odOptions = {
            clientId: process.env.REACT_APP_STORAGE_ONEDRIVE_CLIENT_ID,
            action: "query",
            multiSelect: false,
            openInNewWindow: true,
            advanced: {
                filter: "folder"
            },
            success: function (files) {
                const {id} = files.value[0]
                onFolder(id)
            },
            cancel: function () {
            },
            error: function (e) {
            }
        }

        window.OneDrive.save(odOptions);
    }

    return (
        <a href="#" onClick={e => {
            e.preventDefault();
            load()
        }} className="button button-outline">Select folder</a>
    )
}


const GoogleDriveGetPermission = ({onPermission}) => {

    const client_id = process.env.REACT_APP_STORAGE_GOOGLEDRIVE_CLIENT_ID
    const redirect_uri = `${process.env.REACT_APP_DASHBOARD_URL}/storage/gdrive/redirect_uri`
    const response_type = 'code'
    const access_type = 'offline'
    const prompt = 'consent'
    const scope = 'https://www.googleapis.com/auth/drive openid'
    const link = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${client_id}&redirect_uri=${redirect_uri}&prompt=${prompt}&response_type=${response_type}&access_type=${access_type}&scope=${scope}`

    React.useEffect(() => {
        window.open(link, '', 'height=600,width=450')
        window.addEventListener('message', handleCodemessage)
    }, [])

    const handleCodemessage = event => {
        if(event?.data?.code === undefined && event?.data?.provider === undefined) return
        const {code, provider} = event.data
        onPermission({code, provider})
        window.removeEventListener('message', handleCodemessage)
    }

    return null
}

const GoogleDriveFolderPicker = ({onFolder}) => {

    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = false;
        script.src = `https://apis.google.com/js/api.js`;
        script.onload = () => {
            window.gapi.load('auth');
            window.gapi.load('picker');
        };
        const element = document.head.appendChild(script);
        return () => element.remove()
    }, [])

    const developerKey = process.env.REACT_APP_STORAGE_GOOGLEDRIVE_DEVELOPER_KEY
    const clientId = process.env.REACT_APP_STORAGE_GOOGLEDRIVE_CLIENT_ID
    const scope = 'https://www.googleapis.com/auth/drive.file';
    const appId = process.env.REACT_APP_STORAGE_GOOGLEDRIVE_APP_ID
    let pickerApiLoaded = false;
    let oauthToken;

    // Use the Google API Loader script to load the google.picker script.
    function loadPicker() {
        onAuthApiLoad();
    }

    function onAuthApiLoad() {
        window.gapi.auth.authorize(
            {
                'client_id': clientId,
                'scope': scope,
                'immediate': false
            },
            handleAuthResult
        )
    }

    function handleAuthResult(authResult) {
        if (authResult && !authResult.error) {
            oauthToken = authResult.access_token;
            pickerApiLoaded = true;
            createPicker();
        }
    }

    // Create and render a Picker object for searching images.
    function createPicker() {
        if (pickerApiLoaded && oauthToken) {

            const docsView = new window.google.picker.DocsView()
                .setIncludeFolders(true)
                .setMimeTypes('application/vnd.google-apps.folder')
                .setSelectFolderEnabled(true);


            const picker = new window.google.picker.PickerBuilder()
                .addView(docsView)
                .setCallback(pickerCallback)
                .setAppId(appId)
                .setOAuthToken(oauthToken)
                .setDeveloperKey(developerKey)
                .build();

            picker.setVisible(true);
        }
    }

    // A simple callback implementation.
    function pickerCallback(data) {
        if (data.action === window.google.picker.Action.PICKED) {
            const {id} = data.docs[0];
            onFolder(id)
        }
    }

    return (
        <>
            <div id="result"></div>
            <a href="#" onClick={e => {
                e.preventDefault();
                loadPicker()
            }} className="button button-outline">Select folder</a>
        </>
    )
}

const DropboxGetPermission = ({onPermission}) => {
    const redirectUrl = `${process.env.REACT_APP_DASHBOARD_URL}/storage/dropbox/redirect_uri`
    const client_id = process.env.REACT_APP_STORAGE_DROPBOX_CLIENT_ID
    const response_type = 'code'
    const link = `https://www.dropbox.com/1/oauth2/authorize?client_id=${client_id}&response_type=${response_type}&redirect_uri=${redirectUrl}`

    React.useEffect(() => {
        window.open(link, '', 'height=600,width=450')
        window.addEventListener('message', handleCodemessage)
    }, [])

    const handleCodemessage = event => {
        if(event?.data?.code === undefined && event?.data?.provider === undefined) return
        const {code, provider} = event.data
        onPermission({code, provider})
        window.removeEventListener('message', handleCodemessage)
    }

    return null
}

const DropboxFolderPicker = ({onFolder}) => {

    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = `https://www.dropbox.com/static/api/2/dropins.js`;
        script.setAttribute('data-app-key', process.env.REACT_APP_STORAGE_DROPBOX_CLIENT_ID)
        script.id = "dropboxjs";
        const element = document.head.appendChild(script);
        return () => element.remove()
    }, [])

    const load = () => {
        // var button = window.Dropbox.createChooseButton(options);
        // document.getElementById("result").appendChild(button);
        window.Dropbox.choose(options)
    }

    const options = {

        // Required. Called when a user selects an item in the Chooser.
        success: function (files) {
            const {link, id} = files[0]
            onFolder(id)
        },

        // Optional. Called when the user closes the dialog without selecting a file
        // and does not include any parameters.
        cancel: function () {

        },

        // Optional. "preview" (default) is a preview link to the document for sharing,
        // "direct" is an expiring link to download the contents of the file. For more
        // information about link types, see Link types below.
        linkType: "preview", // or "direct"

        // Optional. A value of false (default) limits selection to a single file, while
        // true enables multiple file selection.
        multiselect: false, // or true

        // Optional. This is a list of file extensions. If specified, the user will
        // only be able to select files with these extensions. You may also specify
        // file types, such as "video" or "images" in the list. For more information,
        // see File types below. By default, all extensions are allowed.
        extensions: ['.Directory'],

        // Optional. A value of false (default) limits selection to files,
        // while true allows the user to select both folders and files.
        // You cannot specify `linkType: "direct"` when using `folderselect: true`.
        folderselect: true, // or true

        // Optional. A limit on the size of each file that may be selected, in bytes.
        // If specified, the user will only be able to select files with size
        // less than or equal to this limit.
        // For the purposes of this option, folders have size zero.
        sizeLimit: 1024, // or any positive number
    }

    return (
        <>
            <div id="result"></div>
            <a href="#" onClick={e => {
                e.preventDefault();
                load()
            }} className="button button-outline">Select folder</a>
        </>
    )
}


