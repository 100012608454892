import React from 'react'
import AdeleteConfirm from '../../components/AdeleteConfirm'
import {TopBarContainer} from "../comonComponents/ElementEditor.TopBarContainer";

export const CarDamageEvaludationEditor = ({
    element,
    remove,
    editElement,
    changeElementEditorMode,
    dragHandleProps = {},
    VisibilityAffected,
    formElements,
    addElementOnPosition,
    index
}) => {

    const [state, setState] = React.useState({
        localElement: {
            ...element
        },
        errors: {}
    })

    const localElement = state.localElement

    const setLocalElement = callback => setState(state => ({
        ...state,
        localElement: {...callback(state.localElement)}
    }))

    const onOptionClick = () => {
        changeElementEditorMode(element.editorMode === "OPTIONS" ? "DEFAULT" : "OPTIONS")
    };

    const onEditClick = () => {
        changeElementEditorMode("EDIT")
    };

    const onSaveClick = () => {
        const errors = {};
        if (localElement.text.length > 600) {
            errors.text = "Maximum 600 characters allowed"
        }
        if (localElement.title.length > 60) {
            errors.title = "Maximum 60 characters allowed"
        }
        setState(state => ({
            ...state,
            errors
        }))
        if (Object.keys(errors).length === 0) {
            editElement({
                ...localElement
            })
            changeElementEditorMode("DEFAULT")
        }
    };

    const onTitleChange = ({target: {value}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                title: value
            }
        ))
    };

    const onDescriptionChange = ({target: {value}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                text: value
            }
        ))
    };

    const onPinnedChange = ({target: {checked}}) => {
        setLocalElement(localElement => (
            {
                ...localElement,
                pinned: {
                    status: checked
                }
            }
        ))
    };

    const duplicateComponent = () => {
        addElementOnPosition(index + 1, {
            ...element,
            editorMode: 'DEFAULT'
        })
    }

    const openEditMode = () => {
        changeElementEditorMode("EDIT")
    };

    return (
        <div
            className={`form-box-wrap ${element.editorMode === "OPTIONS" ? "view-box-options" : ""} ${element.editorMode === "EDIT" && "view-box-edit"} ${element.editorMode === "VALIDATIONS" && "view-box-validation"}`}>
            <TopBarContainer>
                <VisibilityAffected element={element} formElements={formElements}/>
            </TopBarContainer>
            <div className="form-box-wrap-drag-btn" {...dragHandleProps}><i className="icon-ia-drag"/></div>
            <div className="form-box-wrap-center" style={{cursor: "pointer"}} onClick={openEditMode}>
                <div className="form-box-wrap-center-content">
                    {/* <h6 className="supTitle">Raport evaluare achizitie autovehicul</h6> */}
                    <p className="form-box-wrap-descrition">{localElement.title}</p>
                    <p className="form-box-wrap-descrition">{localElement.text}</p>
                </div>
                <div className="car-insurance-component">
                    <nav className="car-insurance-menu">
                        <a href="#outside-car-row"><i className="icon-ia-exterior"/><span>Exterior</span></a>
                        <a href="#inside-car-row"><i className="icon-ia-interior"/><span>Interior</span></a>
                        <a href="#engine-row"><i className="icon-ia-engine"/><span>Pornire Motor</span></a>
                        <a href="#accessory-car-row"><i className="icon-ia-accessories"/><span>Accesorii</span></a>
                    </nav>
                    <img className="cars-component-img" src="img/cars-component-img.svg"/>

                </div>
                {/* <span className="form-box-wrap-id">#s456te</span> */}
                <p className="form-row-validation-text">{localElement.validations.map(v => `[ ${v.validation} ]  `)}{localElement.pinned && localElement.pinned.status ? "[ show on top ]" : ""}</p>
                <div className="form-box-wrap-options">
                    <div className="form-box-wrap-options-buttons">
                        <span onClick={onEditClick}><i className="icon-ia-edit-bold trigger-edit-box"/>Edit</span>
                        <AdeleteConfirm
                            onConfirm={remove}
                            okText="Delete"
                            cancelText="Cancel"
                            title="Are you sure you want to delete this component? All data will be lost. "
                        >
                            <span><i className="icon-ia-trash-bold trigger-delete-box"/>Delete</span>
                        </AdeleteConfirm>
                        <span onClick={duplicateComponent}><i className="icon-copy"/>Duplicate</span>
                    </div>
                </div>
            </div>
            <div className="form-box-wrap-options-btn" onClick={onOptionClick}><i className="icon-ia-more"/></div>
            <div className="form-box-wrap-center-edit">
                <div className={`form-row ${state.errors.title ? "error" : ""}`}>
                    <input id="tbl-e" type="text" value={localElement.title} placeholder="Car Damage Evaluation Sheet Title"
                           onChange={onTitleChange}/>
                    {state.errors.title &&
                    <span className="form-row-error-msg">{state.errors.title}</span>
                    }
                </div>
                <div className={`form-row ${state.errors.text ? "error" : ""}`}>
                    <input id="tbl-e" type="text" value={localElement.text} placeholder="Car Damage Evaluation Sheet Description"
                           onChange={onDescriptionChange}/>
                    {state.errors.text &&
                    <span className="form-row-error-msg">{state.errors.text}</span>
                    }
                </div>
                <div className="frc-box">
                    <div className="form-row-checkbox">
                        <input id={`pinned-${localElement.code}`} type="checkbox"
                               checked={localElement.pinned && localElement.pinned.status} onChange={onPinnedChange}/>
                        <label htmlFor={`pinned-${localElement.code}`}>Show on top in saved session</label>
                    </div>
                </div>
                <a className="form-box-wrap-button" onClick={onSaveClick}>Save</a>
            </div>
        </div>
    )
}