import date from './Date/Date'
import dropDown from './DropDown/DropDown'
import shortAnswer from './TextBox/TextBox'
import vin from './Vin/Vin'
import iban from './Iban/Iban'
import registrationNumber from './RegistrationNumber/RegistrationNumber'
import paragraph from './Paragraph/Paragraph'
import fileUpload from './FileUpload/FileUpload'
import image from './Image/Image'
import longAnswer from './LongAnswer/LognAnswer'
import checkboxGroup from './CheckboxGroup/CheckboxGroup'
import RadioGroup from './RadioGroup/RadioGroup'
import imageUpload from './ImageUpload/ImageUpload'
import heading from './Heading/Heading'
import time from './Time/Time'
import map from './Map/Map'

import contractCommunication from './ContractCommunicationOptions/ContractCommunicationOptions'
import clientSignature from './SignaturePad/SignaturePad'
import agentSignature from './AgentSignature/AgentSignature'
import standardAddress from './StandardPostalAddress/StandardPostalAddress'
import privacyAgreement from './PrivacyAgreement/PrivacyAgreement'
import documentsPreview from './DocumentsPreview/DocumentsPreview'
import idCardExtractor from './IdCardExtractor/IdCardExtractor'
import marketingCommunicationOptions from './MarketingCommunicationOptions/MarketingCommunicationOptions'

import vehicleEvaluation from './CarDamageEvaludation/CarDamageEvaludation'
import customerSatisfaction from './CustomerSatisfaction/CustomerSatisfaction'
import itemsSelectListWithImages from './ItemsSelectListWithImages/ItemsSelectListWithImages'

export default {
    'short answer': shortAnswer,
    'dropdown': dropDown,
    'long answer': longAnswer,
    date,
    time,
    'map element': map,
    'checkbox group': checkboxGroup,
    'radio group': RadioGroup,
    heading,
    paragraph,
    image,
    'image upload': imageUpload,
    iban,
    'file upload': fileUpload,
    signature: clientSignature,
    'agent signature': agentSignature,
    'standard address': standardAddress,
    'privacy agreement': privacyAgreement,
    "contract communication options": contractCommunication,
    'documents preview': documentsPreview,
    'id card extractor': idCardExtractor,
    'communication options': marketingCommunicationOptions,
    'vehicle evaluation': vehicleEvaluation,
    'registration number': registrationNumber,
    'customer satisfaction': customerSatisfaction,
    vin,
    'items select list with images': itemsSelectListWithImages
}