import React from 'react'

import LivePreview from './LivePreview.longAnswer'
import {LongAnswerEditor} from './Editor.longAnswer'
import CodeCopy from './CodeCopy.longAnswer'
import View from './View.longAnswer'
import Input from './Input.longAnswer'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <LongAnswerEditor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,

    default: () => ({
        type: "long answer",
        text: "Text Area Label",
        pinned: {
            status: false
        },
        validations: [],
        placeholder: ""
    }),

    availableValidations: ['required', 'min length', 'max length'],
    validate: () => false,
    group: "GENERAL",
    icon: "icon-ia-textarea",
    name: "Text Area",
    view: args => <View {...args}/>
}