
export const elements = {
    SHORT_ANSWER: 'short answer',
    DROPDOWN: 'dropdown',
    LONG_ANSWER: 'long answer',
    DATE: 'date',
    TIME: 'time',
    CHECKBOX_GROUP: 'checkbox group',
    RADIO_GROUP: 'radio group',
    HEADING: 'heading',
    PARAGRAPH: 'paragraph',
    IMAGE: 'image',
    IMAGE_UPLOAD: 'image upload',
    IBAN: 'iban',
    FILE_UPLOAD: 'file upload',
    SIGNATURE: 'signature',
    AGENT_SIGNATURE: 'agent signature',
    STANDARD_ADDRESS: 'standard address',
    PRIVACY_AGREEMENT: 'privacy agreement',
    CONTRACT_COMMUNICATION_OPTIONS: 'contract communication options',
    OFFERS_LIST: 'offers list',
    DOCUMENTS_PREVIEW: 'documents preview',
    ID_CARD_EXTRACTOR: 'id card extractor',
    COMMUNICATION_OPTIONS: 'communication options',
    VEHICLE_EVALUATION: 'vehicle evaluation',
    REGISTRATION_NUMBER: 'registration number',
    CUSTOMER_SATISFACTION: 'customer satisfaction',
    VIN: 'vin',
    MAP: 'map element'
};