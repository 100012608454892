import { isEmpty } from 'validator'

export default (value = {}, validations) => {
    for(let v of validations) {
        const validation = v.validation

        if(validation === "required" && Object.keys(value).find(key => isEmpty(value[key])))
        return "This information is required"
    }
    return false
}