import React from 'react'

import LivePreview from './LivePreview.RegistrationNumber'
import {RegistrationNumberEditor} from './Editor.RegistrationNumber'
import CodeCopy from './CodeCopy.RegistrationNumber'
import View from './View.RegistrationNumber'
import {elements as ELEMENTS} from '../../constants/elements'
import Input from './Input.registrationNumber'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <RegistrationNumberEditor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,

    default: () => ({
        type: ELEMENTS.REGISTRATION_NUMBER,
        text: "Registration Number Title",
        placeholder: "",
        pinned: {
            status: false
        },
        validations: []
    }),
    
    availableValidations: ['required', 'unique'],
    validate: () => false,
    group: "AUTOMOTIVE",
    icon: "icon-ia-auto-registration-no",
    name: "Registration number",
    view: args => <View {...args}/>
}

