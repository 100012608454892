import React from 'react'

import {synchronizeText} from "../../utils/synchronizeText"

export default ({
    element,
    answers_by_code,
    value = {
        rating: null,
        description: null
    }
}) => {

    return (
        <div className="fieldset-row">
            <h3 className="session-form-box-wrap-title" style={{fontWeight: "lighter"}}>{synchronizeText(element.title, answers_by_code)}</h3>
            <p className="session-form-box-wrap-descrition">{synchronizeText(element.text, answers_by_code)}</p>
            <ul className="feedback-component">
            <li className={value.rating === 0 ? "active" : ""}><i className="icon-feedback-icon-1"/><span>{element.worst}</span></li>
            <li className={value.rating === 1 ? "active" : ""}><i className="icon-feedback-icon-2"/><span>{element.poor}</span></li>
            <li className={value.rating === 2 ? "active" : ""}><i className="icon-feedback-icon-3"/><span>{element.average}</span></li>
            <li className={value.rating === 3 ? "active" : ""}><i className="icon-feedback-icon-4"/><span>{element.good}</span></li>
            <li className={value.rating === 4 ? "active" : ""}><i className="icon-feedback-icon-5"/><span>{element.excellent}</span></li>
            </ul>
            <div className="session-form-row">
                <label>{element.descriptionTitle}</label>
                <span className="input-box">{value.description}</span>
            </div>
        </div>

    )
}