import React from "react"

import Navbar from "../../components/Navbar"
import CategoriesStats from "../../components/CategoriesTopStats/CategoriesTopStats"

import CategoriesData from "./CategoriesData/CategoriesData"

import Portrait from "../../components/Portrait"
import Loading from "../../components/Loading"

const Categories = () => {
	const [state, setState] = React.useState({
		loadedCategoriesOnce: false,
		loadedStatsOnce: false,
	})

	return (
		<>
			<Navbar />
			{(!state.loadedCategoriesOnce || !state.loadedStatsOnce) && <Loading />}
			<div
				className="wrapper categories-page"
				css={`
					display: ${!state.loadedCategoriesOnce || !state.loadedStatsOnce
						? "none"
						: "visible"};
				`}
			>
				<div className="content">
					<CategoriesData categoriesStore={[state, setState]}/>
				</div>
				<div className="sidebar">
					<div className="scrollbar">
						<CategoriesStats categoriesStore={[state, setState]}/>
					</div>
				</div>
			</div>
			<Portrait />
		</>
	)
}

export default Categories
