import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import reactDebugHooks from 'react-debug-hooks'
import {AppContextProvider} from './context'

reactDebugHooks(React)

ReactDOM.render(
    <AppContextProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </AppContextProvider>,
    document.getElementById('root')
);
