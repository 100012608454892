import React, { useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import styled from 'styled-components'
import AdeleteConfirm from './AdeleteConfirm'
import Ratio from 'react-ratio';

export default ({
                    value = null,
                    onChange = f=>f,
                }) => {
    const sigRef = React.useRef({})


    React.useEffect(() => {
        value && sigRef.current.fromDataURL(value)
    }, [value])

    const clear = () => {
        onChange(null)
        sigRef.current.clear()
    }

    const save = () => {
        onChange(sigRef.current.toDataURL("image/png"))
    }

    return (
        <>
            <Ratio ratio={ 16 / 9 }   css={`
            .signature-pad {
                width: 100%;
                height: 100%;
                background: #f8f8f8;
                margin: unset;
            }
            .tbl-btn {
                position: absolute;
                top: .1rem;
                right: .1rem;
                opacity: .5 !important;
                
                z-index: 1;
            }
        `}>
                <AdeleteConfirm
                    title="Are you sure you want to delete the signature?"
                    onConfirm={clear}
                    okText="Delete"
                >
                    <div className="tbl-btn"><i className="icon-refresh-cw"></i>
                        <div className="tooltip tooltip-bottom">Clear</div>
                    </div>
                </AdeleteConfirm>
                <SignatureCanvas
                    onEnd={save}
                    clearOnResize={false}
                    ref={sigRef}
                    penColor='black'
                    canvasProps={{
                        className: "signature-pad"
                    }}
                />
            </Ratio>
        </>
    )
}