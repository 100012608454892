import React from 'react'

import LivePreview from './LivePreview.idCardExtractor'
import {IdCardExtractorEditor} from './Editor.idCardExtractor'
import CodeCopy from './CodeCopy.idCardExtractor'
import View from './View.IdCardExtractor'
import Input from './Input.idCardExtractor'

export default {
    livePreview: args => <LivePreview {...args}/>,
    editor: args => <IdCardExtractorEditor {...args}/>,
    codeCopy: args => <CodeCopy {...args}/>,
    input: args => <Input {...args}/>,

    default: () => ({
        type: "id card extractor",
        text: "Id card extractor title",
        pinned: {
            status: false
        },
        step1: "STEP 1",
        uploadTakePictureLabel: "UPLOAD / TAKE PICTURE",
        uploadImageButton: "Upload Image Button",
        cameraImageButton: "Camera Image Button",
        step2: "STEP 2",
        previewUploadedImage: "PREVIEW UPLOADED IMAGE",
        uploadImageAgainButton: "Upload Image Again Button",
        getIdInformationButton: "Get Id Information Button",
        step3: "STEP 3",
        cardInformation: "CARD INFORMATION",
        uploadAnotherImageButton: "Upload Another Image Button",
        validations: []
    }),
    
    availableValidations: ['skip first two steps'],
    validate: () => false,
    group: "ADVANCED",
    icon: "icon-ia-id",
    name: "Id Card Info Extractor",
    view: args => <View {...args}/>
}