import React from "react";
import ApopUp from "../../../components/ApopUp";
import {LemailList} from "../../../components/LemailList";
import styled from 'styled-components';
import API from "../../../api";

import {AlinkButton} from "../../../components/AlinkButton";
import {AiconButton} from "../../../components/AiconButton";
import Loading from "../../../components/Loading";
import {notification} from "../../../components/Lnotification";


const InviteUsers = styled(({className}) => {

    const [state, setState] = React.useState({
        loading: false,
        modalVisible: false,
        role: 'agent',
        forceLoginWith: 'google'
    }, 'invite-users')

    const [emailListState, setEmailListState] = React.useState({
        email: '',
        emails: [],
        error: null
    })


    const openModal = () => {
        setState(state => ({...state, modalVisible: true}))
    }

    const closeModal = () => {
        setState(state => ({...state, modalVisible: false}))
        setEmailListState({ email: '', emails: [], error: null })
    }

    const setForceLoginWith = forceLoginWith => {
        setState(state => ({...state, forceLoginWith}))
    }

    const setRole = role => {
        setState(state => ({...state, role}))
    }

    const inviteUsers = async () => {

        const invitationsPayload = {
            emailAddresses: emailListState.emails,
            role: state.role,
            forceLoginWith: state.forceLoginWith,
        }

        setState(state => ({...state, loading: true}))

        try {

            const response = await API.post(
                `${process.env.REACT_APP_API_BASE_URL}/users/login-invitations`,
                invitationsPayload
            )

            setEmailListState({ email: '', emails: [], error: null })

        } catch(err) {
            console.log(err);
            setState(state => ({...state, loading: false}))
            throw err;
        }

        setState(state => ({...state, loading: false, modalVisible: false}))

        notification.success({
            message: "Invitations sent",
        })

    }

    const validateAsync = async (email) => {

        try {
            await API.get(
                `${process.env.REACT_APP_API_BASE_URL}/users/login-invitations/validate-email/${email}`
            )

            return true
        } catch(err) {
            console.log(err)

            console.log("response: ", err.response)

            if(err?.response?.data?.error === 'email_already_registered') {
                setEmailListState(emailListState => ({...emailListState, error: `${email} already registered!`}))
            }

            if(err?.response?.data?.error === 'invitation_already_sent') {
                setEmailListState(emailListState => ({...emailListState, error: `${email} already invited!`}))
            }

            return false
        }

    }

    const isFormFilledCorrectly = emailListState.emails.length > 0

    return (
        <>
            <a className="button with-icon-right" onClick={openModal}>
                <i className="icon-plus"></i>
                Invite n users
            </a>
            <ApopUp
                visible={state.modalVisible}
                onCancel={closeModal}
                title="Invite users"
                className={className}
                okText={`Invite ${emailListState.emails.length || ''} user${(emailListState.emails.length > 1) ? 's' : ''}`}
                onConfirm={inviteUsers}
                footer={(
                    <>
                        <AiconButton
                            onClick={inviteUsers}
                            icon="icon-ia-checked-outline"
                            disabled={!isFormFilledCorrectly || state.loading}
                        >
                            Invite {emailListState.emails.length || ''} user{(emailListState.emails.length > 1) ? 's' : ''}
                        </AiconButton>

                        <AlinkButton onClick={closeModal} disabled={state.loading}>
                            Cancel
                        </AlinkButton>
                    </>
                )}
            >

                {state.loading ? <Loading /> :
                    <div className="form">
                        <LemailList
                            value={emailListState}
                            changeValue={setEmailListState}
                            label="Email list"
                            validateAsync={validateAsync}
                        />

                        <div className="form-row">
                            <label>Force login using</label>
                            <select
                                value={state.forceLoginWith}
                                onChange={({target: {value}}) => setForceLoginWith(value)}
                            >
                                <option value="google">Google</option>
                            </select>
                        </div>

                        <div className="form-row">
                            <label>Role</label>
                            <select
                                value={state.role}
                                onChange={({target: {value}}) => setRole(value)}
                            >
                                <option value="agent">User</option>
                                {/*<option value="manager">Manager</option>*/}
                            </select>
                        </div>
                    </div>
                }
            </ApopUp>
        </>
    );

})`
  
  .overlay-body {
    padding: 2rem;
  }
  
`



export {InviteUsers}