import axios from "axios"
import { getStore } from "./context"

const API = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL })

API.interceptors.request.use(
	async config => {
		const {
			auth: [auth],
		} = getStore()

		// if we do not have token
		if (!auth) {
			return config
		}

		// we do have token

		const { expirationTime } = auth
		// if token has not expired
		if (expirationTime > Date.now()) {
			const { access_token: token } = auth
			config.headers.Authorization = `Bearer ${token}`
			config.headers['client-timezone'] = new window.Intl.DateTimeFormat().resolvedOptions().timeZone
			return config
		} else {
			// return config without token
			return config
		}
	},
	function (error) {
		return Promise.reject(error)
	}
)

API.interceptors.response.use(
	response => {
		return response // Do something with response data
	},
	error => {
		if (
			error.request?.responseType === "blob" &&
			error.response?.data instanceof Blob &&
			error.response?.data?.type &&
			error.response?.data?.type.toLowerCase().indexOf("json") !== -1
		) {
			return new Promise((resolve, reject) => {
				let reader = new FileReader()
				reader.onload = () => {
					error.response.data = JSON.parse(reader.result)
					resolve(Promise.reject(error))
				}

				reader.onerror = () => {
					reject(error)
				}

				reader.readAsText(error.response.data)
			})
		}

		return Promise.reject(error)
	}
)

export default API
